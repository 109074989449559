import clsx from "clsx";
import React from "react";

import useComputerGroupDetail from "../../hooks/useComputerGroupDetail";
import Button from "../Button";
import { ComputerMultiSelect } from "../common/ComputerMultiSelect";
import { Icon } from "../Icon";
import TextArea from "../TextArea";
import TextField from "../TextField";

interface ComputerGroupDetailProps {
  id?: number;
  onClose: () => void;
  onSaved: () => void;
}

const ComputerGroupDetail: React.FC<ComputerGroupDetailProps> = (
  props: ComputerGroupDetailProps,
) => {
  const {
    isLoading,
    onSubmit,
    isEditing,
    handleInputChange,
    handleCancel,
    form,
    t,
    isCurrentUserReadOnly,
  } = useComputerGroupDetail({
    id: Number(props.id),
    onCancel: props.onClose,
    onSaved: props.onSaved,
  });
  return (
    <form
      onSubmit={onSubmit}
      className="flex h-full flex-col justify-between space-y-4"
    >
      <div className="flex flex-grow flex-col space-y-4 divide-y divide-medium-gray">
        <div className="flex w-full flex-row items-start justify-start gap-4 px-8 py-4">
          <div className="flex w-full flex-col space-y-4">
            <div className="flex flex-row items-center justify-start gap-4">
              <Icon
                name="GroupOfUsersIcon"
                className="h-8 w-8 text-dark-blue"
              />
              <span
                className={clsx(
                  "text-xl font-medium text-dark-blue",
                  isLoading && "blur-sm",
                )}
              >
                {isEditing
                  ? form.name || "Edit computer group"
                  : form.name || "Add new computer group"}
              </span>
            </div>
            <p>
              {isEditing
                ? t("common.computerGroupUpdateDescription")
                : t("common.computerGroupCreateDescription")}
            </p>
            <div className="grid w-full grid-cols-1 gap-3">
              <TextField
                isReadOnly={isCurrentUserReadOnly}
                name="name"
                type="text"
                label="Name"
                value={form.name}
                placeholder="Choose a name for the group"
                isRequired
                required
                isDisabled={isLoading}
                onChange={(e) => handleInputChange("name", e)}
              />
              <TextArea
                isReadOnly={isCurrentUserReadOnly}
                name="description"
                label="Description"
                value={form.description}
                isDisabled={isLoading}
                onChange={(e) => handleInputChange("description", e)}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 px-8 py-4">
          <span className="text-xl font-medium text-dark-blue">
            {t("common.assignedComputers")} ({form?.computers?.length})
          </span>
          <ComputerMultiSelect
            includeGroups={false}
            id="computers"
            disabled={isLoading || isCurrentUserReadOnly}
            selectedComputers={form.computers}
            onComputerChange={(e) => {
              handleInputChange("computers", e);
            }}
            onComputerRemove={(e) => {
              handleInputChange(
                "computers",
                form.computers?.filter((item) => item.id !== e.id),
              );
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2">
        <div>
          <Button
            onPress={handleCancel}
            type="button"
            isDisabled={isLoading}
            variant="text"
          >
            Cancel
          </Button>
        </div>
        <div>
          <Button
            type="submit"
            isDisabled={isCurrentUserReadOnly}
            isLoading={isLoading}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ComputerGroupDetail;
