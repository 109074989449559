import {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../contexts/AuthContext";
import { useNavigationDirty } from "../contexts/NavigationDirtyContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { usePendingComputerUpdate } from "../contexts/PendingComputerUpdateContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import Computer from "../interfaces/Computer";
import User from "../interfaces/User";
import { ComputerGroupService } from "../services/computerGroupService";

import { useNotifications } from "./useNotifications";

const computerGroupService = new ComputerGroupService();

interface Props {
  id: string | number;
  onSaved: () => void;
  onCancel: () => void;
}

interface FormState {
  name: string;
  description: string;
  users: User[];
  computers: Computer[];
}

function useComputerGroupDetail({ id, onSaved, onCancel }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isRightPanelOpen } = useContext(
    RightPanelContext,
  ) as RightPanelContextProps;
  const [form, setForm] = useState<FormState>({
    computers: [],
    description: "",
    name: "",
    users: [],
  });
  const { createNotification } = useNotifications();
  const { t } = useTranslation();
  const { setDirty, confirmDirtyNavigation } = useNavigationDirty();
  const { setHasPendingUpdates } = usePendingComputerUpdate();
  const { isCurrentUserReadOnly } = useAuth();

  /**
   * Resets the form fields to their initial values.
   *
   * @function
   * @name resetForm
   * @returns {void}
   */
  const resetForm = (): void => {
    setForm({
      computers: [],
      description: "",
      name: "",
      users: [],
    });
  };

  /**
   * Handles the cancel event.
   *
   * This function performs the following steps:
   *  1. Calls the `confirmDirtyNavigation` function asynchronously to confirm dirty navigation.
   *  2. If the confirmation is true, calls the `resetForm` function and the `onCancel` function.
   *
   * @returns {Promise<void>} A promise that resolves to undefined.
   */
  const handleCancel = async (): Promise<void> => {
    const confirm = await confirmDirtyNavigation();
    if (confirm) {
      resetForm();
      onCancel();
    }
  };

  /**
   * Updates the form field value, based on the field type.
   * @param {string} field - The name of the field to update.
   * @param {any} value - The new value for the field.
   * @returns {void}
   */
  const handleInputChange = (field: string, value: any): void => {
    switch (field) {
      default:
        setForm((prevForm) => ({ ...prevForm, [field]: value }));
        break;
    }
    setDirty(true);
  };

  /**
   * @desc Handles the form submission
   * @param e
   */
  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    if (id) {
      await computerGroupService
        .updateComputerGroup(id, { ...form })
        .then(() => {
          createNotification(
            "Computer group updated successfully",
            NotificationTypes.SUCCESS,
          );
          setDirty(false);
          setHasPendingUpdates(true);
          onSaved();
        })
        .catch(() => {
          createNotification(
            t("errors.genericUpdateError", {
              resource: t("common.computerGroup"),
            }),
            NotificationTypes.DANGER,
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await computerGroupService
        .createComputerGroup({ ...form })
        .then(() => {
          createNotification(
            "Computer group created successfully",
            NotificationTypes.SUCCESS,
          );
          setDirty(false);
          setHasPendingUpdates(true);
          resetForm();
          onSaved();
        })
        .catch(() => {
          createNotification(
            t("errors.genericCreateError", {
              resource: t("common.computerGroup"),
            }),
            NotificationTypes.DANGER,
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  /**
   * @desc Fetches user data from the API
   */
  const getUserGroup = useCallback(async () => {
    setIsLoading(true);
    try {
      const [computerGroupRes] = await Promise.all([
        computerGroupService.getComputerGroup(id),
      ]);
      setForm({
        computers: computerGroupRes.computers || [],
        description: computerGroupRes.description,
        name: computerGroupRes.name,
        users: computerGroupRes.users || [],
      });
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  /**
   * @desc Fetches user data from the API when the id changes
   */
  useEffect(() => {
    if (id) {
      getUserGroup().then(() => setIsLoading(false));
    } else {
      resetForm();
      setIsLoading(false);
    }
  }, [getUserGroup, id]);

  const isEditing = useMemo(() => {
    return !!id;
  }, [id]);

  useEffect(() => {
    if (!isRightPanelOpen) {
      resetForm();
    }
  }, [isRightPanelOpen]);

  return {
    form,
    handleCancel,
    handleInputChange,
    isCurrentUserReadOnly,
    isEditing,
    isLoading,
    onSubmit,
    setForm,
    t,
  };
}

export default useComputerGroupDetail;
