import React from "react";
import type { AriaProgressBarProps } from "react-aria";
import { useProgressBar } from "react-aria";

interface ProgressCircleProps extends AriaProgressBarProps {
  isIndeterminate: boolean;
  value?: number;
  minValue?: number;
  maxValue?: number;
}

const ProgressCircle: React.FC<ProgressCircleProps> = (props) => {
  const { isIndeterminate, value, minValue = 0, maxValue = 100 } = props;
  const { progressBarProps } = useProgressBar(props);

  const center = 16;
  const strokeWidth = 4;
  const r = 16 - strokeWidth;
  const c = 2 * r * Math.PI;
  const percentage = isIndeterminate
    ? 0.25
    : (value || 0 - minValue) / (maxValue - minValue);
  const offset = c - percentage * c;

  return (
    <svg
      {...progressBarProps}
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      strokeWidth={strokeWidth}
    >
      <circle
        role="presentation"
        cx={center}
        cy={center}
        r={r}
        stroke="#E0E0E0"
      />
      <circle
        role="presentation"
        cx={center}
        cy={center}
        r={r}
        stroke="#0C4F7D"
        strokeDasharray={`${c} ${c}`}
        strokeDashoffset={offset}
        transform="rotate(-90 16 16)"
      >
        {props.isIndeterminate && (
          <animateTransform
            attributeName="transform"
            type="rotate"
            begin="0s"
            dur="1s"
            from="0 16 16"
            to="360 16 16"
            repeatCount="indefinite"
          />
        )}
      </circle>
    </svg>
  );
};

export default ProgressCircle;
