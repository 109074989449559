import qs from "qs";
import { Key } from "react";

import { APIService, RequestBody } from "../api/apiService";

export class ComputerService extends APIService {
  /**
   * @description Fetches all users
   */
  getComputer(id: string | number) {
    return this.request(`/Admin/Computer/${id}`, "GET");
  }

  /**
   * @description Get computer tamper code
   */
  getComputerCode(id: string | number) {
    return this.request(`/Admin/Computer/${id}/Code`, "GET");
  }

  /**
   * @description Get computer proxy unclock code
   */
  getComputerProxyUnlockCode(id: string | number) {
    return this.request(`/Admin/Computer/${id}/GetProxyUnlockCode`, "GET");
  }

  /**
   * @description Fetch computer groups
   * @param id
   */
  getComputerGroups(id: string | number) {
    return this.request(`/Admin/Computer/${id}/Groups`, "GET");
  }

  /**
   * @desc Fetch computer users
   * @param id
   */
  getComputerUsers(id: string | number) {
    return this.request(`/Admin/Computer/${id}/Users`, "GET");
  }

  /**
   * @description Fetches all computers
   * @param query Query string params
   */
  getComputers(query?: string | null) {
    return this.request(`/Admin/Computer?${query}`, "GET");
  }

  /**
   * @description Update computer
   */
  updateComputer(id: string | number, payload: RequestBody) {
    return this.request(`/Admin/Computer/${id}`, "PUT", payload);
  }

  /**
   * @description Delete computers
   */
  deleteComputer(query: string) {
    return this.request(`/Admin/Computer?${query}`, "DELETE");
  }

  /**
   * Changes the status of one or more computers.
   *
   * @return {Promise} A Promise that resolves to the result of the request.
   * @param params
   */
  changeComputerStatus(params: {
    id?: Key[];
    enable?: boolean;
    blocked?: boolean;
  }): Promise<any> {
    const query = qs.stringify(params, { arrayFormat: "comma" });
    return this.request(`/Admin/Computer/ChangeStatus?${query}`, "PUT");
  }
}
