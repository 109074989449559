import clsx from "clsx";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { DashboardFilterState } from "../../hooks/useDashboard";
import {
  convertDateToTicks,
  convertTicksToCalendarDateTime,
} from "../../utils/utils";
import ProgressBar from "../ProgressBar";
import { TimespanDropdown } from "../TimespanDropdown";

import DashboardItem from "./DashboardItem";

interface Props {
  data: {
    high: number;
    medium: number;
    low: number;
  };
  filters: DashboardFilterState;
  onFilterChange: (e: any) => void;
}

interface CounterProps {
  count: number;
  label: string;
  link: string;
  backgroundColor: string;
  color: string;
}

const Counter = ({
  count,
  label,
  link,
  color,
  backgroundColor,
}: CounterProps) => {
  return (
    <div className="flex w-full flex-row items-center gap-4">
      <div
        className={clsx(
          "flex min-w-[110px] flex-shrink-0 rounded-md border border-gray p-6",
          backgroundColor,
        )}
      >
        <span className={clsx("m-auto text-4xl font-bold", color)}>
          {count > 99 ? "99+" : count}
        </span>
      </div>
      <Link to={link} className="underline">
        {label}
      </Link>
    </div>
  );
};

const DashboardCountersItem: React.FC<Props> = ({
  data,
  onFilterChange,
  filters,
}: Props) => {
  //const { t } = useTranslation();

  const total = useMemo(() => data.high + data.medium + data.low, [data]);
  const highPercentage = useMemo(
    () => Math.round((data?.high / total) * 100),
    [data?.high, total],
  );
  const mediumPercentage = useMemo(
    () => Math.round((data.medium / total) * 100),
    [data?.medium, total],
  );
  const lowPercentage = useMemo(
    () => Math.round((data.low / total) * 100),
    [data?.low, total],
  );

  return (
    <DashboardItem header={<></>}>
      <div className="grid h-full grid-cols-1 gap-4 p-4 md:grid-cols-2">
        <div className="flex h-full flex-1 flex-col gap-4 rounded-md border border-gray">
          <div className="flex flex-row items-center justify-between rounded-t-md border-b border-gray px-2 py-2">
            <TimespanDropdown
              wrapperClassName="bg-white justify-between"
              key="timespan"
              initialOption={filters.timespanOption}
              initialCustomFrom={convertTicksToCalendarDateTime(
                filters.dateStart,
              )}
              initialCustomTo={convertTicksToCalendarDateTime(filters.dateEnd)}
              onChange={(from, to, option) => {
                onFilterChange({
                  dateEnd: convertDateToTicks(to),
                  dateStart: convertDateToTicks(from),
                  timespanOption: option,
                });
              }}
            />
          </div>
          <div className="flex flex-1 flex-col items-center justify-center gap-4 p-4">
            <span className="text-6xl text-dark-blue">{total}</span>
            <span className="text-sm text-extra-dark-gray">Total alerts</span>
            <div className="relative mx-5 w-full pt-1">
              <ProgressBar
                segments={[
                  {
                    backgroundColor: "bg-dark-red",
                    label: "High",
                    percentage: highPercentage || 0,
                  },
                  {
                    backgroundColor: "bg-[#FFBA08]",
                    label: "Medium",
                    percentage: mediumPercentage || 0,
                  },
                  {
                    backgroundColor: "bg-steel-blue",
                    label: "Low",
                    percentage: lowPercentage || 0,
                  },
                ]}
              />
            </div>
            <Link
              className="w-full rounded-lg bg-dark-blue px-4 py-2 text-center text-white transition-all duration-200 ease-in-out hover:opacity-90"
              to={`/activities?dateStart=${filters.dateStart}&dateEnd=${filters.dateEnd}&timespanOption=${filters.timespanOption}`}
            >
              VIEW
            </Link>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4">
          <Counter
            count={data.high}
            label="High-priority alerts"
            link={`/activities?severity=high&notRead=true&dateStart=${filters.dateStart}&dateEnd=${filters.dateEnd}&timespanOption=${filters.timespanOption}`}
            color="text-dark-red"
            backgroundColor="bg-[#ffe4e4]"
          />
          <Counter
            count={data.medium}
            label="Medium-priority alerts"
            link={`/activities?severity=medium&notRead=true&dateStart=${filters.dateStart}&dateEnd=${filters.dateEnd}&timespanOption=${filters.timespanOption}`}
            color="text-[#FFBA08]"
            backgroundColor="bg-[#FFF9D6]"
          />
          <Counter
            count={data.low}
            label="Low-priority alerts"
            link={`/activities?severity=low&notRead=true&dateStart=${filters.dateStart}&dateEnd=${filters.dateEnd}&timespanOption=${filters.timespanOption}`}
            color="text-steel-blue"
            backgroundColor="bg-light-blue"
          />
        </div>
      </div>
    </DashboardItem>
  );
};
export default DashboardCountersItem;
