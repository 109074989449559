import React from "react";
import { CSSTransition } from "react-transition-group";

import LogoVertical from "../../assets/logo-vertical.svg";
import ProgressCircle from "../ProgressCircle";

interface Props {
  isVisible: boolean;
}

function Preloader({ isVisible }: Props) {
  return (
    <CSSTransition
      in={isVisible}
      unmountOnExit
      timeout={{ enter: 0, exit: 250 }}
      classNames={{
        enter: "opacity-0 translate-y-1",
        enterDone: "opacity-1 translate-y-0 transition ease-in",
        exit: "opacity-0 -translate-y-10 transition ease-out",
      }}
    >
      <div className="fixed inset-0 z-[9999] flex h-full w-full flex-1 items-center justify-center">
        <div
          className="relative hidden h-full w-0 max-w-[600px] flex-1 items-center justify-center
      bg-gradient-to-b from-base-gradient-0 via-base-gradient-1 to-base-gradient-2 lg:flex"
        >
          <LogoVertical className="absolute h-full w-full max-w-[450px] p-4" />
        </div>
        <div className="flex h-full w-full flex-1 flex-grow flex-col justify-center bg-extra-light-gray px-4 py-12 sm:px-6 lg:px-20 xl:px-24">
          <div className="mx-auto flex w-full max-w-2xl flex-col items-center justify-center gap-8">
            <h1 className="bg-gradient-to-b from-base-gradient-0 via-base-gradient-1 to-base-gradient-2 bg-clip-text text-transparent">
              We are loading your content
            </h1>
            <div className="flex w-full max-w-lg items-center justify-center">
              <ProgressCircle
                aria-label="Loading..."
                aria-labelledby="Loading..."
                aria-loading="Loading..."
                isIndeterminate
              />
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

export default Preloader;
