import clsx from "clsx";
import React from "react";

import { Icon } from "./Icon";

interface SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
  position?: "left" | "right";
  className?: string;
  children?: React.ReactNode;
}

const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  onToggle,
  position,
  className,
  children,
}) => {
  return (
    <aside
      className={clsx(
        "fixed top-0 z-50 flex min-h-full flex-col shadow-lg transition-all duration-300",
        isOpen ? "w-72" : "w-20",
        position === "right" ? "right-0" : "left-0",
        className,
      )}
    >
      <div className="border-gray-200 flex grow flex-col gap-y-5 overflow-y-auto border-r bg-dark-blue pb-4 text-white">
        <div
          className={clsx(
            "flex h-16 w-full flex-row items-center",
            isOpen ? "pl-6" : "justify-center",
          )}
        >
          <button
            aria-label="Toggle sidebar"
            onClick={onToggle}
            className="flex shrink-0 items-center text-white"
          >
            <Icon
              name="ArrowRightIcon"
              className={clsx(
                "m-auto w-full transition-all duration-200 ease-in-out",
                isOpen ? "rotate-180" : "rotate-0",
              )}
            />
          </button>
        </div>
        {children}
      </div>
    </aside>
  );
};

export default Sidebar;
