import clsx from "clsx";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Button from "../components/Button";
import { Icon } from "../components/Icon";
import TextField from "../components/TextField";
import { StorageItemKeyEnum } from "../enum/StorageItemKeyEnum";
import { AuthService } from "../services/authService";
import { getStorageItem, storeStorageItem } from "../utils/storage";

const authService = new AuthService();

export const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState(t("common.next"));
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [form, setForm] = useState({ username: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const savedTimestamp = getStorageItem(
      "localStorage",
      StorageItemKeyEnum.passwordForgotTimer,
    );
    const parsedTimestamp = Number(savedTimestamp);

    if (!isNaN(parsedTimestamp)) {
      const currentTime = Date.now();
      const remainingTime = parsedTimestamp - currentTime;
      if (remainingTime > 0) {
        startTimer(remainingTime / 1000);
      }
    }
  }, []);

  /**
   * This function starts a timer for a given duration.
   * It displays and updates the button text with the remaining time.
   * When the timer reaches zero, it updates the button text and enables it again.
   * It also stores the end timestamp in local storage.
   *
   * @param {number} duration - The duration of the timer in seconds.
   */
  const startTimer = (duration: number) => {
    let counter = duration;
    setButtonDisabled(true);
    setButtonText(`${t("common.requestNewLink")} (${Math.ceil(counter)})`);
    const intervalId = setInterval(() => {
      counter--;
      setButtonText(`${t("common.requestNewLink")} (${Math.ceil(counter)})`);
      if (counter <= 0) {
        clearInterval(intervalId);
        setButtonText(t("common.requestNewLink"));
        setButtonDisabled(false);
        storeStorageItem(
          "localStorage",
          StorageItemKeyEnum.passwordForgotTimer,
          null,
        );
      }
    }, 1000);
    const endTimestamp = Date.now() + counter * 1000;
    storeStorageItem(
      "localStorage",
      StorageItemKeyEnum.passwordForgotTimer,
      endTimestamp,
    );
  };

  /**
   * Handles the form submission for password reset.
   *
   * @param {FormEvent} e - The form submission event.
   * @returns {void}
   */
  const handleSubmit = (e: FormEvent): void => {
    setIsLoading(true);
    e.preventDefault();
    authService
      .forgotPassword({ username: form.username })
      .then(() => {
        setSuccessMessage(t("notifications.resetPasswordEmailSent"));
        setErrorMessage("");
        startTimer(30);
      })
      .catch((err) => {
        if (err?.response?.errorCode === 5) {
          setErrorMessage(t("errors.invalidUsername"));
        } else {
          setErrorMessage(t("errors.genericError"));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="pt-2">
      <h2 className="text-lg text-extra-dark-gray">
        {t("auth.forgotPasswordSubtitle")}
      </h2>
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        className="mt-20 flex h-full w-full flex-col items-start justify-start"
      >
        <div className="flex w-full flex-col gap-4">
          <TextField
            id="email"
            aria-disabled={isLoading}
            aria-label={t("common.username")}
            isDisabled={isLoading}
            name="username"
            type="text"
            placeholder={t("common.username")}
            value={form.username}
            isRequired
            required
            variant="secondary"
            onChange={(e) => setForm({ ...form, username: e })}
          />
          <div className="mt-4 flex h-[24px] flex-row items-center justify-start text-red">
            {errorMessage ? (
              <>
                <Icon name="ErrorIcon" className="visible h-5 w-5" />
                <span className="ml-2 text-red">{errorMessage}</span>
              </>
            ) : (
              <span className="ml-2 text-green">{successMessage}</span>
            )}
          </div>
          <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
            <div className="flex items-center justify-center">
              <Link
                aria-disabled={isLoading}
                to="/login"
                className={clsx(
                  "text-center text-lg text-dark-blue underline",
                  isLoading && "pointer-events-none opacity-50",
                )}
              >
                {t("common.back")}
              </Link>
            </div>
            <Button
              size="large"
              type="submit"
              isLoading={isLoading}
              isDisabled={buttonDisabled}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
