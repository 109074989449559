import { PolicyTypeEnum } from "../enum/PolicyTypeEnum";
import Policy from "../interfaces/Policy";

import { translate } from "./translation";

export const policies: Policy[] = [
  {
    description: translate("policies.BlockPort.description"),
    hasComputers: true,
    hasNotification: false,
    hasUsers: false,
    icon: "HandRaiseIcon",
    id: PolicyTypeEnum.BlockPort,
    isDisabled: false,
    key: "BlockPort",
    name: translate("policies.BlockPort.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.BlockPort,
  },
  {
    description: translate("policies.PortRDP.description"),
    hasComputers: true,
    hasNotification: false,
    hasUsers: false,
    icon: "ChangeRdpPortIcon",
    id: PolicyTypeEnum.PortRDP,
    isDisabled: false,
    key: "PortRDP",
    name: translate("policies.PortRDP.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.PortRDP,
  },
  /*{
    description: translate("policies.SessionExpiry.description"),
    hasComputers: true,
    hasNotification: false,
    hasUsers: false,
    icon: "ExpiringSessionIcon",
    id: PolicyTypeEnum.SessionExpiry,
    isDisabled: false,
    key: "SessionExpiry",
    name: translate("policies.SessionExpiry.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.SessionExpiry,
  },*/
  /*{
    description: translate("policies.Activities.description"),
    hasComputers: true,
    hasNotification: false,
    hasUsers: false,
    icon: "ActivityIcon",
    id: PolicyTypeEnum.Activities,
    isDisabled: false,
    key: "Activities",
    name: translate("policies.Activities.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.Activities,
  },*/
  {
    description: translate("policies.DeleteFile.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "DeleteIcon",
    id: PolicyTypeEnum.DeleteFile,
    isDisabled: false,
    key: "DeleteFile",
    name: translate("policies.DeleteFile.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.DeleteFile,
  },
  {
    description: translate("policies.FileAccess.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "FileAccessLimitationIcon",
    id: PolicyTypeEnum.FileAccessApplication,
    isDisabled: false,
    key: "FileAccessApplication",
    name: translate("policies.FileAccess.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.FileAccessApplication,
  },
  {
    description: translate("policies.DownloadFile.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "FileDownloadIcon",
    id: PolicyTypeEnum.DownloadFile,
    isDisabled: false,
    key: "DownloadFile",
    name: translate("policies.DownloadFile.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.DownloadFile,
  },
  {
    description: translate("policies.Network.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "NetworkIcon",
    id: PolicyTypeEnum.Network,
    isDisabled: false,
    key: "Network",
    name: translate("policies.Network.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.Network,
  },
  {
    description: translate("policies.Obfuscator.description"),
    hasComputers: true,
    hasNotification: false,
    hasUsers: false,
    icon: "CopyPasteIcon",
    id: PolicyTypeEnum.Obfuscator,
    isDisabled: false,
    key: "Obfuscator",
    name: translate("policies.Obfuscator.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.Obfuscator,
  },
  {
    description: translate("policies.SharedFolder.description"),
    hasComputers: true,
    hasNotification: false,
    hasUsers: false,
    icon: "SharedFoldersIcon",
    id: PolicyTypeEnum.SharedFolder,
    isDisabled: false,
    key: "SharedFolder",
    name: translate("policies.SharedFolder.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.SharedFolder,
  },
  {
    description: translate("policies.UploadNewFileExe.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "UploadExecutableFileIcon",
    id: PolicyTypeEnum.UploadNewFileExe,
    isDisabled: false,
    key: "UploadNewFileExe",
    name: translate("policies.UploadNewFileExe.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.UploadNewFileExe,
  },
  /*{
    description: translate("policies.Country.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "GeolocationIcon",
    id: PolicyTypeEnum.Country,
    isDisabled: false,
    key: "Country",
    name: translate("policies.Country.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.Country,
  },*/
  {
    description: translate("policies.DateTimePlan.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "CalendarIcon",
    id: PolicyTypeEnum.DateTimePlan,
    isDisabled: false,
    key: "DateTimePlan",
    name: translate("policies.DateTimePlan.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.DateTimePlan,
  },
  {
    description: translate("policies.Expiry.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "ExpiredIcon",
    id: PolicyTypeEnum.Expiry,
    isDisabled: false,
    key: "Expiry",
    name: translate("policies.Expiry.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.Expiry,
  },
  {
    description: translate("policies.RangeIPAddress.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "AllowedIpAddressIcon",
    id: PolicyTypeEnum.RangeIPAddress,
    isDisabled: false,
    key: "RangeIPAddress",
    name: translate("policies.RangeIPAddress.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.RangeIPAddress,
  },
  {
    description: translate("policies.WithoutRDP.description"),
    hasComputers: true,
    hasNotification: false,
    hasUsers: false,
    icon: "FacingChevronsIcon",
    id: PolicyTypeEnum.WithoutRDP,
    isDisabled: false,
    key: "WithoutRDP",
    name: translate("policies.WithoutRDP.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.WithoutRDP,
  },
  {
    description: translate("policies.MultiUsersRDP.description"),
    hasComputers: true,
    hasNotification: false,
    hasUsers: false,
    icon: "MultiuserRdpIcon",
    id: PolicyTypeEnum.MultiUsersRDP,
    isDisabled: false,
    key: "MultiUsersRDP",
    name: translate("policies.MultiUsersRDP.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.MultiUsersRDP,
  },
  {
    description: translate("policies.WindowsAccess.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "WindowsIcon",
    id: PolicyTypeEnum.WindowsAccess,
    isDisabled: false,
    key: "WindowsAccess",
    name: translate("policies.WindowsAccess.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.WindowsAccess,
  },
  {
    description: translate("policies.ExternalDrives.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "DriveBlockIcon",
    id: PolicyTypeEnum.ExternalDrives,
    isDisabled: false,
    key: "ExternalDrives",
    name: translate("policies.ExternalDrives.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.ExternalDrives,
  },
  {
    description: translate("policies.AccessFolders.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "FolderLockIcon",
    id: PolicyTypeEnum.AccessFolders,
    isDisabled: false,
    key: "AccessFolders",
    name: translate("policies.AccessFolders.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.AccessFolders,
  },
  {
    description: translate("policies.ProxyControl.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "ProxyIcon",
    id: PolicyTypeEnum.ProxyControl,
    isDisabled: false,
    key: "ProxyControl",
    name: translate("policies.ProxyControl.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.ProxyControl,
  },
  {
    description: translate("policies.EncryptionFile.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "FilegrantLogoIcon",
    id: PolicyTypeEnum.EncryptionFile,
    isDisabled: false,
    key: "EncryptionFile",
    name: translate("policies.EncryptionFile.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.EncryptionFile,
  },
  /*{
    description: translate("policies.SecureBrowser.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "FilegrantLogoIcon",
    id: PolicyTypeEnum.SecureBrowser,
    isDisabled: false,
    key: "SecureBrowser",
    name: translate("policies.SecureBrowser.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.SecureBrowser,
  },*/
  {
    description: translate("policies.AntiPhishing.description"),
    hasComputers: true,
    hasNotification: true,
    hasUsers: false,
    icon: "BugIcon",
    id: PolicyTypeEnum.AntiPhishing,
    isDisabled: false,
    key: "AntiPhishing",
    name: translate("policies.AntiPhishing.name"),
    securityPolicies: [],
    type: PolicyTypeEnum.AntiPhishing,
  },
];
