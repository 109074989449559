import Direction from "../interfaces/Direction";

import { translate } from "./translation";

export const directions: Direction[] = [
  {
    id: "0",
    value: translate("common.inbound"),
  },
  {
    id: "1",
    value: translate("common.outbound"),
  },
  {
    id: "2",
    value: translate("common.both"),
  },
];
