import type { GridNode } from "@react-types/grid";
import React, { useRef } from "react";
import type { AriaTableSelectionCheckboxProps } from "react-aria";
import {
  useTableCell,
  useTableColumnHeader,
  useTableSelectAllCheckbox,
  useTableSelectionCheckbox,
  VisuallyHidden,
} from "react-aria";
import type { TableColumnResizeState, TableState } from "react-stately";

import Checkbox from "../Checkbox";

interface TableCheckboxCellProps<T> {
  cell: GridNode<T>;
  state: TableState<T>;
  layoutState: TableColumnResizeState<T>;
}

interface TableSelectAllCellProps<T> {
  column: GridNode<T>;
  state: TableState<T>;
  layoutState: TableColumnResizeState<T>;
}

export function TableCheckboxCell<T>(props: TableCheckboxCellProps<T>) {
  const { cell, state, layoutState } = props;
  const ref = useRef(null);
  const { gridCellProps } = useTableCell({ node: cell }, state, ref);
  const { checkboxProps } = useTableSelectionCheckbox(
    { key: cell.parentKey } as AriaTableSelectionCheckboxProps,
    state,
  );
  const column = cell.column;

  if (!column) {
    return null;
  }

  return (
    <td
      {...gridCellProps}
      style={{ width: `${layoutState.getColumnWidth(column.key)}px` }}
      className={`sticky left-0 box-border flex items-center justify-center border-r border-extra-light-gray bg-white`}
      ref={ref}
    >
      <Checkbox {...checkboxProps} />
    </td>
  );
}

export function TableSelectAllCell<T>(props: TableSelectAllCellProps<T>) {
  const { column, state, layoutState } = props;
  const ref = useRef(null);
  const { columnHeaderProps } = useTableColumnHeader(
    { node: column },
    state,
    ref,
  );
  const { checkboxProps } = useTableSelectAllCheckbox(state);

  return (
    <th
      {...columnHeaderProps}
      style={{ width: `${layoutState.getColumnWidth(column.key)}px` }}
      className={`sticky left-0 z-10 box-border flex items-center justify-center border-r border-extra-light-gray bg-white`}
      ref={ref}
    >
      {state.selectionManager.selectionMode === "single" ? (
        <VisuallyHidden>{checkboxProps["aria-label"]}</VisuallyHidden>
      ) : (
        <Checkbox {...checkboxProps} />
      )}
    </th>
  );
}
