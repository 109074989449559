import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { Icon } from "./Icon";

interface AccordionProps {
  multiple?: boolean;
  children?: React.ReactNode;
  initialOpenIndices?: number[];
}

interface AccordionItemProps {
  title: string;
  children: React.ReactNode;
  isOpen?: boolean;
  onToggle?: () => void;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  children,
  isOpen,
  onToggle,
}) => {
  const [style, setStyle] = useState({});

  return (
    <div
      className={clsx(
        "w-full border-b border-medium-gray transition-all duration-200 ease-in-out",
        isOpen && "flex-1",
      )}
    >
      <button
        type="button"
        className="z-40 flex w-full flex-row items-center justify-between px-8 py-4 text-left"
        onClick={onToggle}
      >
        <p className="text-xl font-medium text-dark-blue">{title}</p>
        <Icon
          name="ArrowDropDownIcon"
          className={clsx(
            "h-6 w-6 flex-shrink-0 transition-all duration-200 ease-in-out",
            isOpen ? "rotate-180" : "rotate-0",
          )}
        />
      </button>
      <CSSTransition
        in={isOpen}
        timeout={100}
        classNames="accordion-item"
        unmountOnExit
        onEntering={() => setStyle({ maxHeight: "0px", opacity: 0 })}
        onEntered={() =>
          setStyle({
            minHeight: "100%",
            opacity: 1,
            transition: "max-height 300ms ease-in, opacity 300ms ease-in",
          })
        }
        onExiting={() => setStyle({ maxHeight: "500px", opacity: 1 })}
        onExited={() =>
          setStyle({
            maxHeight: "0px",
            opacity: 0,
            transition: "max-height 300ms ease-in, opacity 300ms ease-in",
          })
        }
      >
        <div
          style={style}
          className="h-full flex-1 transform overflow-y-auto transition-all"
        >
          <div className="px-8 py-4">{children}</div>
        </div>
      </CSSTransition>
    </div>
  );
};

const Accordion: React.FC<AccordionProps> = ({
  multiple,
  children,
  initialOpenIndices = [],
}) => {
  const [openIndices, setOpenIndices] = useState<number[]>(initialOpenIndices);
  //const location = useLocation();

  const toggleItem = (index: number) => {
    if (multiple) {
      if (openIndices.includes(index)) {
        setOpenIndices(openIndices.filter((i) => i !== index));
      } else {
        setOpenIndices([...openIndices, index]);
      }
    } else {
      if (openIndices.includes(index)) {
        setOpenIndices([]);
      } else {
        setOpenIndices([index]);
      }
    }
  };

  const validAccordionItems = React.Children.toArray(children).filter(
    (child) =>
      React.isValidElement<AccordionItemProps>(child) &&
      child.type === AccordionItem,
  ) as React.ReactElement<AccordionItemProps>[];

  useEffect(() => {
    if (initialOpenIndices.length === 0) {
      setOpenIndices(initialOpenIndices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex flex-1 flex-grow flex-col overflow-y-auto">
        {validAccordionItems.map((child, index) => {
          return React.cloneElement(child, {
            isOpen: openIndices.includes(index),
            onToggle: () => toggleItem(index),
          });
        })}
      </div>
    </div>
  );
};

export { Accordion, AccordionItem };
