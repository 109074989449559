import Computer from "./Computer";

export enum SoftwareActivityTypeEnum {
  newRegisteredPC = 0, // Verde
  lostConnectionPC, // Rosso
  reconnectedPC, // Verde
  loginPC, // Verde
  logoffPC, // Giallo
  policyApplied, // Verde
  policyNotApplied, // Rosso
}

export default interface SoftwareActivity {
  id: number;
  createdOn: string;
  computer: Computer;
  type: SoftwareActivityTypeEnum;
  entity: any;
  entityId: number;
}
