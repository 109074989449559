import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import AdminActivitiesTab from "../components/activity/AdminActivitiesTab";
import SoftwareActivitiesTab from "../components/activity/SoftwareActivitiesTab";
import UserActivitiesTab from "../components/activity/UserActivitiesTab";
import { Item, Tabs } from "../components/Tabs";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";

export const Activities: React.FC = () => {
  const { t } = useTranslation();
  const { closeRightPanel } = useContext(
    RightPanelContext,
  ) as RightPanelContextProps;
  return (
    <div className="flex h-full w-full flex-col items-start">
      <Tabs
        checkDirtyOnChange
        aria-label="Activities"
        key={"tab"}
        onTabChange={closeRightPanel}
      >
        <Item
          key="computerActivities"
          title={t("common.computerActivities")}
          icon="ActivitiesMenuIcon"
        >
          <UserActivitiesTab />
        </Item>
        <Item
          lazy
          key="adminActivities"
          icon="ActivitiesMenuIcon"
          title={t("common.adminActivities")}
        >
          <AdminActivitiesTab />
        </Item>
        <Item
          lazy
          key="softwareActivities"
          icon="ActivitiesMenuIcon"
          title={t("common.eventLogging")}
        >
          <SoftwareActivitiesTab />
        </Item>
      </Tabs>
    </div>
  );
};
