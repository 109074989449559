import RouterPage from "../interfaces/RouterPage";

import { translate } from "./translation";

export const routerPages: RouterPage[] = [
  {
    hasNotification: false,
    href: "/dashboard",
    icon: "DashboardMenuIcon",
    isDisabled: false,
    key: "dashboard",
    label: translate("pages.dashboard"),
  },
  {
    hasNotification: false,
    href: "/computers",
    icon: "ComputersMenuIcon",
    isDisabled: false,
    key: "computers",
    label: translate("pages.computers"),
  },
  {
    hasNotification: false,
    href: "/users",
    icon: "UsersMenuIcon",
    isDisabled: false,
    key: "users",
    label: translate("pages.users"),
  },
  {
    hasNotification: false,
    href: "/policies",
    icon: "PoliciesMenuIcon",
    isDisabled: false,
    key: "policies",
    label: translate("pages.policies"),
  },
  {
    hasNotification: false,
    href: "/activities",
    icon: "ActivitiesMenuIcon",
    isDisabled: false,
    key: "activities",
    label: translate("pages.activities"),
  },
  {
    hasNotification: false,
    href: "/settings",
    icon: "SettingsIcon",
    isDisabled: false,
    key: "settings",
    label: translate("pages.settings"),
  },
];
