import clsx from "clsx";
import React, { ReactNode } from "react";

import { NotificationTypes } from "../contexts/NotificationContext";

import { Icon } from "./Icon";

interface AlertProps {
  status: NotificationTypes;
  children: ReactNode;
  className?: string;
  onDismiss?: () => void;
  rightContent?: ReactNode;
}

const Alert: React.FC<AlertProps> = ({
  status,
  children,
  className,
  onDismiss,
  rightContent,
}) => {
  const colors = {
    [NotificationTypes.DANGER]: {
      background: "bg-[#FAECEC]",
      border: "border border-red",
      icon: "text-red",
      text: "text-red",
    },
    [NotificationTypes.INFO]: {
      background: "bg-light-gray",
      border: "border border-dark-gray",
      icon: "text-extra-dark-gray",
      text: "text-extra-dark-gray",
    },
    [NotificationTypes.NOTICE]: {
      background: "bg-pale-blue",
      border: "border border-[#1779BC] ",
      icon: "text-[#1779BC]",
      text: "text-[#1779BC]",
    },
    [NotificationTypes.SUCCESS]: {
      background: "bg-light-green",
      border: "border border-green",
      icon: "text-green",
      text: "text-green",
    },
    [NotificationTypes.WARNING]: {
      background: "bg-[#FFFCF3]",
      border: "border border-yellow",
      icon: "text-yellow",
      text: "text-yellow",
    },
  };

  const color = colors[status];

  return (
    <div className={clsx("w-full rounded-md p-4", color.background, className)}>
      <div className="flex w-full flex-row items-center">
        <div className="flex-shrink-0">
          <Icon
            aria-hidden="true"
            className={`h-5 w-5 ${color.text}`}
            name="ErrorIcon"
          />
        </div>
        <div className="ml-3">
          <p className={`text-sm font-medium ${color.text}`}>{children}</p>
        </div>
        <div className="ml-auto pl-3">
          {rightContent}
          {onDismiss && (
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={`inline-flex items-center rounded-md ${color.background} p-1.5 ${color.text}`}
                onClick={onDismiss}
              >
                <span className="sr-only">Dismiss</span>
                <button
                  onClick={() => onDismiss}
                  type="button"
                  className="text-dark-pale-green"
                  aria-label="dismiss"
                >
                  <Icon name="CloseIcon" />
                </button>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alert;
