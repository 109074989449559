import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { OverlayTriggerState } from "react-stately";

import { NotificationTypes } from "../../contexts/NotificationContext";
import { useNotifications } from "../../hooks/useNotifications";
import { AccountService } from "../../services/accountService";
import Button from "../Button";
import { Icon } from "../Icon";
import { Modal } from "../Modal";
import SegmentedInput from "../SegmentedInput";

interface AuthenticatorModalProps {
  state: OverlayTriggerState;
  onActivate: () => void;
  onComplete: () => void;
}

interface AuthenticatorStep {
  number: number;
  title: string;
  description: string;
}

interface CodeState {
  manualEntryKey: string;
  qrCodeImage: string;
}

const accountService = new AccountService();

const SettingSection: React.FC<AuthenticatorModalProps> = ({
  state,
  onComplete,
  onActivate,
}) => {
  const { t } = useTranslation();
  const [authenticatorSteps] = useState<AuthenticatorStep[]>([
    {
      description: t("common.downloadAuthenticatorApp"),
      number: 1,
      title: t("common.downloadApp"),
    },
    {
      description: t("common.openAppScanQRCode"),
      number: 2,
      title: t("common.scanQRCode"),
    },
    {
      description: t("common.enableAppAuthenticator"),
      number: 3,
      title: t("common.verificationCode"),
    },
    {
      description: t("common.authenticatorBackupCodeDescription"),
      number: 4,
      title: t("common.backupKey"),
    },
  ]);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [code, setCode] = useState<CodeState>({
    manualEntryKey: "",
    qrCodeImage: "",
  });
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [isVerificationCodeValid, setIsVerificationCodeValid] =
    useState<boolean>(true);
  const [recoveryKey, setRecoveryKey] = useState<string>("");
  const { createNotification } = useNotifications();

  /**
   * Generates an authenticator code.
   * This function sets isLoading to true, makes a request to the accountService.generateAuthenticatorCode(),
   * logs the response if successful, creates a notification with an error message if unsuccessful,
   * and sets isLoading to false regardless of the outcome.
   *
   * @function generateAuthenticatorCode
   * @returns {void}
   */
  const generateAuthenticatorCode = (): void => {
    setIsLoading(true);
    accountService
      .generateAuthenticatorCode()
      .then((res) => {
        setCode(res);
      })
      .catch(() => {
        createNotification(
          t("errors.authenticatorNotRemoved"),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Verifies the authenticator code.
   *
   * This function sends the OTP (One-Time Password) verification code to the server to verify its validity. It sets the loading state to true before making the request. If the verification
   * is successful, it increments the current step and sets the verification code validity state to true. If the verification fails due to an error with error code 13, it sets the verification
   * code validity state to false. For any other error, it sets the verification code validity state to true and creates a danger notification with a generic error message. Finally, it
   * sets the loading state to false.
   *
   * @returns {void}
   */
  const verifyAuthenticatorCode = (): void => {
    setIsLoading(true);
    accountService
      .verifyAuthenticatorCode({ otp: verificationCode })
      .then((res) => {
        setCurrentStep((prevStep) => prevStep + 1);
        setIsVerificationCodeValid(true);
        setRecoveryKey(res.recoveryKey);
        onActivate();
      })
      .catch((error) => {
        if (error?.response?.errorCode === 13) {
          setIsVerificationCodeValid(false);
        } else {
          setIsVerificationCodeValid(true);
          createNotification(
            t("errors.genericError"),
            NotificationTypes.DANGER,
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Increases the current step by 1 and generates an authenticator code if necessary.
   * @function handleNextStep
   * @returns {void}
   */
  const handleNextStep = (): void => {
    if (currentStep < authenticatorSteps.length) {
      const nextStep = currentStep + 1;
      if (nextStep === 2 && (!code.qrCodeImage || !code.manualEntryKey)) {
        generateAuthenticatorCode();
      }

      if (nextStep === 3) {
        setVerificationCode("");
        setIsVerificationCodeValid(true);
      }

      if (nextStep === 4) {
        verifyAuthenticatorCode();
        return;
      }

      setCurrentStep(nextStep);
    } else {
      onComplete();
    }
  };

  useEffect(() => {
    if (!state.isOpen) {
      setVerificationCode("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isOpen]);

  const isNextButtonDisabled = useMemo(() => {
    if (currentStep === 2) {
      return !code.qrCodeImage || !code.manualEntryKey;
    }

    if (currentStep === 3) {
      return !verificationCode;
    }

    return false;
  }, [currentStep, code.qrCodeImage, code.manualEntryKey, verificationCode]);

  return (
    <Modal
      state={state}
      className="min-w-[50vh] max-w-[800px] transition-all duration-200 ease-in-out"
    >
      <div className="flex w-full flex-col items-center justify-center gap-4 py-12">
        <div className="flex flex-row items-center gap-2">
          <span className="text-xl text-dark-blue">
            {t("common.appAuthentication")}
          </span>
          <Icon name="KeyIcon" className="h-5 w-5 text-dark-gray" />
        </div>
        <div className="mx-auto my-4 w-full pb-4">
          <div className="flex content-center pb-4 text-center text-xs">
            {authenticatorSteps.map((step) => (
              <div
                key={step.number}
                className={clsx(
                  "w-1/4 font-medium",
                  currentStep === step.number
                    ? "text-extra-dark-gray"
                    : "text-cold-gray",
                )}
              >
                {step.title}
              </div>
            ))}
          </div>
          <div className="flex pb-3">
            <div className="flex-1"></div>

            {authenticatorSteps.map((step) => (
              <>
                <div className="flex-1">
                  <div
                    className={clsx(
                      "mx-auto flex h-10 w-10 items-center rounded-full text-lg text-white",
                      currentStep === step.number
                        ? "bg-dark-blue"
                        : "bg-gray-blue",
                    )}
                  >
                    <span className="w-full text-center text-white">
                      {step.number}
                    </span>
                  </div>
                </div>

                {step.number !== authenticatorSteps.length && (
                  <div className="align-center flex w-1/6 content-center items-center align-middle">
                    <div className="align-center w-full flex-1 items-center rounded bg-light-gray align-middle">
                      <div className="h-[2px] w-full rounded bg-light-gray text-center text-xs leading-none"></div>
                    </div>
                  </div>
                )}
              </>
            ))}
            <div className="flex-1"></div>
          </div>
        </div>

        <div className="flex w-full flex-col items-center justify-center gap-2">
          <span className="text-lg text-extra-dark-gray">
            {t("common.step")} {currentStep}
          </span>
          <span className="w-3/4 overflow-hidden break-words text-center text-dark-gray">
            {
              authenticatorSteps.find((s) => s.number === currentStep)
                ?.description
            }
          </span>
        </div>

        <div>
          {(() => {
            switch (currentStep) {
              case 2:
                return (
                  <div className="flex flex-col items-center justify-center gap-4">
                    {isLoading ? (
                      <div className="h-[100px] w-[100px] animate-pulse bg-light-gray"></div>
                    ) : (
                      <img
                        className="max-h-[100px]"
                        src={`data:image/png;base64,${code.qrCodeImage}`}
                        alt="QR code"
                      />
                    )}
                    <span className="text-dark-gray">
                      {t("common.orEnterCodeManually")}
                    </span>
                    {isLoading ? (
                      <div className="h-8 w-3/4 animate-pulse bg-light-gray"></div>
                    ) : (
                      <p className="whitespace-pre-wrap px-2 text-lg font-medium text-dark-gray">
                        {code.manualEntryKey}
                      </p>
                    )}
                  </div>
                );
              case 3:
                return (
                  <div className="flex flex-col items-center justify-center gap-4">
                    <SegmentedInput
                      onCompleted={(e) => setVerificationCode(e)}
                    />
                    <span className="text-lg text-dark-gray">
                      {t("auth.enterOTPCode")}
                    </span>
                    <p className="text-xs text-dark-red">
                      {isVerificationCodeValid ? "" : t("errors.codeIncorrect")}
                    </p>
                  </div>
                );
              case 4:
                return (
                  <div className="rounded-md p-6 font-medium text-dark-blue shadow-xl">
                    {recoveryKey}
                  </div>
                );
              default:
                return null;
            }
          })()}
        </div>

        <div className="mt-8 flex w-full flex-row items-center justify-center">
          <div className="flex w-1/2 flex-row items-center justify-center gap-2">
            <Button
              className={clsx(
                "transition-all duration-200 ease-in-out",
                currentStep === 1 || currentStep === authenticatorSteps.length
                  ? "hidden -translate-x-2 opacity-0"
                  : "translate-x-0 opacity-100",
              )}
              isLoading={isLoading}
              type="button"
              variant="text"
              onPress={() => setCurrentStep((prevStep) => prevStep - 1)}
            >
              {t("common.back")}
            </Button>
            <Button
              isLoading={isLoading}
              isDisabled={isNextButtonDisabled}
              onPress={handleNextStep}
              type="button"
            >
              {currentStep === authenticatorSteps.length
                ? t("common.finish")
                : t("common.next")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SettingSection;
