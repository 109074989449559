import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import LogoVertical from "../assets/logo-vertical.svg";
import ErrorBoundary from "../components/ErrorBoundary";
import { useAuth } from "../contexts/AuthContext";

function AuthLayout() {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard", { replace: true });
    }
  }, [isLoggedIn, navigate]);

  /**
   * A memoized function that returns the subtitle based on the current location pathname.
   *
   * @param {string} location.pathname - The current location pathname.
   * @param {function} t - The translation function.
   * @returns {string|null} The subtitle based on the current location pathname.
   */
  const title = useMemo(() => {
    switch (location.pathname) {
      case "/forgot-password":
        return t("auth.forgotPasswordTitle");
      case "/reset-password":
        return t("auth.resetPasswordTitle");
      default:
        return t("auth.title");
    }
  }, [location.pathname, t]);

  return (
    <div className="flex min-h-full flex-1">
      <div
        className="relative hidden w-0 max-w-[600px] flex-1 items-center justify-center
      bg-gradient-to-b from-base-gradient-0 via-base-gradient-1 to-base-gradient-2 lg:flex"
      >
        <LogoVertical className="absolute h-full w-full max-w-[450px] p-4" />
      </div>
      <div className="flex flex-1 flex-grow flex-col justify-center overflow-auto bg-extra-light-gray px-4 py-12 sm:px-6 lg:px-20 xl:px-24">
        <ErrorBoundary>
          <div className="mx-auto w-full max-w-2xl">
            <h1 className="bg-gradient-to-b from-base-gradient-0 via-base-gradient-1 to-base-gradient-2 bg-clip-text text-transparent">
              {title}
            </h1>
            <div className="max-w-lg">
              <Outlet />
            </div>
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default AuthLayout;
