export enum AdminActivityTypeEnum {
  Login = 0,
  FailedLogin = 1,
  CreateRole = 2,
  UpdateRole = 3,
  RemoveRole = 4,
  UpdateComputer = 5,
  RemoveComputer = 6,
  CreateGroupUsers = 7,
  UpdateGroupUsers = 8,
  RemoveGroupUsers = 9,
  CreateGroupComputers = 10,
  UpdateGroupComputers = 11,
  RemoveGroupComputers = 12,
  CreatePolicy = 13,
  UpdatePolicy = 14,
  RemovePolicy = 15,
  CreateUser = 16,
  UpdateUser = 17,
  RemoveUser = 18,
  ChangePasswordUser = 19,
  SetAvatarUser = 20,
  SendInvitation = 21,
  ForgotPassword = 22,
  ChangeMyAccount = 23,
  ChangeNotify = 24,
  ChangeAgentUpdate = 25,
  Register = 26,
  ChangeAgentOptions = 27,
  Logout = 28,
}
