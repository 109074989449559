import type { GridNode } from "@react-types/grid";
import clsx from "clsx";
import React, { ReactNode, useRef } from "react";
import { mergeProps, useFocusRing, useTableRow } from "react-aria";
import type { TableState } from "react-stately";

interface TableRowProps<T> {
  item: GridNode<T>;
  children: ReactNode;
  state: TableState<T>;
  borderless?: boolean;
  striped?: boolean;
  active?: boolean;
  isClickable?: boolean;
}

export default function TableRow<T>(props: TableRowProps<T>) {
  const {
    item,
    children,
    state,
    borderless,
    active = false,
    striped = false,
    isClickable = true,
  } = props;
  const ref = useRef(null);
  const isSelected = state.selectionManager.isSelected(item.key);
  const { rowProps, isPressed } = useTableRow(
    {
      node: item,
    },
    state,
    ref,
  );
  const { isFocusVisible, focusProps } = useFocusRing();
  let background;
  if (isSelected) {
    background = "bg-light-gray";
  } else if (isPressed) {
    background = "bg-light-blue";
  } else if (item.index && item.index % 2 && striped) {
    background = "bg-extra-light-gray";
  } else {
    background = "none";
  }

  if (active) {
    background = "bg-light-blue";
  }

  const boxShadow = isFocusVisible
    ? "shadow-[inset_0_0_0_1px_azure]"
    : "shadow-none";

  return (
    <tr
      className={clsx(
        background,
        boxShadow,
        !borderless && "border-b border-gray",
        "flex w-full outline-none",
        isClickable ? "cursor-pointer" : "cursor-default",
      )}
      {...mergeProps(rowProps, focusProps)}
      ref={ref}
    >
      {children}
    </tr>
  );
}
