import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import DirtyLink from "../components/DirtyLink";
import { Icon } from "../components/Icon";
import PolicyTab from "../components/policy/PolicyTab";
import { policies } from "../utils/policies";

export const Policy: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  // If no id is provided in the URL, navigate to /policies.
  if (!id) {
    navigate("/policies", { replace: true });
    return null; // Return null or a placeholder while the redirect takes effect.
  }

  // Convert the id string to a number.
  const policyTypeNumber = parseInt(id, 10);

  // If the id in the URL is not a valid number, navigate to /policies.
  if (isNaN(policyTypeNumber)) {
    navigate("/policies", { replace: true });
    return null;
  }

  // Find the policy by its type number.
  const foundPolicy = policies.find(
    (policy) => policy.type === policyTypeNumber,
  );

  // If no policy matches the provided id, navigate to /policies.
  if (!foundPolicy) {
    navigate("/policies", { replace: true });
    return null;
  }

  return (
    <div className="flex h-full w-full flex-col items-start">
      <div className="flex flex-row items-center space-x-2 px-6 py-4 text-dark-blue">
        <DirtyLink
          to={`/policies${location.search}`}
          className="flex flex-row items-center space-x-2"
        >
          <Icon name="PoliciesMenuIcon" />
          <span className="text-xl underline underline-offset-4">Policies</span>
        </DirtyLink>
        <p className="text-xl">{">"}</p>
        <span className="text-xl font-semibold">{foundPolicy.name}</span>
      </div>
      <PolicyTab />
    </div>
  );
};
