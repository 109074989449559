import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import PolicySeverity from "../../interfaces/PolicySeverity";
import { policySeverities } from "../../utils/policySeverities";
import MultiSelectComboBox, {
  MultiselectComboboxOption,
} from "../MultiSelectComboBox";

interface Props {
  id: string;
  selectedObjects: PolicySeverity[];
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (e: PolicySeverity[]) => void;
  loading?: boolean;
  maxSelectableItems?: number;
  clearable?: boolean;
}

export const PolicySeverityMultiselect: React.FC<Props> = ({
  id,
  selectedObjects,
  label,
  placeholder,
  disabled,
  onChange,
  loading,
  maxSelectableItems,
  clearable = true,
}) => {
  const [query, setQuery] = useState<string>("");
  const { t } = useTranslation();

  /**
   * Represents a variable that stores filtered policies based on a query.
   *
   * @type {Array}
   * @name filteredItems
   *
   * @param {string} query - The query used for filtering policies.
   *
   * @returns {Array} - An array containing policies that match the given query.
   *
   * @example
   * // Usage
   * const filteredPolicies = useMemo(() => {
   */
  const filteredItems: Array<MultiselectComboboxOption> = useMemo(() => {
    return policySeverities
      .filter((item) => {
        return item?.name.toLowerCase().includes(query.toLowerCase());
      })
      .map((item) => {
        return {
          id: item.id,
          value: item?.name || "N/D",
        };
      });
  }, [query]);

  /**
   * Represents the parsed selected objects.
   *
   * @type {Array<MultiselectComboboxOption>}
   */
  const parsedSelectedObjects: Array<MultiselectComboboxOption> =
    useMemo(() => {
      return selectedObjects.map((item) => {
        return {
          id: item.id,
          value: item?.name || "N/D",
        };
      });
    }, [selectedObjects]);

  /**
   * Updates the selected objects and triggers the 'onChange' event.
   *
   * @param {MultiselectComboboxOption[]} selectedObjects - The selected objects to update.
   */
  const handleSelectionObjectChange = (
    selectedObjects: MultiselectComboboxOption[],
  ) => {
    const newSelectedObjects = selectedObjects
      .map((selectedObject) => {
        return policySeverities.find((item) => item.id === selectedObject.id);
      })
      .filter((item): item is PolicySeverity => item !== undefined);

    onChange(newSelectedObjects);
  };

  return (
    <div className="flex flex-col gap-4">
      <MultiSelectComboBox
        id={id}
        maxSelectableItems={maxSelectableItems}
        disabled={disabled}
        selectedObjects={parsedSelectedObjects}
        clearable={clearable}
        onSelectionObjectChange={(e) => {
          handleSelectionObjectChange(e);
        }}
        aria-label={label || t("common.policyPlaceholder")}
        placeholder={placeholder || t("common.policyPlaceholder")}
        isLoading={loading}
        items={filteredItems}
        onSearch={(e) => setQuery(e)}
      />
    </div>
  );
};
