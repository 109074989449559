import React from "react";
import {
  Cell,
  Column,
  Item,
  Row,
  Section,
  TableBody,
  TableHeader,
} from "react-stately";

import ComputerGroupIllustration from "../../assets/images/computer-group-illustration.svg";
import useComputerGroupsTab from "../../hooks/useComputerGroupsTab";
import Button from "../Button";
import { ConfirmDeletionModal } from "../common/ConfirmDeletionModal";
import { Icon } from "../Icon";
import { MenuButton } from "../Menu";
import Pagination from "../Pagination";
import { Table } from "../table/Table";
import { TableActionsBar } from "../TableActionsBar";

function ComputerGroupsTab() {
  const {
    computerGroups,
    isLoading,
    selectedKeys,
    setSelectedKeys,
    tableActions,
    onMenuAction,
    filters,
    setFilters,
    t,
    dateFormatter,
    onRowAction,
    activeComputerGroup,
    handleOpenRightPanel,
    total,
    deleteModalState,
    handleComputerGroupDelete,
    activeComputerGroupsForDeletion,
    isCurrentUserReadOnly,
    selectedComputerGroup,
  } = useComputerGroupsTab();

  return (
    <div className="flex h-full w-full flex-col overflow-x-hidden transition-all duration-200 ease-in-out">
      <TableActionsBar
        actions={tableActions}
        searchValue={filters.search}
        onSearchChange={(value) => {
          setFilters({ ...filters, search: value });
        }}
      />
      {computerGroups.length === 0 && !isLoading && !filters.search ? (
        <div className="flex flex-col gap-24 p-8">
          <span className="text-medium-gray">
            {t("common.noComputerGroups")}
          </span>
          <div className="mx-auto flex h-full w-full max-w-xl flex-col items-center justify-center gap-8 text-center">
            <ComputerGroupIllustration className="h-auto w-full" />
            <span className="leading-8 text-extra-dark-gray">
              {t("common.noComputerGroupsMessage")}
            </span>
            <Button
              isDisabled={isCurrentUserReadOnly}
              onPress={() => {
                handleOpenRightPanel().then(() => {});
              }}
            >
              {t("common.addComputerGroup")}
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-1 flex-col gap-4">
          <div className="flex-1">
            <Table
              activeRowKey={activeComputerGroup}
              aria-labelledby={t("common.computersGroups")}
              isLoading={isLoading}
              aria-label={t("common.computersGroups")}
              selectionMode={isCurrentUserReadOnly ? "none" : "multiple"}
              selectedKeys={selectedKeys}
              onSelectionChange={setSelectedKeys}
              sortDescriptor={filters.sort}
              onSortChange={(e) => setFilters({ ...filters, sort: e })}
              onRowAction={onRowAction}
            >
              <TableHeader>
                <Column allowsResizing allowsSorting key="name">
                  {t("common.name")}
                </Column>
                <Column allowsResizing allowsSorting key="description">
                  {t("common.description")}
                </Column>
                <Column allowsResizing allowsSorting key="createdOn">
                  {t("common.createdOn")}
                </Column>
                <Column width={50} key="_actions">
                  {""}
                </Column>
              </TableHeader>
              <TableBody items={computerGroups}>
                {(item) => (
                  <Row>
                    {(columnKey) => {
                      switch (columnKey) {
                        case "createdOn":
                          return (
                            <Cell>
                              {item?.createdOn
                                ? dateFormatter.format(
                                    new Date(item?.createdOn),
                                  )
                                : "N/D"}
                            </Cell>
                          );
                        case "_actions":
                          if (isCurrentUserReadOnly) {
                            return <Cell>{""}</Cell>;
                          }

                          return (
                            <Cell>
                              <div className="flex h-full items-center justify-center">
                                <MenuButton
                                  label={
                                    <Icon
                                      name="MenuIcon"
                                      className="h-full text-extra-dark-gray"
                                    />
                                  }
                                  onAction={(key) => onMenuAction(item.id, key)}
                                >
                                  <Section>
                                    <Item key="edit">{t("common.edit")}</Item>
                                  </Section>
                                  <Section>
                                    <Item
                                      key="delete"
                                      textValue={t("common.delete")}
                                    >
                                      <span className="text-red">
                                        {t("common.delete")}
                                      </span>
                                    </Item>
                                  </Section>
                                </MenuButton>
                              </div>
                            </Cell>
                          );
                        default:
                          return (
                            <Cell>
                              {(item as any)[String(columnKey)] || "N/D"}
                            </Cell>
                          );
                      }
                    }}
                  </Row>
                )}
              </TableBody>
            </Table>
          </div>
          <Pagination
            offset={Number(filters.offset)}
            total={total}
            count={Number(filters.count)}
            onCountChange={(e) =>
              setFilters((prevState) => ({ ...prevState, count: e }))
            }
            onOffsetChange={(e) =>
              setFilters((prevState) => ({ ...prevState, offset: e }))
            }
          />
        </div>
      )}
      <ConfirmDeletionModal
        state={deleteModalState}
        title={t("dialog.deleteComputerGroup")}
        message={t("dialog.deleteComputerGroupMessage", {
          count: activeComputerGroupsForDeletion.includes("all")
            ? total
            : activeComputerGroupsForDeletion.length,
          deleteKeyword: "delete",
          resourceName: selectedComputerGroup?.name,
        })}
        onConfirm={handleComputerGroupDelete}
        onCancel={deleteModalState.close}
        isLoading={isLoading}
      />
    </div>
  );
}

export default ComputerGroupsTab;
