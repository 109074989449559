import type { AriaListBoxOptions } from "@react-aria/listbox";
import type { ListState } from "@react-stately/list";
import type { Node } from "@react-types/shared";
import clsx from "clsx";
import React from "react";
import { useListBox, useListBoxSection, useOption } from "react-aria";

interface ListBoxProps extends AriaListBoxOptions<unknown> {
  listBoxRef?: React.RefObject<HTMLUListElement>;
  state: ListState<unknown>;
}

interface SectionProps {
  section: Node<unknown>;
  state: ListState<unknown>;
}

interface OptionProps {
  item: Node<unknown>;
  state: ListState<unknown>;
}

function Option({ item, state }: OptionProps) {
  const ref = React.useRef<HTMLLIElement>(null);
  const { optionProps, isDisabled, isSelected, isFocused } = useOption(
    {
      key: item.key,
    },
    state,
    ref,
  );
  let text = "text-extra-dark-gray";
  if (isFocused || isSelected) {
    text = "text-dark-blue";
  } else if (isDisabled) {
    text = "opacity-50";
  }
  return (
    <li
      {...optionProps}
      ref={ref}
      className={clsx(
        "m-1 flex cursor-default items-center justify-between rounded-md px-2 py-2 text-sm outline-none transition-all duration-100 ease-in-out",
        text,
        isFocused && !isDisabled ? "bg-light-gray" : "",
        isSelected ? "font-bold" : "font-normal",
        isDisabled ? "pointer-events-none opacity-50" : "",
      )}
    >
      {item.rendered}
      {/*{isSelected && <span>X</span>}*/}
    </li>
  );
}

function ListBoxSection({ section, state }: SectionProps) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    "aria-label": section["aria-label"],
    heading: section.rendered,
  });

  return (
    <>
      <li {...itemProps} className="pt-2">
        {section.rendered && (
          <span
            {...headingProps}
            className="text-gray-500 mx-3 text-xs font-bold uppercase"
          >
            {section.rendered}
          </span>
        )}
        <ul {...groupProps}>
          {[...section.childNodes].map((node) => (
            <Option key={node.key} item={node} state={state} />
          ))}
        </ul>
      </li>
    </>
  );
}

function ListBox(props: ListBoxProps) {
  const ref = React.useRef<HTMLUListElement>(null);
  const { listBoxRef = ref, state } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);
  return (
    <ul
      {...listBoxProps}
      ref={listBoxRef}
      className="max-h-72 w-full overflow-auto outline-none"
    >
      {[...state.collection].map((item) =>
        item.type === "section" ? (
          <ListBoxSection key={item.key} section={item} state={state} />
        ) : (
          <Option key={item.key} item={item} state={state} />
        ),
      )}
    </ul>
  );
}

export default ListBox;
