import React from "react";
import { Item } from "react-stately";

import useUserDetail from "../../hooks/useUserDetail";
import { UserAdminEnum } from "../../interfaces/User";
import Button from "../Button";
import { ComboBox } from "../ComboBox";
import { ComputerMultiSelect } from "../common/ComputerMultiSelect";
import DatePicker from "../DatePicker";
import { Icon } from "../Icon";
import TextField from "../TextField";

interface UserDetailProps {
  id?: number;
  onCancel: () => void;
  onSaved: () => void;
}

const UserDetail: React.FC<UserDetailProps> = (props: UserDetailProps) => {
  const {
    isLoading,
    onSubmit,
    isEditing,
    handleInputChange,
    handleCancel,
    form,
    t,
    isCurrentUserReadOnly,
    showChangeRoleMessage,
  } = useUserDetail({
    id: Number(props.id),
    onCancel: props.onCancel,
    onSaved: props.onSaved,
  });

  return (
    <form
      onSubmit={onSubmit}
      className="flex h-full flex-col justify-between space-y-4"
    >
      <div className="flex flex-col space-y-4 divide-y divide-medium-gray">
        <div className="flex w-full flex-row items-start justify-start gap-4 px-8 py-4">
          <div className="flex w-full flex-col space-y-4">
            <div className="flex flex-row items-center justify-start gap-4">
              <Icon name="UserIcon" className="h-8 w-8 text-dark-blue" />
              <span className="text-xl font-medium text-dark-blue">
                {isEditing ? t("common.editUser") : t("common.addUser")}
              </span>
            </div>
            <p>
              {isEditing
                ? t("common.editUserDescription")
                : t("common.addUserDescription")}
            </p>
            <div className="grid grid-cols-1 gap-3">
              <TextField
                isReadOnly={isCurrentUserReadOnly}
                name="username"
                type="email"
                label={t("common.usernameEmailFormat")}
                value={form.username}
                isRequired
                required
                isDisabled={isLoading}
                onChange={(e) => handleInputChange("username", e)}
              />
              <TextField
                isReadOnly={isCurrentUserReadOnly}
                name="name"
                type="text"
                label={t("common.firstName")}
                value={form.name}
                isRequired
                required
                isDisabled={isLoading}
                onChange={(e) => handleInputChange("name", e)}
              />
              <TextField
                isReadOnly={isCurrentUserReadOnly}
                name="surname"
                type="text"
                label={t("common.surname")}
                value={form.surname}
                isRequired
                required
                isDisabled={isLoading}
                onChange={(e) => handleInputChange("surname", e)}
              />
              <DatePicker
                isReadOnly={isCurrentUserReadOnly}
                isDisabled={isLoading}
                value={form.expirationDate}
                aria-label={t("common.expiry")}
                onChange={(e) => {
                  handleInputChange("expirationDate", e);
                }}
                label={t("common.expiry")}
              />
              <ComboBox
                isReadOnly={isCurrentUserReadOnly}
                id="isAdmin"
                selectedKey={String(form.isAdmin)}
                aria-label={t("common.adminUserRole")}
                label={t("common.adminUserRole")}
                onSelectionChange={(value) => {
                  handleInputChange("isAdmin", Number(value));
                }}
              >
                {Object.entries(UserAdminEnum)
                  .filter(([key]) => isNaN(Number(key)))
                  .map(([key, value]) => (
                    <Item key={value} aria-label={t(`userRoles.${key}`)}>
                      <div className="flex flex-col">
                        <span>{t(`userRoles.${key}`)}</span>
                        <p className="text-sm font-light text-dark-gray">
                          {t(`userRoles.${key}Description`)}
                        </p>
                      </div>
                    </Item>
                  ))}
              </ComboBox>
              {showChangeRoleMessage && (
                <p className="px-2 text-sm text-dark-gray">
                  {t("common.userChangeRoleMessage")}
                </p>
              )}
            </div>
            {isEditing && form?.computerGroups?.length > 0 && (
              <>
                <span className="pt-4 text-extra-dark-gray">
                  {t("common.partOfUserGroups")}:
                </span>
                <ul className="list-disc gap-2 pl-4 text-extra-dark-gray">
                  {form?.userGroups?.map((group) => (
                    <li key={group.id}>{group.name}</li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-6 px-8 py-4">
          <span className="text-xl font-medium text-dark-blue">
            {t("common.assignedComputers")} (
            {form?.computers?.length + form?.computerGroups?.length})
          </span>
          <ComputerMultiSelect
            id="computers"
            disabled={isLoading || isCurrentUserReadOnly}
            selectedComputers={form.computers}
            selectedGroups={form.computerGroups}
            onComputerChange={(e) => {
              handleInputChange("computers", e);
            }}
            onGroupChange={(e) => {
              handleInputChange("computerGroups", e);
            }}
            onComputerRemove={(e) => {
              handleInputChange(
                "computers",
                form.computers?.filter((item) => item.id !== e.id),
              );
            }}
            onGroupRemove={(e) => {
              handleInputChange(
                "computerGroups",
                form.computerGroups?.filter((item) => item.id !== e.id),
              );
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 border-t border-gray p-4 md:grid-cols-2">
        <div>
          <Button
            onPress={handleCancel}
            type="button"
            isDisabled={isLoading}
            variant="text"
          >
            {t("common.cancel")}
          </Button>
        </div>
        <div>
          <Button
            isDisabled={isCurrentUserReadOnly}
            type="submit"
            isLoading={isLoading}
            variant="contained"
          >
            {t("common.save")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UserDetail;
