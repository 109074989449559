import clsx from "clsx";
import React, { ReactNode, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useNavigationDirty } from "../contexts/NavigationDirtyContext";

import { Icon } from "./Icon";
import { iconComponents } from "./IconImports";

interface TabProps {
  title: string;
  icon?: keyof typeof iconComponents;
  isActive: boolean;
  onClick: () => void;
}

interface TabsProps {
  children: ReactNode | ReactNode[];
  onTabChange?: (index: number) => void;
  checkDirtyOnChange?: boolean;
}

interface TabPanelProps {
  isActive: boolean;
  children: ReactNode;
  lazy?: boolean;
}

interface ItemProps {
  title: string;
  icon?: keyof typeof iconComponents;
  children: React.ReactNode;
  lazy?: boolean;
}

export const Tab: React.FC<TabProps> = ({ title, icon, isActive, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        "border-r border-dark-gray transition-all duration-200 ease-in-out first:rounded-tl-lg",
        isActive ? "bg-light-blue" : "bg-white",
      )}
    >
      <div
        className={clsx(
          "border-b-2 px-10 py-3 text-dark-blue first:rounded-tl-lg",
          isActive
            ? "border-b-dark-blue-disabled font-semibold"
            : "border-b-transparent",
        )}
      >
        <div className="flex flex-row items-center gap-2.5">
          {icon && (
            <Icon
              variant={isActive ? "active" : "default"}
              className="h-5 w-5"
              name={icon}
            />
          )}
          <span
            className={clsx(
              "text-lg",
              !isActive && "underline underline-offset-4",
            )}
          >
            {title}
          </span>
        </div>
      </div>
    </button>
  );
};

const LazyTabPanel: React.FC<TabPanelProps> = ({
  isActive,
  children,
  lazy,
}) => {
  const [loaded, setLoaded] = useState(!lazy);

  useEffect(() => {
    if (lazy && isActive) {
      setLoaded(true);
    }
  }, [lazy, isActive]);

  return (
    <div
      className={clsx(
        "h-full w-full transition-all duration-200 ease-in-out",
        !isActive && "hidden",
      )}
    >
      {loaded && children}
    </div>
  );
};

const queryTabIndex = new URLSearchParams(window.location.search).get("tab")
  ? parseInt(new URLSearchParams(window.location.search).get("tab") as string)
  : 0;

export const Tabs: React.FC<TabsProps> = ({
  children,
  onTabChange,
  checkDirtyOnChange,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(queryTabIndex);
  const childrenArray = React.Children.toArray(children);
  const { setDirty, confirmDirtyNavigation } = useNavigationDirty();
  const [searchParams, setSearchParams] = useSearchParams();

  const setTabParams = (index: number) => {
    const newParams: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      newParams[key] = value;
    });

    newParams.tab = index.toString();

    setSearchParams(newParams);
  };

  /**
   * Handles the change of the active tab.
   *
   * @param {number} index - The index of the tab to set as active.
   * @returns {Promise<void>} - A promise that resolves when the tab change is complete.
   */
  const handleTabChange = async (index: number): Promise<void> => {
    if (checkDirtyOnChange) {
      const confirm = await confirmDirtyNavigation();
      if (confirm) {
        setActiveTabIndex(index);
        if (onTabChange && activeTabIndex !== index) {
          onTabChange(index);
          setTabParams(index);
        }
        setDirty(false);
      }
    } else {
      setActiveTabIndex(index);
      if (onTabChange && activeTabIndex !== index) {
        onTabChange(index);
        setTabParams(index);
      }
    }
  };

  useEffect(() => {
    const queryTab = searchParams.get("tab");
    if (queryTab !== null) {
      const tabIndex = parseInt(queryTab, 10);
      if (!isNaN(tabIndex) && tabIndex !== activeTabIndex) {
        setActiveTabIndex(tabIndex);
      }
    }
  }, [searchParams, activeTabIndex]);

  return (
    <div className="flex h-full w-full border-collapse flex-col rounded-t-lg bg-white">
      <div className="flex w-full border-collapse flex-row rounded-tl-lg rounded-tr-lg border-b border-dark-gray">
        {childrenArray.map((child, index) => {
          const { title, icon } = (child as React.ReactElement).props;

          return (
            <Tab
              key={index}
              title={title}
              icon={icon}
              isActive={activeTabIndex === index}
              onClick={() => {
                handleTabChange(index).then(() => {});
              }}
            />
          );
        })}
      </div>

      {childrenArray.map((child, index) => {
        const childProps = (child as React.ReactElement<ItemProps>).props;
        const { children: childChildren, lazy } = childProps;

        return (
          <LazyTabPanel
            key={index}
            isActive={activeTabIndex === index}
            lazy={lazy}
          >
            {childChildren}
          </LazyTabPanel>
        );
      })}
    </div>
  );
};

export const Item: React.FC<ItemProps> = ({ children }) => {
  return <div className="flex flex-row items-center space-x-2">{children}</div>;
};
