import { FormEvent, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../contexts/AuthContext";
import { useNavigationDirty } from "../contexts/NavigationDirtyContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { usePendingComputerUpdate } from "../contexts/PendingComputerUpdateContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import Computer from "../interfaces/Computer";
import ComputerGroup from "../interfaces/ComputerGroup";
import User from "../interfaces/User";
import { ComputerService } from "../services/computerService";

import { useNotifications } from "./useNotifications";

const computerService = new ComputerService();

interface Props {
  id: string | number;
  onSaved: () => void;
  onCancel: () => void;
}

interface FormState {
  description: string;
  anonymous: boolean;
  users: User[];
  groups: ComputerGroup[];
}

function useComputerDetail({ id, onSaved, onCancel }: Props) {
  const { isRightPanelOpen } = useContext(
    RightPanelContext,
  ) as RightPanelContextProps;
  const [computer, setComputer] = useState<Computer | null>(null);
  const [groups, setGroups] = useState<ComputerGroup[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [form, setForm] = useState<FormState>({
    anonymous: false,
    description: "",
    groups: [],
    users: [],
  });

  const { createNotification } = useNotifications();
  const { t } = useTranslation();
  const { setDirty, confirmDirtyNavigation } = useNavigationDirty();
  const { isCurrentUserReadOnly } = useAuth();
  const { setHasPendingUpdates } = usePendingComputerUpdate();

  /**
   * Resets the form by setting the values of its input fields to their default states.
   *
   * @function resetForm
   * @returns {void}
   */
  const resetForm = (): void => {
    setForm({
      anonymous: false,
      description: "",
      groups: [],
      users: [],
    });
  };

  /**
   * Handles the cancellation of a certain operation.
   *
   * @async
   * @returns {Promise<void>} A promise that resolves when the cancellation is handled.
   */
  const handleCancel = async (): Promise<void> => {
    const confirm = await confirmDirtyNavigation();
    if (confirm) {
      resetForm();
      onCancel();
    }
  };

  /**
   * Updates the form field value, based on the field type.
   * @param {string} field - The name of the field to update.
   * @param {any} value - The new value for the field.
   * @returns {void}
   */
  const handleInputChange = (field: string, value: any): void => {
    switch (field) {
      default:
        setForm((prevForm) => ({ ...prevForm, [field]: value }));
        break;
    }
    setDirty(true);
  };

  /**
   * Handles form submission.
   *
   * @param {FormEvent} e - The form event object.
   * @returns {void}
   */
  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    setIsLoading(true);
    const updatedForm = structuredClone(form);

    // Revert anonymous
    updatedForm.anonymous = !updatedForm.anonymous;

    if (id) {
      await computerService
        .updateComputer(id, { ...updatedForm })
        .then(() => {
          createNotification(
            t("notifications.computerUpdated"),
            NotificationTypes.SUCCESS,
          );
          onSaved();
          setDirty(false);
          setHasPendingUpdates(true);
        })
        .catch(() => {
          createNotification(
            t("errors.genericUpdateError", {
              resource: t("common.computer"),
            }),
            NotificationTypes.DANGER,
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  /**
   * @desc Fetches user data from the API
   */
  const getComputer = useCallback(async () => {
    setIsLoading(true);
    try {
      const [computerRes, groupsRes, usersRes] = await Promise.all([
        computerService.getComputer(id),
        computerService.getComputerGroups(id),
        computerService.getComputerUsers(id),
      ]);
      setComputer(computerRes);
      setForm({
        anonymous: !computerRes.anonymous,
        description: computerRes.description,
        groups: groupsRes,
        users: usersRes,
      });
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  /**
   * @desc Fetches user data from the API when the id changes
   */
  useEffect(() => {
    if (id) {
      getComputer().then(() => setIsLoading(false));
    } else {
      setComputer(null);
      resetForm();
      setGroups([]);
      setIsLoading(false);
    }
  }, [getComputer, id]);

  useEffect(() => {
    if (!isRightPanelOpen) {
      resetForm();
    }
  }, [isRightPanelOpen]);

  return {
    computer,
    form,
    groups,
    handleCancel,
    handleInputChange,
    isCurrentUserReadOnly,
    isLoading,
    onSubmit,
    setForm,
    t,
  };
}

export default useComputerDetail;
