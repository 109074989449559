import { EventBus, EventBusEvents } from "../utils/EventBus";
import { clearStorage, getStorageItem } from "../utils/storage";

export interface RequestHeaders {
  [header: string]: string;
}

export interface RequestBody {
  [key: string]: unknown;
}

export interface ErrorResponse extends Error {
  statusText: string;
  response?: Promise<unknown>;
  status: number;
}

export class APIService {
  protected request(
    endpoint: string,
    method: string,
    body?: RequestBody | string,
    headers?: RequestHeaders,
  ) {
    const url = `${import.meta.env.VITE_APP_API_BASE_URL}${endpoint}`;
    const token =
      getStorageItem("localStorage", "token") ||
      getStorageItem("sessionStorage", "token");
    const headersInit: HeadersInit = {
      "Content-Type": "application/json",
      ...headers,
    };
    if (token) {
      headersInit.Authorization = "Bearer " + token;
    }

    const options: RequestInit = {
      headers: headersInit,
      method,
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    return fetch(url, options).then(async (res) => {
      EventBus.emit(EventBusEvents.API_REQUEST, { url });

      if (!res.ok) {
        if (
          res.status === 401 &&
          !["/register", "/reset-password"].includes(window.location.pathname)
        ) {
          clearStorage();
          window.location.href = "/login";
        }
        const response = await res
          .text()
          .then((text) => (text ? JSON.parse(text) : {}));

        EventBus.emit(EventBusEvents.UNAUTHORIZED_API_REQUEST, {});

        const error: ErrorResponse = new Error(res.statusText) as ErrorResponse;
        error.statusText = res.statusText;
        error.response = response;
        error.status = res.status;

        throw error;
      }

      const contentType = res.headers.get("content-type");

      if (contentType && contentType.indexOf("application/json") !== -1) {
        try {
          return await res
            .text()
            .then((text) => (text ? JSON.parse(text) : {}));
        } catch (error) {
          console.error("Error in parsing JSON", error);
          throw error;
        }
      } else {
        return res;
      }
    });
  }
}
