import React from "react";
import {
  Cell,
  Column,
  Item,
  Row,
  Section,
  TableBody,
  TableHeader,
} from "react-stately";

import UserIllustration from "../../assets/images/user-illustration.svg";
import useUsersTab from "../../hooks/useUsersTab";
import { userStatusToColor, userStatusToString } from "../../interfaces/User";
import Button from "../Button";
import { ConfirmDeletionModal } from "../common/ConfirmDeletionModal";
import { Icon } from "../Icon";
import { MenuButton } from "../Menu";
import Pagination from "../Pagination";
import { Table } from "../table/Table";
import { TableActionsBar } from "../TableActionsBar";

function UsersTab() {
  const {
    users,
    isLoading,
    selectedKeys,
    setSelectedKeys,
    tableActions,
    onMenuAction,
    setFilters,
    filters,
    getUserActions,
    t,
    dateFormatter,
    activeUser,
    onRowAction,
    handleOpenRightPanel,
    total,
    handleUserDelete,
    deleteModalState,
    activeUsersForDeletion,
    isCurrentUserReadOnly,
    selectedUser,
  } = useUsersTab();

  return (
    <div className="flex h-full w-full flex-col overflow-x-hidden transition-all duration-200 ease-in-out">
      <TableActionsBar
        actions={tableActions}
        searchValue={filters.search}
        onSearchChange={(value) => {
          setFilters({ ...filters, search: value });
        }}
      />
      {users.length === 0 && !isLoading && !filters.search ? (
        <div className="flex flex-col gap-24 p-8">
          <span className="text-medium-gray">{t("common.noUsers")}</span>
          <div className="mx-auto flex h-full w-full max-w-xl flex-col items-center justify-center gap-8 text-center">
            <UserIllustration className="h-auto w-full" />
            <span className="leading-8 text-extra-dark-gray">
              {t("common.noUsersMessage")}
            </span>
            <Button
              isDisabled={isCurrentUserReadOnly}
              onPress={() => {
                handleOpenRightPanel().then(() => {});
              }}
            >
              {t("common.addUser")}
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-1 flex-col gap-4">
          <div className="flex-1">
            <Table
              activeRowKey={activeUser}
              aria-labelledby={t("common.users")}
              isLoading={isLoading}
              aria-label={t("common.users")}
              selectionMode={isCurrentUserReadOnly ? "none" : "multiple"}
              selectedKeys={selectedKeys}
              onSelectionChange={setSelectedKeys}
              sortDescriptor={filters.sort}
              onSortChange={(e) => setFilters({ ...filters, sort: e })}
              onRowAction={onRowAction}
            >
              <TableHeader>
                <Column allowsResizing allowsSorting key="username">
                  {t("common.username")}
                </Column>
                <Column allowsResizing allowsSorting key="name">
                  {t("common.firstName")}
                </Column>
                <Column allowsResizing allowsSorting key="surname">
                  {t("common.surname")}
                </Column>
                <Column allowsResizing allowsSorting key="createdOn">
                  {t("common.lastActivity")}
                </Column>
                <Column allowsResizing allowsSorting key="expiry">
                  {t("common.expiry")}
                </Column>
                <Column allowsResizing allowsSorting key="status">
                  {t("common.status")}
                </Column>
                <Column width={50} key="_actions">
                  {""}
                </Column>
              </TableHeader>
              <TableBody items={users}>
                {(item) => (
                  <Row>
                    {(columnKey) => {
                      switch (columnKey) {
                        case "createdOn":
                        case "expiry":
                          return (
                            <Cell>
                              {(item as any)[columnKey]
                                ? dateFormatter.format(
                                    new Date((item as any)[columnKey]),
                                  )
                                : "N/D"}
                            </Cell>
                          );
                        case "status":
                          return (
                            <Cell>
                              <p
                                style={{
                                  color: userStatusToColor(
                                    (item as any)[columnKey],
                                  ),
                                }}
                              >
                                {(item as any)[columnKey]
                                  ? userStatusToString((item as any)[columnKey])
                                  : "N/D"}
                              </p>
                            </Cell>
                          );
                        case "_actions":
                          if (isCurrentUserReadOnly) {
                            return <Cell>{""}</Cell>;
                          }

                          return (
                            <Cell>
                              <MenuButton
                                label={
                                  <Icon
                                    name="MenuIcon"
                                    className="h-full text-extra-dark-gray"
                                  />
                                }
                                onAction={(key) => onMenuAction(item.id, key)}
                              >
                                {getUserActions(item.id).map((action) =>
                                  (() => {
                                    switch (action.key) {
                                      case "edit":
                                        return (
                                          <Section>
                                            <Item key={action.key}>
                                              {t("common.edit")}
                                            </Item>
                                          </Section>
                                        );
                                      case "delete":
                                        return (
                                          <Section>
                                            <Item
                                              key={action.key}
                                              textValue={t("common.delete")}
                                            >
                                              <span className="text-red">
                                                {t("common.delete")}
                                              </span>
                                            </Item>
                                          </Section>
                                        );
                                      default:
                                        return (
                                          <Section>
                                            <Item key={action.key}>
                                              {action.label}
                                            </Item>
                                          </Section>
                                        );
                                    }
                                  })(),
                                )}
                              </MenuButton>
                            </Cell>
                          );
                        default:
                          return (
                            <Cell>
                              {(item as any)[String(columnKey)] || "N/D"}
                            </Cell>
                          );
                      }
                    }}
                  </Row>
                )}
              </TableBody>
            </Table>
          </div>
          <Pagination
            offset={Number(filters.offset)}
            total={total}
            count={Number(filters.count)}
            onCountChange={(e) =>
              setFilters((prevState) => ({ ...prevState, count: e }))
            }
            onOffsetChange={(e) =>
              setFilters((prevState) => ({ ...prevState, offset: e }))
            }
          />
        </div>
      )}
      <ConfirmDeletionModal
        state={deleteModalState}
        title={t("dialog.deleteUser")}
        message={t("dialog.deleteUserMessage", {
          count: activeUsersForDeletion.includes("all")
            ? total
            : activeUsersForDeletion.length,
          deleteKeyword: "delete",
          resourceName: selectedUser?.username,
        })}
        onConfirm={handleUserDelete}
        onCancel={deleteModalState.close}
        isLoading={isLoading}
      />
    </div>
  );
}

export default UsersTab;
