import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation } from "react-router-dom";

import RouterPage from "../../interfaces/RouterPage";
import { settingsPages } from "../../utils/settingsPages";
import ErrorBoundary from "../ErrorBoundary";
import { Icon } from "../Icon";

function BaseMainLayout() {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-col gap-4">
      <h1 className="text-lg text-dark-blue">{t("common.settings")}</h1>
      <div className="flex h-full flex-col gap-4 overflow-x-auto xl:flex-row">
        <div className="h-full w-full max-w-full rounded-lg border border-medium-gray bg-white xl:h-max xl:max-w-[320px]">
          <ul role="list" className="flex w-full flex-row xl:flex-col">
            {settingsPages.map((link: RouterPage) => (
              <li
                key={link.key}
                aria-disabled={link.isDisabled}
                className={clsx(
                  "w-full transition-all duration-200 ease-in-out first:rounded-bl-lg first:rounded-tl-lg hover:bg-light-blue xl:first:rounded-bl-none xl:first:rounded-tr-lg",
                  location.pathname === link.href && "bg-light-blue",
                  link.isDisabled && "pointer-events-none cursor-not-allowed",
                )}
              >
                <Link
                  to={link.href}
                  aria-disabled={link.isDisabled}
                  className={clsx(
                    link.isDisabled &&
                      "pointer-events-none cursor-not-allowed opacity-50",
                  )}
                >
                  <div
                    className={clsx(
                      "flex flex-row items-center gap-4 px-8 py-8 text-dark-blue transition-all duration-200 ease-in-out ",
                    )}
                  >
                    <Icon name={link.icon} />
                    <span
                      className={clsx("text-lg transition-all duration-700")}
                    >
                      {link.label}
                    </span>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="min-h-full w-full rounded-lg border border-medium-gray bg-white transition-all duration-200 ease-in-out">
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}

export default BaseMainLayout;
