import React from "react";
import { useParams } from "react-router-dom";
import {
  Cell,
  Column,
  Item,
  Row,
  Section,
  TableBody,
  TableHeader,
} from "react-stately";

import usePolicyTab from "../../hooks/usePolicyTab";
import Computer from "../../interfaces/Computer";
import ComputerGroup from "../../interfaces/ComputerGroup";
import PolicySeverity from "../../interfaces/PolicySeverity";
import SecurityPolicy from "../../interfaces/SecurityPolicy";
import { ComputerMultiSelect } from "../common/ComputerMultiSelect";
import { ConfirmDeletionModal } from "../common/ConfirmDeletionModal";
import { PolicySeverityMultiselect } from "../common/PolicySeverityMultiSelect";
import { Icon } from "../Icon";
import { MenuButton } from "../Menu";
import { Table } from "../table/Table";
import { TableActionsBar } from "../TableActionsBar";

function PolicyTab() {
  const { id } = useParams();
  const {
    securityPolicies,
    isLoading,
    tableActions,
    onMenuAction,
    setFilters,
    filters,
    dateFormatter,
    t,
    onRowMove,
    onRowDrop,
    onRowAction,
    activeSecurityPolicy,
    appliedFiltersCount,
    handleResetAdvancedFilters,
    handlePolicyDelete,
    deleteModalState,
    activePoliciesForDeletion,
    showTooltip,
    hideTooltip,
    isCurrentUserReadOnly,
    selectedSecurityPolicy,
  } = usePolicyTab({ id });

  return (
    <div className="flex h-full w-full flex-col overflow-x-hidden transition-all duration-200 ease-in-out">
      <TableActionsBar
        hasMoreFilters
        filtersCount={appliedFiltersCount}
        actions={tableActions}
        onSearchChange={(value) => {
          setFilters({ ...filters, search: value });
        }}
        isAdvancedSearchInitialOpen={appliedFiltersCount > 0}
        onAdvancedFilterOpenChange={(isOpen) => {
          if (!isOpen) {
            handleResetAdvancedFilters();
          }
        }}
        additionalFilters={
          <div className="grid w-full grid-cols-1 gap-4 px-2 py-4 md:grid-cols-2 lg:grid-cols-4">
            <ComputerMultiSelect
              maxSelectableItems={5}
              id="computers"
              hasList={false}
              hasNegativeIds={false}
              label={t("common.computers")}
              placeholder={t("common.computers")}
              selectedComputers={filters.computerID as Computer[]}
              selectedGroups={filters.groupComputerID as ComputerGroup[]}
              onComputerChange={(e) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  computerID: e,
                }));
              }}
              onGroupChange={(e) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  groupComputerID: e,
                }));
              }}
            />
            <PolicySeverityMultiselect
              id="severities"
              label={t("common.severity")}
              placeholder={t("common.severity")}
              selectedObjects={filters.severity as PolicySeverity[]}
              onChange={(e) => {
                setFilters({ ...filters, severity: e });
              }}
            />
          </div>
        }
      />
      <Table
        activeRowKey={activeSecurityPolicy}
        isDraggable={
          !isCurrentUserReadOnly && !isLoading && securityPolicies.length > 1
        }
        aria-labelledby="Security Policies"
        isLoading={isLoading}
        aria-label="Security Policies"
        sortDescriptor={filters.sort}
        onSortChange={(e) => setFilters({ ...filters, sort: e })}
        onRowMove={onRowMove}
        onRowDrop={onRowDrop}
        onRowAction={onRowAction}
      >
        <TableHeader>
          <Column allowsResizing allowsSorting key="name">
            {t("common.name")}
          </Column>
          <Column allowsResizing allowsSorting key="description">
            {t("common.description")}
          </Column>
          <Column allowsResizing allowsSorting key="createdOn">
            {t("common.dateOfCreation")}
          </Column>
          <Column allowsSorting key="priority">
            {t("common.levelOfPriority")}
          </Column>
          <Column width={50} key="status">
            {""}
          </Column>
          <Column width={50} key="_actions">
            {""}
          </Column>
        </TableHeader>
        <TableBody items={securityPolicies}>
          {(item: SecurityPolicy) => (
            <Row key={item.id}>
              {(columnKey) => {
                switch (columnKey) {
                  case "createdOn":
                    return (
                      <Cell>
                        {dateFormatter.format(new Date(item.createdOn))}
                      </Cell>
                    );
                  case "_actions":
                    if (isCurrentUserReadOnly) {
                      return <Cell>{""}</Cell>;
                    }
                    return (
                      <Cell>
                        <MenuButton
                          label={
                            <Icon
                              name="MenuIcon"
                              className="h-full text-extra-dark-gray"
                            />
                          }
                          onAction={(key) => onMenuAction(item.id, key)}
                        >
                          <Section>
                            <Item key="edit">{t("common.edit")}</Item>
                            {item.enable ? (
                              <Item key="suspend">
                                {t("common.suspendPolicy")}
                              </Item>
                            ) : (
                              <Item key="enable">
                                {t("common.enablePolicy")}
                              </Item>
                            )}
                          </Section>
                          <Section>
                            <Item key="delete" textValue={t("common.delete")}>
                              <span className="text-red">
                                {t("common.delete")}
                              </span>
                            </Item>
                          </Section>
                        </MenuButton>
                      </Cell>
                    );
                  case "status":
                    return (
                      <Cell>
                        {item.enable ? (
                          <div className="flex h-full w-full items-center justify-center">
                            <div className="my-auto h-4 w-4 rounded-full bg-green" />
                          </div>
                        ) : (
                          <div className="flex h-full w-full items-center justify-center">
                            <div
                              onMouseEnter={(e) =>
                                showTooltip(
                                  t("common.policyDisabled"),
                                  e,
                                  true,
                                  100,
                                )
                              }
                              onMouseLeave={hideTooltip}
                              className="my-auto h-4 w-4 rounded-full bg-dark-gray"
                            />
                          </div>
                        )}
                      </Cell>
                    );
                  default:
                    return (
                      <Cell>{(item as any)[String(columnKey)] || "N/D"}</Cell>
                    );
                }
              }}
            </Row>
          )}
        </TableBody>
      </Table>
      <ConfirmDeletionModal
        state={deleteModalState}
        title={t("dialog.deleteSecurityPolicy")}
        message={t("dialog.deleteSecurityPolicyMessage", {
          count: activePoliciesForDeletion.includes("all")
            ? securityPolicies.length
            : activePoliciesForDeletion.length,
          deleteKeyword: "delete",
          resourceName: selectedSecurityPolicy?.name,
        })}
        onConfirm={handlePolicyDelete}
        onCancel={deleteModalState.close}
        isLoading={isLoading}
      />
    </div>
  );
}

export default PolicyTab;
