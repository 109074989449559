import clsx from "clsx";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useOverlayTriggerState } from "react-stately";
import { Scrollbar } from "swiper/modules";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import useTooltip from "../../hooks/useTooltip";
import UserActivity from "../../interfaces/UserActivity";
import { findPolicyByType } from "../../utils/utils";
import { Icon } from "../Icon";
import { iconComponents } from "../IconImports";
import { Modal } from "../Modal";

import DashboardItem from "./DashboardItem";

interface Props {
  items: UserActivity[];
  dateFormatter: Intl.DateTimeFormat;
  isLoading?: boolean;
}

const DashboardRecentAlertsItem: React.FC<Props> = ({
  items,
  dateFormatter,
  isLoading,
}: Props) => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [selectedActivity, setSelectedActivity] = useState<UserActivity>();
  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const { t } = useTranslation();
  const { showTooltip, hideTooltip } = useTooltip();
  const detailModalState = useOverlayTriggerState({});

  /**
   * Handles the click event on a detail element.
   *
   * @param {UserActivity} element - The user activity element clicked.
   *
   * @returns {void}
   */
  const handleDetailClick = (element: UserActivity): void => {
    setSelectedActivity(element);
    detailModalState.open();
  };

  return (
    <DashboardItem
      title={t("dashboard.recentAlerts")}
      subtitle={
        <div className="flex flex-row items-center gap-2 text-azure">
          <Link to={"/activities"} className="underline">
            View all
          </Link>
          <Icon name="ArrowRightIcon" className="h-4 w-4" />
        </div>
      }
    >
      <div className="relative flex h-full w-full items-center justify-center p-4">
        <button
          className={clsx(
            "absolute -left-1 top-[45%] z-10 flex h-10 w-10 items-center justify-center rounded-full bg-light-gray opacity-95 transition-opacity duration-300 hover:opacity-100",
            items.length === 0 && "hidden",
          )}
          onClick={handlePrevious}
        >
          <Icon
            name="ArrowRightIcon"
            className="h-6 w-6 rotate-180 text-dark-blue"
          />
        </button>

        {!isLoading && items.length === 0 ? (
          <div className="flex h-[180px] flex-col items-center justify-center">
            <span className="leading-8">{t("dashboard.noRecentAlerts")}</span>
          </div>
        ) : (
          <Swiper
            modules={[Scrollbar]}
            onSwiper={setSwiperRef}
            spaceBetween={20}
            slidesPerView={1}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 3,
              },
            }}
            pagination={{ clickable: true }}
            scrollbar={{ hide: true }}
            className="w-full"
          >
            {isLoading ? (
              <>
                {Array.from({ length: 5 }).map((_, i) => (
                  <SwiperSlide key={i}>
                    <div className="m-1 h-[180px] animate-pulse bg-gray" />
                  </SwiperSlide>
                ))}
              </>
            ) : (
              <>
                {items?.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div className="flex h-[180px] flex-col gap-1 rounded-md border border-gray p-4">
                      <div className="flex flex-1 flex-col gap-1">
                        <div className="flex flex-row items-center justify-start gap-2">
                          <Icon
                            name="ComputersMenuIcon"
                            className="h-4 w-4 text-dark-blue"
                          />
                          <span
                            onMouseEnter={(e) =>
                              showTooltip(
                                item?.computer?.fullname ||
                                  "Undefined computer",
                                e,
                              )
                            }
                            onMouseLeave={hideTooltip}
                            className="flex-1 truncate text-sm leading-8"
                          >
                            {item?.computer?.fullname || "Undefined computer"}
                          </span>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-2">
                          <Icon
                            name={
                              item?.policy
                                ? (findPolicyByType(item?.policy?.type)
                                    ?.icon as keyof typeof iconComponents)
                                : "PoliciesMenuIcon"
                            }
                            className="h-4 w-4 text-dark-blue"
                          />
                          <span
                            onMouseEnter={(e) =>
                              showTooltip(
                                item?.policy
                                  ? findPolicyByType(item?.policy?.type)?.name
                                  : "Undefined policy",
                                e,
                              )
                            }
                            onMouseLeave={hideTooltip}
                            className="flex-1 truncate text-sm leading-8"
                          >
                            {item?.policy
                              ? findPolicyByType(item?.policy?.type)?.name
                              : "Undefined policy"}
                          </span>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-2">
                          <Icon
                            name="PoliciesMenuIcon"
                            className="h-4 w-4 text-dark-blue"
                          />
                          <span
                            onMouseEnter={(e) =>
                              showTooltip(
                                item?.policy?.name || "Undefined policy name",
                                e,
                              )
                            }
                            onMouseLeave={hideTooltip}
                            className="flex-1 truncate text-sm leading-8"
                          >
                            {item?.policy?.name || "Undefined policy name"}
                          </span>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-2">
                          <Icon
                            name="UserIcon"
                            className="h-4 w-4 text-dark-blue"
                          />
                          <span
                            onMouseEnter={(e) =>
                              showTooltip(
                                item?.user?.fullname || "Undefined user",
                                e,
                              )
                            }
                            onMouseLeave={hideTooltip}
                            className="flex-1 truncate text-sm leading-8"
                          >
                            {item?.user?.fullname || "Undefined user"}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between gap-2">
                        <div
                          onMouseEnter={(e) =>
                            showTooltip(
                              <pre className="max-w-[200px] grow-0 cursor-pointer overflow-scroll whitespace-pre-wrap rounded-md bg-extra-dark-gray p-2 text-white duration-200 ease-in-out hover:bg-dark-blue">
                                {JSON.stringify(item?.entity, null, 2)}
                              </pre>,
                              e,
                              true,
                              100,
                            )
                          }
                          onMouseLeave={hideTooltip}
                          className={clsx(
                            !item?.entity
                              ? "pointer-events-none opacity-50"
                              : "cursor-pointer",
                          )}
                          onClick={() => {
                            if (!item.entity) return;
                            handleDetailClick(item);
                          }}
                        >
                          <Icon
                            name="EyeIcon"
                            className="h-4 w-4 text-dark-blue"
                          />
                        </div>
                        <time className="text-sm text-dark-gray">
                          {item.createdOn
                            ? dateFormatter.format(new Date(item.createdOn))
                            : "N/D"}
                        </time>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </>
            )}
          </Swiper>
        )}
        <button
          className={clsx(
            "absolute right-0 top-[45%] z-10 flex h-10 w-10 items-center justify-center rounded-full bg-light-gray opacity-95 transition-opacity duration-300 hover:opacity-100",
            items.length === 0 && "hidden",
          )}
          onClick={handleNext}
        >
          <Icon name="ArrowRightIcon" className="h-6 w-6 text-dark-blue" />
        </button>
      </div>
      <Modal
        state={detailModalState}
        className="min-w-[50vh] max-w-[80vh] transition-all duration-200 ease-in-out"
      >
        <div className="flex w-full flex-1 grow flex-col gap-2">
          <h3 className="pb-2 text-xl text-dark-blue">
            {t("common.activityDescription")}
          </h3>
          <pre className="col-span-2 max-w-[80vh] grow-0 cursor-pointer overflow-scroll whitespace-pre-wrap rounded-md bg-extra-dark-gray p-2 text-white duration-200 ease-in-out">
            {JSON.stringify(selectedActivity?.entity, null, 2)}
          </pre>
        </div>
      </Modal>
    </DashboardItem>
  );
};
export default DashboardRecentAlertsItem;
