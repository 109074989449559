import clsx from "clsx";
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import User from "../../interfaces/User";
import { UserService } from "../../services/userService";
import Button from "../Button";
import { Icon } from "../Icon";
import MultiSelectComboBox, {
  MultiselectComboboxOption,
} from "../MultiSelectComboBox";

interface UserMultiSelectProps {
  id: string;
  selectedObjects: User[];
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  hasList?: boolean;
  onChange: (e: User[]) => void;
  onRemove?: (e: User) => void;
  maxSelectableItems?: number;
  clearable?: boolean;
  isReadOnly?: boolean;
}

interface FilterState {
  search?: string;
  count: number;
  offset: number;
}

const userService = new UserService();

export const UserMultiSelect: React.FC<UserMultiSelectProps> = ({
  id,
  selectedObjects,
  label,
  placeholder,
  disabled,
  hasList = true,
  onChange,
  onRemove,
  maxSelectableItems,
  clearable = true,
  isReadOnly,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [total, setTotal] = useState<number>(0);
  const { t } = useTranslation();
  const [filters, setFilters] = useState<FilterState>({
    count: 15,
    offset: 0,
    search: "",
  });

  /**
   * @desc Fetches computers from the API
   */
  const getUsers = useCallback(
    async (newFilters: FilterState, reset = false) => {
      setIsLoading(true);
      await userService
        .getUsers(qs.stringify(newFilters))
        .then((res) => {
          if (reset) {
            setUsers(res.data);
          } else {
            setUsers((prevUsers) =>
              reset ? res.data : [...prevUsers, ...res.data],
            );
          }
          setTotal(res.total);
          if (reset) setFilters((f) => ({ ...f, offset: 0 }));
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    },
    [],
  );

  useEffect(() => {
    getUsers(filters, true).then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * An array of parsed items.
   *
   * @type {Array<MultiselectComboboxOption>}
   */
  const parsedItems: Array<MultiselectComboboxOption> = useMemo(() => {
    return users.map((item) => {
      return {
        id: item.id,
        value: item?.fullname || "N/D",
      };
    });
  }, [users]);

  /**
   * Represents the parsed selected objects.
   *
   * @type {Array<MultiselectComboboxOption>}
   */
  const parsedSelectedObjects: Array<MultiselectComboboxOption> =
    useMemo(() => {
      return selectedObjects.map((item) => {
        return {
          id: item.id,
          value: item?.name || "N/D",
        };
      });
    }, [selectedObjects]);

  /**
   * Updates the selected objects and triggers the 'onChange' event.
   *
   * @param {MultiselectComboboxOption[]} selectedObjects - The selected objects to update.
   */
  const handleSelectionObjectChange = (
    selectedObjects: MultiselectComboboxOption[],
  ) => {
    const newSelectedObjects = selectedObjects
      .map((selectedObject) => {
        return users.find((item) => item.id === selectedObject.id);
      })
      .filter((item): item is User => item !== undefined);

    onChange(newSelectedObjects);
  };

  return (
    <div className="flex flex-col gap-4">
      <MultiSelectComboBox
        maxSelectableItems={maxSelectableItems}
        id={id}
        disabled={disabled || isReadOnly}
        clearable={clearable}
        selectedObjects={parsedSelectedObjects}
        onSelectionObjectChange={(e) => {
          handleSelectionObjectChange(e);
        }}
        totalItems={total}
        aria-label={label || t("common.usersPlaceholder")}
        placeholder={placeholder || t("common.usersPlaceholder")}
        isLoading={isLoading}
        items={parsedItems}
        onSearch={(searchTerm) => {
          const newFilters = { ...filters, offset: 0, search: searchTerm };
          setFilters(newFilters);
          getUsers(newFilters, true).then(() => {});
        }}
        onLoadMore={() => {
          if (isLoading || users.length >= total) return;
          const newFilters = {
            ...filters,
            offset: filters.offset + filters.count,
          };
          setFilters(newFilters);
          getUsers(newFilters, false).then(() => {});
        }}
      />
      {hasList && (
        <ul
          className={clsx(
            "grid grid-cols-1 gap-2 text-extra-dark-gray transition-all duration-200 ease-in-out",
            disabled && "pointer-events-none opacity-50",
          )}
        >
          {selectedObjects?.map((user) => (
            <li
              key={user.id}
              className="group flex w-full flex-row items-center justify-between gap-2"
            >
              <Icon name="UserIcon" className="h-5 w-5 text-pale-blue" />
              <span
                title={`${user.fullname}`}
                className="w-full truncate text-left"
              >
                {user.fullname}
              </span>
              <div className="z-0 opacity-0 transition-all duration-200 group-hover:opacity-100">
                <Button
                  variant="text"
                  onPress={() => {
                    if (disabled) return;
                    if (onRemove) {
                      onRemove(user);
                    }
                  }}
                >
                  <Icon name="RemoveIcon" className="h-5 w-5 text-red" />
                </Button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
