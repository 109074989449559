import clsx from "clsx";
import React from "react";
import type { AriaSwitchProps } from "react-aria";
import { useFocusRing, useSwitch, VisuallyHidden } from "react-aria";
import { useToggleState } from "react-stately";

interface SwitchProps extends AriaSwitchProps {
  prependChildren?: boolean;
}

export default function Switch(props: SwitchProps) {
  const state = useToggleState(props);
  const ref = React.useRef(null);
  const { inputProps } = useSwitch(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <label
      className={clsx(
        "flex items-center justify-center gap-2",
        props.isDisabled ? "cursor-not-allowed opacity-40" : "cursor-pointer",
        props.prependChildren ? "flex-row-reverse" : "flex-row",
      )}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <svg width={36} height={18} aria-hidden="true" style={{ marginRight: 4 }}>
        <rect
          x={0}
          y={0}
          width={36}
          height={18}
          rx={9}
          fill={state.isSelected ? "#0C4F7D" : "gray"}
        />
        <circle
          className="transition-all duration-100 ease-in-out"
          cx={state.isSelected ? 27 : 9}
          cy={9}
          r={7}
          fill="white"
        />
        {isFocusVisible && (
          <rect
            x={1}
            y={1}
            width={38}
            height={22}
            rx={11}
            fill="none"
            stroke="orange"
            strokeWidth={2}
          />
        )}
      </svg>
      {props.children}
    </label>
  );
}
