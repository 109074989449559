import clsx from "clsx";
import React from "react";

interface ResizablePanelProps {
  minWidth: number;
  maxWidth: number;
  width: number;
  className?: string;
  children: React.ReactNode;
  onWidthChange?: (width: number) => void;
}

const ResizablePanel: React.FC<ResizablePanelProps> = ({
  minWidth,
  maxWidth,
  className,
  children,
  onWidthChange,
  width,
}) => {
  const startResizing = (mouseDownEvent: React.MouseEvent<HTMLDivElement>) => {
    mouseDownEvent.preventDefault();

    const startX = mouseDownEvent.clientX;
    const startWidth = width;

    const onMouseMove = (mouseMoveEvent: MouseEvent) => {
      const dx = mouseMoveEvent.clientX - startX;

      const newWidth = Math.max(minWidth, Math.min(maxWidth, startWidth + dx));

      onWidthChange?.(newWidth);
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  return (
    <div
      style={{
        width: `${width}px`,
      }}
      className={clsx("relative", className)}
    >
      <div
        className="absolute bottom-0 right-0 top-0 z-40 w-[5px] cursor-ew-resize"
        onMouseDown={startResizing}
      />
      {children}
    </div>
  );
};

export default ResizablePanel;
