import { APIService } from "../api/apiService";

export class GenericService extends APIService {
  getPendingUpdateComputers() {
    return this.request("/Admin/ForceUpdate", "GET");
  }

  forceUpdatePendingComputers() {
    return this.request("/Admin/ForceUpdate", "POST");
  }
}
