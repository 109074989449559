import type { GridNode } from "@react-types/grid";
import type { SelectionBehavior } from "@react-types/shared";
import type { TableProps } from "@react-types/table";
import React, {
  CSSProperties,
  Key,
  useCallback,
  useRef,
  useState,
} from "react";
import type { AriaTableProps } from "react-aria";
import { useTable } from "react-aria";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTableColumnResizeState, useTableState } from "react-stately";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import { NoResultState } from "../common/NoResultState";
import { Icon } from "../Icon";

import DraggableTableRow from "./DraggableTableRow";
import TableCell from "./TableCell";
import { TableCheckboxCell, TableSelectAllCell } from "./TableCheckbox";
import TableColumnHeader from "./TableColumnHeader";
import TableHeaderRow from "./TableHeaderRow";
import TableRow from "./TableRow";
import TableRowGroup from "./TableRowGroup";

interface TableComponentProps<T> extends AriaTableProps<T>, TableProps<T> {
  selectionBehavior?: SelectionBehavior;
  onResizeStart?: (widths: Map<Key, number | string>) => void;
  onResize?: (widths: Map<Key, number | string>) => void;
  onResizeEnd?: (widths: Map<Key, number | string>) => void;
  isLoading?: boolean;
  children: any;
  style?: CSSProperties;
  isDraggable?: boolean;
  borderless?: boolean;
  striped?: boolean;
  onRowMove?: (fromIndex: number, toIndex: number) => void;
  onRowDrop?: (fromIndex: number, toIndex: number) => void;
  hasRowsClickable?: boolean;
  activeRowKey?: Key | null;
  noResultTitle?: string;
  noResultMessage?: string;
}

export function Table<T extends object>(props: TableComponentProps<T>) {
  const {
    selectionMode,
    selectionBehavior,
    onResizeStart,
    onResize,
    onResizeEnd,
    style,
    borderless,
    noResultTitle,
    noResultMessage,
  } = props;

  const ref = useRef(null);
  const bodyRef = useRef(null);
  const headerRef = useRef(null);
  const skeletonRef = useRef<HTMLTableSectionElement>(null);
  const tableRef = useRef<HTMLTableSectionElement>(null);
  const nodeRef = props.isLoading ? skeletonRef : tableRef;
  // Track the width of the table viewport (not the actual table body width)
  // for useTableColumnResizeState. This allows us to update column widths if
  // the table's width is dynamic instead of a static value (e.g. a percentage/vw value)
  const [tableWidth] = useState(0);
  const state = useTableState({
    ...props,
    showSelectionCheckboxes:
      selectionMode === "multiple" && selectionBehavior !== "replace",
  });

  const { collection } = state;
  const { gridProps } = useTable(props, state, ref);

  // Selection cell column should always take up a specific width, doesn't need to be resizable
  // All other columns have a undefined default width and a min width of 75px
  const getDefaultWidth = useCallback((node: GridNode<object>) => {
    if (node.props.isSelectionCell) {
      return 50;
    }
    return node.props.maxWidth || 75;
  }, []);

  const getDefaultMinWidth = useCallback((node: GridNode<object>) => {
    if (node.props.isSelectionCell) {
      return 50;
    }
    return node.props.minWidth || 100;
  }, []);

  const layoutState = useTableColumnResizeState(
    {
      getDefaultMinWidth,
      getDefaultWidth,
      tableWidth: tableWidth,
    },
    state,
  );

  /**
   * @description Callback fired when a row move starts
   */
  const moveRow = useCallback(
    (fromIndex: number, toIndex: number) => {
      if (props.onRowMove) {
        props.onRowMove(fromIndex, toIndex);
      }
    },
    [props],
  );

  /**
   * @description Callback fired when a row is dropped
   */
  const dropRow = useCallback(
    (fromIndex: number, toIndex: number) => {
      if (props.onRowDrop) {
        props.onRowDrop(fromIndex, toIndex);
      }
    },
    [props],
  );

  return (
    <div className="relative h-full overflow-auto">
      {/* <div
        className={clsx(
          "absolute inset-0 flex items-center justify-center bg-black backdrop-blur-md backdrop-saturate-50 transition-opacity duration-500 ease-in-out",
          props.isLoading && [...collection.body.childNodes].length !== 0
            ? "z-50 opacity-50"
            : "-z-10 opacity-0"
        )}
      >
        <ProgressCircle aria-label="loading" isIndeterminate={true} />
      </div>*/}
      <CSSTransition
        in={[...collection.body.childNodes].length === 0 && !props.isLoading}
        unmountOnExit
        timeout={{ enter: 0, exit: 250 }}
        classNames={{
          enter: "opacity-0 translate-y-0",
          enterDone:
            "opacity-1 translate-y-2 backdrop-blur-sm transition ease-in",
          exit: "opacity-0 translate-y-0 backdrop-blur-none transition ease-out",
        }}
      >
        <div className="absolute inset-x-0 inset-y-20 flex items-center justify-center">
          <NoResultState title={noResultTitle} message={noResultMessage} />
        </div>
      </CSSTransition>
      <DndProvider backend={HTML5Backend}>
        <table
          {...gridProps}
          ref={ref}
          className="relative w-full border-collapse overflow-x-auto"
          style={style}
        >
          <TableRowGroup
            type="thead"
            ref={headerRef}
            className="sticky top-0 z-10 w-full overflow-auto truncate bg-white"
          >
            {collection.headerRows.map((headerRow) => (
              <TableHeaderRow
                key={headerRow.key}
                item={headerRow}
                state={state}
                borderless={borderless}
              >
                {props.isDraggable && <div className="px-6" />}
                {[...headerRow.childNodes].map((column) =>
                  column.props.isSelectionCell ? (
                    <TableSelectAllCell
                      key={column.key}
                      column={column}
                      state={state}
                      layoutState={layoutState}
                    />
                  ) : (
                    <TableColumnHeader
                      key={column.key}
                      column={column}
                      state={state}
                      layoutState={layoutState}
                      onResizeStart={onResizeStart}
                      onResize={onResize}
                      onResizeEnd={onResizeEnd}
                    />
                  ),
                )}
              </TableHeaderRow>
            ))}
          </TableRowGroup>
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={props.isLoading ? "loading" : "loaded"}
              nodeRef={nodeRef}
              addEndListener={(done) => {
                nodeRef.current?.addEventListener("transitionend", done, false);
              }}
              timeout={300}
              classNames={{
                enter: "opacity-0",
                enterActive: "opacity-100 transition-opacity duration-300",
                exit: "opacity-100",
                exitActive: "opacity-0 transition-opacity duration-300",
              }}
              unmountOnExit
            >
              {props.isLoading ? (
                <tbody className="grid h-full w-full grid-cols-1 gap-2 divide-y divide-gray">
                  {Array.from({ length: 5 }, (_, index) => index).map((i) => (
                    <tr
                      key={i}
                      className="flex w-full flex-row items-center justify-between gap-4 px-2 py-5"
                    >
                      {collection.headerRows.map((headerRow, idx) => (
                        <React.Fragment key={idx}>
                          {[...headerRow.childNodes].map((column) => {
                            if (column.props.isSelectionCell) {
                              return (
                                <td
                                  key={column.key}
                                  className="ml-2 mr-2 h-4 w-4 flex-shrink-0 animate-pulse bg-medium-gray"
                                ></td>
                              );
                            } else if (column.rendered) {
                              return (
                                <td
                                  key={column.key}
                                  className="h-4 w-full animate-pulse bg-medium-gray"
                                ></td>
                              );
                            } else {
                              return (
                                <td
                                  key={column.key}
                                  className="h-4 w-4 animate-pulse bg-medium-gray"
                                ></td>
                              );
                            }
                          })}
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <TableRowGroup
                  ref={bodyRef}
                  className="h-52 overflow-auto"
                  type="tbody"
                >
                  {[...collection.body.childNodes].map((row, index) =>
                    props.isDraggable ? (
                      <DraggableTableRow
                        key={row.key}
                        item={row}
                        state={state}
                        index={index}
                        moveRow={moveRow}
                        onDrop={dropRow}
                        active={String(props.activeRowKey) === String(row.key)}
                      >
                        <td className="flex items-center justify-center px-3">
                          <Icon
                            name="DragIcon"
                            className="h-6 w-6 text-dark-gray"
                          />
                        </td>
                        {[...row.childNodes].map((cell) =>
                          cell.props.isSelectionCell ? (
                            <TableCheckboxCell
                              key={cell.key}
                              cell={cell}
                              state={state}
                              layoutState={layoutState}
                            />
                          ) : (
                            <TableCell
                              key={cell.key}
                              cell={cell}
                              state={state}
                              layoutState={layoutState}
                            />
                          ),
                        )}
                      </DraggableTableRow>
                    ) : (
                      <TableRow
                        borderless={borderless}
                        key={row.key}
                        item={row}
                        state={state}
                        isClickable={props.hasRowsClickable}
                        active={String(props.activeRowKey) === String(row.key)}
                      >
                        {[...row.childNodes].map((cell) =>
                          cell.props.isSelectionCell ? (
                            <TableCheckboxCell
                              key={cell.key}
                              cell={cell}
                              state={state}
                              layoutState={layoutState}
                            />
                          ) : (
                            <TableCell
                              isCompact={borderless}
                              key={cell.key}
                              cell={cell}
                              state={state}
                              layoutState={layoutState}
                            />
                          ),
                        )}
                      </TableRow>
                    ),
                  )}
                </TableRowGroup>
              )}
            </CSSTransition>
          </SwitchTransition>
        </table>
      </DndProvider>
    </div>
  );
}
