import clsx from "clsx";
import React, { useRef } from "react";
import type { AriaButtonProps } from "react-aria";
import { useButton } from "react-aria";

import ProgressCircle from "./ProgressCircle";

interface ButtonProps extends AriaButtonProps {
  children: React.ReactNode;
  variant?: "contained" | "text" | "outlined";
  size?: "normal" | "large";
  color?: "blue" | "red";
  isLoading?: boolean;
  noPadding?: boolean;
  className?: string;
}

const Button = (props: ButtonProps) => {
  const {
    children,
    variant = "contained",
    size = "normal",
    isLoading = false,
    noPadding = false,
    className,
  } = props;
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);

  const variantStyles = {
    contained: "bg-dark-blue text-white focus:opacity-90 ",
    outlined: "border border-dark-blue text-dark-blue focus:border-opacity-90 ",
    text: "bg-transparent text-dark-blue focus:opacity-90 ",
  };

  const sizeStyles = {
    large: "px-4 py-3.5",
    normal: "px-4 py-2",
  };

  const buttonVariantStyles = variantStyles[variant];
  const buttonSizeStyles = sizeStyles[size];

  return (
    <button
      {...buttonProps}
      ref={ref}
      className={clsx(
        buttonVariantStyles,
        !noPadding && buttonSizeStyles,
        "relative w-full rounded-md outline-none transition-opacity duration-200 ease-in-out disabled:cursor-not-allowed disabled:opacity-50",
        isLoading
          ? "pointer-events-none opacity-50"
          : "hover:opacity-90 active:opacity-80",
        className,
      )}
      disabled={isLoading || props.isDisabled}
    >
      {isLoading && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
          <ProgressCircle
            aria-label="Loading..."
            aria-labelledby="Loading..."
            aria-loading="Loading..."
            isIndeterminate
          />
        </div>
      )}
      <span
        className={clsx("transition-opacity duration-200", {
          "opacity-0": isLoading,
        })}
      >
        {children}
      </span>
    </button>
  );
};

export default Button;
