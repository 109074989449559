import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "../components/Icon";
import PoliciesTab from "../components/policy/PoliciesTab";

export const Policies: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex h-full w-full flex-col items-start">
      <div className="px-6 py-4 text-dark-blue">
        <div className="flex flex-row items-center space-x-2">
          <Icon name="PoliciesMenuIcon" className="h-5 w-5" />
          <span className="text-lg font-semibold">{t("common.policies")}</span>
        </div>
      </div>
      <PoliciesTab />
    </div>
  );
};
