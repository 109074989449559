import React from "react";

import { NotificationTypes } from "../../contexts/NotificationContext";
import Notification from "../Notification";

function NotificationList() {
  const notificationTypes = Object.values(NotificationTypes);

  const getRandomNotificationType = () => {
    const randomIndex = Math.floor(Math.random() * notificationTypes.length);
    return notificationTypes[randomIndex];
  };

  return (
    <>
      <div className="sticky top-0 z-10 w-full bg-white/50 px-4 py-6 backdrop-blur-sm">
        <p className="text-lg font-semibold uppercase text-steel-blue">
          NOTIFICATIONS
        </p>
      </div>
      <div className="grid grid-cols-1 gap-2 overflow-y-scroll px-4 pb-4">
        {Array.from({ length: 30 }).map((_, index) => (
          <Notification
            key={index}
            message={"Lorem ipsum"}
            type={getRandomNotificationType()}
            onClose={() => {}}
            hasBorder={false}
            hasIcon={false}
            hasBoxShadow={false}
          />
        ))}
      </div>
    </>
  );
}

export default NotificationList;
