import React, { useContext, useRef } from "react";
import type {
  AriaCheckboxGroupItemProps,
  AriaCheckboxGroupProps,
} from "react-aria";
import { useCheckboxGroup, useCheckboxGroupItem } from "react-aria";
import type { CheckboxGroupState } from "react-stately";
import { useCheckboxGroupState } from "react-stately";

interface CheckboxGroupProps extends AriaCheckboxGroupProps {
  children: React.ReactNode;
  description?: string;
  errorMessage?: string;
}

interface CheckboxProps extends AriaCheckboxGroupItemProps {
  children: React.ReactNode;
}

const CheckboxGroupContext = React.createContext<CheckboxGroupState | null>(
  null,
);

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  children,
  description,
  errorMessage,
  ...props
}) => {
  const state = useCheckboxGroupState(props);
  const { groupProps, labelProps, descriptionProps, errorMessageProps } =
    useCheckboxGroup(props, state);

  return (
    <div {...groupProps}>
      <span className="block" {...labelProps}>
        {props.label}
      </span>
      <CheckboxGroupContext.Provider value={state}>
        {children}
      </CheckboxGroupContext.Provider>
      {description && (
        <div {...descriptionProps} style={{ fontSize: 12 }}>
          {description}
        </div>
      )}
      {errorMessage && props.validationState === "invalid" && (
        <div {...errorMessageProps} style={{ color: "red", fontSize: 12 }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

const CheckboxGroupItem: React.FC<CheckboxProps> = ({ children, ...props }) => {
  const state = useContext(CheckboxGroupContext);
  const ref = useRef<HTMLInputElement>(null);

  if (!state) {
    // handle the case where state is null
    // this can be a return of some default UI or throw an error, depending on your needs
    throw new Error("Checkbox used outside of a CheckboxGroupContext Provider");
  }

  const { inputProps } = useCheckboxGroupItem(props, state, ref);

  //let isDisabled = state?.isDisabled || props.isDisabled;
  const isSelected = state?.isSelected(props.value);

  return (
    <label className="flex cursor-pointer items-center gap-2">
      <div
        className={`
        relative flex h-[16px] w-[16px] cursor-pointer items-center justify-center overflow-hidden rounded-sm border text-xs transition-all duration-200 hover:opacity-90 
        ${
          isSelected ? "border-dark-blue bg-dark-blue" : "border-gray bg-white "
        }`}
      >
        <input
          {...inputProps}
          ref={ref}
          className="absolute inset-0 z-10 cursor-pointer appearance-none opacity-0"
        />
        {isSelected && <span className="z-0 text-white">✓</span>}
      </div>
      {children && <p className="text-base text-extra-dark-gray">{children}</p>}
    </label>
  );
};

export { CheckboxGroup, CheckboxGroupItem };
