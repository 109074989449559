import clsx from "clsx";
import React from "react";

import useComputerDetail from "../../hooks/useComputerDetail";
import { Accordion, AccordionItem } from "../Accordion";
import Button from "../Button";
import Checkbox from "../Checkbox";
import { UserMultiSelect } from "../common/UserMultiSelect";
import { Icon } from "../Icon";
import TextArea from "../TextArea";

interface ComputerDetailProps {
  id?: number;
  onCancel: () => void;
  onSaved: () => void;
}

const ComputerDetail: React.FC<ComputerDetailProps> = (
  props: ComputerDetailProps,
) => {
  const {
    isLoading,
    groups,
    onSubmit,
    handleCancel,
    handleInputChange,
    form,
    computer,
    isCurrentUserReadOnly,
    t,
  } = useComputerDetail({
    id: Number(props.id),
    onCancel: props.onCancel,
    onSaved: props.onSaved,
  });
  return (
    <form
      onSubmit={onSubmit}
      className="flex h-full flex-col justify-between space-y-4"
    >
      <div className="flex flex-grow flex-col space-y-4 divide-y divide-medium-gray">
        <div className="flex w-full flex-row items-start justify-start gap-4 px-8 py-4">
          <div className="flex w-full flex-col space-y-4">
            <div className="flex flex-row items-center justify-start gap-4">
              <Icon
                name="ComputersMenuIcon"
                className="h-8 w-8 text-dark-blue"
              />
              <span
                className={clsx(
                  "text-xl font-medium text-dark-blue",
                  isLoading && "blur-sm",
                )}
              >
                {computer?.name ? computer?.name : t("common.editComputer")}
              </span>
            </div>
            <p
              className={clsx("text-sm font-semibold", isLoading && "blur-sm")}
            >{`Created on ${computer?.createdOn}`}</p>
            <div className="grid w-full grid-cols-1 gap-3">
              <TextArea
                name="description"
                type="text"
                label={t("common.description")}
                value={form.description}
                isDisabled={isLoading}
                isReadOnly={isCurrentUserReadOnly}
                onChange={(e) => handleInputChange("description", e)}
              />
              <Checkbox
                isSelected={form.anonymous}
                isDisabled={isLoading || isCurrentUserReadOnly}
                onChange={(e) => handleInputChange("anonymous", e)}
              >
                <span className="text-extra-dark-gray">
                  {t("common.accessWithoutOTP")}
                </span>
              </Checkbox>
            </div>
            {groups.length > 0 && (
              <>
                <span className="mt-4 text-extra-dark-gray">
                  {t("common.partOfUserGroups")}:
                </span>
                <ul className="list-disc gap-2 pl-4 pt-2 text-extra-dark-gray">
                  {groups?.map((group) => <li key={group.id}>{group.name}</li>)}
                </ul>
              </>
            )}
          </div>
        </div>
        <Accordion>
          <AccordionItem title={`${t("common.users")} (${form.users.length})`}>
            <UserMultiSelect
              id="users"
              selectedObjects={form.users}
              disabled={!form.anonymous || isLoading || isCurrentUserReadOnly}
              onChange={(e) => {
                handleInputChange("users", e);
              }}
              onRemove={(e) => {
                handleInputChange(
                  "users",
                  form.users?.filter((item) => item.id !== e.id),
                );
              }}
            />
          </AccordionItem>
        </Accordion>
      </div>
      <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2">
        <div>
          <Button
            onPress={handleCancel}
            type="button"
            isDisabled={isLoading}
            variant="text"
          >
            {t("common.cancel")}
          </Button>
        </div>
        <div>
          <Button
            type="submit"
            isLoading={isLoading}
            isDisabled={isCurrentUserReadOnly}
            variant="contained"
          >
            {t("common.save")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ComputerDetail;
