import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Notification from "../components/Notification";
import { EventBus, EventBusEvents } from "../utils/EventBus";
import { generateUUID } from "../utils/utils";

export enum NotificationTypes {
  SUCCESS = "success",
  NOTICE = "notice",
  DANGER = "danger",
  WARNING = "warning",
  INFO = "info",
}

export interface NotificationType {
  id: string;
  message: string;
  type: NotificationTypes;
}

export interface NotificationContextType {
  createNotification: (message: string, type: NotificationTypes) => void;
}

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationContext = React.createContext<
  Partial<NotificationContextType>
>({});

const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  /**
   * @desc Adds a notification to the list of notifications
   * @param message
   * @param type
   */
  const createNotification = useCallback(
    (message: string, type: NotificationTypes) => {
      setNotifications((prevNotifications) =>
        prevNotifications.concat({
          id: generateUUID(),
          message,
          type,
        }),
      );
    },
    [],
  );

  /**
   * @desc Listens for CREATE_NOTIFICATION events and calls createNotification
   */
  useEffect(() => {
    const handler = (data: { message: string; type: NotificationTypes }) => {
      createNotification(data.message, data.type);
    };
    EventBus.on(EventBusEvents.CREATE_NOTIFICATION, handler);
    return () => {
      EventBus.off(EventBusEvents.CREATE_NOTIFICATION, handler);
    };
  }, [createNotification]);

  return (
    <NotificationContext.Provider value={{ createNotification }}>
      {children}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed bottom-24 right-4 z-40 w-full max-w-lg"
      >
        <TransitionGroup className="grid grid-cols-1 gap-2">
          {notifications.map((notification) => (
            <CSSTransition
              timeout={300}
              key={notification.id}
              classNames={{
                enter: "opacity-0 translate-x-2",
                enterActive:
                  "opacity-100 translate-x-0 transition-all duration-300 ease-out",
                exit: "opacity-100 translate-x-0",
                exitActive:
                  "opacity-0 translate-x-full transition-all duration-300 ease-in",
              }}
              unmountOnExit
            >
              <Notification
                {...notification}
                onClose={() => {
                  setNotifications((prevNotifications) =>
                    prevNotifications.filter(
                      (item) => item.id !== notification.id,
                    ),
                  );
                }}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
