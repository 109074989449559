import {
  CalendarDate,
  CalendarDateTime,
  getLocalTimeZone,
  ZonedDateTime,
} from "@internationalized/date";
import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { TimespanOptionKey } from "../interfaces/TimespanOption";
import { TimespanOptions } from "../utils/timespanOptions";
import { getTimestampOptionDates } from "../utils/utils";

import DatePicker from "./DatePicker";
import { Icon } from "./Icon";
import { Radio, RadioGroup } from "./RadioGroup";

interface TimespanDropdownProps {
  onChange: (from: Date, to: Date, option: TimespanOptionKey) => void;
  disabled?: boolean;
  initialOption?: TimespanOptionKey;
  initialCustomFrom?: CalendarDateTime | ZonedDateTime | CalendarDate;
  initialCustomTo?: CalendarDateTime | ZonedDateTime | CalendarDate;
  wrapperClassName?: string;
}

export const TimespanDropdown: React.FC<TimespanDropdownProps> = ({
  onChange,
  disabled,
  initialOption,
  wrapperClassName,
  initialCustomFrom,
  initialCustomTo,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isListOpenAbove, setIsListOpenAbove] = useState(false);
  const [selectedOption, setSelectedOption] = useState<TimespanOptionKey>(
    initialOption || TimespanOptionKey.LastTwentyFourHours,
  );
  const [customFrom, setCustomFrom] = useState<
    CalendarDateTime | ZonedDateTime | CalendarDate | undefined
  >(initialCustomFrom);
  const [customTo, setCustomTo] = useState<
    CalendarDateTime | ZonedDateTime | CalendarDate | undefined
  >(initialCustomTo);
  const { t } = useTranslation();

  /**
   * Handles the change event of a radio input.
   * @param {TimespanOptionKey} value - The selected value of the radio input.
   * @returns {void}
   */
  const handleRadioChange = (value: TimespanOptionKey): void => {
    setSelectedOption(value);
    if (value === TimespanOptionKey.Custom && !customFrom && !customTo) {
      return;
    }

    const { from, to } = getTimestampOptionDates(value);

    onChange(from, to, value);
  };

  /**
   * Returns the label of the selected option.
   *
   * @returns {string} The label of the selected option.
   *
   * @param {number} selectedOption - The index of the selected option.
   * @param {Array<Object>} TimespanOptions - The array of timespan options.
   */
  const selectedOptionLabel = useMemo(() => {
    if (!selectedOption) {
      return t("common.selectAnOption");
    }
    return TimespanOptions[selectedOption].label;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  /**
   * @description Handles the click event on the dropdown.
   */
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        contentRef.current &&
        !contentRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  /**
   * @description Check if is disabled and close the dropdown.
   */
  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
    }
  }, [disabled]);

  useEffect(() => {
    if (selectedOption !== TimespanOptionKey.Custom) {
      setIsOpen(false);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (isOpen && ref.current) {
      const rect = ref.current?.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect) {
        const isInputLowerThanHalfScreen = rect.top > windowHeight / 2;
        setIsListOpenAbove(isInputLowerThanHalfScreen);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && ref.current && contentRef.current) {
      const triggerRect = ref.current.getBoundingClientRect();
      const listBoxHeight = contentRef.current.offsetHeight;
      const spaceAbove = triggerRect.top;
      const spaceBelow = window.innerHeight - triggerRect.bottom;
      const hasEnoughSpaceBelow = spaceBelow >= listBoxHeight;

      setIsListOpenAbove(!hasEnoughSpaceBelow);

      const listBoxStyle = contentRef.current.style;
      listBoxStyle.width = `${triggerRect.width}px`;
      listBoxStyle.left = `${triggerRect.left}px`;

      if (!hasEnoughSpaceBelow && spaceAbove >= listBoxHeight) {
        listBoxStyle.top = "";
        listBoxStyle.bottom = `${window.innerHeight - triggerRect.top + 10}px`;
      } else {
        listBoxStyle.bottom = "";
        listBoxStyle.top = `${triggerRect.bottom + 10}px`;
      }
    }
  }, [isOpen, ref, contentRef]);

  /**
   * @description Handles the scroll event on the window to keep the dropdown in the right position.
   */
  useEffect(() => {
    const handleScroll = () => {
      if (isOpen && ref.current && contentRef.current) {
        const triggerRect = ref.current.getBoundingClientRect();
        const listBoxHeight = contentRef.current.offsetHeight;
        const spaceAbove = triggerRect.top;
        const spaceBelow = window.innerHeight - triggerRect.bottom;
        const hasEnoughSpaceBelow = spaceBelow >= listBoxHeight;

        setIsListOpenAbove(!hasEnoughSpaceBelow);

        const listBoxStyle = contentRef.current.style;
        listBoxStyle.width = `${triggerRect.width}px`;
        listBoxStyle.left = `${triggerRect.left}px`;

        if (!hasEnoughSpaceBelow && spaceAbove >= listBoxHeight) {
          listBoxStyle.top = "";
          listBoxStyle.bottom = `${
            window.innerHeight - triggerRect.top + 10
          }px`;
        } else {
          listBoxStyle.bottom = "";
          listBoxStyle.top = `${triggerRect.bottom + 10}px`;
        }
      }
    };

    if (isOpen) {
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, [isOpen, ref, contentRef]);

  return (
    <div
      ref={ref}
      className={clsx(
        "relative w-full transition-all duration-200 ease-in-out",
        disabled && "pointer-events-none bg-light-gray opacity-50",
      )}
    >
      <div
        className={clsx(
          "flex cursor-pointer flex-row items-center justify-center gap-2 rounded-full bg-extra-light-blue px-2 py-1 text-dark-blue",
          wrapperClassName,
        )}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span className="font-semibold">{t("common.timespan")}</span>
        <div className="flex flex-row items-center gap-0.5">
          <span>{selectedOptionLabel}</span>
          <Icon name="ArrowDropDownIcon" className="h-5 w-5" />
        </div>
      </div>
      {isOpen &&
        createPortal(
          <div
            ref={contentRef}
            className={clsx(
              "fixed z-30 min-w-[150px] overflow-y-auto rounded-[6px] border border-gray bg-white p-3 shadow-md transition-transform duration-200 ease-in-out",
              isListOpenAbove && "bottom-16 top-auto",
              isOpen ? "translate-y-0 opacity-100" : "translate-y-2 opacity-0",
            )}
          >
            <RadioGroup
              value={selectedOption}
              onChange={(value) =>
                handleRadioChange(value as TimespanOptionKey)
              }
              label={t("common.timespan")}
              errorMessage="Error message"
            >
              {Object.values(TimespanOptions).map((option) => (
                <Radio key={option.key} value={option.key}>
                  {option.label}
                </Radio>
              ))}
            </RadioGroup>
            {selectedOption === TimespanOptionKey.Custom && (
              <div className="grid w-full grid-cols-1 gap-2 pl-4 pt-2">
                <DatePicker
                  minValue={new CalendarDate(2020, 1, 1)}
                  value={customFrom}
                  onChange={(value) => {
                    if (value && customTo) {
                      onChange(
                        value.toDate(getLocalTimeZone()),
                        customTo.toDate(getLocalTimeZone()),
                        selectedOption,
                      );
                    }
                    setCustomFrom(value);
                  }}
                  granularity={"minute"}
                  label={t("common.from")}
                />
                <DatePicker
                  value={customTo}
                  minValue={new CalendarDate(2020, 1, 1)}
                  onChange={(value) => {
                    if (value && customFrom) {
                      onChange(
                        customFrom.toDate(getLocalTimeZone()),
                        value.toDate(getLocalTimeZone()),
                        selectedOption,
                      );
                    }
                    setCustomTo(value);
                  }}
                  label={t("common.to")}
                  granularity={"minute"}
                />
              </div>
            )}
          </div>,
          document.getElementById("select-root")!,
        )}
    </div>
  );
};
