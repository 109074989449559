import clsx from "clsx";
import React from "react";

import useTooltip from "../hooks/useTooltip";

interface ProgressBarSegment {
  percentage: number;
  label: string;
  backgroundColor: string;
}

interface Props {
  segments: ProgressBarSegment[];
}

const ProgressBar: React.FC<Props> = ({ segments }) => {
  const { showTooltip, hideTooltip } = useTooltip();

  return (
    <div className="flex flex-col">
      <div className="mb-2 flex h-3 overflow-hidden rounded bg-gray text-xs">
        {" "}
        {segments.map((segment, index) => (
          <div
            onMouseEnter={(e) =>
              showTooltip(
                `${segment.label} - ${Math.ceil(segment.percentage)}%`,
                e,
                true,
              )
            }
            onMouseLeave={hideTooltip}
            key={index}
            style={{ width: `${Math.ceil(segment.percentage)}%` }}
            className={clsx(
              "flex flex-col justify-center truncate whitespace-nowrap text-center text-[8px] font-medium text-white shadow-none transition-all duration-200 ease-in-out",
              segment.backgroundColor,
            )}
          >
            {segment.percentage >= 15 && segment.percentage + "%"}
          </div>
        ))}
      </div>
      <div className="flex justify-between">
        {" "}
        {segments.map((segment, index) => (
          <div
            key={index}
            className={clsx(
              "truncate text-center text-[8px] font-medium transition-all duration-200 ease-in-out",
              segment.percentage < 15 && "opacity-0",
            )}
            style={{
              width: `${Math.ceil(segment.percentage)}%`,
            }}
          >
            {segment.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
