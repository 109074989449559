import { APIService, RequestBody } from "../api/apiService";

export class UserGroupService extends APIService {
  /**
   * @description Fetch user group by id
   */
  getUserGroup(id: string | number) {
    return this.request(`/Admin/UserGroup/${id}`, "GET");
  }

  /**
   * @description Fetches all users groups
   */
  getUserGroups(query?: string | null) {
    return this.request(`/Admin/UserGroup?${query}`, "GET");
  }

  /**
   * @description Updates a user group
   */
  updateUserGroup(id: string | number, payload: RequestBody) {
    return this.request(`/Admin/UserGroup/${id}`, "PUT", payload);
  }

  /**
   * @description Create a user group
   */
  createUserGroup(payload: RequestBody) {
    return this.request(`/Admin/UserGroup`, "POST", payload);
  }

  /**
   * @description Delete a user group
   */
  deleteUserGroups(query: string) {
    return this.request(`/Admin/UserGroup?${query}`, "DELETE");
  }
}
