import type { GridNode } from "@react-types/grid";
import React, { ReactNode, useRef } from "react";
import { mergeProps, useFocusRing, useTableRow } from "react-aria";
import { useDrag, useDrop } from "react-dnd";
import type { TableState } from "react-stately";

interface TableRowProps<T> {
  item: GridNode<T>;
  children: ReactNode;
  state: TableState<T>;
  index: number;
  moveRow: (fromIndex: number, toIndex: number) => void;
  onDrop?: (fromIndex: number, toIndex: number) => void;
  striped?: boolean;
  active?: boolean;
}

export default function DraggableTableRow<T>(props: TableRowProps<T>) {
  const { item, children, state, index, active, striped, moveRow, onDrop } =
    props;
  const ref = useRef(null);
  const isSelected = state.selectionManager.isSelected(item.key);
  const { rowProps, isPressed } = useTableRow(
    {
      node: item,
    },
    state,
    ref,
  );
  const { isFocusVisible, focusProps } = useFocusRing();

  const [, drop] = useDrop({
    accept: "ROW",
    drop: (draggedItem: { type: string; index: number }) => {
      if (onDrop) {
        onDrop(draggedItem.index, index);
      }
    },
    hover: (draggedItem: { type: string; index: number }) => {
      if (draggedItem.index !== index) {
        moveRow(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { index, type: "ROW" },
    type: "ROW",
  });

  drag(drop(ref)); // Combining drag and drop refs

  let background;
  if (isSelected) {
    background = "bg-light-gray";
  } else if (isPressed) {
    background = "bg-light-blue";
  } else if (item.index && item.index % 2 && striped) {
    background = "bg-extra-light-gray";
  } else {
    background = "none";
  }

  if (active) {
    background = "bg-light-blue";
  }

  const boxShadow = isFocusVisible
    ? "shadow-[inset_0_0_0_2px_azure]"
    : "shadow-none";

  return (
    <tr
      className={`${background} cursor-pointer border-b border-medium-gray outline-none ${boxShadow} flex w-full ${
        isDragging ? "opacity-50" : ""
      }`}
      {...mergeProps(rowProps, focusProps)}
      ref={ref}
    >
      {children}
    </tr>
  );
}
