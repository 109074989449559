import CryptoJS from "crypto-js";

export interface IStorageProvider {
  localStorage: Storage;
  sessionStorage: Storage;
}

interface StorageChangeCallback {
  (key: string, newValue: any, oldValue: any): void;
}

const StorageProvider: IStorageProvider = {
  localStorage: window.localStorage,
  sessionStorage: window.sessionStorage,
};

const SECRET_KEY = import.meta.env.VITE_APP_CRYPTO_KEY || "default";
const onChangeCallbacks: StorageChangeCallback[] = [];

/**
 * Registers a callback to be invoked on storage change.
 * @param callback The callback to register.
 */
export function onStorageChange(callback: StorageChangeCallback): () => void {
  onChangeCallbacks.push(callback);

  // Restituisce una funzione per rimuovere il callback
  return () => {
    const index = onChangeCallbacks.indexOf(callback);
    if (index !== -1) {
      onChangeCallbacks.splice(index, 1);
    }
  };
}

/**
 * Invokes registered callbacks with change details.
 * @param key The key that was changed.
 * @param newValue The new value of the item.
 * @param oldValue The old value of the item.
 */
function invokeOnChangeCallbacks(
  key: string,
  newValue: any,
  oldValue: any,
): void {
  onChangeCallbacks.forEach((callback) => callback(key, newValue, oldValue));
}

/**
 * @desc Save data (object or string) to storage
 * @param storageType
 * @param key
 * @param data
 */
export function storeStorageItem(
  storageType: keyof IStorageProvider,
  key: string,
  data: object | string | number | boolean | bigint | null,
): void {
  const storage = StorageProvider[storageType];
  const oldValue = getStorageItem(storageType, key);
  const stringData = typeof data === "string" ? data : JSON.stringify(data);
  const encryptedData = CryptoJS.AES.encrypt(stringData, SECRET_KEY).toString();
  storage.setItem(key, encryptedData);
  invokeOnChangeCallbacks(key, data, oldValue);
}

/**
 * @desc Load data (object or string) from storage
 * @param storageType
 * @param key
 */
export function getStorageItem(
  storageType: keyof IStorageProvider,
  key: string,
): object | string | number | boolean | null {
  const storage = StorageProvider[storageType];
  const encryptedData = storage.getItem(key);
  if (!encryptedData) {
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    try {
      return JSON.parse(decryptedData);
    } catch {
      return decryptedData;
    }
  } catch (err) {
    console.error("Decryption failed", err);
    return null;
  }
}

/**
 * @desc Delete data from storage
 * @param storageType
 * @param key
 */
export function deleteStorageItem(
  storageType: keyof IStorageProvider,
  key: string,
): void {
  const oldValue = getStorageItem(storageType, key); // Ottieni il valore precedente per il callback
  const storage = StorageProvider[storageType];
  storage.removeItem(key);
  invokeOnChangeCallbacks(key, null, oldValue);
}

/**
 * @desc Clear all data from storage
 */
export function clearStorage(): void {
  window.localStorage.clear();
  window.sessionStorage.clear();
}
