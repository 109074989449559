export enum StorageItemKeyEnum {
  routesWithNotifications = "routesWithNotifications",
  userActivitiesFilters = "userActivitiesFilters",
  userActivitiesGroupingCriteria = "userActivitiesGroupingCriteria",
  userActivitiesResizablePanelWidth = "userActivitiesResizablePanelWidth",
  token = "token",
  tokenSR = "tokenSR",
  currentLicense = "currentLicense",
  currentUser = "currentUser",
  currentComputer = "currentComputer",
  currentRole = "currentRole",
  passwordForgotTimer = "passwordForgotTimer",
}
