import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from "@microsoft/signalr";

let connection: HubConnection | null = null;

export const getSignalRConnection = (
  tokenSR: string,
  licenseId: string,
): HubConnection => {
  if (!connection) {
    connection = new HubConnectionBuilder()
      .withUrl(
        `${import.meta.env.VITE_APP_SIGNALR_URL}?hub=RemotegrantHub&licenseId=${encodeURIComponent(licenseId)}`,

        {
          accessTokenFactory: () => tokenSR,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        },
      )
      .withAutomaticReconnect()
      .build();
  }

  return connection;
};
