import clsx from "clsx";
import React from "react";

interface SettingSectionProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  borderless?: boolean;
  withoutPadding?: boolean;
}

const SettingSection: React.FC<SettingSectionProps> = ({
  title,
  subtitle,
  children,
  borderless,
  withoutPadding,
}) => {
  return (
    <div
      className={clsx(
        "flex h-full w-full flex-col items-start",
        !borderless && "border-b border-medium-gray",
      )}
    >
      <div className="flex flex-col gap-2 p-4 lg:p-8">
        <h1 className="text-xl text-dark-blue">{title}</h1>
        <p className="text-dark-gray">{subtitle}</p>
      </div>
      <div className={clsx("w-full", !withoutPadding && "p-8")}>{children}</div>
    </div>
  );
};

export default SettingSection;
