import { AdminActivityTypeEnum } from "../enum/AdminActivityTypeEnum";
import AdminActivityType from "../interfaces/AdminActivityType";

import { translate } from "./translation";

export const adminActivityTypes: AdminActivityType[] = [
  {
    description: translate("adminActivityTypes.login.description"),
    id: AdminActivityTypeEnum.Login,
    key: "Login",
    name: translate("adminActivityTypes.login.name"),
    type: AdminActivityTypeEnum.Login,
  },
  {
    description: translate("adminActivityTypes.failedLogin.description"),
    id: AdminActivityTypeEnum.FailedLogin,
    key: "FailedLogin",
    name: translate("adminActivityTypes.failedLogin.name"),
    type: AdminActivityTypeEnum.FailedLogin,
  },
  {
    description: translate("adminActivityTypes.createRole.description"),
    id: AdminActivityTypeEnum.CreateRole,
    key: "CreateRole",
    name: translate("adminActivityTypes.createRole.name"),
    type: AdminActivityTypeEnum.CreateRole,
  },
  {
    description: translate("adminActivityTypes.updateRole.description"),
    id: AdminActivityTypeEnum.UpdateRole,
    key: "UpdateRole",
    name: translate("adminActivityTypes.updateRole.name"),
    type: AdminActivityTypeEnum.UpdateRole,
  },
  {
    description: translate("adminActivityTypes.removeRole.description"),
    id: AdminActivityTypeEnum.RemoveRole,
    key: "RemoveRole",
    name: translate("adminActivityTypes.removeRole.name"),
    type: AdminActivityTypeEnum.RemoveRole,
  },
  {
    description: translate("adminActivityTypes.updateComputer.description"),
    id: AdminActivityTypeEnum.UpdateComputer,
    key: "UpdateComputer",
    name: translate("adminActivityTypes.updateComputer.name"),
    type: AdminActivityTypeEnum.UpdateComputer,
  },
  {
    description: translate("adminActivityTypes.removeComputer.description"),
    id: AdminActivityTypeEnum.RemoveComputer,
    key: "RemoveComputer",
    name: translate("adminActivityTypes.removeComputer.name"),
    type: AdminActivityTypeEnum.RemoveComputer,
  },
  {
    description: translate("adminActivityTypes.createGroupUsers.description"),
    id: AdminActivityTypeEnum.CreateGroupUsers,
    key: "CreateGroupUsers",
    name: translate("adminActivityTypes.createGroupUsers.name"),
    type: AdminActivityTypeEnum.CreateGroupUsers,
  },
  {
    description: translate("adminActivityTypes.updateGroupUsers.description"),
    id: AdminActivityTypeEnum.UpdateGroupUsers,
    key: "UpdateGroupUsers",
    name: translate("adminActivityTypes.updateGroupUsers.name"),
    type: AdminActivityTypeEnum.UpdateGroupUsers,
  },
  {
    description: translate("adminActivityTypes.removeGroupUsers.description"),
    id: AdminActivityTypeEnum.RemoveGroupUsers,
    key: "RemoveGroupUsers",
    name: translate("adminActivityTypes.removeGroupUsers.name"),
    type: AdminActivityTypeEnum.RemoveGroupUsers,
  },
  {
    description: translate(
      "adminActivityTypes.createGroupComputers.description",
    ),
    id: AdminActivityTypeEnum.CreateGroupComputers,
    key: "CreateGroupComputers",
    name: translate("adminActivityTypes.createGroupComputers.name"),
    type: AdminActivityTypeEnum.CreateGroupComputers,
  },
  {
    description: translate(
      "adminActivityTypes.updateGroupComputers.description",
    ),
    id: AdminActivityTypeEnum.UpdateGroupComputers,
    key: "UpdateGroupComputers",
    name: translate("adminActivityTypes.updateGroupComputers.name"),
    type: AdminActivityTypeEnum.UpdateGroupComputers,
  },
  {
    description: translate(
      "adminActivityTypes.removeGroupComputers.description",
    ),
    id: AdminActivityTypeEnum.RemoveGroupComputers,
    key: "RemoveGroupComputers",
    name: translate("adminActivityTypes.removeGroupComputers.name"),
    type: AdminActivityTypeEnum.RemoveGroupComputers,
  },
  {
    description: translate("adminActivityTypes.createPolicy.description"),
    id: AdminActivityTypeEnum.CreatePolicy,
    key: "CreatePolicy",
    name: translate("adminActivityTypes.createPolicy.name"),
    type: AdminActivityTypeEnum.CreatePolicy,
  },
  {
    description: translate("adminActivityTypes.updatePolicy.description"),
    id: AdminActivityTypeEnum.UpdatePolicy,
    key: "UpdatePolicy",
    name: translate("adminActivityTypes.updatePolicy.name"),
    type: AdminActivityTypeEnum.UpdatePolicy,
  },
  {
    description: translate("adminActivityTypes.removePolicy.description"),
    id: AdminActivityTypeEnum.RemovePolicy,
    key: "RemovePolicy",
    name: translate("adminActivityTypes.removePolicy.name"),
    type: AdminActivityTypeEnum.RemovePolicy,
  },
  {
    description: translate("adminActivityTypes.createUser.description"),
    id: AdminActivityTypeEnum.CreateUser,
    key: "CreateUser",
    name: translate("adminActivityTypes.createUser.name"),
    type: AdminActivityTypeEnum.CreateUser,
  },
  {
    description: translate("adminActivityTypes.updateUser.description"),
    id: AdminActivityTypeEnum.UpdateUser,
    key: "UpdateUser",
    name: translate("adminActivityTypes.updateUser.name"),
    type: AdminActivityTypeEnum.UpdateUser,
  },
  {
    description: translate("adminActivityTypes.removeUser.description"),
    id: AdminActivityTypeEnum.RemoveUser,
    key: "RemoveUser",
    name: translate("adminActivityTypes.removeUser.name"),
    type: AdminActivityTypeEnum.RemoveUser,
  },
  {
    description: translate("adminActivityTypes.changePasswordUser.description"),
    id: AdminActivityTypeEnum.ChangePasswordUser,
    key: "ChangePasswordUser",
    name: translate("adminActivityTypes.changePasswordUser.name"),
    type: AdminActivityTypeEnum.ChangePasswordUser,
  },
  {
    description: translate("adminActivityTypes.setAvatarUser.description"),
    id: AdminActivityTypeEnum.SetAvatarUser,
    key: "SetAvatarUser",
    name: translate("adminActivityTypes.setAvatarUser.name"),
    type: AdminActivityTypeEnum.SetAvatarUser,
  },
  {
    description: translate("adminActivityTypes.sendInvitation.description"),
    id: AdminActivityTypeEnum.SendInvitation,
    key: "SendInvitation",
    name: translate("adminActivityTypes.sendInvitation.name"),
    type: AdminActivityTypeEnum.SendInvitation,
  },
  {
    description: translate("adminActivityTypes.forgotPassword.description"),
    id: AdminActivityTypeEnum.ForgotPassword,
    key: "ForgotPassword",
    name: translate("adminActivityTypes.forgotPassword.name"),
    type: AdminActivityTypeEnum.ForgotPassword,
  },
  {
    description: translate("adminActivityTypes.changeMyAccount.description"),
    id: AdminActivityTypeEnum.ChangeMyAccount,
    key: "ChangeMyAccount",
    name: translate("adminActivityTypes.changeMyAccount.name"),
    type: AdminActivityTypeEnum.ChangeMyAccount,
  },
  {
    description: translate("adminActivityTypes.changeNotify.description"),
    id: AdminActivityTypeEnum.ChangeNotify,
    key: "ChangeNotify",
    name: translate("adminActivityTypes.changeNotify.name"),
    type: AdminActivityTypeEnum.ChangeNotify,
  },
  {
    description: translate("adminActivityTypes.changeAgentUpdate.description"),
    id: AdminActivityTypeEnum.ChangeAgentUpdate,
    key: "ChangeAgentUpdate",
    name: translate("adminActivityTypes.changeAgentUpdate.name"),
    type: AdminActivityTypeEnum.ChangeAgentUpdate,
  },
  {
    description: translate("adminActivityTypes.register.description"),
    id: AdminActivityTypeEnum.Register,
    key: "Register",
    name: translate("adminActivityTypes.register.name"),
    type: AdminActivityTypeEnum.Register,
  },
  {
    description: translate("adminActivityTypes.changeAgentOptions.description"),
    id: AdminActivityTypeEnum.ChangeAgentOptions,
    key: "ChangeAgentOptions",
    name: translate("adminActivityTypes.changeAgentOptions.name"),
    type: AdminActivityTypeEnum.ChangeAgentOptions,
  },
  {
    description: translate("adminActivityTypes.logout.description"),
    id: AdminActivityTypeEnum.Logout,
    key: "Logout",
    name: translate("adminActivityTypes.logout.name"),
    type: AdminActivityTypeEnum.Logout,
  },
];
