import clsx from "clsx";
import React from "react";
import {
  Cell,
  Column,
  Item,
  Row,
  Section,
  TableBody,
  TableHeader,
} from "react-stately";

import ComputerIllustration from "../../assets/images/computer-illustration.svg";
import useComputersTab from "../../hooks/useComputersTab";
import Computer from "../../interfaces/Computer";
import { getComputerStatusDetail } from "../../utils/utils";
import Button from "../Button";
import { ConfirmDeletionModal } from "../common/ConfirmDeletionModal";
import { Icon } from "../Icon";
import { MenuButton } from "../Menu";
import { Modal } from "../Modal";
import Pagination from "../Pagination";
import { Table } from "../table/Table";
import { TableActionsBar } from "../TableActionsBar";

function ComputersTab() {
  const {
    computers,
    isLoading,
    selectedKeys,
    setSelectedKeys,
    tableActions,
    onMenuAction,
    setFilters,
    filters,
    t,
    dateFormatter,
    onRowAction,
    activeComputer,
    total,
    showTooltip,
    hideTooltip,
    deleteComputerModalState,
    handleComputerDelete,
    activeComputersForDeletion,
    getOperatingSystemIcon,
    computerTamperCodeModalState,
    setIsTamperedCodeVisible,
    isTamperedCodeVisible,
    handleCopyCode,
    tamperedCode,
    isCurrentUserReadOnly,
    selectedComputer,
    isLoadingTamperedCode,
    proxyCode,
    isProxyCodeVisible,
    setIsProxyCodeVisible,
    computerProxyCodeModalState,
    isLoadingProxyCode,
  } = useComputersTab();

  return (
    <div className="flex h-full w-full flex-col overflow-x-hidden transition-all duration-200 ease-in-out">
      <TableActionsBar
        actions={tableActions}
        searchValue={filters.search}
        onSearchChange={(value) => {
          setFilters({ ...filters, search: value });
        }}
      />
      {computers.length === 0 && !isLoading && !filters.search ? (
        <div className="flex flex-col gap-24 p-8">
          <span className="text-medium-gray">{t("common.noComputers")}</span>
          <div className="mx-auto flex h-full w-full max-w-xl flex-col items-center justify-center gap-8 text-center">
            <ComputerIllustration className="h-auto w-full" />
            <span className="leading-8 text-extra-dark-gray">
              {t("common.noComputersMessage")}
            </span>
          </div>
        </div>
      ) : (
        <div className="flex flex-1 flex-col gap-4">
          <div className="flex-1">
            <Table
              activeRowKey={activeComputer}
              aria-labelledby={t("common.users")}
              isLoading={isLoading}
              aria-label={t("common.users")}
              selectionMode={isCurrentUserReadOnly ? "none" : "multiple"}
              selectedKeys={selectedKeys}
              onSelectionChange={setSelectedKeys}
              sortDescriptor={filters.sort}
              onSortChange={(e) => setFilters({ ...filters, sort: e })}
              onRowAction={onRowAction}
            >
              <TableHeader>
                <Column allowsResizing allowsSorting key="name">
                  {t("common.hostname")}
                </Column>
                <Column allowsResizing allowsSorting key="operatingSystem">
                  {t("common.operatingSystem")}
                </Column>
                <Column allowsResizing allowsSorting key="ipAddress">
                  {t("common.ipAddress")}
                </Column>
                <Column allowsResizing allowsSorting key="lastAccess">
                  {t("common.lastAccess")}
                </Column>
                <Column allowsResizing allowsSorting key="agentVersion">
                  {t("common.agentVersion")}
                </Column>

                <Column width={75} allowsSorting key="status">
                  {t("common.status")}
                </Column>
                <Column width={50} key="_actions">
                  {""}
                </Column>
              </TableHeader>
              <TableBody items={computers}>
                {(item: Computer) => (
                  <Row>
                    {(columnKey) => {
                      switch (columnKey) {
                        case "name":
                          return (
                            <Cell textValue={item?.name || "N/D"}>
                              <div className="flex flex-row items-center gap-2">
                                <>
                                  {item?.blocked && (
                                    <div
                                      onMouseEnter={(e) =>
                                        showTooltip(
                                          t("common.computerBlockedMessage"),
                                          e,
                                          true,
                                        )
                                      }
                                      onMouseLeave={hideTooltip}
                                    >
                                      <Icon
                                        name="ErrorIcon"
                                        className="h-5 w-5 text-red"
                                      />
                                    </div>
                                  )}
                                  {item?.enable === false && (
                                    <div
                                      onMouseEnter={(e) =>
                                        showTooltip(
                                          t(
                                            "common.computerPoliciesDisabledMessage",
                                          ),
                                          e,
                                          true,
                                        )
                                      }
                                      onMouseLeave={hideTooltip}
                                    >
                                      <Icon
                                        name="ErrorIcon"
                                        className="h-5 w-5 text-yellow"
                                      />
                                    </div>
                                  )}
                                  <span>{item?.name || "N/D"}</span>
                                </>
                              </div>
                            </Cell>
                          );

                        case "lastAccess":
                          return (
                            <Cell>
                              {item?.lastAccess
                                ? dateFormatter.format(
                                    new Date(item?.lastAccess),
                                  )
                                : "N/D"}
                            </Cell>
                          );
                        case "operatingSystem":
                          return (
                            <Cell>
                              {item.operatingSystem ? (
                                <div className="flex flex-row items-center gap-2">
                                  <Icon
                                    name={getOperatingSystemIcon(
                                      item.operatingSystem,
                                    )}
                                    className="h-6 w-6"
                                  />
                                  <span>{item.operatingSystem || "N/D"}</span>
                                </div>
                              ) : (
                                "N/D"
                              )}
                            </Cell>
                          );

                        case "status":
                          return (
                            <Cell>
                              {item.status !== undefined ? (
                                <div className="flex h-full w-full items-center justify-start">
                                  <div
                                    onMouseEnter={(e) =>
                                      showTooltip(
                                        getComputerStatusDetail(
                                          item.status || 0,
                                        ).description,
                                        e,
                                        true,
                                      )
                                    }
                                    onMouseLeave={hideTooltip}
                                    className={clsx(
                                      "my-auto h-3 w-3 rounded-full",
                                      getComputerStatusDetail(item.status)
                                        .background,
                                    )}
                                  />
                                </div>
                              ) : (
                                "N/D"
                              )}
                            </Cell>
                          );

                        case "_actions":
                          if (isCurrentUserReadOnly) return <Cell>{""}</Cell>;

                          return (
                            <Cell>
                              <div className="flex h-full items-center justify-center">
                                <MenuButton
                                  label={
                                    <Icon
                                      name="MenuIcon"
                                      className="h-full text-extra-dark-gray"
                                    />
                                  }
                                  disabledKeys={
                                    item.blocked
                                      ? ["suspendPolicy", "enablePolicy"]
                                      : !item.enable
                                        ? ["blockAccess", "unblockAccess"]
                                        : []
                                  }
                                  onAction={(key) => onMenuAction(item.id, key)}
                                >
                                  <Section>
                                    <Item key="edit">{t("common.edit")}</Item>
                                  </Section>
                                  <Section>
                                    {item.enable ? (
                                      <Item key="suspendPolicy">
                                        {t("common.suspendPolicy")}
                                      </Item>
                                    ) : (
                                      <Item key="enablePolicy">
                                        {t("common.enablePolicy")}
                                      </Item>
                                    )}
                                    <Item key="proxyCode">
                                      {t("common.proxyUnlockCode")}
                                    </Item>
                                  </Section>
                                  <Section>
                                    {item.blocked ? (
                                      <Item key="unblockAccess">
                                        {t("common.unblockAccess")}
                                      </Item>
                                    ) : (
                                      <Item key="blockAccess">
                                        {t("common.blockAccess")}
                                      </Item>
                                    )}
                                    <Item key="tamperedCode">
                                      {t("common.tamperedCode")}
                                    </Item>
                                  </Section>
                                  <Section>
                                    <Item
                                      key="delete"
                                      textValue={t("common.delete")}
                                    >
                                      <span className="text-red">
                                        {t("common.delete")}
                                      </span>
                                    </Item>
                                  </Section>
                                </MenuButton>
                              </div>
                            </Cell>
                          );
                        default:
                          return (
                            <Cell>
                              {(item as any)[String(columnKey)] || "N/D"}
                            </Cell>
                          );
                      }
                    }}
                  </Row>
                )}
              </TableBody>
            </Table>
          </div>
          <Pagination
            offset={Number(filters.offset)}
            total={total}
            count={Number(filters.count)}
            onCountChange={(e) =>
              setFilters((prevState) => ({ ...prevState, count: e }))
            }
            onOffsetChange={(e) =>
              setFilters((prevState) => ({ ...prevState, offset: e }))
            }
          />

          <ConfirmDeletionModal
            state={deleteComputerModalState}
            title={t("dialog.deleteComputer")}
            message={t("dialog.deleteComputerMessage", {
              count: activeComputersForDeletion.includes("all")
                ? total
                : activeComputersForDeletion.length,
              deleteKeyword: "delete",
              resourceName: selectedComputer?.name,
            })}
            onConfirm={handleComputerDelete}
            onCancel={deleteComputerModalState.close}
            isLoading={isLoading}
          />
          <Modal
            state={computerTamperCodeModalState}
            aria-label={t("dialog.tamperedCode")}
          >
            <div className="max-w-lg outline-none">
              <div className="flex flex-col items-start gap-2 pb-2 text-dark-blue">
                <h3 className="text-lg">{t("dialog.tamperedCode")}</h3>
                <p>{t("dialog.tamperedCodeMessage")}</p>
              </div>
              <div className="flex flex-row items-center justify-center gap-4 pt-8">
                <div
                  className="flex w-full items-center justify-center rounded-md border border-light-gray px-10 py-4 font-bold text-dark-blue shadow-2xl"
                  style={{ fontFamily: "Courier New, monospace" }}
                >
                  {isTamperedCodeVisible
                    ? tamperedCode || "N/D"
                    : "********************"}
                </div>
                <div>
                  <Button
                    variant="text"
                    noPadding
                    isDisabled={isLoadingTamperedCode}
                    onPress={() => {
                      setIsTamperedCodeVisible(!isTamperedCodeVisible);
                    }}
                  >
                    <Icon
                      name={
                        isTamperedCodeVisible ? "EyeIcon" : "HidePasswordIcon"
                      }
                      className="h-6 w-6"
                    />
                  </Button>
                </div>
                <div>
                  <Button
                    variant="text"
                    noPadding
                    isLoading={isLoadingTamperedCode}
                    isDisabled={!tamperedCode}
                    onPress={() => {
                      handleCopyCode("tampered");
                    }}
                  >
                    <Icon name="CopyPasteIcon" className="h-6 w-6" />
                  </Button>
                </div>
              </div>
              <div className="flex justify-end space-x-3 pt-16">
                <div>
                  <Button
                    autoFocus
                    variant="contained"
                    onPress={computerTamperCodeModalState.close}
                  >
                    {t("common.close")}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            state={computerProxyCodeModalState}
            aria-label={t("dialog.proxyCode")}
          >
            <div className="max-w-lg outline-none">
              <div className="flex flex-col items-start gap-2 pb-2 text-dark-blue">
                <h3 className="text-lg">{t("dialog.proxyCode")}</h3>
                <p>{t("dialog.proxyCodeMessage")}</p>
              </div>
              <div className="flex flex-row items-center justify-center gap-4 pt-8">
                <div className="flex w-full items-center justify-center rounded-md border border-light-gray px-10 py-4 font-bold text-dark-blue shadow-2xl">
                  {isProxyCodeVisible
                    ? proxyCode || "N/D"
                    : "********************"}
                </div>
                <div>
                  <Button
                    variant="text"
                    noPadding
                    isDisabled={isLoadingProxyCode}
                    onPress={() => {
                      setIsProxyCodeVisible(!isProxyCodeVisible);
                    }}
                  >
                    <Icon
                      name={isProxyCodeVisible ? "EyeIcon" : "HidePasswordIcon"}
                      className="h-6 w-6"
                    />
                  </Button>
                </div>
                <div>
                  <Button
                    variant="text"
                    noPadding
                    isDisabled={!proxyCode}
                    onPress={() => {
                      handleCopyCode("proxy");
                    }}
                  >
                    <Icon name="CopyPasteIcon" className="h-6 w-6" />
                  </Button>
                </div>
              </div>
              <div className="flex justify-end space-x-3 pt-16">
                <div>
                  <Button
                    autoFocus
                    variant="contained"
                    onPress={computerProxyCodeModalState.close}
                  >
                    {t("common.close")}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default ComputersTab;
