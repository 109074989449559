import React, { CSSProperties, ElementType, ReactNode } from "react";
import { useTableRowGroup } from "react-aria";

interface TableRowGroupProps {
  type: ElementType;
  style?: CSSProperties;
  children: ReactNode;
  onScroll?: () => void;
  className?: string;
}

const TableRowGroup = React.forwardRef((props: TableRowGroupProps, ref) => {
  const { type: Element, style, children, onScroll, className } = props;
  const { rowGroupProps } = useTableRowGroup();
  return (
    <Element
      {...rowGroupProps}
      onScroll={onScroll}
      style={style}
      className={className}
      ref={ref}
    >
      {children}
    </Element>
  );
});

TableRowGroup.displayName = "TableRowGroup";

export default TableRowGroup;
