import {
  CalendarDate,
  getLocalTimeZone,
  isSameDay,
  today,
} from "@internationalized/date";
import { RefObject, useRef } from "react";
import type { AriaCalendarCellProps } from "react-aria";
import { mergeProps, useCalendarCell, useFocusRing } from "react-aria";
import type { CalendarState } from "react-stately";

interface CalendarCellProps extends AriaCalendarCellProps {
  state: CalendarState;
  date: CalendarDate;
}

export function CalendarCell({ state, date }: CalendarCellProps) {
  const ref: RefObject<HTMLDivElement> = useRef(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    formattedDate,
    isInvalid,
  } = useCalendarCell({ date }, state, ref);

  const { focusProps, isFocusVisible } = useFocusRing();

  const currentDay = today(getLocalTimeZone());
  const isToday = isSameDay(date, currentDay);

  return (
    <td
      {...cellProps}
      className={`relative rounded-md py-0.5 transition-all duration-100 ease-in-out ${
        isFocusVisible ? "z-10" : "z-0"
      }`}
    >
      <div
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        hidden={isOutsideVisibleRange}
        className={`group h-10 w-10 rounded-md outline-none 
        ${
          isSelected
            ? isInvalid
              ? "bg-dark-red text-white"
              : "bg-dark-blue text-white"
            : isToday && "bg-light-blue"
        } 
        ${isDisabled ? "opacity-75" : ""}`}
      >
        <div
          className={`flex h-full w-full items-center justify-center rounded-md ${
            isDisabled && !isInvalid ? "text-gray" : ""
          } ${
            isFocusVisible
              ? "group-focus:z-2 ring-2 ring-dark-blue ring-offset-2"
              : ""
          } ${
            !isSelected && !isDisabled ? "hover:bg-light-blue" : ""
          } cursor-default`}
        >
          {formattedDate}
        </div>
      </div>
    </td>
  );
}
