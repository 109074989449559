import clsx from "clsx";
import React from "react";
import { Cell, Column, Row, TableBody, TableHeader } from "react-stately";

import useSoftwareActivitiesTab from "../../hooks/useSoftwareActivitiesTab";
import SoftwareActivity from "../../interfaces/SoftwareActivity";
import SoftwareActivityType from "../../interfaces/SoftwareActivityType";
import {
  convertDateToTicks,
  convertTicksToCalendarDateTime,
  findSoftwareActivityByType,
} from "../../utils/utils";
import { SoftwareActivityTypeMultiselect } from "../common/SoftwareActivityTypeMultiSelect";
import { Icon } from "../Icon";
import { Modal } from "../Modal";
import Pagination from "../Pagination";
import { Table } from "../table/Table";
import { TableActionsBar } from "../TableActionsBar";
import { TimespanDropdown } from "../TimespanDropdown";

function SoftwareActivitiesTab() {
  const {
    activities,
    isLoading,
    setFilters,
    filters,
    t,
    dateFormatter,
    total,
    showTooltip,
    hideTooltip,
    detailModalState,
    handleDetailClick,
    selectedActivity,
    appliedFiltersCount,
    handleResetAdvancedFilters,
  } = useSoftwareActivitiesTab();

  return (
    <div className="flex h-full w-full flex-col overflow-x-hidden transition-all duration-200 ease-in-out">
      <TableActionsBar
        filtersCount={appliedFiltersCount}
        hasMoreFilters
        onSearchChange={(value) => {
          setFilters({ ...filters, offset: 0, search: value });
        }}
        searchValue={filters.search}
        isAdvancedSearchInitialOpen={appliedFiltersCount > 0}
        onAdvancedFilterOpenChange={(isOpen) => {
          if (!isOpen) {
            handleResetAdvancedFilters();
          }
        }}
        additionalFilters={
          <div className="grid w-full grid-cols-1 gap-4 px-2 py-4 md:grid-cols-2 lg:grid-cols-4">
            <SoftwareActivityTypeMultiselect
              maxSelectableItems={5}
              id="activityType"
              selectedObjects={filters.activityType as SoftwareActivityType[]}
              onChange={(e) => {
                setFilters({ ...filters, activityType: e, offset: 0 });
              }}
            />
          </div>
        }
        customFilters={[
          <TimespanDropdown
            key="timespan"
            initialOption={filters.timespanOption}
            initialCustomFrom={convertTicksToCalendarDateTime(
              filters.dateStart,
            )}
            initialCustomTo={convertTicksToCalendarDateTime(filters.dateEnd)}
            onChange={(from, to, option) => {
              setFilters({
                ...filters,
                dateEnd: convertDateToTicks(to),
                dateStart: convertDateToTicks(from),
                timespanOption: option,
              });
            }}
          />,
        ]}
      />

      {/*<pre>{JSON.stringify(filters.sort, null, 2)}</pre>*/}
      <div className="flex flex-1 flex-col gap-4">
        <div className="flex-1">
          <Table
            aria-labelledby={t("dashboard.criticalControlCenter")}
            isLoading={isLoading}
            aria-label={t("dashboard.criticalControlCenter")}
            sortDescriptor={filters.sort}
            onSortChange={(e) => setFilters({ ...filters, offset: 0, sort: e })}
          >
            <TableHeader>
              <Column allowsResizing allowsSorting key="computer">
                {t("common.computer")}
              </Column>
              <Column allowsResizing allowsSorting key="type">
                {t("common.type")}
              </Column>
              <Column allowsResizing allowsSorting key="createdOn">
                {t("common.createdOn")}
              </Column>
              <Column width={100} key="_actions">
                {""}
              </Column>
            </TableHeader>
            <TableBody items={activities}>
              {(item: SoftwareActivity) => (
                <Row>
                  {(columnKey) => {
                    switch (columnKey) {
                      case "computer":
                        if (
                          item?.entity?.notApplied?.length > 1 ||
                          item?.entity?.applied?.length > 1
                        ) {
                          return <Cell>{t("common.multipleComputers")}</Cell>;
                        }
                        if (item?.entity?.notApplied.length === 1) {
                          return (
                            <Cell>
                              {item?.entity?.notApplied?.[0]?.fullname || "N/D"}
                            </Cell>
                          );
                        }
                        if (item?.entity?.applied.length === 1) {
                          return (
                            <Cell>
                              {item?.entity?.applied?.[0]?.fullname || "N/D"}
                            </Cell>
                          );
                        }
                        return <Cell>{item?.computer?.fullname || "N/D"}</Cell>;
                      case "createdOn":
                        return (
                          <Cell>
                            {(item as any)[columnKey]
                              ? dateFormatter.format(
                                  new Date((item as any)[columnKey]),
                                )
                              : "N/D"}
                          </Cell>
                        );
                      case "type":
                        return (
                          <Cell>
                            <div className="flex flex-row items-start justify-start gap-2">
                              <div
                                className={clsx(
                                  "my-auto h-3 w-3 rounded-full",
                                  findSoftwareActivityByType(item?.type)
                                    ?.background,
                                )}
                              />
                              <span>
                                {findSoftwareActivityByType(item?.type)?.name ||
                                  "N/D"}
                              </span>
                            </div>
                          </Cell>
                        );
                      case "_actions":
                        return (
                          <Cell>
                            <div
                              onMouseEnter={(e) =>
                                showTooltip(
                                  <pre className="max-w-[200px] grow-0 cursor-pointer overflow-scroll whitespace-pre-wrap rounded-md bg-extra-dark-gray p-2 text-white duration-200 ease-in-out hover:bg-dark-blue">
                                    {JSON.stringify(item.entity, null, 2)}
                                  </pre>,
                                  e,
                                  true,
                                  100,
                                )
                              }
                              onMouseLeave={hideTooltip}
                              className={clsx(
                                !item.entity &&
                                  "pointer-events-none opacity-50",
                              )}
                              onClick={() => {
                                if (!item.entity) return;
                                handleDetailClick(item);
                              }}
                            >
                              <Icon
                                name="EyeIcon"
                                className="h-6 w-6 text-dark-blue"
                              />
                            </div>
                          </Cell>
                        );
                      default:
                        return (
                          <Cell>
                            {(item as any)[String(columnKey)] || "N/D"}
                          </Cell>
                        );
                    }
                  }}
                </Row>
              )}
            </TableBody>
          </Table>
        </div>
        <Pagination
          offset={Number(filters.offset)}
          total={total}
          count={Number(filters.count)}
          onCountChange={(e) =>
            setFilters((prevState) => ({ ...prevState, count: e, offset: 0 }))
          }
          onOffsetChange={(e) =>
            setFilters((prevState) => ({ ...prevState, offset: e }))
          }
        />
      </div>
      <Modal
        state={detailModalState}
        className="min-w-[50vh] max-w-[80vh] transition-all duration-200 ease-in-out"
      >
        <div className="flex w-full flex-1 grow flex-col gap-2">
          <h3 className="pb-2 text-xl text-dark-blue">
            {t("common.activityDescription")}
          </h3>
          <pre className="col-span-2 max-w-[80vh] grow-0 cursor-pointer overflow-scroll whitespace-pre-wrap rounded-md bg-extra-dark-gray p-2 text-white duration-200 ease-in-out">
            {JSON.stringify(selectedActivity?.entity, null, 2)}
          </pre>
        </div>
      </Modal>
    </div>
  );
}

export default SoftwareActivitiesTab;
