import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import ComputerGroupsTab from "../components/computer/ComputerGroupsTab";
import ComputersTab from "../components/computer/ComputersTab";
import { Item, Tabs } from "../components/Tabs";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";

export const Computers: React.FC = () => {
  const { t } = useTranslation();
  const { closeRightPanel } = useContext(
    RightPanelContext,
  ) as RightPanelContextProps;
  return (
    <div className="flex h-full w-full flex-col items-start">
      <Tabs
        checkDirtyOnChange
        aria-label="Users"
        key={"tab"}
        onTabChange={closeRightPanel}
      >
        <Item
          key="computers"
          title={t("common.computers")}
          icon="ComputersMenuIcon"
        >
          <ComputersTab />
        </Item>
        <Item
          lazy
          key="computer-groups"
          icon="GroupOfComputersIcon"
          title={t("common.groupsOfComputers")}
        >
          <ComputerGroupsTab />
        </Item>
      </Tabs>
    </div>
  );
};
