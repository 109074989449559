import { iconComponents } from "../components/IconImports";
import { UserActivityTypeEnum } from "../enum/UserActivityTypeEnum";

import { translate } from "./translation";

export type UserActivitiesGroupedPoliciesTreeItem = {
  level: number;
  tableFields: {
    header: string;
    column: string;
    actions?: {
      key: string;
      label: string;
      section: string;
      icon: keyof typeof iconComponents;
    }[];
  }[];
};

export const userActivitiesGroupedPoliciesTreeItems: Partial<
  Record<UserActivityTypeEnum, UserActivitiesGroupedPoliciesTreeItem[]>
> = {
  // Specify File Access
  [UserActivityTypeEnum.FileAccessApplication]: [
    {
      level: 0,
      tableFields: [
        { column: "policy.name", header: translate("userActivities.ruleName") },
        {
          column: "activities",
          header: translate("userActivities.violations"),
        },
        { column: "computers", header: translate("userActivities.computers") },
      ],
    },
    {
      level: 1,
      tableFields: [
        {
          column: "application",
          header: translate("userActivities.application"),
        },
        {
          column: "applicationDetail.version",
          header: translate("userActivities.version"),
        },
        {
          column: "activities",
          header: translate("userActivities.cumulativeAccessAttempts"),
        },
        { column: "files", header: translate("userActivities.filesInvolved") },
        { column: "computers", header: translate("userActivities.pc") },
        {
          actions: [
            {
              icon: "CertificateIcon",
              key: "authorize-certificate",
              label: translate("common.authorizeCertificate"),
              section: "authorize",
            },
            {
              icon: "BarcodeBarIcon",
              key: "authorize-hash",
              label: translate("common.authorizeHash"),
              section: "authorize",
            },
            {
              icon: "DeniedIcon",
              key: "hide",
              label: translate("common.hideFromList"),
              section: "hide",
            },
            {
              icon: "DeniedIcon",
              key: "restore",
              label: translate("common.restoreFromList"),
              section: "hide",
            },
          ],
          column: "_actions",
          header: translate("userActivities.actions"),
        },
      ],
    },
    {
      level: 2,
      tableFields: [
        {
          column: "computer.fullname",
          header: translate("userActivities.computer"),
        },
        {
          column: "entity.process",
          header: translate("userActivities.appPath"),
        },
        {
          column: "entity.filenameOnly",
          header: translate("userActivities.fileNameAttacked"),
        },
        {
          column: "entity.filename",
          header: translate("userActivities.filePathAttacked"),
        },
        { column: "user.fullname", header: translate("userActivities.user") },
        { column: "createdOn", header: translate("userActivities.dateTime") },
        {
          column: "policy.severity",
          header: translate("userActivities.severity"),
        },
      ],
    },
  ],
  // File Deletion protection
  [UserActivityTypeEnum.DeleteFile]: [
    {
      level: 0,
      tableFields: [
        { column: "policy.name", header: translate("userActivities.ruleName") },
        {
          column: "activities",
          header: translate("userActivities.violations"),
        },
        { column: "computers", header: translate("userActivities.computers") },
      ],
    },
    {
      level: 1,
      tableFields: [
        {
          column: "application",
          header: translate("userActivities.application"),
        },
        {
          column: "applicationDetail.version",
          header: translate("userActivities.version"),
        },
        {
          column: "activities",
          header: translate("userActivities.cumulativeAccessAttempts"),
        },
        { column: "files", header: translate("userActivities.filesInvolved") },
        { column: "computers", header: translate("userActivities.pc") },
        {
          actions: [
            {
              icon: "DeniedIcon",
              key: "hide",
              label: translate("common.hideFromList"),
              section: "hide",
            },
            {
              icon: "DeniedIcon",
              key: "restore",
              label: translate("common.restoreFromList"),
              section: "hide",
            },
          ],
          column: "_actions",
          header: translate("userActivities.actions"),
        },
      ],
    },
    {
      level: 2,
      tableFields: [
        { column: "computer.fullname", header: translate("common.computer") },
        {
          column: "entity.filenameOnly",
          header: translate("userActivities.fileNameAttacked"),
        },
        {
          column: "entity.filename",
          header: translate("userActivities.filePathAttacked"),
        },
        { column: "user.fullname", header: translate("userActivities.user") },
        { column: "createdOn", header: translate("userActivities.dateTime") },
      ],
    },
  ],
  // Secure RDP File Transfer
  [UserActivityTypeEnum.DownloadFile]: [
    {
      level: 0,
      tableFields: [
        { column: "policy.name", header: translate("userActivities.ruleName") },
        {
          column: "activities",
          header: translate("userActivities.violations"),
        },
        { column: "computers", header: translate("userActivities.computers") },
      ],
    },
    {
      level: 1,
      tableFields: [
        {
          column: "computer.name",
          header: translate("userActivities.computer"),
        },
        {
          column: "activities",
          header: translate("userActivities.copyingAttempts"),
        },
      ],
    },
    {
      level: 2,
      tableFields: [
        { column: "computer.fullname", header: translate("common.computer") },
        {
          column: "entity.filename",
          header: translate("userActivities.fileName"),
        },
        {
          column: "entity.process",
          header: translate("userActivities.filePath"),
        },
        { column: "user.fullname", header: translate("userActivities.user") },
        { column: "createdOn", header: translate("userActivities.dateTime") },
        {
          column: "policy.severity",
          header: translate("userActivities.severity"),
        },
      ],
    },
  ],
  // Internet Access Control
  [UserActivityTypeEnum.Network]: [
    {
      level: 0,
      tableFields: [
        { column: "policy.name", header: translate("userActivities.ruleName") },
        {
          column: "activities",
          header: translate("userActivities.violations"),
        },
        { column: "computers", header: translate("userActivities.computers") },
      ],
    },
    {
      level: 1,
      tableFields: [
        { column: "url", header: translate("userActivities.URLHacked") },
        {
          column: "activities",
          header: translate("userActivities.cumulativeAccessAttempts"),
        },
        { column: "computers", header: translate("userActivities.pc") },
      ],
    },
    {
      level: 2,
      tableFields: [
        {
          column: "computer.fullname",
          header: translate("userActivities.computer"),
        },
        { column: "user.fullname", header: translate("userActivities.user") },
        { column: "createdOn", header: translate("userActivities.dateTime") },
        {
          column: "policy.severity",
          header: translate("userActivities.severity"),
        },
      ],
    },
  ],
  // Block Writing to New Files
  [UserActivityTypeEnum.UploadNewFileExe]: [
    {
      level: 0,
      tableFields: [
        { column: "policy.name", header: translate("userActivities.ruleName") },
        {
          column: "activities",
          header: translate("userActivities.violations"),
        },
        { column: "computers", header: translate("userActivities.computers") },
      ],
    },
    {
      level: 1,
      tableFields: [
        {
          column: "application",
          header: translate("userActivities.application"),
        },
        {
          column: "activities",
          header: translate("userActivities.cumulativeAccessAttempts"),
        },
        { column: "files", header: translate("userActivities.filesInvolved") },
        { column: "computers", header: translate("userActivities.pc") },
      ],
    },
    {
      level: 2,
      tableFields: [
        {
          column: "computer.fullname",
          header: translate("userActivities.computer"),
        },
        {
          column: "entity.filenameOnly",
          header: translate("userActivities.fileNameAttacked"),
        },
        {
          column: "entity.filename",
          header: translate("userActivities.filePathAttacked"),
        },
        {
          column: "user.fullname",
          header: translate("userActivities.user"),
        },
        { column: "createdOn", header: translate("userActivities.dateTime") },
        {
          column: "policy.severity",
          header: translate("userActivities.severity"),
        },
      ],
    },
  ],
  // Schedule device availability
  [UserActivityTypeEnum.DateTimePlan]: [
    {
      level: 0,
      tableFields: [
        { column: "policy.name", header: translate("userActivities.ruleName") },
        {
          column: "activities",
          header: translate("userActivities.violations"),
        },
        { column: "computers", header: translate("userActivities.computers") },
      ],
    },
    {
      level: 1,
      tableFields: [
        {
          column: "computer.fullname",
          header: translate("userActivities.computer"),
        },
        {
          column: "activities",
          header: translate("userActivities.accessAttempts"),
        },
      ],
    },
    {
      level: 2,
      tableFields: [
        {
          column: "computer.fullname",
          header: translate("userActivities.computer"),
        },
        {
          column: "user.fullname",
          header: translate("userActivities.user"),
        },
        {
          column: "policy.name",
          header: translate("userActivities.matchExpiryPolicy"),
        },
        { column: "createdOn", header: translate("userActivities.dateTime") },
        {
          column: "policy.severity",
          header: translate("userActivities.severity"),
        },
      ],
    },
  ],
  // Set device expiry date
  [UserActivityTypeEnum.Expiry]: [
    {
      level: 0,
      tableFields: [
        { column: "policy.name", header: translate("userActivities.ruleName") },
        {
          column: "activities",
          header: translate("userActivities.violations"),
        },
        { column: "computers", header: translate("userActivities.computers") },
      ],
    },
    {
      level: 1,
      tableFields: [
        {
          column: "computer.name",
          header: translate("userActivities.computer"),
        },
        {
          column: "activities",
          header: translate("userActivities.accessAttempts"),
        },
      ],
    },
    {
      level: 2,
      tableFields: [
        {
          column: "computer.fullname",
          header: translate("userActivities.computer"),
        },
        {
          column: "user.fullname",
          header: translate("userActivities.user"),
        },
        {
          column: "policy.name",
          header: translate("userActivities.matchExpiryPolicy"),
        },
        { column: "createdOn", header: translate("userActivities.dateTime") },
        {
          column: "policy.severity",
          header: translate("userActivities.severity"),
        },
      ],
    },
  ],
  // IP Range access controlo
  [UserActivityTypeEnum.RangeIPAddress]: [
    {
      level: 0,
      tableFields: [
        { column: "policy.name", header: translate("userActivities.ruleName") },
        {
          column: "activities",
          header: translate("userActivities.violations"),
        },
        { column: "computers", header: translate("userActivities.computers") },
      ],
    },
    {
      level: 1,
      tableFields: [
        {
          column: "computer.name",
          header: translate("userActivities.computer"),
        },
        {
          column: "activities",
          header: translate("userActivities.accessAttempts"),
        },
      ],
    },
    {
      level: 2,
      tableFields: [
        {
          column: "user.fullname",
          header: translate("userActivities.user"),
        },
        { column: "createdOn", header: translate("userActivities.dateTime") },
        {
          column: "policy.name",
          header: translate("userActivities.matchExpiryPolicy"),
        },
        {
          column: "entity.publicIP",
          header: translate("userActivities.blockedIP"),
        },
        {
          column: "policy.severity",
          header: translate("userActivities.severity"),
        },
      ],
    },
  ],
  // Username based access control
  [UserActivityTypeEnum.WindowsAccess]: [
    {
      level: 0,
      tableFields: [
        { column: "policy.name", header: translate("userActivities.ruleName") },
        {
          column: "activities",
          header: translate("userActivities.violations"),
        },
        { column: "computers", header: translate("userActivities.computers") },
      ],
    },
    {
      level: 1,
      tableFields: [
        {
          column: "computer.name",
          header: translate("userActivities.computer"),
        },
        {
          column: "activities",
          header: translate("userActivities.accessAttempts"),
        },
      ],
    },
    {
      level: 2,
      tableFields: [
        {
          column: "user.fullname",
          header: translate("userActivities.user"),
        },
        { column: "createdOn", header: translate("userActivities.dateTime") },
        {
          column: "entity.username",
          header: translate("userActivities.blockedUsername"),
        },
        {
          column: "policy.severity",
          header: translate("userActivities.severity"),
        },
      ],
    },
  ],
  [UserActivityTypeEnum.ExternalDrives]: [
    {
      level: 0,
      tableFields: [
        { column: "policy.name", header: translate("userActivities.ruleName") },
        {
          column: "activities",
          header: translate("userActivities.violations"),
        },
        { column: "computers", header: translate("userActivities.computers") },
      ],
    },
    {
      level: 1,
      tableFields: [
        {
          column: "computer.name",
          header: translate("userActivities.computer"),
        },
        {
          column: "activities",
          header: translate("userActivities.accessAttempts"),
        },
      ],
    },
    {
      level: 2,
      tableFields: [
        {
          column: "computer.fullname",
          header: translate("userActivities.computer"),
        },
        {
          column: "entity.process",
          header: translate("userActivities.appPath"),
        },
        {
          column: "entity.filenameOnly",
          header: translate("userActivities.fileNameAttacked"),
        },
        {
          column: "entity.filename",
          header: translate("userActivities.filePathAttacked"),
        },
        { column: "user.fullname", header: translate("userActivities.user") },
        { column: "createdOn", header: translate("userActivities.dateTime") },
        {
          column: "policy.severity",
          header: translate("userActivities.severity"),
        },
      ],
    },
  ],
  [UserActivityTypeEnum.AccessFolders]: [
    {
      level: 0,
      tableFields: [
        { column: "policy.name", header: translate("userActivities.ruleName") },
        {
          column: "activities",
          header: translate("userActivities.violations"),
        },
        { column: "computers", header: translate("userActivities.computers") },
      ],
    },
    {
      level: 1,
      tableFields: [
        {
          column: "computer.name",
          header: translate("userActivities.computer"),
        },
        {
          column: "activities",
          header: translate("userActivities.accessAttempts"),
        },
      ],
    },
    {
      level: 2,
      tableFields: [
        {
          column: "computer.fullname",
          header: translate("userActivities.computer"),
        },
        {
          column: "entity.process",
          header: translate("userActivities.appPath"),
        },
        {
          column: "entity.filenameOnly",
          header: translate("userActivities.fileFolderAttacked"),
        },
        {
          column: "entity.filename",
          header: translate("userActivities.pathAttacked"),
        },
        { column: "user.fullname", header: translate("userActivities.user") },
        { column: "createdOn", header: translate("userActivities.dateTime") },
        {
          column: "policy.severity",
          header: translate("userActivities.severity"),
        },
      ],
    },
  ],
  [UserActivityTypeEnum.ProxyControl]: [
    {
      level: 0,
      tableFields: [
        { column: "policy.name", header: translate("userActivities.ruleName") },
        {
          column: "activities",
          header: translate("userActivities.violations"),
        },
        { column: "computers", header: translate("userActivities.computers") },
      ],
    },
    {
      level: 1,
      tableFields: [
        {
          column: "computer.name",
          header: translate("userActivities.computer"),
        },
        {
          column: "activities",
          header: translate("userActivities.accessAttempts"),
        },
      ],
    },
    {
      level: 2,
      tableFields: [
        {
          column: "computer.fullname",
          header: translate("userActivities.computer"),
        },
        { column: "user.fullname", header: translate("userActivities.user") },
        { column: "createdOn", header: translate("userActivities.dateTime") },
        {
          column: "policy.severity",
          header: translate("userActivities.severity"),
        },
      ],
    },
  ],
  // File Encryption
  [UserActivityTypeEnum.EncryptionFile]: [
    {
      level: 0,
      tableFields: [
        { column: "policy.name", header: translate("userActivities.ruleName") },
        {
          column: "activities",
          header: translate("userActivities.violations"),
        },
        { column: "computers", header: translate("userActivities.computers") },
      ],
    },
    {
      level: 1,
      tableFields: [
        {
          column: "computer.fullname",
          header: translate("userActivities.computer"),
        },
        {
          column: "activities",
          header: translate("userActivities.accessAttempts"),
        },
      ],
    },
    {
      level: 2,
      tableFields: [
        { column: "computer.fullname", header: translate("common.computer") },
        {
          column: "entity.filenameOnly",
          header: translate("userActivities.fileNameAttacked"),
        },
        {
          column: "entity.filename",
          header: translate("userActivities.filePathAttacked"),
        },
        { column: "user.fullname", header: translate("userActivities.user") },
        { column: "createdOn", header: translate("userActivities.dateTime") },
      ],
    },
  ],
  [UserActivityTypeEnum.AntiPhishing]: [
    {
      level: 0,
      tableFields: [
        { column: "policy.name", header: translate("userActivities.ruleName") },
        {
          column: "activities",
          header: translate("userActivities.violations"),
        },
        { column: "computers", header: translate("userActivities.computers") },
      ],
    },
    {
      level: 1,
      tableFields: [
        {
          column: "computer.fullname",
          header: translate("userActivities.computer"),
        },
        {
          column: "activities",
          header: translate("userActivities.accessAttempts"),
        },
      ],
    },
    {
      level: 2,
      tableFields: [
        {
          column: "computer.fullname",
          header: translate("userActivities.computer"),
        },
        {
          column: "entity.site",
          header: translate("userActivities.URLHacked"),
        },
        {
          column: "entity.value",
          header: translate("userActivities.score"),
        },
        {
          column: "user.fullname",
          header: translate("userActivities.user"),
        },
        { column: "createdOn", header: translate("userActivities.dateTime") },
        {
          column: "policy.severity",
          header: translate("userActivities.severity"),
        },
      ],
    },
  ],
};
