import clsx from "clsx";
import React, { Key, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Item } from "react-stately";

import DashboardIllustration from "../../assets/images/dashboard-illustration.svg";
import { DashboardFilterState, PolicyReport } from "../../hooks/useDashboard";
import Policy from "../../interfaces/Policy";
import { policies } from "../../utils/policies";
import { policySeverities } from "../../utils/policySeverities";
import { getStorageItem, storeStorageItem } from "../../utils/storage";
import { Icon } from "../Icon";
import { Select } from "../Select";

import DashboardItem from "./DashboardItem";

interface Props {
  data: PolicyReport[];
  isLoading?: boolean;
  dateFormatter: Intl.DateTimeFormat;
  filters: DashboardFilterState;
}

interface ReportItemProps {
  policy: Policy;
  policiesCount: number;
  time: string;
  high: number;
  medium: number;
  low: number;
  severity: Key;
  setSeverity: (value: Key) => void;
  filters: DashboardFilterState;
  highRead: boolean;
  mediumRead: boolean;
  lowRead: boolean;
}

const ReportItem: React.FC<ReportItemProps> = ({
  policy,
  policiesCount,
  time,
  high,
  medium,
  low,
  severity,
  setSeverity,
  filters,
  highRead,
  mediumRead,
  lowRead,
}: ReportItemProps) => {
  /**
   * Determines whether to hide the counter based on the counter value and type.
   *
   * @param {number} counterValue - The value of the counter.
   * @param {string} counterType - The type of the counter.
   * @returns {boolean} Returns true if the counter should be hidden, false otherwise.
   */
  const shouldHideCounter = (
    counterValue: number,
    counterType: string,
  ): boolean => {
    if (counterValue === 0) return true;
    if (severity === "all") {
      if (high > 0 && counterType === "high") return true;
      if (high === 0 && medium > 0 && counterType === "medium") return true;
      if (high === 0 && medium === 0 && low > 0 && counterType === "low")
        return true;
    } else {
      return severity === counterType;
    }

    return false;
  };

  const shouldHideAlert = (): boolean => {
    switch (severity) {
      case "high":
        return !highRead && high > 0;
      case "medium":
        return !mediumRead && medium > 0;
      case "low":
        return !lowRead && low > 0;
      default:
        return (
          (!highRead && high > 0) ||
          (!mediumRead && medium > 0) ||
          (!lowRead && low > 0)
        );
    }
  };

  /**
   * Return color values based on the given type.
   *
   * @param {string} type - The type of color to retrieve.
   * @returns {object} - The color values for the given type.
   */
  const getColors = useMemo(() => {
    // Default colors when 'all' or an unexpected value is set
    let backgroundColor = "";
    let color = "text-green";

    if (severity === "high") {
      if (high > 0) {
        backgroundColor = "bg-[#ffe4e4]";
      }
      color = "text-[#B44040]";
    } else if (severity === "medium") {
      if (medium > 0) {
        backgroundColor = "bg-[#FFFCEC]";
      }
      color = "text-[#FFBA08]";
    } else if (severity === "low") {
      if (low > 0) {
        backgroundColor = "bg-[#DDEBF5]";
      }
      color = "text-[#3197E0]";
    } else if (severity === "all") {
      if (high > 0) {
        backgroundColor = "bg-[#ffe4e4]";
        color = "text-[#B44040]";
      } else if (medium > 0) {
        backgroundColor = "bg-[#FFFCEC]";
        color = "text-[#FFBA08]";
      } else if (low > 0) {
        backgroundColor = "";
        color = "text-[#3197E0]";
      }
    }

    return { backgroundColor, color };
  }, [high, medium, low, severity]);

  return (
    <Link
      to={`/activities?policyID=${policy.id}&severity=${severity}&notRead=true&dateStart=${filters.dateStart}&dateEnd=${filters.dateEnd}&timespanOption=${filters.timespanOption}`}
      className={clsx(
        "flex h-[200px] w-full flex-col gap-4 border border-transparent shadow",
      )}
      style={{
        boxShadow: "0px 0px 0.5px 0.5px lightgray",
      }}
    >
      <div
        className={clsx(
          " flex h-full w-full flex-col items-center gap-2 border border-transparent p-1",
          getColors.backgroundColor,
        )}
      >
        <div className="flex w-full flex-row justify-end gap-1">
          <div className="flex min-h-[20px] flex-row items-center gap-1">
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSeverity("high");
              }}
              className={clsx(
                "flex w-[32px] cursor-pointer items-center  justify-center rounded-md border border-[#B44040] bg-[#f8EDED] px-1 text-[#d06e6e]",
                shouldHideCounter(high, "high") && "hidden",
              )}
            >
              <span className="text-sm hover:underline">
                {high > 99 ? "99+" : high}
              </span>
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSeverity("medium");
              }}
              className={clsx(
                "flex w-[32px] cursor-pointer items-center justify-center rounded-md border border-[#FFBA08] bg-[#FFFCEC] px-1 text-[#D7B400]",
                shouldHideCounter(medium, "medium") && "hidden",
              )}
            >
              <span className="text-sm hover:underline">
                {medium > 99 ? "99+" : medium}
              </span>
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSeverity("low");
              }}
              className={clsx(
                "flex w-[32px] cursor-pointer items-center justify-center rounded-md border border-[#3197E0] bg-[#DDEBF5] px-1 text-[#3197E0]",
                shouldHideCounter(low, "low") && "hidden",
              )}
            >
              <span className="text-sm hover:underline">
                {low > 99 ? "99+" : low}
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center gap-2">
          {shouldHideAlert() && (
            <Icon
              name="ErrorIcon"
              className={clsx("h-6 w-6", getColors.color)}
            />
          )}

          <span className={clsx("text-4xl font-bold", getColors.color)}>
            {(() => {
              switch (severity) {
                case "high":
                  return high;
                case "medium":
                  return medium;
                case "low":
                  return low;
                default:
                  return high || medium || low || 0;
              }
            })()}
          </span>
        </div>
        <div className="flex flex-row items-center gap-2">
          <Icon name={policy.icon} className="h-5 w-5 text-medium-pale-blue" />
          <p className="text-center text-lg font-medium text-extra-dark-gray">
            {policy.name}
          </p>
        </div>
        <span>
          <strong>{policiesCount}</strong> policies set
        </span>
        <div className="flex flex-1 items-end">
          <time className="text-dark-gray">{time}</time>
        </div>
      </div>
    </Link>
  );
};

const DashboardReportItem: React.FC<Props> = ({
  isLoading,
  dateFormatter,
  data,
  filters,
}) => {
  const { t } = useTranslation();
  const [severity, setSeverity] = useState(() => {
    return (
      (getStorageItem("sessionStorage", "dashboardSeverity") as Key) || "all"
    );
  });

  useEffect(() => {
    storeStorageItem("sessionStorage", "dashboardSeverity", severity as Key);
  }, [severity]);

  return (
    <DashboardItem
      noPadding
      header={
        <div
          className={clsx(
            "flex flex-row items-center justify-between rounded-t-lg border-b border-medium-gray bg-extra-light-gray px-5 py-4",
            !isLoading && data.length === 0 ? "overflow-hidden" : null,
          )}
        >
          <div className="flex flex-1 flex-row items-center gap-8">
            <p className="font-semibold text-steel-blue">
              {t("dashboard.report")}
            </p>
            <div className="flex min-w-[200px] flex-row items-center justify-center gap-2">
              <Icon name="FunnelIcon" className="h-6 w-6 text-dark-gray" />
              <Select
                selectedKey={String(severity)}
                aria-label={t("pagination.selectItemsPerPage")}
                placeholder={t("pagination.selectItemsPerPage")}
                onSelectionChange={(value) => {
                  setSeverity(value);
                }}
              >
                {[
                  <Item key="all" aria-label={t("common.all")}>
                    {t("common.all")}
                  </Item>,
                  ...policySeverities
                    .filter((s) => s.key !== "info")
                    .map((item) => (
                      <Item key={item.key} aria-label={String(item.name)}>
                        <span className={clsx(item.color)}>{item.name}</span>
                      </Item>
                    )),
                ]}
              </Select>
            </div>
          </div>
          <Link
            to={
              severity !== "all"
                ? `/activities?severity=${severity}&notRead=true&dateStart=${filters.dateStart}&dateEnd=${filters.dateEnd}&timespanOption=${filters.timespanOption}`
                : `/activities&notRead=true&dateStart=${filters.dateStart}&dateEnd=${filters.dateEnd}&timespanOption=${filters.timespanOption}`
            }
            className="flex flex-row items-center gap-1 text-azure underline"
          >
            <span>{t("common.viewAll")}</span>
            <Icon name="ArrowRightIcon" className="h-5 w-5" />
          </Link>
        </div>
      }
    >
      {!isLoading && data.length === 0 && (
        <div className="mx-auto flex h-[98%] w-full max-w-md flex-col items-center justify-center gap-8 p-4 text-center">
          <div className="max-w-full">
            <DashboardIllustration className="h-auto w-full" />
          </div>
          <p>{t("dashboard.criticalControlCenterEmptyTitle")}</p>
          <span className="leading-8 text-extra-dark-gray">
            {t("dashboard.criticalControlCenterEmptyDescription")}
          </span>
        </div>
      )}

      <div className="grid h-fit max-h-[800px] w-full grid-cols-1 overflow-y-auto pb-1 md:grid-cols-2 lg:grid-cols-1 3xl:grid-cols-2 4xl:grid-cols-3 5xl:grid-cols-4 6xl:grid-cols-5">
        {isLoading ? (
          <>
            {policies.map((policy) => (
              <div
                key={policy.id}
                className="m-1 min-h-[200px] animate-pulse bg-gray"
              />
            ))}
          </>
        ) : (
          <>
            {data.map((item) => (
              <ReportItem
                filters={filters}
                key={item.policy.key}
                policy={item.policy}
                policiesCount={item.securityPoliciesCount}
                time={
                  item.lastActivity
                    ? dateFormatter.format(new Date(item.lastActivity))
                    : "N/D"
                }
                high={item.high}
                medium={item.medium}
                low={item.low}
                highRead={item.highRead}
                mediumRead={item.mediumRead}
                lowRead={item.lowRead}
                severity={severity}
                setSeverity={setSeverity}
              />
            ))}
          </>
        )}
      </div>
    </DashboardItem>
  );
};

export default DashboardReportItem;
