import clsx from "clsx";
import React from "react";

import Button from "../components/Button";
import { Icon } from "../components/Icon";
import AuthenticatorModal from "../components/settings/AuthenticatorModal";
import SettingSection from "../components/settings/SettingSection";
import TextField from "../components/TextField";
import useSecuritySettings from "../hooks/useSecuritySettings";

export const SecuritySettings: React.FC = () => {
  const {
    isLoading,
    isEditingPassword,
    passwordForm,
    handlePasswordInputChange,
    handlePasswordFormSubmit,
    setIsEditingPassword,
    passwordErrors,
    t,
    currentUser,
    handleRemoveAuthenticator,
    authenticatorModalState,
    handleOpenAuthenticatorModal,
    fetchCurrentUser,
    handleChangeStatusAuthenticator,
  } = useSecuritySettings();

  return (
    <div className="grid grid-cols-1 gap-6">
      <SettingSection
        title={t("pages.security")}
        subtitle={t("common.securitySettingsSubtitle")}
      >
        <form
          onSubmit={handlePasswordFormSubmit}
          className="flex w-full flex-row items-center justify-between"
        >
          <div className="grid w-full grid-cols-6 items-center">
            <p className="text-lg text-dark-blue">{t("common.password")}</p>
            {isEditingPassword ? (
              <>
                <div className="relative col-span-4 grid grid-cols-1 gap-1">
                  <div className="grid grid-cols-3 gap-2">
                    <TextField
                      type="password"
                      isDisabled={isLoading}
                      id="oldPassword"
                      label={t("common.currentPassword")}
                      name="oldPassword"
                      value={passwordForm.oldPassword}
                      isRequired
                      required
                      title={t("validation.currentPassword")}
                      onChange={(value) => {
                        handlePasswordInputChange("oldPassword", value);
                      }}
                    />
                    <TextField
                      type="password"
                      isDisabled={isLoading}
                      id="newPassword"
                      label={t("common.newPassword")}
                      name="newPassword"
                      value={passwordForm.newPassword}
                      isRequired
                      required
                      isInvalid={passwordErrors.length > 0}
                      pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                      title={t("validation.newPassword")}
                      onChange={(value) => {
                        handlePasswordInputChange("newPassword", value);
                      }}
                    />
                    <TextField
                      type="password"
                      isDisabled={isLoading}
                      id="confirmNewPassword"
                      label={t("common.confirmNewPassword")}
                      name="confirmNewPassword"
                      value={passwordForm.confirmNewPassword}
                      isRequired
                      required
                      isInvalid={passwordErrors.length > 0}
                      title={t("validation.newPasswordConfirmation")}
                      onChange={(value) => {
                        handlePasswordInputChange("confirmNewPassword", value);
                      }}
                    />
                  </div>
                  <span className="absolute left-0 top-12 text-xs text-red">
                    {passwordErrors[0]}
                  </span>
                </div>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  <div>
                    <Button
                      type="button"
                      isDisabled={isLoading}
                      variant="text"
                      onPress={() => setIsEditingPassword(false)}
                    >
                      {t("common.cancel")}
                    </Button>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      isLoading={isLoading}
                      isDisabled={passwordErrors.length > 0}
                      variant="contained"
                    >
                      {t("common.save")}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p
                  className={clsx(
                    "col-span-2 transition-all duration-200 ease-in-out",
                    isLoading && "blur-sm",
                  )}
                >
                  **********
                </p>
                <div>
                  <Button
                    variant="text"
                    onPress={() => {
                      setIsEditingPassword(true);
                    }}
                    aria-label={`Change password`}
                  >
                    <Icon name="EditIcon" className="h-6 w-6 text-dark-blue" />
                  </Button>
                </div>
              </>
            )}
          </div>
        </form>
      </SettingSection>
      <SettingSection
        title="2FA"
        subtitle="Increase your account security with Two-Factor Authentication."
        borderless
      >
        <div className="flex w-full flex-col gap-4">
          <div className="flex w-full max-w-[400px] flex-row items-center justify-between">
            <p className="text-lg text-dark-blue">
              {t("common.appAuthenticator")}
            </p>
            {currentUser?.hasAuthenticator ? (
              <div className="grid grid-cols-2 gap-4">
                {[0, 3].includes(currentUser.twoStepAuth) && (
                  <Button
                    isLoading={isLoading}
                    type="button"
                    variant={
                      currentUser.twoStepAuth === 0 ? "outlined" : "contained"
                    }
                    onPress={handleChangeStatusAuthenticator}
                  >
                    {currentUser.twoStepAuth === 0
                      ? t("common.enable")
                      : t("common.disable")}
                  </Button>
                )}
                <Button
                  isLoading={isLoading}
                  type="button"
                  onPress={handleRemoveAuthenticator}
                  className="bg-dark-red"
                >
                  {t("common.remove")}
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  isLoading={isLoading}
                  onPress={handleOpenAuthenticatorModal}
                  type="button"
                >
                  {t("common.enable")}
                </Button>
              </div>
            )}
          </div>
          <p className="text-dark-gray">
            {t("common.appAuthenticatorDescription")}
          </p>
        </div>
      </SettingSection>
      {/*<SettingSection
        title="Web browser"
        subtitle="Monitor the list of your accesses to Remotegrant."
        borderless
      >
        <div className="flex w-full flex-col lg:w-3/4">
          <Table
            aria-labelledby="Activity on computers"
            isLoading={isLoading}
            aria-label="Activity on computers"
          >
            <TableHeader>
              <Column key="browser">Browser</Column>
              <Column key="lastActivity">Most recent activity</Column>
              <Column key="_action">{""}</Column>
            </TableHeader>
            <TableBody items={activities}>
              {(item) => (
                <Row>
                  {(columnKey) => {
                    if (columnKey === "_action") {
                      return (
                        <Cell>
                          <Button noPadding variant="text">
                            <Icon
                              name="TrashIcon"
                              className="h-6 w-6 text-extra-dark-gray"
                            />
                          </Button>
                        </Cell>
                      );
                    } else {
                      return (
                        <Cell>{(item as any)[String(columnKey)] || "N/D"}</Cell>
                      );
                    }
                  }}
                </Row>
              )}
            </TableBody>
          </Table>
        </div>
      </SettingSection>*/}

      <AuthenticatorModal
        state={authenticatorModalState}
        onActivate={fetchCurrentUser}
        onComplete={authenticatorModalState.close}
      />
    </div>
  );
};
