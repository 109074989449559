export enum EventBusEvents {
  CREATE_NOTIFICATION = "createNotification",
  RIGHT_PANEL_OPEN = "rightPanelOpen",
  RIGHT_PANEL_CLOSE = "rightPanelClose",
  UNAUTHORIZED_API_REQUEST = "unauthorizedRequest",
  ON_LOGOUT = "onLogout",
  API_REQUEST = "firstApiRequest",
  ON_SECURITY_POLICY_OPTIONS_CHANGE = "onSecurityPolicyOptionsChange",
}

export class EventBus {
  static emit(eventName: EventBusEvents, data: any): void {
    const event = new CustomEvent(eventName, { detail: data });
    window.dispatchEvent(event);
  }

  static on(eventName: EventBusEvents, handler: (data: any) => void): void {
    window.addEventListener(eventName, (event: Event) => {
      const customEvent = event as CustomEvent;
      if (customEvent.detail) {
        handler(customEvent.detail);
      }
    });
  }

  static off(eventName: EventBusEvents, handler: (data: any) => void): void {
    window.removeEventListener(eventName, handler);
  }
}
