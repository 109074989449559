import { SortDescriptor } from "@react-types/shared";
import qs from "qs";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDateFormatter } from "react-aria";
import { useTranslation } from "react-i18next";
import type { Selection } from "react-stately";
import { useOverlayTriggerState } from "react-stately";

import { NotificationTypes } from "../contexts/NotificationContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import AdminActivity from "../interfaces/AdminActivity";
import AdminActivityType from "../interfaces/AdminActivityType";
import { TimespanOptionKey } from "../interfaces/TimespanOption";
import User from "../interfaces/User";
import { ActivityService } from "../services/activityService";
import { getStorageItem, storeStorageItem } from "../utils/storage";
import {
  convertDateToTicks,
  getTimestampOptionDates,
  transformObjectArraysToArrayOfIds,
} from "../utils/utils";

import { useNotifications } from "./useNotifications";
import useTooltip from "./useTooltip";

const activityService = new ActivityService();

interface FilterState {
  activityType?: AdminActivityType[];
  count: number;
  dateEnd: number;
  dateStart: number;
  offset: number;
  search?: string;
  userID?: User[];
  sort?: SortDescriptor;
  timespanOption: TimespanOptionKey;
}

function useUsersTab() {
  const { closeRightPanel } = useContext(
    RightPanelContext,
  ) as RightPanelContextProps;
  const [activities, setActivities] = useState<AdminActivity[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]));
  const [selectedActivity, setSelectedActivity] = useState<AdminActivity>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const initialFilters: FilterState = (() => {
    const storedFilters = getStorageItem(
      "sessionStorage",
      "adminActivitiesFilters",
    ) as FilterState;

    const { from, to } = getTimestampOptionDates(
      storedFilters?.timespanOption || TimespanOptionKey.LastTwentyFourHours,
    );

    const filters: Partial<FilterState> = storedFilters || {
      activityType: [],
      count: 15,
      dateEnd: convertDateToTicks(to),
      dateStart: convertDateToTicks(from),
      offset: 0,
      search: undefined,
      timespanOption: TimespanOptionKey.LastTwentyFourHours,
      userID: [],
    };

    if (
      storedFilters?.dateStart &&
      storedFilters?.dateEnd &&
      storedFilters.timespanOption !== "custom"
    ) {
      filters.dateStart = convertDateToTicks(from);
      filters.dateEnd = convertDateToTicks(to);
    }

    return filters as FilterState;
  })();
  const [filters, setFilters] = useState<FilterState>(initialFilters);
  const detailModalState = useOverlayTriggerState({});
  const { t } = useTranslation();
  const { showTooltip, hideTooltip } = useTooltip();
  const { createNotification } = useNotifications();

  const dateFormatter = useDateFormatter({
    dateStyle: "medium",
    timeStyle: "short",
  });

  /**
   * @desc Fetch users
   */
  const getAdminActivities = useCallback(async () => {
    setIsLoading(true);

    const updatedFilters = structuredClone(filters);

    // Update the date with the latest moments
    if (filters.timespanOption !== TimespanOptionKey.Custom) {
      const { from, to } = getTimestampOptionDates(filters.timespanOption);
      updatedFilters.dateEnd = convertDateToTicks(to);
      updatedFilters.dateStart = convertDateToTicks(from);
    }

    const transformedFilters =
      transformObjectArraysToArrayOfIds(updatedFilters);
    if (transformedFilters.sort !== undefined) {
      transformedFilters.sortCol = filters.sort?.column;
      transformedFilters.sortDir = filters.sort?.direction;
      delete transformedFilters.sort;
    } else {
      transformedFilters.sortCol = "createdOn";
      transformedFilters.sortDir = "descending";
    }

    activityService
      .getAdminActivities(
        qs.stringify(transformedFilters, {
          filter: (prefix, value) => (value === "" ? undefined : value),
          skipNulls: true,
        }),
      )
      .then((res) => {
        setActivities(res.data);
        setTotal(res.total);
      })
      .catch(() => {
        createNotification(
          t("errors.genericFetchError", {
            resource: t("common.activities"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createNotification, filters, t]);

  /**
   * Handles the click event on a detail element.
   *
   * @param {UserActivity} element - The user activity element clicked.
   *
   * @returns {void}
   */
  const handleDetailClick = (element: AdminActivity): void => {
    setSelectedActivity(element);
    detailModalState.open();
  };

  /**
   * Resets the advanced filters by clearing the values for the activityType, computerID, groupComputerID, policyID, and userID properties in the filters state.
   * @function
   * @name handleResetAdvancedFilters
   * @memberof yourNamespace
   * @return {void}
   */
  const handleResetAdvancedFilters = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      activityType: [],
      offset: 0,
      userID: [],
    }));
  };

  useEffect(() => {
    getAdminActivities().then(() => {});
  }, [getAdminActivities]);

  useEffect(() => {
    if (selectedKeys instanceof Set && selectedKeys.size > 0) {
      closeRightPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys]);

  /**
   * Calculates the count of applied filters.
   *
   * @function
   * @returns {number} The count of applied filters.
   */
  const appliedFiltersCount = useMemo(() => {
    let count = 0;
    Object.entries(filters).forEach(([, value]) => {
      if (Array.isArray(value)) {
        count += value.length;
      }
    });
    return count;
  }, [filters]);

  useEffect(() => {
    storeStorageItem("sessionStorage", "adminActivitiesFilters", filters);
  }, [filters]);

  return {
    activities,
    appliedFiltersCount,
    dateFormatter,
    detailModalState,
    filters,
    handleDetailClick,
    handleResetAdvancedFilters,
    hideTooltip,
    isLoading,
    selectedActivity,
    selectedKeys,
    setFilters,
    setSelectedKeys,
    showTooltip,
    t,
    total,
  };
}

export default useUsersTab;
