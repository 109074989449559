import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useState,
} from "react";
import { createRoot } from "react-dom/client";
import { CSSTransition } from "react-transition-group";

interface TooltipState {
  content: ReactNode;
  x: number;
  y: number;
  visible: boolean;
}

interface TooltipContextProps {
  tooltip: TooltipState;
  showTooltip: (
    content: ReactNode,
    event: React.MouseEvent<HTMLElement>,
    forceShow?: boolean,
    contentWidth?: number,
  ) => void;
  hideTooltip: () => void;
}

const TooltipContext = createContext<TooltipContextProps | undefined>(
  undefined,
);

interface TooltipProviderProps {
  children: ReactNode;
}

export const TooltipProvider: FunctionComponent<TooltipProviderProps> = ({
  children,
}) => {
  const [tooltip, setTooltip] = useState<TooltipState>({
    content: <></>,
    visible: false,
    x: 0,
    y: 0,
  });

  /**
   * Checks if the text of an HTML element is truncated or not.
   *
   * @param {HTMLElement} element - The HTML element to check.
   * @returns {boolean} - True if the text is truncated, false otherwise.
   */
  const isTextTruncated = (element: HTMLElement): boolean => {
    return element.offsetWidth < element.scrollWidth;
  };

  const showTooltip = (
    content: ReactNode,
    event: React.MouseEvent<HTMLElement>,
    forceShow?: boolean,
    contentWidth?: number,
  ) => {
    const target = event.currentTarget;
    const targetRect = target.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    let xPosition = event.clientX;
    let yPosition = event.clientY;

    if (forceShow || isTextTruncated(target)) {
      // Create an invisible tooltip element to calculate its actual width and height
      const tooltipElement = document.createElement("div");
      tooltipElement.style.visibility = "hidden";
      tooltipElement.style.position = "absolute";
      tooltipElement.style.whiteSpace = "nowrap";

      // If the content is a string, set it as innerHTML, otherwise render the React element into the tooltipElement
      if (typeof content === "string") {
        tooltipElement.innerHTML = content;
      } else {
        const root = createRoot(tooltipElement);
        root.render(<>{content}</>);
      }

      document.body.appendChild(tooltipElement);
      const tooltipWidth = contentWidth || tooltipElement.offsetWidth;
      const tooltipHeight = tooltipElement.offsetHeight;
      document.body.removeChild(tooltipElement);

      // If the element is beyond the middle of the screen, align the right side of the tooltip with the start of the element
      if (targetRect.left + targetRect.width / 2 > windowWidth / 2) {
        xPosition = targetRect.left - tooltipWidth;
        xPosition = Math.max(xPosition, 0);
      }

      // If the tooltip height is greater than half the window height, align the bottom of the tooltip with the top of the element
      if (tooltipHeight > window.innerHeight / 2) {
        yPosition = targetRect.top - tooltipHeight;
        yPosition = Math.max(yPosition, 0);
      }

      setTooltip({
        content,
        visible: true,
        x: xPosition,
        y: yPosition,
      });
    }
  };

  /**
   * Hides the tooltip, setting its content, visibility, x and y coordinate values.
   *
   * @function hideTooltip
   * @returns {void}
   */
  const hideTooltip = (): void => {
    setTooltip({ content: "", visible: false, x: -9999, y: -9999 });
  };

  return (
    <TooltipContext.Provider value={{ hideTooltip, showTooltip, tooltip }}>
      {children}
      <CSSTransition
        in={tooltip.visible}
        unmountOnExit
        timeout={{ enter: 0, exit: 0 }}
        style={{
          left: tooltip.x,
          top: tooltip.y,
          zIndex: 1000,
        }}
        classNames={{
          enter: "opacity-0 translate-y-0",
          enterDone:
            "opacity-1 translate-y-2 backdrop-blur-sm transition ease-in",
          exit: "opacity-0 translate-y-0 backdrop-blur-none transition ease-out",
        }}
      >
        <div className="pointer-events-none absolute rounded-md border border-gray bg-light-gray p-2 text-xs text-extra-dark-gray shadow-lg">
          {tooltip.content}
        </div>
      </CSSTransition>
    </TooltipContext.Provider>
  );
};

export default TooltipContext;
