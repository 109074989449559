import clsx from "clsx";
import React, {
  Key,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

import useTooltip from "../hooks/useTooltip";

import Checkbox from "./Checkbox";
import { Icon } from "./Icon";
import { iconComponents } from "./IconImports";
import ProgressCircle from "./ProgressCircle";

export type MultiselectComboboxOption = {
  id: string | number;
  value: string;
  icon?: keyof typeof iconComponents;
  sectionId?: string;
  sectionLabel?: string;
};

export type MultiSelectComboBoxProps = {
  id: string;
  items: MultiselectComboboxOption[];
  selectedKeys?: Key[];
  disabledKeys?: Key[];
  enabledKeys?: Key[];
  selectedObjects?: MultiselectComboboxOption[];
  onSelectionChange?: (keys: Key[]) => void;
  onSelectionObjectChange?: (keys: MultiselectComboboxOption[]) => void;
  onSearch?: (searchTerm: string) => void;
  placeholder?: string;
  "aria-label"?: string;
  isLoading?: boolean;
  isAllSelectable?: boolean;
  useDebounceForSearch?: boolean;
  disabled?: boolean;
  icon?: keyof typeof iconComponents;
  label?: string;
  maxSelectableItems?: number;
  onLoadMore?: () => void;
  totalItems?: number;
  clearable?: boolean;
  isReadOnly?: boolean;
};

const MultiSelectComboBox: React.FC<MultiSelectComboBoxProps> = ({
  selectedKeys = [],
  items = [],
  onSelectionChange,
  onSearch,
  placeholder,
  "aria-label": ariaLabel,
  isLoading = false,
  onSelectionObjectChange,
  selectedObjects = [],
  isAllSelectable = false,
  useDebounceForSearch = true,
  disabled = false,
  id,
  maxSelectableItems,
  onLoadMore,
  totalItems,
  disabledKeys,
  enabledKeys,
  clearable = false,
  isReadOnly = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isListOpenAbove, setIsListOpenAbove] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Key[]>(selectedKeys);
  const [selectedItemObjects, setSelectedItemObjects] =
    useState<MultiselectComboboxOption[]>(selectedObjects);
  const [searchValue, setSearchValue] = useState("");
  const ref = useRef<HTMLDivElement | null>(null);
  const ulRef = useRef<HTMLUListElement>(null);
  const [placeholderText, setPlaceholderText] = useState(placeholder);
  const [searchTimeout, setSearchTimeout] = useState<number | null>(null);
  const [isSelectableLimitReached, setIsSelectableLimitReached] =
    useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [openedSections, setOpenedSections] = useState<string[]>([]);
  const { showTooltip, hideTooltip } = useTooltip();

  /**
   * Handles the change event of a search input field.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The event object representing the change event.
   * @returns {void}
   */
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);

    if (useDebounceForSearch) {
      if (searchTimeout !== null) {
        window.clearTimeout(searchTimeout);
      }

      const newTimeout = window.setTimeout(() => {
        if (onSearch) {
          onSearch(newSearchValue);
          if (ulRef.current) {
            ulRef.current.scrollTop = 0;
          }
        }
      }, 300);
      setSearchTimeout(newTimeout);
    } else {
      if (onSearch) {
        onSearch(newSearchValue);
      }
    }
  };

  /**
   * Handles the click event for an item in a list.
   *
   * @param {Key} value - The value of the clicked item.
   */
  const handleItemClick = (value: Key) => {
    const foundItem = items.find((i) => String(i.id) === String(value));
    let newSelectedItems: Key[] = [];
    let newSelectedObjects: MultiselectComboboxOption[] = [];
    if (
      selectedItems.includes(value) ||
      selectedItemObjects.some((item) => String(item.id) === String(value))
    ) {
      newSelectedItems = selectedItems.filter((item) => item !== value);
      newSelectedObjects = selectedItemObjects.filter(
        (item) => String(item.id) !== String(value),
      );
    } else {
      newSelectedItems = [...selectedItems, value];
      if (foundItem) {
        newSelectedObjects = [...selectedObjects, foundItem];
      }
    }
    setSelectedItems(newSelectedItems);
    if (onSelectionChange) {
      onSelectionChange(newSelectedItems);
    }
    setSelectedItemObjects(newSelectedObjects);
    if (onSelectionObjectChange) {
      onSelectionObjectChange(newSelectedObjects);
    }
  };

  /**
   * Handles the select all functionality.
   *
   * @returns {void}
   */
  const handleSelectAll = (): void => {
    if (items.length === 0) return;

    let newSelectedItems: Key[] = [];
    let newSelectedObjects: MultiselectComboboxOption[] = [];

    if (selectedObjects.length === items.length) {
      newSelectedItems = [];
      newSelectedObjects = [];
    } else {
      newSelectedItems = items.map((item) => String(item.id));
      newSelectedObjects = items.slice();
    }

    setSelectedItems(newSelectedItems);
    setSelectedItemObjects(newSelectedObjects);

    if (onSelectionChange) {
      onSelectionChange(newSelectedItems);
    }
    if (onSelectionObjectChange) {
      onSelectionObjectChange(newSelectedObjects);
    }
  };

  /**
   * Handles the selection of a section.
   *
   * @param {string} section - The section to be selected.
   * @returns {void}
   */
  const handleSelectSection = (section: string): void => {
    const sectionItems = groupedItems[section];
    let newSelectedItems: Key[] = [];
    let newSelectedObjects: MultiselectComboboxOption[] = [];

    const allSelected = sectionItems.every(
      (item: MultiselectComboboxOption) =>
        selectedItems.includes(String(item.id)) ||
        selectedItemObjects.some(
          (selected) => String(selected.id) === String(item.id),
        ),
    );

    if (allSelected) {
      newSelectedItems = selectedItems.filter(
        (item) =>
          !sectionItems.some(
            (sectionItem: MultiselectComboboxOption) =>
              String(sectionItem.id) === String(item),
          ),
      );
      newSelectedObjects = selectedItemObjects.filter(
        (item) =>
          !sectionItems.some(
            (sectionItem: MultiselectComboboxOption) =>
              String(sectionItem.id) === String(item.id),
          ),
      );
    } else {
      newSelectedItems = [
        ...selectedItems,
        ...sectionItems
          .filter(
            (item: MultiselectComboboxOption) =>
              !selectedItems.includes(String(item.id)),
          )
          .map((item: MultiselectComboboxOption) => String(item.id)),
      ];
      newSelectedObjects = [
        ...selectedItemObjects,
        ...sectionItems.filter(
          (item: MultiselectComboboxOption) =>
            !selectedItemObjects.some(
              (obj) => String(obj.id) === String(item.id),
            ),
        ),
      ];
    }

    setSelectedItems(newSelectedItems);
    setSelectedItemObjects(newSelectedObjects);

    if (onSelectionChange) {
      onSelectionChange(newSelectedItems);
    }
    if (onSelectionObjectChange) {
      onSelectionObjectChange(newSelectedObjects);
    }
  };

  /**
   * Toggles the given section in the UI.
   *
   * @param {string} section - The section to toggle.
   * @returns {void}
   */
  const handleToggleSection = (section: string): void => {
    if (openedSections.includes(section)) {
      setOpenedSections(openedSections.filter((s: string) => s !== section));
    } else {
      setOpenedSections([...openedSections, section]);
    }
  };

  /**
   * Handles toggling the open state of the component.
   *
   * @returns {void}
   */
  const handleToggleOpen = (): void => {
    const rect = inputRef.current?.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    setIsOpen(!isOpen);

    if (rect) {
      const isInputLowerThanHalfScreen = rect.top > windowHeight / 2;
      setIsListOpenAbove(isInputLowerThanHalfScreen);
    }
  };

  /**
   * A callback function that checks if an element has reached the bottom of its scrollable area and
   * triggers a callback function when it does.
   *
   * @callback handleScroll
   *
   * @param {function} onLoadMore - The callback function to be executed when the element reaches the bottom.
   *
   * @returns {void}
   */
  const handleScroll = useCallback(() => {
    const ul = ulRef.current;
    if (!ul) return;

    const atBottom = ul.scrollHeight - ul.scrollTop - ul.clientHeight < 1;
    if (atBottom) {
      onLoadMore?.();
    }
  }, [onLoadMore]);

  /**
   * Sets focus on the input field when the label element is clicked.
   *
   * @param {React.MouseEvent<HTMLLabelElement>} event - The click event object.
   */
  const focusInput = (event: React.MouseEvent<HTMLLabelElement>) => {
    event.preventDefault();
    inputRef.current?.focus();
  };

  useEffect(() => {
    const ul = ulRef.current;
    if (ul) {
      ul.addEventListener("scroll", handleScroll);

      return () => {
        ul.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    if (JSON.stringify(selectedKeys) === JSON.stringify(selectedItems)) return;
    setSelectedItems(selectedKeys);
  }, [selectedItems, selectedKeys]);

  useEffect(() => {
    if (JSON.stringify(selectedObjects) === JSON.stringify(selectedItemObjects))
      return;

    setSelectedItemObjects(selectedObjects);
  }, [selectedItemObjects, selectedObjects]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        ulRef.current &&
        !ulRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        if (searchValue !== "") {
          handleSearchChange({
            target: { value: "" },
          } as React.ChangeEvent<HTMLInputElement>);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, ulRef, searchValue, onSearch]);

  useEffect(() => {
    const selectionCount = selectedObjects.length;
    const itemCount = items.length;
    const newPlaceholder = `${placeholder} (Selected ${selectionCount}${
      maxSelectableItems ? "/" + maxSelectableItems : ""
    } of ${totalItems || itemCount})`;
    if (placeholderText !== newPlaceholder) {
      setPlaceholderText(newPlaceholder);
    }
  }, [
    items,
    maxSelectableItems,
    placeholder,
    setPlaceholderText,
    selectedObjects,
    totalItems,
  ]);

  useEffect(() => {
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchTimeout]);

  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
    }
  }, [disabled]);

  useEffect(() => {
    if (
      maxSelectableItems &&
      selectedObjects.length >= maxSelectableItems &&
      !isSelectableLimitReached
    ) {
      setIsSelectableLimitReached(true);
    } else if (isSelectableLimitReached) {
      setIsSelectableLimitReached(false);
    }
  }, [
    maxSelectableItems,
    selectedObjects,
    setIsSelectableLimitReached,
    selectedObjects,
  ]);

  useEffect(() => {
    if (isOpen && ref.current && ulRef.current) {
      const rect = ref.current.getBoundingClientRect();
      const ulHeight = ulRef.current.offsetHeight;
      const topPos = rect.bottom + window.scrollY;
      const bottomPos = window.innerHeight - rect.top + window.scrollY;
      const isAbove =
        isListOpenAbove && window.innerHeight - rect.bottom < ulHeight;

      ulRef.current.style.width = `${rect.width}px`;
      ulRef.current.style.left = `${rect.left}px`;

      if (isAbove) {
        ulRef.current.style.bottom = `${bottomPos + 14}px`;
        ulRef.current.style.top = "auto";
      } else {
        ulRef.current.style.top = `${topPos}px`;
        ulRef.current.style.bottom = "auto";
      }
    }
  }, [isOpen, isListOpenAbove, inputRef, ulRef]);

  /**
   * Group the items based on their section.
   *
   * @param {Array} items - The items to be grouped.
   * @returns {Object} - The grouped items object.
   */
  const groupedItems = items.reduce(
    (acc, item) => {
      const section = item?.sectionId || "__noSection__";
      if (!acc[section]) acc[section] = [];
      acc[section].push(item);
      return acc;
    },
    {} as Record<string, any[]>,
  );

  useEffect(() => {
    const handleElementScroll = () => {
      if (!isOpen || !ref.current || !ulRef.current) {
        return;
      }

      const rect = ref.current.getBoundingClientRect();
      const ulHeight = ulRef.current.offsetHeight;
      const shouldOpenAbove =
        window.innerHeight - rect.bottom < ulHeight && rect.top > ulHeight;

      setIsListOpenAbove(shouldOpenAbove);

      ulRef.current.style.width = `${rect.width}px`;
      ulRef.current.style.left = `${window.pageXOffset + rect.left}px`;

      if (shouldOpenAbove) {
        ulRef.current.style.top = "auto";
        ulRef.current.style.bottom = `${window.innerHeight - rect.top}px`;
      } else {
        ulRef.current.style.bottom = "auto";
        ulRef.current.style.top = `${window.pageYOffset + rect.bottom}px`;
      }
    };

    document.addEventListener("scroll", handleElementScroll, true);
    document.addEventListener("resize", handleElementScroll, true);

    return () => {
      document.removeEventListener("scroll", handleElementScroll, true);
      document.removeEventListener("resize", handleElementScroll, true);
    };
  }, [isOpen, ref, ulRef, isListOpenAbove]);

  /**
   * Computes and memoizes the section labels based on the given items.
   *
   * @param {Array<Object>} items - The array of items to compute section labels from.
   *
   * @returns {Record<string, string>} - The object of section labels where sectionId is the key and sectionLabel is the value.
   */
  const sectionLabels = useMemo(
    () =>
      items.reduce(
        (acc, item) => {
          if (item?.sectionId && item?.sectionLabel) {
            acc[item?.sectionId] = item?.sectionLabel;
          }
          return acc;
        },
        {} as Record<string, string>,
      ),
    [items],
  );

  return (
    <div
      ref={ref}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      className={clsx(
        "relative w-full rounded-[6px] transition-all duration-200 ease-in-out",
        disabled && "pointer-events-none opacity-50",
        isReadOnly ? "pointer-events-none bg-light-gray" : "bg-white",
      )}
    >
      <div className="relative flex h-[40px] w-full flex-row items-center gap-2 rounded-[6px] border border-gray pr-3">
        <input
          role="textbox"
          aria-roledescription="input"
          ref={inputRef}
          disabled={disabled}
          type="text"
          name={id}
          placeholder=" "
          className="peer h-[40px] w-full rounded-[6px] bg-transparent
        px-3 pt-3 font-sans text-sm font-normal text-extra-dark-gray outline outline-0 transition-all duration-100
        ease-in-out
        focus:outline-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
          onFocus={() => setIsOpen(true)}
          autoComplete="none"
          aria-autocomplete="none"
          value={searchValue}
          onChange={handleSearchChange}
        />
        {placeholderText && (
          <label
            htmlFor={id}
            onClick={focusInput}
            onMouseEnter={(e) => showTooltip(placeholderText, e)}
            onMouseLeave={hideTooltip}
            className={clsx(
              "absolute start-3 top-3 z-10 origin-[0] -translate-y-2.5 scale-75 transform truncate text-sm duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-2.5 peer-focus:scale-75 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4 ",
              selectedObjects.length > 0
                ? "font-medium text-extra-dark-gray peer-focus:text-extra-dark-gray"
                : "text-dark-gray peer-focus:text-dark-gray",
              "text-overflow-ellipsis max-w-[calc(100%-70px)] overflow-hidden whitespace-nowrap",
            )}
          >
            {placeholderText}
          </label>
        )}
        <div className=" flex flex-row items-center bg-white pl-2">
          {clearable && selectedObjects.length > 0 && (
            <button
              disabled={disabled}
              type="button"
              aria-label="Clear"
              onClick={() => {
                if (onSelectionChange) {
                  onSelectionChange([]);
                }
                if (onSelectionObjectChange) {
                  onSelectionObjectChange([]);
                }
                setIsOpen(false);
              }}
              title="Clear selection"
              className={clsx(
                "transition-all duration-100 ease-in-out disabled:cursor-not-allowed disabled:opacity-50",
                isLoading
                  ? "pointer-events-none opacity-50"
                  : "hover:opacity-90 active:opacity-80",
              )}
            >
              <Icon
                name="CloseIcon"
                className="m-auto h-4 w-4 text-extra-dark-gray"
              />
            </button>
          )}

          <button
            disabled={disabled}
            type="button"
            aria-label="Toggle menu"
            onClick={handleToggleOpen}
            className={clsx(
              "transition-all duration-100 ease-in-out disabled:cursor-not-allowed disabled:opacity-50",
              isLoading
                ? "pointer-events-none opacity-50"
                : "hover:opacity-90 active:opacity-80",
            )}
          >
            {isLoading ? (
              <div className="transform">
                <ProgressCircle
                  aria-label="Loading..."
                  aria-labelledby="Loading..."
                  aria-loading="Loading..."
                  isIndeterminate
                />
              </div>
            ) : (
              <Icon
                name="ArrowDropDownIcon"
                className={clsx(
                  "durataion-200 m-auto transition-all ease-in-out",
                  isOpen ? "rotate-180" : "rotate-0",
                )}
              />
            )}
          </button>
        </div>
      </div>
      {isOpen &&
        createPortal(
          <ul
            role="listbox"
            ref={ulRef}
            className={clsx(
              "fixed z-50 max-h-80 overflow-y-auto rounded-[6px] border border-gray bg-white p-3 shadow-md",
              isOpen
                ? "translate-y-2 opacity-100"
                : "pointer-events-none translate-y-0 opacity-0",
              isListOpenAbove && "bottom-16 top-auto",
            )}
          >
            {isAllSelectable && !searchValue && (
              <li
                onClick={handleSelectAll}
                className="durataion-100 flex cursor-pointer items-center gap-2 border-b border-gray py-2 font-medium transition-all ease-in-out"
              >
                <Checkbox
                  aria-label="Select all"
                  isSelected={selectedObjects.length === items.length}
                  onChange={handleSelectAll}
                />
                {selectedObjects.length === items.length
                  ? "Deselect all"
                  : "Select all"}
              </li>
            )}

            {Object.keys(groupedItems).map((sectionId) => (
              <React.Fragment key={sectionId}>
                {sectionId !== "__noSection__" && (
                  <li className="sticky -top-3 z-10 flex cursor-pointer items-center gap-2 border-b border-gray bg-white py-2 text-sm">
                    <Checkbox
                      aria-label={`Select all in ${sectionId}`}
                      isSelected={groupedItems[sectionId].every(
                        (item: MultiselectComboboxOption) =>
                          selectedObjects.some(
                            (selectedObject) =>
                              String(selectedObject.id) === String(item.id),
                          ),
                      )}
                      onChange={() => handleSelectSection(sectionId)}
                    />
                    <div className="flex w-full items-center justify-between">
                      <span className="flex-shrink-0">
                        {sectionLabels[sectionId]} (
                        {
                          items.filter(
                            (item) =>
                              String(item?.sectionId) === String(sectionId) &&
                              selectedObjects.some(
                                (selectedObject) =>
                                  String(selectedObject?.id) ===
                                  String(item?.id),
                              ),
                          ).length
                        }
                        /{groupedItems?.[sectionId]?.length || 0})
                      </span>
                      <button
                        className="flex w-full justify-end"
                        onClick={() => handleToggleSection(sectionId)}
                      >
                        <Icon
                          name="ArrowDropDownIcon"
                          className={clsx(
                            "m-auto transition-all duration-200 ease-in-out",
                            openedSections.includes(sectionId)
                              ? "rotate-180"
                              : "rotate-0",
                          )}
                        />
                      </button>
                    </div>
                  </li>
                )}
                {groupedItems[sectionId].map(
                  (option: MultiselectComboboxOption) => (
                    <li
                      key={option.id}
                      onClick={() => handleItemClick(option.id)}
                      className={clsx(
                        "flex cursor-pointer flex-row items-center gap-2 truncate py-1.5 pl-2 text-sm transition-all duration-100 ease-in-out",
                        selectedItems.includes(option.id) ||
                          selectedItemObjects.some(
                            (item) => String(item?.id) === String(option?.id),
                          )
                          ? ""
                          : "text-extra-dark-gray hover:text-dark-blue",
                        isSelectableLimitReached &&
                          !(
                            selectedItems.includes(option.id) ||
                            selectedItemObjects.some(
                              (item) =>
                                String(item.id) === (option.id as string),
                            )
                          ) &&
                          "pointer-events-none opacity-50",
                        (isLoading || disabled) &&
                          "pointer-events-none opacity-50",
                        disabledKeys?.includes(option.id) &&
                          "pointer-events-none opacity-50",
                        enabledKeys?.length &&
                          !enabledKeys?.includes(option.id) &&
                          "pointer-events-none opacity-50",
                        sectionId !== "__noSection__" &&
                          !openedSections.includes(sectionId) &&
                          "hidden",
                      )}
                    >
                      <Checkbox
                        aria-label="Select item"
                        isDisabled={
                          isSelectableLimitReached &&
                          !(
                            selectedItems.includes(option.id) ||
                            selectedItemObjects.some(
                              (item: MultiselectComboboxOption) =>
                                String(item?.id) === String(option.id),
                            )
                          )
                        }
                        isSelected={
                          selectedItems.includes(option.id) ||
                          selectedItemObjects.some(
                            (item: MultiselectComboboxOption) =>
                              String(item?.id) === String(option.id),
                          )
                        }
                        onChange={() => {
                          handleItemClick(option.id);
                          setIsOpen(true);
                        }}
                      />
                      <div className="flex flex-row items-center gap-1">
                        {option.icon && (
                          <Icon
                            name={option.icon}
                            className="h-4 w-4 text-dark-blue"
                          />
                        )}
                        {option.value}
                      </div>
                    </li>
                  ),
                )}
              </React.Fragment>
            ))}
          </ul>,
          document.getElementById("select-root")!,
        )}
    </div>
  );
};

export default MultiSelectComboBox;
