import React, { createContext, ReactNode, useContext, useRef } from "react";
import type { AriaRadioGroupProps } from "react-aria";
import { useRadio, useRadioGroup } from "react-aria";
import { useRadioGroupState } from "react-stately";

interface RadioGroupProps extends AriaRadioGroupProps {
  children: ReactNode;
  label: string;
  description?: string;
  errorMessage?: string;
}

const RadioContext = createContext<any>(null);

const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const { children, label, description, errorMessage } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } =
    useRadioGroup(props, state);

  return (
    <div {...radioGroupProps} className="grid-col-1 grid gap-2">
      <span {...labelProps} className="font-semibold text-dark-blue">
        {label}
      </span>
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
      {description && (
        <div {...descriptionProps} className="text-xs">
          {description}
        </div>
      )}
      {errorMessage && state.isInvalid && (
        <div {...errorMessageProps} className="text-xs text-red">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

interface RadioProps {
  children: ReactNode;
  value: string;
}

const Radio: React.FC<RadioProps> = (props) => {
  const { children } = props;
  const state = useContext(RadioContext);
  const ref = useRef<HTMLInputElement>(null);
  const { inputProps } = useRadio(props, state, ref);

  return (
    <label className="flex cursor-pointer flex-row items-center gap-2 text-extra-dark-gray">
      <input {...inputProps} ref={ref} className="checked:bg-azure" />
      {children}
    </label>
  );
};

export { RadioGroup, Radio };
