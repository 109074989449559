import { CalendarDate, getWeeksInMonth } from "@internationalized/date";
import type { AriaCalendarGridProps } from "react-aria";
import { useCalendarGrid, useLocale } from "react-aria";
import type { CalendarState } from "react-stately";

import { CalendarCell } from "./CalendarCell";

interface CalendarGridProps extends AriaCalendarGridProps {
  state: CalendarState;
}

export function CalendarGrid({ state, ...props }: CalendarGridProps) {
  const { locale } = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <table {...gridProps} cellPadding="0" className="flex-1">
      <thead {...headerProps} className="text-extra-dark-gray">
        <tr>
          {weekDays.map((day, index) => (
            <th className="text-center" key={index}>
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date: CalendarDate | null, i: number) =>
                date ? (
                  <CalendarCell
                    isDisabled={state.isDisabled}
                    key={i}
                    state={state}
                    date={date}
                  />
                ) : (
                  <td key={i} />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
