import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { StorageItemKeyEnum } from "../enum/StorageItemKeyEnum";
import { useNotifications } from "../hooks/useNotifications";
import Computer from "../interfaces/Computer";
import User, { UserAdminEnum } from "../interfaces/User";
import { UserLicense } from "../interfaces/UserLicense";
import { AccountService } from "../services/accountService";
import { AuthService } from "../services/authService";
import {
  deleteStorageItem,
  getStorageItem,
  IStorageProvider,
  storeStorageItem,
} from "../utils/storage";

import { NotificationTypes } from "./NotificationContext";

interface AuthContextType {
  isLoggedIn: boolean;
  currentUser: User | null;
  currentLicense: UserLicense | null;
  fetchCurrentUser: () => void;
  login: (
    storage: "localStorage" | "sessionStorage",
    user: User,
    token: string,
    tokenSR: string,
    computer: Computer,
    role: any,
    license: UserLicense,
  ) => void;
  tokenSR: string | null;
  token: string | null;
  logout: () => void;
  checkToken: () => void;
  isCurrentUserReadOnly: boolean;
  isCurrentUserGuest: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);
const accountService = new AccountService();
const authService = new AuthService();

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentLicense, setCurrentLicense] = useState<UserLicense | null>(
    null,
  );
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { createNotification } = useNotifications();
  const { t } = useTranslation();

  useEffect(() => {
    const token =
      (getStorageItem("localStorage", StorageItemKeyEnum.token) as
        | string
        | null) ||
      (getStorageItem("sessionStorage", StorageItemKeyEnum.token) as
        | string
        | null);
    setIsLoggedIn(!!token);
    const user =
      getStorageItem("localStorage", StorageItemKeyEnum.currentUser) ||
      getStorageItem("sessionStorage", StorageItemKeyEnum.currentUser);
    if (user) {
      setCurrentUser(typeof user === "string" ? JSON.parse(user) : user);
    }

    const license =
      getStorageItem("localStorage", StorageItemKeyEnum.currentLicense) ||
      getStorageItem("sessionStorage", StorageItemKeyEnum.currentLicense);

    if (license) {
      setCurrentLicense(
        typeof license === "string" ? JSON.parse(license) : license,
      );
    }
  }, [location.pathname]);

  /**
   * Logs the user in and stores the necessary data in the specified storage.
   *
   * @param {("localStorage" | "sessionStorage")} storage - The type of storage to use.
   * @param {User} user - The user object.
   * @param {string} token - The authentication token.
   * @param tokenSR
   * @param {Computer} computer - The computer object.
   * @param {*} role - The user's role.
   * @param {*} license - The user's license.
   * @returns {void}
   */
  const login = (
    storage: "localStorage" | "sessionStorage",
    user: User,
    token: string,
    tokenSR: string,
    computer: Computer,
    role: any,
    license: UserLicense,
  ): void => {
    storeStorageItem(storage, StorageItemKeyEnum.token, token);
    storeStorageItem(storage, StorageItemKeyEnum.tokenSR, tokenSR);
    storeStorageItem(storage, StorageItemKeyEnum.currentUser, user);
    storeStorageItem(storage, StorageItemKeyEnum.currentComputer, computer);
    storeStorageItem(storage, StorageItemKeyEnum.currentRole, role);
    storeStorageItem(storage, StorageItemKeyEnum.currentLicense, license);
    setIsLoggedIn(true);
    setCurrentUser(user);
  };

  /**
   * Finds the storage type for a given item.
   *
   * @param {StorageItemKeyEnum} item - The item key to check.
   * @returns {string | null} - The storage type for the item or null if not found.
   */
  const findStorageTypeByItem = (
    item: StorageItemKeyEnum,
  ): keyof IStorageProvider | null => {
    if (getStorageItem("localStorage", item) !== null) {
      return "localStorage";
    }
    if (getStorageItem("sessionStorage", item) !== null) {
      return "sessionStorage";
    }
    return null;
  };

  /**
   * Fetches the current user data from the account service.
   *
   * @function
   * @name fetchCurrentUser
   * @returns {void}
   *
   * @example
   * fetchCurrentUser();
   */
  const fetchCurrentUser = (): void => {
    accountService
      .getData()
      .then((data) => {
        if (
          getStorageItem("localStorage", StorageItemKeyEnum.currentUser) !==
          null
        ) {
          storeStorageItem(
            "localStorage",
            StorageItemKeyEnum.currentUser,
            data,
          );
        }
        if (
          getStorageItem("sessionStorage", StorageItemKeyEnum.currentUser) !==
          null
        ) {
          storeStorageItem(
            "sessionStorage",
            StorageItemKeyEnum.currentUser,
            data,
          );
        }
        setCurrentUser(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /**
   * Determines if the current user is read-only.
   *
   * @returns {boolean} Returns `true` if the current user is read-only, otherwise `false`.
   *
   * @example
   * const isCurrentUserReadOnly = useMemo(() => {
   *   return currentUser?.isAdmin === UserAdminEnum.readOnly;
   * }, [currentUser]);
   */
  const isCurrentUserReadOnly = useMemo(() => {
    return currentUser?.isAdmin === UserAdminEnum.readOnly;
  }, [currentUser]);

  /**
   * Determines if the current user is a guest.
   *
   * @function
   * @name isCurrentUserGuest
   * @returns {boolean} - Returns true if the current user is a guest, otherwise false.
   *
   * @example
   * // Usage:
   * const guest = isCurrentUserGuest();
   *
   * @see UserAdminEnum
   */
  const isCurrentUserGuest = useMemo(() => {
    return currentUser?.isAdmin === UserAdminEnum.user;
  }, [currentUser]);

  /**
   * Return the value of the tokenSR variable.
   *
   * The tokenSR variable is a memoized value obtained from either the "localStorage" or "sessionStorage".
   * If the tokenSR value is not found in the "localStorage" or "sessionStorage", it will be null.
   * The value is stored as a string or null.
   *
   * The tokenSR value is calculated using the useMemo hook with the given getStorageItem function.
   * The getStorageItem function is responsible for retrieving the value from the storage, either "localStorage" or "sessionStorage".
   *
   * The dependency array [isLoggedIn] is used to trigger recalculation of the tokenSR value
   * whenever the isLoggedIn variable changes.
   *
   * @returns {string | null} The value of the tokenSR variable.
   */
  const tokenSR = useMemo(() => {
    return (
      (getStorageItem("localStorage", StorageItemKeyEnum.tokenSR) as
        | string
        | null) ||
      (getStorageItem("sessionStorage", StorageItemKeyEnum.tokenSR) as
        | string
        | null)
    );
  }, [isLoggedIn]);

  /**
   * Retrieves the authentication token from local or session storage.
   *
   * @function
   * @returns {string | null} - The retrieved authentication token, or null if it is not found.
   * @throws {Error} - If the `getStorageItem` function throws an error.
   * @since 1.0.0
   * @param {boolean} isLoggedIn - A boolean value indicating if the user is currently logged in.
   * @see {@link getStorageItem}
   */
  const token = useMemo(() => {
    return (
      (getStorageItem("localStorage", StorageItemKeyEnum.token) as
        | string
        | null) ||
      (getStorageItem("sessionStorage", StorageItemKeyEnum.token) as
        | string
        | null)
    );
  }, [isLoggedIn]);

  /**
   * Clears user session data and performs logout.
   *
   * @function logout
   * @returns {undefined}
   *
   * @example
   *
   * // Clears user session data and performs logout
   * logout();
   */
  const logout = (): void => {
    authService.deleteAuth(token || "");
    deleteStorageItem("localStorage", StorageItemKeyEnum.token);
    deleteStorageItem("sessionStorage", StorageItemKeyEnum.token);
    deleteStorageItem("localStorage", StorageItemKeyEnum.tokenSR);
    deleteStorageItem("sessionStorage", StorageItemKeyEnum.tokenSR);
    deleteStorageItem("localStorage", StorageItemKeyEnum.currentUser);
    deleteStorageItem("sessionStorage", StorageItemKeyEnum.currentUser);
    deleteStorageItem("localStorage", StorageItemKeyEnum.currentComputer);
    deleteStorageItem("sessionStorage", StorageItemKeyEnum.currentComputer);
    deleteStorageItem("localStorage", StorageItemKeyEnum.currentRole);
    deleteStorageItem("sessionStorage", StorageItemKeyEnum.currentRole);
    deleteStorageItem("localStorage", StorageItemKeyEnum.currentLicense);
    deleteStorageItem("sessionStorage", StorageItemKeyEnum.currentLicense);
    setIsLoggedIn(false);
    setCurrentUser(null);
  };

  /**
   * Executes the checkToken functionality.
   *
   * This function is responsible for checking the token using the authService.checkToken method.
   * It logs the token to the console and updates the token in the local and session storage if necessary.
   * If the token check fails, it creates a notification with an error message.
   *
   * @param {function} createNotification - The function used to create a notification.
   * @param {string} token - The token to be checked.
   * @param {function} t - The function used for translation.
   */
  const checkToken = useCallback(() => {
    authService
      .checkToken(token || "")
      .then((res) => {
        // Get default storage type by checking where is token is saved
        const defaultStorageType = findStorageTypeByItem(
          StorageItemKeyEnum.token,
        );

        if (defaultStorageType === null) {
          return;
        }

        [
          StorageItemKeyEnum.token,
          StorageItemKeyEnum.tokenSR,
          StorageItemKeyEnum.currentLicense,
          StorageItemKeyEnum.currentUser,
          StorageItemKeyEnum.currentComputer,
          StorageItemKeyEnum.currentRole,
        ].map((key) => {
          if (res?.[key]) {
            switch (findStorageTypeByItem(StorageItemKeyEnum[key])) {
              case "localStorage":
                storeStorageItem(
                  "localStorage",
                  StorageItemKeyEnum[key],
                  res[key],
                );
                break;
              case "sessionStorage":
                storeStorageItem(
                  "sessionStorage",
                  StorageItemKeyEnum[key],
                  res[key],
                );
                break;
              default:
                storeStorageItem(
                  defaultStorageType,
                  StorageItemKeyEnum[key],
                  res[key],
                );
                break;
            }
          }
        });
      })
      .catch(() => {
        createNotification(
          t("errors.genericFetchError", {
            resource: "Token",
          }),
          NotificationTypes.DANGER,
        );
      });
  }, [createNotification, token, t]);

  useEffect(() => {
    if (isCurrentUserGuest) {
      navigate("/guest-computers");
    }
  }, [currentUser, isCurrentUserGuest, navigate]);

  return (
    <AuthContext.Provider
      value={{
        checkToken,
        currentLicense,
        currentUser,
        fetchCurrentUser,
        isCurrentUserGuest,
        isCurrentUserReadOnly,
        isLoggedIn,
        login,
        logout,
        token,
        tokenSR,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
