import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { useAuth } from "../../contexts/AuthContext";
import { NotificationTypes } from "../../contexts/NotificationContext";
import { usePendingComputerUpdate } from "../../contexts/PendingComputerUpdateContext";
import { SoftwareActivityTypeEnum } from "../../interfaces/SoftwareActivity";
import Alert from "../Alert";
import Button from "../Button";

interface Props {
  onVisibilityChange: (isVisible: boolean) => void;
}

export const PendingComputerUpdateAlert: React.FC<Props> = ({
  onVisibilityChange,
}) => {
  const {
    totalErrorUpdates,
    isAlertVisible,
    isAlertLoading,
    getPendingErrorComputers,
    forceUpdatePendingComputers,
  } = usePendingComputerUpdate();

  const [isMounted, setIsMounted] = React.useState(false);
  const { isCurrentUserGuest } = useAuth();

  const { t } = useTranslation();

  useEffect(() => {
    if (isMounted && !isCurrentUserGuest) {
      getPendingErrorComputers().catch(() => {});
    }
  }, [getPendingErrorComputers, isCurrentUserGuest, isMounted]);

  useEffect(() => {
    onVisibilityChange(isAlertVisible);
  }, [isAlertVisible, onVisibilityChange]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <CSSTransition
      in={isAlertVisible}
      unmountOnExit
      timeout={{ enter: 0, exit: 250 }}
      classNames={{
        enter: "opacity-0 -translate-y-2",
        enterDone:
          "opacity-1 translate-y-0 backdrop-blur-sm transition ease-in",
        exit: "opacity-0 -translate-y-2 backdrop-blur-none transition ease-out",
      }}
    >
      <Alert
        className="absolute left-0 right-0 top-16 pl-8 pr-10"
        status={NotificationTypes.DANGER}
        rightContent={
          <Button
            isLoading={isAlertLoading}
            className="font-bold text-red underline"
            onPress={() => {
              forceUpdatePendingComputers().catch(() => {});
            }}
            noPadding
            variant="text"
          >
            {t("common.tryAgain")}
          </Button>
        }
      >
        <Link
          className="underline"
          to={`/activities?tab=2&activityType=${SoftwareActivityTypeEnum.policyNotApplied}`}
        >
          {t("common.errorUpdateComputerAlertMessage", {
            total: totalErrorUpdates,
          })}
        </Link>
      </Alert>
    </CSSTransition>
  );
};
