import { SortDescriptor } from "@react-types/shared";
import qs from "qs";
import React, {
  Key,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDateFormatter } from "react-aria";
import { useTranslation } from "react-i18next";
import type { Selection } from "react-stately";
import { useOverlayTriggerState } from "react-stately";

import ComputerGroupDetail from "../components/computer/ComputerGroupDetail";
import { TableAction } from "../components/TableActionsBar";
import { useAuth } from "../contexts/AuthContext";
import { useDialogContext } from "../contexts/DialogContext";
import { useNavigationDirty } from "../contexts/NavigationDirtyContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { usePendingComputerUpdate } from "../contexts/PendingComputerUpdateContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import ComputerGroup from "../interfaces/ComputerGroup";
import { ComputerGroupService } from "../services/computerGroupService";
import { transformObjectArraysToArrayOfIds } from "../utils/utils";

import { useNotifications } from "./useNotifications";

const computerGroupService = new ComputerGroupService();

interface FilterState {
  search?: string;
  count: number;
  offset: number;
  sort?: SortDescriptor;
}

function useComputerGroupsTab() {
  const {
    openRightPanel,
    isRightPanelOpen,
    setRightPanelContent,
    closeRightPanel,
  } = useContext(RightPanelContext) as RightPanelContextProps;
  const [computerGroups, setComputerGroups] = useState<ComputerGroup[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]));
  const [selectedComputerGroup, setSelectedComputerGroup] = useState<
    ComputerGroup | undefined
  >(undefined); // Used for display the name of the item in the dialogs ( Only one )
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<FilterState>({
    count: 15,
    offset: 0,
    search: undefined,
  });
  const [activeComputerGroup, setActiveComputerGroup] = useState<Key | null>(
    null,
  );
  const [activeComputerGroupsForDeletion, setActiveComputerGroupsForDeletion] =
    useState<Key[]>([]);
  const dialog = useDialogContext();
  const { t } = useTranslation();
  const { confirmDirtyNavigation } = useNavigationDirty();
  const { createNotification } = useNotifications();
  const { setHasPendingUpdates } = usePendingComputerUpdate();
  const { isCurrentUserReadOnly } = useAuth();
  const deleteModalState = useOverlayTriggerState({});

  const dateFormatter = useDateFormatter({
    dateStyle: "medium",
    timeStyle: "short",
  });

  /**
   * Fetches computer groups from the server.
   * @function getComputerGroups
   * @async
   * @returns {void}
   */
  const getComputerGroups = useCallback(async () => {
    setIsLoading(true);
    const transformedFilters = transformObjectArraysToArrayOfIds(filters);
    if (transformedFilters.sort !== undefined) {
      transformedFilters.sortCol = filters.sort?.column;
      transformedFilters.sortDir = filters.sort?.direction;
      delete transformedFilters.sort;
    } else {
      transformedFilters.sortCol = "createdOn";
      transformedFilters.sortDir = "descending";
    }

    computerGroupService
      .getComputerGroups(qs.stringify(filters))
      .then((res) => {
        const filteredResponse = res.data.filter(
          (group: ComputerGroup) => group.id >= 0,
        );
        setComputerGroups(filteredResponse);
        setTotal(res.total);
      })
      .catch(() => {
        createNotification(
          t("errors.genericFetchError", {
            resource: t("common.computersGroups"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createNotification, filters, t]);

  /**
   * Handles the opening of the right panel.
   * @async
   * @param {number} [computerGroupId] - The user ID.
   */
  const handleOpenRightPanel = async (computerGroupId?: number) => {
    const confirm = await confirmDirtyNavigation();
    if (!confirm) {
      return;
    }
    openRightPanel();
    setRightPanelContent(
      <ComputerGroupDetail
        key={Math.random()}
        id={computerGroupId}
        onClose={() => {
          closeRightPanel();
        }}
        onSaved={() => {
          getComputerGroups().then(closeRightPanel);
        }}
      />,
    );
    if (!computerGroupId) {
      setActiveComputerGroup(null);
    }
  };

  /**
   * Deletes a computer group with the specified ID.
   */
  const handleComputerGroupDelete = () => {
    setIsLoading(true);
    computerGroupService
      .deleteComputerGroup(
        qs.stringify({
          id: activeComputerGroupsForDeletion.includes("all")
            ? [-1000]
            : activeComputerGroupsForDeletion,
        }),
      )
      .then(() => {
        setSelectedKeys(new Set([]));
        closeRightPanel();
        getComputerGroups().then(() => {
          deleteModalState.close();
          setHasPendingUpdates(true);
        });
      })
      .catch(() => {
        createNotification(
          t("errors.genericDeleteError", {
            resource: t("common.computersGroups"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Variable: tableActions
   * Description: Defines an array of table actions based on the selected keys.
   *
   * @type {TableAction[]}
   *
   * @return {TableAction[]} Array of table actions
   *
   * @param {Function} closeRightPanel - Function to close the right panel
   * @param {Object} dialog - Dialog object used for displaying messages
   * @param {Function} getComputerGroups - Function to fetch computer groups
   * @param {Function} openRightPanel - Function to open the right panel
   * @param {Set|String} selectedKeys - Set of selected keys or "all" to select all keys
   * @param {Function} setRightPanelContent - Function to set the content of the right panel
   * @param {Function} t - Translation function
   */
  const tableActions: TableAction[] = useMemo(() => {
    if (isCurrentUserReadOnly) {
      return [];
    }

    const baseAction: TableAction = {
      icon: "AddIcon",
      label: t("common.addComputerGroup"),
      onClick: () => {
        handleOpenRightPanel().then(() => {});
      },
    };

    const additionalActions: TableAction[] = [
      {
        icon: "DeleteIcon",
        label: t("common.delete"),
        onClick: () => {
          deleteModalState.open();
          setActiveComputerGroupsForDeletion(
            selectedKeys === "all" ? ["all"] : Array.from(selectedKeys),
          );
        },
      },
    ];

    if (selectedKeys === "all" || selectedKeys.size > 0) {
      return additionalActions;
    } else {
      return [baseAction];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeRightPanel,
    dialog,
    getComputerGroups,
    openRightPanel,
    selectedKeys,
    setRightPanelContent,
    t,
  ]);

  /**
   * Executes action based on the given key.
   *
   * @param {number} id - The id of the item.
   * @param {Key} key - The action key.
   */
  const onMenuAction = (id: number, key: Key) => {
    switch (key) {
      case "edit":
        handleOpenRightPanel(Number(id)).then(() => {});
        break;
      case "delete":
        deleteModalState.open();
        setActiveComputerGroupsForDeletion([id]);
        break;
      default:
        break;
    }
  };

  /**
   * Executes an action when a row is clicked.
   *
   * @param {Key} key - The key of the row being clicked.
   * @returns {void}
   */
  const onRowAction = (key: Key): void => {
    handleOpenRightPanel(Number(key)).then(() => {
      setActiveComputerGroup(key);
    });
  };

  useEffect(() => {
    getComputerGroups().then(() => {});
  }, [getComputerGroups]);

  useEffect(() => {
    if (!isRightPanelOpen) {
      setActiveComputerGroup(null);
    }
  }, [isRightPanelOpen]);

  useEffect(() => {
    if (selectedKeys instanceof Set && selectedKeys.size > 0) {
      closeRightPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys]);

  useEffect(() => {
    if (activeComputerGroupsForDeletion.length === 1) {
      const foundComputerGroup = computerGroups.find(
        (computerGroup) =>
          computerGroup.id === Number(activeComputerGroupsForDeletion[0]),
      );
      setSelectedComputerGroup(foundComputerGroup);
      return;
    }

    if (selectedKeys !== "all" && selectedKeys.size === 1) {
      const foundComputerGroup = computerGroups.find(
        (computerGroup) =>
          computerGroup.id === Number(selectedKeys.values().next().value),
      );
      setSelectedComputerGroup(foundComputerGroup);
      return;
    }
  }, [computerGroups, activeComputerGroupsForDeletion, selectedKeys]);

  return {
    activeComputerGroup,
    activeComputerGroupsForDeletion,
    computerGroups,
    dateFormatter,
    deleteModalState,
    filters,
    handleComputerGroupDelete,
    handleOpenRightPanel,
    isCurrentUserReadOnly,
    isLoading,
    onMenuAction,
    onRowAction,
    selectedComputerGroup,
    selectedKeys,
    setFilters,
    setSelectedKeys,
    t,
    tableActions,
    total,
  };
}

export default useComputerGroupsTab;
