import React, { createContext, ReactNode, useContext, useState } from "react";
import { useOverlayTriggerState } from "react-stately";

import { Dialog } from "../components/Dialog";
import { iconComponents } from "../components/IconImports";

interface DialogContextProps {
  open: (
    title: string,
    message: string,
    icon?: keyof typeof iconComponents,
    confirmButtonClassName?: string,
    iconClassName?: string,
    confirmLabel?: string,
    cancelLabel?: string,
  ) => Promise<void>;
  close: (confirmed: boolean) => void;
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined);

export function useDialogContext() {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialogContext must be used within DialogProvider");
  }
  return context;
}

export function DialogProvider({ children }: { children: ReactNode }) {
  const dialogState = useOverlayTriggerState({});
  const [dialogData, setDialogData] = useState<{
    title: string;
    message?: string;
    confirmLabel?: string;
    cancelLabel?: string;
    icon?: keyof typeof iconComponents;
    confirmButtonClassName?: string;
    iconClassName?: string;
    resolve?: () => void;
    reject?: (reason?: string) => void;
  }>({
    cancelLabel: "Cancel",
    confirmLabel: "Confirm",
    message: "",
    title: "",
  });

  /**
   * Opens a dialog box with the specified title and message.
   *
   * @param {string} title - The title of the dialog box.
   * @param {string} message - The message to be displayed in the dialog box.
   * @param {string} [confirmLabel] - Optional. The label for the confirm button. Default is undefined.
   * @param {string} [cancelLabel] - Optional. The label for the cancel button. Default is undefined.
   * @param {keyof typeof iconComponents} [icon] - Optional. The icon to be displayed in the dialog box. Default is undefined.
   * @param {string} [confirmButtonClassName] - Optional. The class name for the confirm button. Default is undefined.
   * @param {string} [iconClassName] - Optional. The class name for the icon. Default is undefined.
   * @returns {Promise} A promise that resolves when the dialog box is closed, or rejects if there is an error.
   * @throws {Error} If there is an error while opening the dialog box.
   */
  const open = (
    title: string,
    message: string,
    icon?: keyof typeof iconComponents,
    confirmButtonClassName?: string,
    iconClassName?: string,
    confirmLabel?: string,
    cancelLabel?: string,
  ): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      setDialogData({
        cancelLabel,
        confirmButtonClassName,
        confirmLabel,
        icon,
        iconClassName,
        message,
        reject,
        resolve,
        title,
      });
      dialogState.open();
    }).catch((reason) => {
      throw reason;
    });
  };

  const close = (confirmed: boolean) => {
    if (confirmed && dialogData.resolve) {
      dialogData.resolve();
    } else if (!confirmed && dialogData.reject) {
      dialogData.reject("Dialog closed without confirmation");
    }
    dialogState.close();
    setDialogData((prev) => ({
      ...prev,
      message: "",
      title: "",
    }));
  };

  return (
    <DialogContext.Provider value={{ close, open }}>
      {children}
      {dialogState.isOpen && (
        <Dialog
          title={dialogData.title}
          confirmLabel={dialogData.confirmLabel}
          cancelLabel={dialogData.cancelLabel}
          onClose={() => close(false)}
          onConfirm={() => close(true)}
          icon={dialogData.icon}
          confirmButtonClassName={dialogData.confirmButtonClassName}
          iconClassName={dialogData.iconClassName}
          state={dialogState}
        >
          {dialogData.message}
        </Dialog>
      )}
    </DialogContext.Provider>
  );
}
