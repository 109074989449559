import { translate } from "../utils/translation";

export enum UserStatus {
  unknown = 0,
  enabled = 1,
  suspended = 2,
  pending = 3,
  expired = 1000,
}

export enum UserAdminEnum {
  user = 0,
  readOnly = 1,
  admin = 2,
}

const userStatusTranslationKeys = {
  [UserStatus.enabled]: "userStatus.enabled",
  [UserStatus.suspended]: "userStatus.suspended",
  [UserStatus.pending]: "userStatus.pending",
  [UserStatus.expired]: "userStatus.expired",
  [UserStatus.unknown]: "userStatus.unknown",
};

export function userStatusToString(status: UserStatus): string {
  return translate(userStatusTranslationKeys[status]);
}

export function userStatusToColor(status: UserStatus): string {
  switch (status) {
    case UserStatus.enabled:
      return "#72BBA1";
    case UserStatus.suspended:
      return "#B74949";
    case UserStatus.pending:
      return "#EBCE36";
    case UserStatus.expired:
      return "#A9A9A9";
    default:
      return "#525B62";
  }
}

export default interface User {
  id: number;
  accessNotification: boolean;
  avatar: string;
  createdOn: string;
  email: string;
  expiry: null;
  fullname: string;
  lastAccess: string;
  name: string;
  notes: string;
  password: null;
  phone: string;
  sso: boolean;
  status: UserStatus;
  surname: string;
  twoStepAuth: number;
  username: string;
  hasAuthenticator: boolean;
  isAdmin: UserAdminEnum;
  licenseId: number;
}
