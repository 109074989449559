import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import React, { createContext, useContext, useEffect, useState } from "react";

import { UserLicense } from "../interfaces/UserLicense"; // Aggiorna il percorso se necessario
import { getSignalRConnection } from "../services/signalRService"; // Aggiorna il percorso se necessario
import { getStorageItem, onStorageChange } from "../utils/storage";

interface ISignalRContext {
  signalRConnection: HubConnection | null;
  signalRConnectionState: HubConnectionState;
}

const SignalRContext = createContext<ISignalRContext | undefined>(undefined);

export const useSignalR = (): ISignalRContext => {
  const context = useContext(SignalRContext);
  if (!context) {
    throw new Error("useSignalR must be used within a SignalRProvider");
  }
  return context;
};

export const SignalRProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [connectionState, setConnectionState] = useState<HubConnectionState>(
    HubConnectionState.Disconnected,
  );

  useEffect(() => {
    /**
     * Checks and starts the connection to the server using SignalR.
     *
     * @returns {Function} A cleanup function to stop the connection on unmount or connection change.
     */
    const checkAndStartConnection = () => {
      const license: UserLicense | null =
        (getStorageItem("localStorage", "currentLicense") as UserLicense) ||
        (getStorageItem("sessionStorage", "currentLicense") as UserLicense);
      const tokenSR: string | null =
        (getStorageItem("localStorage", "tokenSR") as string) ||
        (getStorageItem("sessionStorage", "tokenSR") as string);

      if (!license?.id || !tokenSR) return;

      if (!connection || connection.state === HubConnectionState.Disconnected) {
        const conn = getSignalRConnection(tokenSR, license.id.toString());
        setConnection(conn);

        const startConnection = async () => {
          try {
            await conn.start();
            setConnectionState(conn.state);
          } catch (err) {
            console.error("Connection failed:", err);
            setConnectionState(conn.state);
          }
        };

        startConnection();

        // Cleanup on unmount or connection change
        return () => {
          conn
            .stop()
            .then(() => setConnectionState(HubConnectionState.Disconnected));
        };
      }
    };

    checkAndStartConnection();

    // Listen for storage changes to start the connection
    const unsubscribeStorageChange = onStorageChange((key) => {
      if (key === "currentLicense" || key === "tokenSR") {
        checkAndStartConnection();
      }
    });

    // Remove the listener when the component is unmounted
    return () => {
      unsubscribeStorageChange && unsubscribeStorageChange();
    };
  }, [connection]);

  return (
    <SignalRContext.Provider
      value={{
        signalRConnection: connection,
        signalRConnectionState: connectionState,
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
};
