import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import UserActivityType from "../../interfaces/UserActivityType";
import { userActivityTypes } from "../../utils/userActivityTypes";
import MultiSelectComboBox, {
  MultiselectComboboxOption,
} from "../MultiSelectComboBox";

interface Props {
  id: string;
  selectedObjects: UserActivityType[];
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (e: UserActivityType[]) => void;
  loading?: boolean;
  maxSelectableItems?: number;
  clearable?: boolean;
}

export const UserActivityTypeMultiselect: React.FC<Props> = ({
  id,
  selectedObjects,
  label,
  placeholder,
  disabled,
  onChange,
  loading,
  maxSelectableItems,
  clearable = true,
}) => {
  const [query, setQuery] = useState<string>("");
  const { t } = useTranslation();

  /**
   * Represents a variable that stores filtered policies based on a query.
   *
   * @type {Array}
   * @name filteredItems
   *
   * @param {string} query - The query used for filtering policies.
   *
   * @returns {Array} - An array containing policies that match the given query.
   *
   * @example
   * // Usage
   * const filteredPolicies = useMemo(() => {
   */
  const filteredItems: Array<MultiselectComboboxOption> = useMemo(() => {
    return userActivityTypes
      .filter((item) => {
        return item?.policy?.name.toLowerCase().includes(query.toLowerCase());
      })
      .map((item) => {
        return {
          icon: item?.policy?.icon,
          id: item.id,
          value: item?.policy?.name || "N/D",
        };
      });
  }, [query]);

  /**
   * Represents the parsed selected objects.
   *
   * @type {Array<MultiselectComboboxOption>}
   */
  const parsedSelectedObjects: Array<MultiselectComboboxOption> =
    useMemo(() => {
      return selectedObjects.map((item) => {
        return {
          icon: item?.policy?.icon,
          id: item.id,
          value: item?.policy?.name || "N/D",
        };
      });
    }, [selectedObjects]);

  /**
   * Updates the selected objects and triggers the 'onChange' event.
   *
   * @param {MultiselectComboboxOption[]} selectedObjects - The selected objects to update.
   */
  const handleSelectionObjectChange = (
    selectedObjects: MultiselectComboboxOption[],
  ) => {
    const newSelectedObjects = selectedObjects
      .map((selectedObject) => {
        return userActivityTypes.find((item) => item.id === selectedObject.id);
      })
      .filter((item): item is UserActivityType => item !== undefined);

    onChange(newSelectedObjects);
  };

  return (
    <div className="flex flex-col gap-4">
      <MultiSelectComboBox
        maxSelectableItems={maxSelectableItems}
        id={id}
        disabled={disabled}
        clearable={clearable}
        selectedObjects={parsedSelectedObjects}
        onSelectionObjectChange={(e) => {
          handleSelectionObjectChange(e);
        }}
        aria-label={label || t("common.policyType")}
        placeholder={placeholder || t("common.policyType")}
        isLoading={loading}
        items={filteredItems}
        onSearch={(e) => setQuery(e)}
      />
    </div>
  );
};
