import clsx from "clsx";
import { FC, useEffect, useMemo, useState } from "react";

import { Icon } from "./Icon";

let interval: NodeJS.Timeout;

const baseStyles = {
  danger: "bg-[#FAECEC]  border-red text-red",
  info: "bg-light-gray  border-dark-gray text-extra-dark-gray",
  notice: "bg-pale-blue  border-[#1779BC] text-[#1779BC]",
  success: "bg-light-green text-green  border-green",
  warning: "bg-[#FFFCF3]  border-yellow text-yellow",
};

interface NotificationProps {
  message: string;
  type: "success" | "notice" | "danger" | "warning" | "info";
  onClose: () => void;
  hasBorder?: boolean;
  hasIcon?: boolean;
  hasBoxShadow?: boolean;
}

const Notification: FC<NotificationProps> = ({
  message,
  type = "info",
  onClose,
  hasIcon = true,
  hasBorder = true,
  hasBoxShadow = true,
}) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    interval = setInterval(() => {
      setProgress((prev) => prev + 1);
    }, 30);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      clearInterval(interval);
    }
  }, [progress]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      onClose();
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [onClose]);

  const iconName = useMemo(() => {
    switch (type) {
      case "success":
      case "info":
      case "notice":
        return "DoneIcon";
      case "danger":
      case "warning":
        return "ErrorIcon";
      default:
        return "DoneIcon";
    }
  }, [type]);

  return (
    <>
      <div
        className={clsx(
          "pointer-events-auto relative w-full translate-x-0 transform overflow-hidden rounded-lg transition duration-500 ease-in-out",
          baseStyles[type],
          hasBorder && "border",
          hasBoxShadow && "shadow-lg hover:shadow-xl",
        )}
      >
        <div className="py-5 pl-4 pr-4">
          <div className="flex flex-row items-center justify-center">
            {hasIcon && (
              <div className="flex-shrink-0">
                <Icon name={iconName} />
              </div>
            )}
            <div className="ml-3 w-0 flex-1">
              <p className="text-sm">{message}</p>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                aria-label="Close"
                type="button"
                className="inline-flex rounded-md text-dark-pale-green transition duration-150 ease-in-out hover:text-gray focus:outline-none active:text-dark-gray"
                onClick={() => {
                  onClose();
                }}
              >
                <Icon name="CloseIcon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
