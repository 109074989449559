import { GregorianCalendar } from "@internationalized/date";
import type { AriaDateFieldProps } from "@react-aria/datepicker";
import clsx from "clsx";
import * as React from "react";
import { useDateField, useDateSegment } from "react-aria";
import { useDateFieldState } from "react-stately";

interface DateFieldProps extends AriaDateFieldProps<any> {
  placeholder?: string;
}

interface DateSegmentProps {
  segment: any;
  state: any;
}

function DateSegment({ segment, state }: DateSegmentProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      className={`text-sm font-normal text-extra-dark-gray outline-none ${
        segment.isPlaceholder ? "placeholder" : ""
      }`}
    >
      {segment.text}
    </div>
  );
}

export function DateField(props: DateFieldProps) {
  const createCalendar = (identifier: string) => {
    switch (identifier) {
      case "gregory":
        return new GregorianCalendar();
      default:
        throw new Error(`Unsupported calendar ${identifier}`);
    }
  };

  // let { locale } = useLocale();
  const state = useDateFieldState({
    ...props,
    createCalendar,
    locale: "it-IT",
  });

  const ref = React.useRef<HTMLDivElement>(null);

  const { fieldProps } = useDateField(props, state, ref);
  return (
    <div
      {...fieldProps}
      ref={ref}
      className={clsx(
        "relative flex w-full flex-row items-center outline-none",
        state.validationState === "invalid"
          ? "border-orange placeholder-orange"
          : "border-dark-blue",
      )}
    >
      <p className="pr-2 text-dark-blue text-opacity-50">{props.placeholder}</p>
      {state.segments.map((segment, i) => (
        <DateSegment key={i} segment={segment} state={state} />
      ))}
    </div>
  );
}
