import RouterPage from "../interfaces/RouterPage";

import { translate } from "./translation";

export const settingsPages: RouterPage[] = [
  {
    hasNotification: false,
    href: "/settings",
    icon: "OutlineSettingsIcon",
    isDisabled: false,
    key: "profile",
    label: translate("pages.profile"),
  },
  {
    hasNotification: false,
    href: "/settings/security",
    icon: "OutlineShieldIcon",
    isDisabled: false,
    key: "security",
    label: translate("pages.security"),
  },
  {
    hasNotification: false,
    href: "/settings/notifications",
    icon: "OutlineBellIcon",
    isDisabled: false,
    key: "notifications",
    label: translate("pages.notifications"),
  },
  {
    hasNotification: false,
    href: "/settings/configuration",
    icon: "FileIcon",
    isDisabled: false,
    key: "configuration",
    label: translate("pages.configuration"),
  },
];
