import clsx from "clsx";
import React, { useState } from "react";
import type { AriaModalOverlayProps } from "react-aria";
import { Overlay, useModalOverlay } from "react-aria";
import type { OverlayTriggerState } from "react-stately";
import { CSSTransition } from "react-transition-group";

interface ModalProps extends AriaModalOverlayProps {
  children: React.ReactNode;
  state: OverlayTriggerState;
  className?: string;
}

export function Modal(props: ModalProps) {
  const { children, state, className } = props;

  const ref = React.useRef(null);
  const { modalProps, underlayProps } = useModalOverlay(props, state, ref);
  const [exited, setExited] = useState(!state.isOpen);

  // Don't render anything if the modal is not open and we're not animating out.
  if (!(state.isOpen || !exited)) {
    return null;
  }

  return (
    <Overlay>
      <CSSTransition
        in={state.isOpen}
        appear
        onEntered={() => setExited(false)}
        onExited={() => setExited(true)}
        timeout={{ enter: 0, exit: 250 }}
        classNames={{
          enter: "opacity-0",
          enterDone: "opacity-1 backdrop-blur-sm transition ease-in",
          exit: "opacity-0 backdrop-blur-none transition ease-out",
        }}
      >
        <div
          onClick={state.close}
          className="z-100 fixed inset-0 z-50 flex justify-center bg-black/50"
          {...underlayProps}
        >
          <CSSTransition
            in={state.isOpen}
            appear
            nodeRef={ref}
            timeout={{ enter: 0, exit: 250 }}
            classNames={{
              appear: "translate-y-2",
              appearDone: "translate-y-0 transition ease-in",
              exit: "translate-y-2 transition ease-out",
            }}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              {...modalProps}
              ref={ref}
              className={clsx(
                "relative z-10 m-auto flex h-fit max-h-[80vh] max-w-fit rounded-lg bg-white p-8 shadow-2xl focus:outline-none",
                className,
              )}
            >
              {children}
            </div>
          </CSSTransition>
        </div>
      </CSSTransition>
    </Overlay>
  );
}
