export enum PolicyTypeEnum {
  BlockPort = 0,
  PortRDP = 1,
  SessionExpiry = 2,
  Activities = 3,
  DeleteFile = 4,
  DownloadFile = 5,
  Network = 6,
  Obfuscator = 7,
  SharedFolder = 8,
  UploadNewFileExe = 9,
  Country = 10,
  DateTimePlan = 11,
  Expiry = 12,
  RangeIPAddress = 13,
  WithoutRDP = 14,
  MultiUsersRDP = 15,
  WindowsAccess = 16,
  FileAccessApplication = 17,
  ExternalDrives = 18,
  AccessFolders = 19,
  ProxyControl = 20,
  EncryptionFile = 21,
  AntiPhishing = 22,
  SecureBrowser = 23,
}
