import clsx from "clsx";
import React from "react";

import AvatarField from "../components/AvatarField";
import Button from "../components/Button";
import { Icon } from "../components/Icon";
import SettingSection from "../components/settings/SettingSection";
import TextField from "../components/TextField";
import useProfileSettings from "../hooks/useProfileSettings";

export const ProfileSettings: React.FC = () => {
  const {
    form,
    fields,
    setForm,
    fieldEditing,
    setFieldEditing,
    isLoading,
    onSubmit,
    onAvatarChange,
    t,
  } = useProfileSettings();
  return (
    <div className="grid grid-cols-1 gap-4">
      <SettingSection borderless withoutPadding title={t("pages.profile")}>
        <form
          onSubmit={onSubmit}
          className="flex w-full flex-col divide-y divide-gray"
        >
          <div className="grid h-[100px] w-full grid-cols-5 items-center px-6">
            <p className="text-lg text-dark-blue">{t("common.photo")}</p>
            <AvatarField
              isLoading={isLoading}
              value={form.avatar}
              onChange={(_, base64WithoutPrefix) => {
                onAvatarChange(base64WithoutPrefix).then(() => {});
              }}
            ></AvatarField>
          </div>
          {fields.map((field) => (
            <div
              key={field.id}
              className="grid h-[100px] w-full grid-cols-5 items-center px-6"
            >
              <p className="text-lg text-dark-blue">{field.label}</p>
              {fieldEditing === field.id ? (
                <>
                  <div className="col-span-2">
                    <TextField
                      isDisabled={isLoading}
                      id={field.id}
                      label={field.label}
                      name={field.name}
                      value={form[field.id]}
                      isRequired={field.required}
                      required={field.required}
                      title={field.title}
                      type={field.type}
                      maxLength={140}
                      pattern={field.pattern}
                      onChange={(value) => {
                        setForm({ ...form, [field.id]: value });
                      }}
                    />
                  </div>
                  <div className="col-span-2 grid grid-cols-1 gap-4 md:grid-cols-2 xl:col-span-1">
                    <div>
                      <Button
                        type="button"
                        isDisabled={isLoading}
                        variant="text"
                        onPress={() => setFieldEditing(null)}
                      >
                        {t("common.cancel")}
                      </Button>
                    </div>
                    <div>
                      <Button
                        type="submit"
                        isLoading={isLoading}
                        variant="contained"
                      >
                        {t("common.save")}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p
                    className={clsx(
                      "col-span-2 transition-all duration-200 ease-in-out",
                      isLoading && "blur-sm",
                    )}
                  >
                    {form[field.id] || "N/D"}
                  </p>
                  <Button
                    variant="text"
                    onPress={() => setFieldEditing(field.id)}
                    aria-label={`Edit ${field.label}`}
                  >
                    <Icon name="EditIcon" className="h-6 w-6 text-dark-blue" />
                  </Button>
                </>
              )}
            </div>
          ))}
          {/*<div className="grid h-[100px] w-full grid-cols-5 items-center px-6">
            <p className="text-lg text-dark-blue">{t("common.language")}</p>
            <div className="col-span-2">
              <Select
                selectedKey={String(form.language)}
                aria-label={t("common.language")}
                placeholder={t("common.selectLanguage")}
                onSelectionChange={(value) => {
                  setForm({ ...form, language: String(value) });
                }}
              >
                {availableLanguages.map((item) => (
                  <Item key={item.key} aria-label={item.value}>
                    {item.value}
                  </Item>
                ))}
              </Select>
            </div>
          </div>*/}
        </form>
      </SettingSection>

      {/*<SettingSection
        title="Delete account"
        subtitle="Deleting your account will eliminate you data - we will store your data for 30 days and then delete it."
      >
        <div className="-ml-4">
          <Button variant="text">
            <div className="flex flex-row items-center gap-1">
              <Icon
                name="DeleteIcon"
                className="text-r h-6 w-6 text-dark-red"
              />
              <span className="text-dark-red">Delete account</span>
            </div>
          </Button>
        </div>
      </SettingSection>*/}
    </div>
  );
};
