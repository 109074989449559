import { useCallback, useEffect, useState } from "react";
import { useDateFormatter } from "react-aria";
import { useTranslation } from "react-i18next";

import { NotificationTypes } from "../contexts/NotificationContext";
import Computer from "../interfaces/Computer";
import { AuthService } from "../services/authService";

import { useNotifications } from "./useNotifications";
import useTooltip from "./useTooltip";

const authService = new AuthService();

interface Props {
  computer?: Computer;
}

function useGuestComputerDetail({ computer }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agentOTP, setAgentOTP] = useState<string>("");
  const [isAgentOTPVisible, setIsAgentOTPVisible] = useState<boolean>(false);
  const [agentOTPCountdown, setAgentOTPCountdown] = useState<number>(0);
  const [formattedAgentOTPCountdown, setFormattedAgentOTPCountdown] =
    useState<string>("");

  const { createNotification } = useNotifications();
  const { t } = useTranslation();
  const { showTooltip, hideTooltip } = useTooltip();

  const dateFormatter = useDateFormatter({
    dateStyle: "medium",
    timeStyle: "short",
  });

  /**
   * @desc Fetches user data from the API
   */
  const getAgentOTP = useCallback(() => {
    if (!computer?.id) return;

    setIsLoading(true);
    authService
      .agentOTP({ id: computer?.id })
      .then(async (result) => {
        result.text().then((textData: string) => {
          setAgentOTP(textData);
          setAgentOTPCountdown(10 * 60);
        });
      })
      .catch(() => {
        createNotification(
          t("errors.genericFetchError", {
            resource: t("common.computer"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [computer?.id, createNotification, t]);

  /**
   * Copies the agent OTP to the clipboard and displays a notification.
   *
   * @returns {void}
   */
  const handleCopyAgentOTP = (): void => {
    navigator.clipboard
      .writeText(agentOTP)
      .then(() =>
        createNotification(
          t("notifications.agentOTPCopied"),
          NotificationTypes.SUCCESS,
        ),
      )
      .catch(() =>
        createNotification(
          t("errors.genericCopyError"),
          NotificationTypes.DANGER,
        ),
      );
  };

  /**
   * Function to handle the download of an RDP configuration file.
   */
  const handleDownloadRDPConfigurationFile = () => {
    const rdpContent = `screen mode id:i:2
use multimon:i:0
desktopwidth:i:3840
desktopheight:i:2160
session bpp:i:32
winposstr:s:0,1,1825,18,2625,618
compression:i:1
keyboardhook:i:2
audiocapturemode:i:0
videoplaybackmode:i:1
connection type:i:7
networkautodetect:i:1
bandwidthautodetect:i:1
displayconnectionbar:i:1
enableworkspacereconnect:i:0
disable wallpaper:i:0
allow font smoothing:i:0
allow desktop composition:i:0
disable full window drag:i:1
disable menu anims:i:1
disable themes:i:0
disable cursor setting:i:0
bitmapcachepersistenable:i:1
full address:s:${computer?.ipAddress}
audiomode:i:0
redirectprinters:i:1
redirectlocation:i:0
redirectcomports:i:0
redirectsmartcards:i:1
redirectwebauthn:i:1
redirectclipboard:i:1
redirectposdevices:i:0
autoreconnection enabled:i:1
authentication level:i:2
prompt for credentials:i:0
negotiate security layer:i:1
remoteapplicationmode:i:0
alternate shell:s:
shell working directory:s:
gatewayhostname:s:
gatewayusagemethod:i:4
gatewaycredentialssource:i:4
gatewayprofileusagemethod:i:0
promptcredentialonce:i:0
gatewaybrokeringtype:i:0
use redirection server name:i:0
rdgiskdcproxy:i:0
kdcproxyname:s:
enablerdsaadauth:i:0`;

    // Create a blob from the RDP content
    const blob = new Blob([rdpContent], { type: "application/rdp" });

    // Trim and remove all spaces from the computer name
    const computerName = computer?.name?.replace(/\s/g, "");

    // Create an anchor element and trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${computerName || "configuration"}.rdp`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (agentOTPCountdown > 0) {
      const interval = setInterval(() => {
        setAgentOTPCountdown((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(interval);
            setAgentOTP("");
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [agentOTPCountdown]);

  useEffect(() => {
    const minutes = Math.floor(agentOTPCountdown / 60);
    const seconds = agentOTPCountdown % 60;
    setFormattedAgentOTPCountdown(
      `${minutes}:${seconds.toString().padStart(2, "0")}`,
    );
  }, [agentOTPCountdown]);

  return {
    agentOTP,
    computer,
    dateFormatter,
    formattedAgentOTPCountdown,
    getAgentOTP,
    handleCopyAgentOTP,
    handleDownloadRDPConfigurationFile,
    hideTooltip,
    isAgentOTPVisible,
    isLoading,
    setIsAgentOTPVisible,
    showTooltip,
    t,
  };
}

export default useGuestComputerDetail;
