import { PolicySeverityEnum } from "../enum/PolicySeverityEnum";
import PolicySeverity from "../interfaces/PolicySeverity";

import { translate } from "./translation";

export const policySeverities: PolicySeverity[] = [
  {
    backgroundColor: "bg-[#B4C2CD]",
    color: "text-[#525B62]",
    id: PolicySeverityEnum.info,
    key: "info",
    name: translate("common.info"),
  },
  {
    backgroundColor: "bg-[#FFF8F8]",
    color: "text-[#d06e6e]",
    id: PolicySeverityEnum.high,
    key: "high",
    name: translate("common.high"),
  },
  {
    backgroundColor: "bg-[#FFFCEC]",
    color: "text-[#FFBA08]",
    id: PolicySeverityEnum.medium,
    key: "medium",
    name: translate("common.medium"),
  },
  {
    backgroundColor: "bg-[#DDEBF5]",
    color: "text-[#3197E0]",
    id: PolicySeverityEnum.low,
    key: "low",
    name: translate("common.low"),
  },
];
