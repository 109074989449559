import { useContext } from "react";

import TooltipContext from "../contexts/TooltipContext";

const useTooltip = () => {
  const context = useContext(TooltipContext);
  if (!context) {
    throw new Error("useTooltip must be used within a TooltipProvider");
  }
  return context;
};

export default useTooltip;
