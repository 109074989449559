import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Item, Tabs } from "../components/Tabs";
import UserGroupsTab from "../components/user/UserGroupsTab";
import UsersTab from "../components/user/UsersTab";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";

export const Users: React.FC = () => {
  const { t } = useTranslation();
  const { closeRightPanel } = useContext(
    RightPanelContext,
  ) as RightPanelContextProps;
  return (
    <div className="flex h-full w-full flex-col items-start">
      <Tabs
        aria-label={t("common.users")}
        key={"tab"}
        onTabChange={closeRightPanel}
        checkDirtyOnChange
      >
        <Item key="users" title={t("common.users")} icon="UserIcon">
          <UsersTab />
        </Item>
        <Item
          lazy
          key="user-group"
          icon="GroupOfUsersIcon"
          title={t("common.groupsOfUsers")}
        >
          <UserGroupsTab />
        </Item>
      </Tabs>
    </div>
  );
};
