import clsx from "clsx";
import qs from "qs";
import React, { Key, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import useTooltip from "../../hooks/useTooltip";
import Computer from "../../interfaces/Computer";
import ComputerGroup from "../../interfaces/ComputerGroup";
import { ComputerGroupService } from "../../services/computerGroupService";
import { ComputerService } from "../../services/computerService";
import Button from "../Button";
import { Icon } from "../Icon";
import MultiSelectComboBox, {
  MultiselectComboboxOption,
} from "../MultiSelectComboBox";

interface ComputerMultiSelectProps {
  id: string;
  selectedComputers?: Computer[];
  selectedGroups?: ComputerGroup[];
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  hasList?: boolean;
  includeGroups?: boolean;
  includeComputers?: boolean;
  onComputerChange?: (e: Computer[]) => void;
  onComputerRemove?: (e: Computer) => void;
  onGroupChange?: (e: ComputerGroup[]) => void;
  onGroupRemove?: (e: ComputerGroup) => void;
  maxSelectableItems?: number;
  hasNegativeIds?: boolean;
  clearable?: boolean;
  isReadOnly?: boolean;
}

interface FilterState {
  search?: string;
  count: number;
  offset: number;
}

const computerService = new ComputerService();
const computerGroupService = new ComputerGroupService();

export const ComputerMultiSelect: React.FC<ComputerMultiSelectProps> = ({
  isReadOnly,
  id,
  selectedComputers,
  selectedGroups,
  label = "Computers",
  placeholder = "Computers or Groups",
  disabled,
  hasList = true,
  onGroupRemove,
  onComputerRemove,
  onComputerChange,
  onGroupChange,
  includeComputers = true,
  includeGroups = true,
  maxSelectableItems,
  hasNegativeIds = true,
  clearable = true,
}) => {
  const [isLoadingComputers, setIsLoadingComputers] = useState<boolean>(false);
  const [isLoadingGroups, setIsLoadingGroups] = useState<boolean>(false);
  const [computers, setComputers] = useState<Computer[]>([]);
  const [groups, setGroups] = useState<ComputerGroup[]>([]);
  const [totalGroups, setTotalGroups] = useState<number>(0);
  const [totalComputers, setTotalComputers] = useState<number>(0);
  const [groupFilters, setGroupFilters] = useState<FilterState>({
    count: 15,
    offset: 0,
    search: "",
  });
  const [computerFilters, setComputerFilters] = useState<FilterState>({
    count: 15,
    offset: 0,
    search: "",
  });
  const [disabledKeys, setDisabledKeys] = useState<Key[]>([]);
  const { showTooltip, hideTooltip } = useTooltip();
  const { t } = useTranslation();

  /**
   * Retrieves computers based on the provided filters.
   *
   * @param {FilterState} newFilters - The new filter state to be applied.
   * @param {boolean} reset - If set to true, the existing computers will be replaced with the new set of computers.
   * @returns {void}
   */
  const getComputers = useCallback(
    async (newFilters: FilterState, reset = false) => {
      setIsLoadingComputers(true);
      await computerService
        .getComputers(qs.stringify(newFilters))
        .then((res) => {
          let result = res.data;
          if (!hasNegativeIds) {
            result = result.filter((computer: Computer) => computer.id > 0);
          }

          const updatedComputers: Computer[] = result.map(
            (computer: Computer) => ({
              ...computer,
              id: `computer-${computer.id}`,
            }),
          );

          if (reset) {
            setComputers(updatedComputers);
          } else {
            setComputers((prevGroups) =>
              reset ? res.data : [...prevGroups, ...updatedComputers],
            );
          }
          setTotalComputers(res.total);
          if (reset) setComputerFilters((f) => ({ ...f, offset: 0 }));
        })
        .catch(() => {})
        .finally(() => setIsLoadingComputers(false));
    },
    [hasNegativeIds],
  );

  /**
   * Retrieves computer groups based on the provided filters.
   *
   * @param {FilterState} newFilters - The new filter state to be applied.
   * @param {boolean} reset - If set to true, the existing computer groups will be replaced with the new set of computer groups.
   * @returns {void}
   */
  const getComputerGroups = useCallback(
    async (newFilters: FilterState, reset = false) => {
      setIsLoadingGroups(true);
      await computerGroupService
        .getComputerGroups(qs.stringify(newFilters))
        .then((res) => {
          let result = res.data;
          if (!hasNegativeIds) {
            result = result.filter((group: ComputerGroup) => group.id > 0);
          }

          const updatedGroups: ComputerGroup[] = result.map(
            (group: ComputerGroup) => ({
              ...group,
              id: `group-${group.id}`,
            }),
          );

          if (reset) {
            setGroups(updatedGroups);
          } else {
            setGroups((prevGroups) =>
              reset ? res.data : [...prevGroups, ...updatedGroups],
            );
          }
          setTotalGroups(res.total);
          if (reset) setGroupFilters((f) => ({ ...f, offset: 0 }));
        })
        .catch(() => {})
        .finally(() => setIsLoadingGroups(false));
    },
    [hasNegativeIds],
  );

  /**
   * Extracts the original ID from a modified ID.
   *
   * @param {string} modifiedId - The modified ID to extract the original ID from.
   * @returns {number} The original ID.
   */
  const extractOriginalId = (modifiedId: string): number => {
    const idStripped = modifiedId
      .replace("computer-", "")
      .replace("group-", "");
    return parseInt(idStripped, 10);
  };

  /**
   * A memoized value that combines the items from the "groups" and "computers" arrays.
   *
   * @type {Array}
   * @public
   */
  const combinedItems: Array<MultiselectComboboxOption> = useMemo(() => {
    const elements: MultiselectComboboxOption[] = [];
    groups.forEach((group) => {
      elements.push({
        icon: String(group.id).includes("--")
          ? "AllComputersIcon"
          : "GroupOfComputersIcon",
        id: group.id,
        value: String(group.id).includes("--")
          ? t("common.anyComputer")
          : group.name || "N/D",
      });
    });

    computers.forEach((computer) => {
      elements.push({
        icon: String(computer.id).includes("--")
          ? "AllComputersIcon"
          : "ComputersMenuIcon",
        id: computer.id,
        value: `${computer.name}${computer.description ? ` (${computer.description})` : ""}`,
      });
    });
    return elements;
  }, [groups, computers]);

  /**
   * Checks if the selected group contains a negative ID
   * and performs certain actions based on the result.
   *
   * @name checkIfSelectedGroupContainsNegativeId
   * @function
   *
   * @param {CombinedObject[]} objects - An array of combined objects
   *
   * @returns {boolean} - Returns true if the selected group contains a negative ID,
   *                      otherwise returns false
   */
  const checkIfSelectedGroupContainsNegativeId = (
    objects: MultiselectComboboxOption[],
  ): boolean => {
    const elementNegativeID = objects?.find((element) =>
      String(element.id).includes("--"),
    );

    if (elementNegativeID) {
      if (String(elementNegativeID.id).includes("group-")) {
        // Find correct group
        let foundGroup = groups.find(
          (group) => group.id === elementNegativeID.id,
        );
        if (foundGroup) {
          foundGroup = {
            ...foundGroup,
            id: extractOriginalId(String(foundGroup.id)),
          };
          onGroupChange?.([foundGroup] as ComputerGroup[]);
          onComputerChange?.([] as Computer[]);
        }
      } else {
        // Find correct computer
        let foundComputer = computers.find(
          (computer) => computer.id === elementNegativeID.id,
        );
        if (foundComputer) {
          foundComputer = {
            ...foundComputer,
            id: extractOriginalId(String(foundComputer.id)),
          };
          onComputerChange?.([foundComputer] as Computer[]);
          onGroupChange?.([] as ComputerGroup[]);
        }
      }
      setDisabledKeys(
        combinedItems
          .filter((object) => object.id !== elementNegativeID.id)
          .map((object) => object.id),
      );
      return true;
    } else {
      setDisabledKeys([]);
      return false;
    }
  };

  /**
   * Handles the change in selection objects.
   *
   * @param {any[]} objects - The selection objects.
   */
  const handleSelectionObjectChange = (
    objects: MultiselectComboboxOption[],
  ) => {
    // Check if selected group contains an object with negative id
    if (checkIfSelectedGroupContainsNegativeId(objects)) return;

    let availableComputers = objects
      .map((selectedObject) => {
        return computers.find((item) => item.id === selectedObject.id);
      })
      .filter((item): item is Computer => item !== undefined);

    availableComputers = availableComputers.map((computer) => ({
      ...computer,
      id: extractOriginalId(String(computer.id)),
    })) as Computer[];

    let availableGroups = objects
      .map((selectedObject) => {
        return groups.find((item) => item.id === selectedObject.id);
      })
      .filter((item): item is ComputerGroup => item !== undefined);

    availableGroups = availableGroups.map((group) => ({
      ...group,
      id: extractOriginalId(String(group.id)),
    })) as Computer[];

    onComputerChange?.(availableComputers);
    if (onGroupChange) {
      onGroupChange(availableGroups);
    }
  };

  /**
   * @desc Fetches computers from the API
   */
  useEffect(() => {
    if (includeComputers) {
      getComputers(computerFilters, true).then(() => {});
    }
    if (includeGroups) {
      getComputerGroups(groupFilters, true).then(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeGroups]);

  /**
   * Represents a combined array of selected objects.
   *
   * @typedef {Array} CombinedObject
   * @property {number} id - The unique identifier of the object.
   * @property {string} internalId - The internal identifier of the object.
   * @property {...} [additionalProperties] - Any additional properties specific to the object.
   */
  const combinedSelectedObjects = useMemo(() => {
    const updatedGroups = selectedGroups
      ? selectedGroups.map((group) => ({
          ...group,
          id: `group-${group.id}`,
        }))
      : [];

    const updatedComputers =
      selectedComputers?.map((computer) => ({
        ...computer,
        id: `computer-${computer.id}`,
      })) || [];

    return [...updatedGroups, ...updatedComputers];
  }, [selectedGroups, selectedComputers]);

  /**
   * Determines if any of the selected objects have a negative ID.
   *
   * @function
   * @name hasNegativeIdSelected
   * @returns {boolean} - true if any selected object has a negative ID, false otherwise.
   *
   * @example
   * const combinedSelectedObjects = [{ id: -1 }, { id: 2 }, { id: -3 }];
   * const hasNegativeIdSelected = useMemo(() => {
   *   return combinedSelectedObjects?.some((object) =>
   *     String(object.id).includes("--")
   *   );
   * }, [combinedSelectedObjects]);
   */
  const hasNegativeIdSelected = useMemo(() => {
    return combinedSelectedObjects?.some((object) =>
      String(object.id).includes("--"),
    );
  }, [combinedSelectedObjects]);

  useEffect(() => {
    const elementNegativeID = combinedSelectedObjects?.find((element) =>
      String(element.id).includes("--"),
    );
    if (elementNegativeID) {
      setDisabledKeys(
        combinedItems
          .filter(
            (object) => String(object.id) !== String(elementNegativeID.id),
          )
          .map((object) => object.id),
      );
    } else {
      setDisabledKeys([]);
    }
  }, [combinedSelectedObjects, combinedItems]);

  /**
   * Represents the parsed selected objects.
   *
   * @type {Array<MultiselectComboboxOption>}
   */
  const parsedSelectedObjects: Array<MultiselectComboboxOption> =
    useMemo(() => {
      return combinedSelectedObjects.map((item) => {
        return {
          id: item.id,
          value: item?.name || "N/D",
        };
      });
    }, [combinedSelectedObjects]);

  return (
    <div className="flex flex-col gap-4">
      <MultiSelectComboBox
        isReadOnly={isReadOnly}
        maxSelectableItems={maxSelectableItems}
        id={id}
        disabled={disabled}
        selectedObjects={parsedSelectedObjects}
        totalItems={totalGroups + totalComputers}
        onSelectionObjectChange={(e) => {
          handleSelectionObjectChange(e);
        }}
        clearable={clearable}
        aria-label={label}
        placeholder={placeholder}
        isLoading={isLoadingComputers || isLoadingGroups}
        items={combinedItems}
        disabledKeys={disabledKeys}
        onSearch={(searchTerm) => {
          const newFilters = { count: 15, offset: 0, search: searchTerm };
          setGroupFilters(newFilters);
          setComputerFilters(newFilters);
          if (includeGroups) {
            getComputerGroups(newFilters, true).then(() => {});
          }
          if (includeComputers) {
            getComputers(newFilters, true).then(() => {});
          }
        }}
        onLoadMore={() => {
          if (hasNegativeIdSelected) return;

          if (
            !isLoadingGroups &&
            groups.length < totalGroups &&
            includeGroups
          ) {
            const newFilters = {
              ...groupFilters,
              offset: groupFilters.offset + groupFilters.count,
            };
            setGroupFilters(newFilters);
            getComputerGroups(newFilters, false).then(() => {});
          }

          if (
            !isLoadingComputers &&
            computers.length < totalComputers &&
            includeComputers
          ) {
            const newFilters = {
              ...computerFilters,
              offset: computerFilters.offset + computerFilters.count,
            };
            setComputerFilters(newFilters);
            getComputers(newFilters, false).then(() => {});
          }
        }}
      />
      {hasList && (
        <ul
          className={clsx(
            "grid grid-cols-1 gap-2 text-extra-dark-gray transition-all duration-200 ease-in-out",
            disabled && "pointer-events-none opacity-50",
          )}
        >
          {combinedSelectedObjects?.map((object) => (
            <li
              key={object.id}
              className="group flex w-full flex-row items-center justify-between gap-2"
            >
              <Icon
                name={
                  object.id.includes("group-")
                    ? "GroupOfComputersIcon"
                    : "ComputersMenuIcon"
                }
                className="h-5 w-5 text-pale-blue"
              />

              <span
                className="w-full truncate text-left"
                onMouseEnter={(e) =>
                  showTooltip(
                    `${object.name}${
                      object.description ? ` (${object.description})` : ""
                    }`,
                    e,
                  )
                }
                onMouseLeave={hideTooltip}
              >
                {object.name}{" "}
                {object.description ? ` (${object.description})` : ""}
              </span>
              <div className="z-0 opacity-0 transition-all duration-200 group-hover:opacity-100">
                <Button
                  variant="text"
                  onPress={() => {
                    const originalId = extractOriginalId(object.id);
                    if (object.id.includes("group-")) {
                      if (onGroupRemove) {
                        onGroupRemove({
                          ...object,
                          id: originalId,
                        } as ComputerGroup);
                      }
                    } else {
                      if (onComputerRemove) {
                        onComputerRemove({
                          ...object,
                          id: originalId,
                        } as Computer);
                      }
                    }
                  }}
                >
                  <Icon name="RemoveIcon" className="h-5 w-5 text-red" />
                </Button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
