import React from "react";

import { iconComponents } from "./IconImports";

const variantColors = {
  active: "text-dark-blue-disabled",
  default: "text-medium-gray",
  disabled: "text-light-gray",
};

interface IconProps {
  name: keyof typeof iconComponents;
  variant?: keyof typeof variantColors;
  color?: string;
  className?: string;
}

export const Icon: React.FC<IconProps> = ({
  name,
  variant = "default",
  color,
  className,
}) => {
  // Select the icon component based on the name prop
  const SelectedIcon = iconComponents[name];

  if (!SelectedIcon) {
    console.warn(
      `Icon with name "${name}" does not exist. Please check the name.`,
    );

    return "N/D";
  }

  // If the color prop is provided, use that, otherwise use the variant color
  const iconColor = color || variantColors[variant];

  return (
    <span style={{ color: iconColor }}>
      <SelectedIcon className={className} />
    </span>
  );
};
