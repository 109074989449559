import clsx from "clsx";
import React, { ReactNode } from "react";

interface DashboardItemProps {
  title?: string;
  subtitle?: ReactNode;
  header?: ReactNode;
  containerClassName?: string;
  headerClassName?: string;
  children?: ReactNode;
  noPadding?: boolean;
}

const DashboardItem: React.FC<DashboardItemProps> = ({
  title,
  subtitle,
  header,
  containerClassName,
  headerClassName,
  children,
  noPadding = false,
}: DashboardItemProps) => {
  return (
    <div
      className={clsx(
        [containerClassName],
        "flex h-full border-collapse flex-col rounded-lg border border-medium-gray bg-white",
      )}
    >
      {header ? (
        header
      ) : (
        <div
          className={clsx(
            [headerClassName],
            "flex flex-row items-center justify-between rounded-t-lg border-b border-medium-gray bg-extra-light-gray px-5 py-4",
          )}
        >
          <p className="font-semibold text-steel-blue">{title}</p>
          <div>{subtitle}</div>
        </div>
      )}

      <div
        className={clsx("h-full w-full overflow-auto", !noPadding && "p-1.5")}
      >
        {children}
      </div>
    </div>
  );
};

export default DashboardItem;
