import clsx from "clsx";
import React from "react";
import { Cell, Column, Row, TableBody, TableHeader } from "react-stately";

import ComputerIllustration from "../../assets/images/computer-illustration.svg";
import useGuestComputersTab from "../../hooks/useGuestComputersTab";
import Computer from "../../interfaces/Computer";
import { getComputerStatusDetail } from "../../utils/utils";
import { Icon } from "../Icon";
import { Table } from "../table/Table";
import { TableActionsBar } from "../TableActionsBar";

function ComputersTab() {
  const {
    computers,
    isLoading,
    setFilters,
    filters,
    t,
    onRowAction,
    activeComputer,
    showTooltip,
    hideTooltip,
    getOperatingSystemIcon,
  } = useGuestComputersTab();

  return (
    <div className="flex h-full w-full flex-col overflow-x-hidden transition-all duration-200 ease-in-out">
      <TableActionsBar
        onSearchChange={(value) => {
          setFilters({ ...filters, search: value });
        }}
      />
      {computers.length === 0 && !isLoading && !filters.search ? (
        <div className="flex flex-col gap-24 p-8">
          <span className="text-medium-gray">{t("common.noComputers")}</span>
          <div className="mx-auto flex h-full w-full max-w-xl flex-col items-center justify-center gap-8 text-center">
            <ComputerIllustration className="h-auto w-full" />
            <span className="leading-8 text-extra-dark-gray">
              {t("common.noGuestComputersMessage")}
            </span>
            <div className="flex flex-row items-center gap-2 rounded-full bg-medium-light-blue px-4 py-3 text-dark-blue md:px-16">
              <Icon name="ErrorIcon" className="h-6 w-6" />
              <span>{t("common.weArePreparingYourComputer")}...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-1 flex-col gap-4">
          <div className="flex-1">
            <Table
              activeRowKey={activeComputer}
              aria-labelledby={t("common.users")}
              isLoading={isLoading}
              aria-label={t("common.users")}
              onSortChange={(e) => setFilters({ ...filters, sort: e })}
              onRowAction={onRowAction}
            >
              <TableHeader>
                <Column allowsResizing allowsSorting key="name">
                  {t("common.hostname")}
                </Column>
                <Column allowsResizing allowsSorting key="operatingSystem">
                  {t("common.operatingSystem")}
                </Column>
                <Column allowsResizing allowsSorting key="ipAddress">
                  {t("common.ipAddress")}
                </Column>
                <Column
                  defaultWidth={20}
                  width={20}
                  maxWidth={20}
                  allowsSorting
                  key="status"
                >
                  {t("common.status")}
                </Column>
              </TableHeader>
              <TableBody items={computers}>
                {(item: Computer) => (
                  <Row>
                    {(columnKey) => {
                      switch (columnKey) {
                        case "operatingSystem":
                          return (
                            <Cell>
                              {item.operatingSystem ? (
                                <div className="flex flex-row items-center gap-2">
                                  <Icon
                                    name={getOperatingSystemIcon(
                                      item.operatingSystem,
                                    )}
                                    className="h-6 w-6"
                                  />
                                  <span>{item.operatingSystem || "N/D"}</span>
                                </div>
                              ) : (
                                "N/D"
                              )}
                            </Cell>
                          );

                        case "status":
                          return (
                            <Cell>
                              {item.status !== undefined ? (
                                <div className="flex h-full w-full items-center justify-start">
                                  <div
                                    onMouseEnter={(e) =>
                                      showTooltip(
                                        getComputerStatusDetail(
                                          item.status || 0,
                                        ).description,
                                        e,
                                        true,
                                      )
                                    }
                                    onMouseLeave={hideTooltip}
                                    className={clsx(
                                      "my-auto h-3 w-3 rounded-full",
                                      getComputerStatusDetail(item.status)
                                        .background,
                                    )}
                                  />
                                </div>
                              ) : (
                                "N/D"
                              )}
                            </Cell>
                          );
                        default:
                          return (
                            <Cell>
                              {(item as any)[String(columnKey)] || "N/D"}
                            </Cell>
                          );
                      }
                    }}
                  </Row>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}

export default ComputersTab;
