import type { GridNode } from "@react-types/grid";
import clsx from "clsx";
import React, { Key, useRef } from "react";
import {
  mergeProps,
  useFocusRing,
  useHover,
  useTableColumnHeader,
} from "react-aria";
import type { TableColumnResizeState, TableState } from "react-stately";

import { Icon } from "../Icon";

import { Resizer } from "./Resizer";

interface TableColumnHeaderProps<T> {
  column: GridNode<T>;
  state: TableState<T>;
  layoutState: TableColumnResizeState<T>;
  style?: React.CSSProperties;
  onResizeStart?: (widths: Map<Key, number | string>) => void;
  onResize?: (widths: Map<Key, number | string>) => void;
  onResizeEnd?: (widths: Map<Key, number | string>) => void;
}

export default function TableColumnHeader<T>(props: TableColumnHeaderProps<T>) {
  const {
    column,
    state,
    layoutState,
    style,
    onResizeStart,
    onResize,
    onResizeEnd,
  } = props;
  const ref = useRef(null);
  const resizerRef = useRef(null);
  const triggerRef = useRef(null);
  const { columnHeaderProps } = useTableColumnHeader(
    { node: column },
    state,
    ref,
  );
  const { isFocusVisible, focusProps } = useFocusRing();
  const { hoverProps, isHovered } = useHover({});
  const showResizer = isHovered || layoutState.resizingColumn === column.key;
  const allowsSorting = column.props?.allowsSorting;
  const allowsResizing = column.props?.allowsResizing;

  const sortIcons = (
    <div
      className={clsx(
        "flex flex-col items-center justify-center -space-y-2 text-dark-blue",
        isHovered || column.key === state.sortDescriptor?.column
          ? "opacity-100"
          : "opacity-0",
      )}
    >
      <button
        className={clsx(
          "-rotate-90",
          state.sortDescriptor?.direction === "ascending" &&
            column.key === state.sortDescriptor?.column
            ? "opacity-100"
            : "opacity-50",
          state.sortDescriptor?.direction === "ascending" &&
            "pointer-events-none",
        )}
        disabled={!allowsSorting}
      >
        <Icon className="h-5 w-5" name="ArrowRightIcon" />
      </button>
      <button
        className={clsx(
          "rotate-90",
          state.sortDescriptor?.direction === "descending" &&
            column.key === state.sortDescriptor?.column
            ? "opacity-100"
            : "opacity-50",
          state.sortDescriptor?.direction === "descending" &&
            "pointer-events-none",
        )}
        disabled={!allowsSorting}
      >
        <Icon className="h-5 w-5" name="ArrowRightIcon" />
      </button>
    </div>
  );

  // Only render a menu trigger as the table column header if resizing is allowed
  // otherwise clicking on the column header should sort if available
  const contents = allowsResizing ? (
    <>
      <div className="flex-[1_1_auto] truncate">
        <div className="flex min-h-[28px] flex-row items-center gap-1">
          <p className="my-auto truncate text-left text-lg font-normal text-dark-blue">
            {column.rendered}
          </p>
          {column.props.allowsSorting && sortIcons}
        </div>
      </div>
      <Resizer
        showResizer={showResizer}
        ref={resizerRef}
        triggerRef={triggerRef}
        column={column}
        layoutState={layoutState}
        onResizeStart={onResizeStart}
        onResize={onResize}
        onResizeEnd={onResizeEnd}
      />
    </>
  ) : (
    <div className="flex-[1_1_auto]">
      <div className="flex min-h-[28px] flex-row items-center gap-1">
        <p className="my-auto truncate text-left text-base font-normal text-dark-blue">
          {column.rendered}
        </p>
        {column.props.allowsSorting && sortIcons}
      </div>
    </div>
  );

  const textAlign =
    column?.colspan && column.colspan > 1 ? "text-center" : "text-left";
  const boxShadow = isFocusVisible
    ? "shadow-[inset_0_0_0_2px_orange]"
    : "shadow-none";

  return (
    <th
      {...mergeProps(columnHeaderProps, focusProps, hoverProps)}
      className={clsx(
        "box-border cursor-default px-4 py-4 outline-none",
        boxShadow,
        textAlign,
        column.props.width === undefined && "flex-[1_1_auto]",
      )}
      style={{
        ...style,
        width: `${
          column.props.width
            ? column.props.width
            : layoutState.getColumnWidth(column.key)
        }px`,
      }}
      ref={ref}
    >
      <div className="relative flex">{contents}</div>
    </th>
  );
}
