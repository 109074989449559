import React from "react";

import DashboardCountersItem from "../components/dashboard/DashboardCountersItem";
import DashboardRecentAlertsItem from "../components/dashboard/DashboardRecentAlertsItem";
import DashboardReportItem from "../components/dashboard/DashboardReportItem";
import DashboardTotalsItem from "../components/dashboard/DashboardTotalsItem";
import useDashboard from "../hooks/useDashboard";

export const Dashboard: React.FC = () => {
  const {
    dateFormatter,
    isLoading,
    dashboardData,
    userActivities,
    setFilters,
    filters,
  } = useDashboard();

  return (
    <div className="flex flex-col gap-4 xl:flex-row">
      <div className="grid h-full w-full grid-cols-1 gap-6 xl:max-w-[650px]">
        <DashboardCountersItem
          data={dashboardData.summary}
          filters={filters}
          onFilterChange={(e) => {
            setFilters({ ...filters, ...e });
          }}
        />

        <DashboardRecentAlertsItem
          isLoading={isLoading}
          items={userActivities}
          dateFormatter={dateFormatter}
        />
        <DashboardTotalsItem
          isLoading={isLoading}
          data={dashboardData.computers}
        />
      </div>
      <div className="w-full flex-1">
        <DashboardReportItem
          filters={filters}
          data={dashboardData.policies}
          dateFormatter={dateFormatter}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
