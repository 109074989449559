import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";

import useTooltip from "../hooks/useTooltip";

import Button from "./Button";
import { Icon } from "./Icon";
import { iconComponents } from "./IconImports";
import SearchField from "./SearchField";

export interface TableAction {
  icon: keyof typeof iconComponents;
  label: string;
  isLoading?: boolean;
  onClick?: () => void;
}

const TableTableActionButton: React.FC<TableAction> = ({
  icon,
  label,
  onClick,
  isLoading = false,
}) => {
  const { showTooltip, hideTooltip } = useTooltip();

  return (
    <button
      name={label}
      aria-label={label}
      aria-labelledby={label}
      className={clsx(
        "flex flex-row items-center gap-2 border-gray px-4 text-steel-blue transition-all duration-200 ease-in-out hover:opacity-90 [&:not(:last-child)]:border-r",
        isLoading && "pointer-events-none animate-pulse",
      )}
      onClick={onClick}
    >
      <div
        onMouseEnter={(e) => showTooltip(label, e, true)}
        onMouseLeave={hideTooltip}
      >
        <Icon name={icon} className="h-5 w-5 lg:h-4 lg:w-4" />
      </div>
      <span className="hidden text-base lg:block">{label}</span>
    </button>
  );
};

interface TableActionsBarProps {
  actions?: TableAction[];
  onSearchChange?: (value: string) => void;
  customFilters?: React.ReactNode[];
  hasMoreFilters?: boolean;
  additionalFilters?: React.ReactNode;
  leftContent?: React.ReactNode;
  filtersCount?: number;
  onAdvancedFilterOpenChange?: (isOpen: boolean) => void;
  isAdvancedSearchInitialOpen?: boolean;
  isLoading?: boolean;
  searchValue?: string;
}

export const TableActionsBar: React.FC<TableActionsBarProps> = ({
  actions,
  onSearchChange,
  customFilters,
  hasMoreFilters = false,
  additionalFilters,
  filtersCount,
  onAdvancedFilterOpenChange,
  isAdvancedSearchInitialOpen,
  isLoading,
  leftContent,
  searchValue,
}) => {
  const { t } = useTranslation();
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] =
    React.useState<boolean>(isAdvancedSearchInitialOpen || false);

  return (
    <div className="flex w-full flex-col gap-0 transition-all duration-200 ease-in-out">
      <div className="flex w-full flex-row items-center justify-between bg-light-gray py-2 pr-4">
        <div className="flex flex-row items-center text-steel-blue">
          {leftContent}
          {actions?.map((action, index) => (
            <TableTableActionButton
              isLoading={isLoading}
              key={index}
              {...action}
            />
          ))}
        </div>
        <div className="relative flex flex-row items-center justify-center gap-4">
          {customFilters?.map((filter, index) => (
            <div key={index}>{filter}</div>
          ))}

          <div className="flex flex-row items-center gap-2">
            <SearchField
              aria-label={t("common.search")}
              aria-labelledby={t("common.search")}
              onDebounce={onSearchChange}
              placeholder={t("common.search")}
              defaultValue={searchValue}
            />
            {hasMoreFilters && (
              <>
                <CSSTransition
                  in={filtersCount ? filtersCount > 0 : false}
                  unmountOnExit
                  timeout={{ enter: 0, exit: 250 }}
                  classNames={{
                    enter: "opacity-0 translate-y-1",
                    enterDone: "opacity-1 translate-y-0 transition ease-in",
                    exit: "opacity-0 translate-y-1 transition ease-out",
                  }}
                >
                  <div className="absolute -right-2 -top-1 z-10 flex h-5 w-5 rounded-full border border-dark-gray bg-light-gray">
                    <span className="m-auto text-xs text-extra-dark-gray">
                      {filtersCount}
                    </span>
                  </div>
                </CSSTransition>
                <Button
                  className={clsx(
                    "h-full px-2 transition-all duration-200 ease-in-out",
                    isAdvancedSearchOpen && "bg-dark-red",
                  )}
                  onPress={() => {
                    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
                    onAdvancedFilterOpenChange?.(!isAdvancedSearchOpen);
                  }}
                >
                  <Icon
                    name={isAdvancedSearchOpen ? "CloseIcon" : "FunnelIcon"}
                    className="h-5 w-5"
                  />
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className={clsx(
          "border-t border-gray bg-light-gray transition-all duration-200 ease-in-out",
          !isAdvancedSearchOpen && "hidden",
        )}
      >
        {additionalFilters}
      </div>
    </div>
  );
};
