import { APIService } from "../api/apiService";

export class AuthService extends APIService {
  /**
   * @description Login method
   * @param payload
   */
  login(payload: { username: string; password: string }) {
    return this.request("/Admin/Auth", "POST", payload);
  }

  /**
   * @description Forgot password method
   * @param payload
   */
  forgotPassword(payload: { username: string }) {
    return this.request("/Admin/Auth/ForgotPassword", "POST", payload);
  }

  /**
   * @description Reset password method
   * @param payload
   * @param token
   */
  resetPassword(payload: { newPassword: string }, token: string) {
    return this.request("/Admin/Auth/ResetPassword", "POST", payload, {
      Authorization: "Bearer " + token,
    });
  }

  /**
   * @description Reset password method
   * @param payload
   * @param token
   */
  register(payload: { newPassword: string }, token: string) {
    return this.request("/Admin/Auth/Register", "POST", payload, {
      Authorization: "Bearer " + token,
    });
  }

  /**
   * @description Get agent configuration file
   */
  agentConfiguration() {
    return this.request("/Admin/Auth/AgentConfiguration", "GET");
  }

  /**
   * @description Login method
   * @param payload
   */
  agentOTP(payload: { id: number }) {
    return this.request("/Admin/Auth/AgentOTP", "POST", payload);
  }

  /**
   * Checks the validity of a token.
   *
   * @param {string} token - The token to be checked.
   * @returns {Promise} - A promise that resolves to the response of the token check request.
   */
  checkToken(token: string) {
    return this.request("/Admin/Auth/CheckToken", "GET", "", {
      Authorization: "Bearer " + token,
    });
  }

  /**
   * Checks the validity of a token.
   *
   * @param {string} token - The token to be checked.
   * @returns {Promise} - A promise that resolves to the response of the token check request.
   */
  deleteAuth(token: string) {
    return this.request("/Admin/Auth", "DELETE", "", {
      Authorization: "Bearer " + token,
    });
  }
}
