import clsx from "clsx";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import { useNavigationDirty } from "../contexts/NavigationDirtyContext";

interface DirtyLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
  className?: string;
}

const DirtyLink: React.FC<DirtyLinkProps> = ({
  to,
  children,
  className,
  ...rest
}) => {
  const { requestDirtyNavigation, isDirty } = useNavigationDirty();

  /**
   * Callback function to handle a click event on an anchor element.
   *
   * @param {React.MouseEvent<HTMLAnchorElement, MouseEvent>} event - The event object representing the click event.
   */
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      requestDirtyNavigation(to);
    },
    [to, requestDirtyNavigation],
  );

  return (
    <>
      {!isDirty ? (
        <Link to={to} className={clsx(className)} {...rest}>
          {children}
        </Link>
      ) : (
        <a
          href={to}
          className={clsx(className)}
          onClick={handleClick}
          {...rest}
        >
          {children}
        </a>
      )}
    </>
  );
};

export default DirtyLink;
