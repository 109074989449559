import clsx from "clsx";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import { Icon } from "../components/Icon";
import SegmentedInput from "../components/SegmentedInput";
import TextField from "../components/TextField";
import { useAuth } from "../contexts/AuthContext";
import { UserAdminEnum } from "../interfaces/User";
import { AuthService } from "../services/authService";
import { clearStorage } from "../utils/storage";

interface FormState {
  username: string;
  password: string;
  rememberMe: boolean;
  otp?: string;
}

interface CredentialStepProps {
  form: FormState;
  setForm: React.Dispatch<React.SetStateAction<FormState>>;
  error?: string;
  isLoading?: boolean;
}

interface AuthenticatorStepProps {
  form: FormState;
  setForm: React.Dispatch<React.SetStateAction<FormState>>;
  error?: string;
  isLoading?: boolean;
  onBack: () => void;
}

const CredentialStep: React.FC<CredentialStepProps> = ({
  form,
  setForm,
  error,
  isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col gap-4">
      <TextField
        id="username"
        invalid={!!error}
        aria-disabled={isLoading}
        aria-label={t("common.username")}
        isDisabled={isLoading}
        name="username"
        type="text"
        placeholder={t("common.username")}
        value={form.username}
        isRequired
        required
        variant="secondary"
        onChange={(e) => setForm({ ...form, username: e })}
      />
      <TextField
        id="password"
        invalid={!!error}
        aria-disabled={isLoading}
        isDisabled={isLoading}
        aria-label={t("common.password")}
        name="password"
        type="password"
        autoComplete="password"
        placeholder={t("common.password")}
        isRequired
        required
        variant="secondary"
        value={form.password}
        onChange={(e) => setForm({ ...form, password: e })}
      />
      <div className="mt-4 flex flex-row items-center justify-between">
        <Checkbox
          isDisabled={isLoading}
          size="large"
          onChange={(e) => setForm({ ...form, rememberMe: e })}
        >
          <span>{t("auth.rememberMe")}</span>
        </Checkbox>
        <Link
          aria-disabled={isLoading}
          to="/forgot-password"
          className={clsx(
            "text-lg text-dark-blue underline",
            isLoading && "pointer-events-none opacity-50",
          )}
        >
          {t("auth.forgotPassword")}
        </Link>
      </div>
      <div className="mt-4 flex h-[24px] flex-row items-center justify-start text-red">
        <Icon
          name="ErrorIcon"
          className={clsx("h-5 w-5", error ? "visible" : "invisible")}
        />
        <span className="ml-2 text-red">{error ? error : ""}</span>
      </div>
      <div className="mt-4 flex w-full flex-row">
        <Button size="large" type="submit" isLoading={isLoading}>
          {t("common.next")}
        </Button>
      </div>
      <span className="w-full text-center text-xs text-extra-dark-gray">
        {t("auth.agreements")}{" "}
        <a
          target="_blank"
          href="https://www.cybergrant.net/terms-of-service"
          rel="noreferrer"
        >
          {t("auth.termsAndConditions")}
        </a>
      </span>
    </div>
  );
};

const AuthenticatorStep: React.FC<AuthenticatorStepProps> = ({
  form,
  setForm,
  error,
  isLoading,
  onBack,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-col gap-4">
      <SegmentedInput
        onCompleted={(value) => setForm({ ...form, otp: value })}
      />
      <span className="text-center text-lg text-dark-gray">
        {t("auth.enterOTPCode")}
      </span>
      <div className="mt-20 flex h-[24px] flex-row items-center justify-start text-red">
        <Icon
          name="ErrorIcon"
          className={clsx("h-5 w-5", error ? "visible" : "invisible")}
        />
        <span className="ml-2 text-red">{error ? error : ""}</span>
      </div>
      <div className="mt-4 flex w-full flex-row">
        <Button
          size="large"
          onPress={onBack}
          variant="text"
          type="button"
          isDisabled={isLoading}
        >
          {t("common.back")}
        </Button>
        <Button
          size="large"
          type="submit"
          isDisabled={!form.otp}
          isLoading={isLoading}
        >
          {t("common.next")}
        </Button>
      </div>
    </div>
  );
};

export const Login: React.FC = () => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<FormState>({
    password: "",
    rememberMe: false,
    username: "",
  });
  const [apiError, setApiError] = useState("");
  const authService = new AuthService();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation();
  const { login } = useAuth();
  /**
   * Handle form submission
   * @param e
   */
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (form.password === "" || form.username === "") {
      return;
    }

    setIsLoading(true);
    authService
      .login(form)
      .then((res) => {
        if (
          res.currentUser.isAdmin === UserAdminEnum.user &&
          !window.location.href.includes(
            import.meta.env.VITE_APP_GUEST_PUBLIC_DOMAIN as string,
          )
        ) {
          clearStorage();
          window.location.href =
            (import.meta.env.VITE_APP_GUEST_PUBLIC_DOMAIN as string) + "/login";
          return;
        }

        clearStorage();
        login(
          form.rememberMe ? "localStorage" : "sessionStorage",
          res.currentUser,
          res.token,
          res.tokenSR,
          res.currentComputer,
          res.currentRole,
          res.currentLicense,
        );

        if (
          window.location.href.includes(
            import.meta.env.VITE_APP_GUEST_PUBLIC_DOMAIN as string,
          )
        ) {
          navigate("/guest-computers");
        } else {
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        if (error?.response?.errorCode === 12) {
          setForm({ ...form, otp: "" });
          setStep(2);
        }
        if (step === 1) {
          switch (error?.response?.errorCode) {
            case 5:
              setApiError(t("errors.invalidCredentials"));
              break;
            case 10:
              setApiError(t("errors.accountSuspended"));
              break;
            case 11:
              setApiError(t("errors.accountExpired"));
              break;
            case 18:
              setApiError(t("errors.invalidCredentials"));
              break;
            default:
              setApiError(t("errors.licenseExpired"));
              break;
          }
        } else {
          setApiError(t("errors.authenticatorCodeInvalid"));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * @desc Reset error message when step changes
   */
  useEffect(() => {
    setApiError("");
  }, [step]);

  return (
    <form
      ref={formRef}
      onSubmit={onSubmit}
      className="mt-20 flex h-full w-full flex-col items-start justify-start"
    >
      {step === 1 && (
        <CredentialStep
          error={apiError}
          form={form}
          setForm={setForm}
          isLoading={isLoading}
        />
      )}

      {step === 2 && (
        <AuthenticatorStep
          error={apiError}
          form={form}
          setForm={setForm}
          isLoading={isLoading}
          onBack={() => setStep(1)}
        />
      )}
    </form>
  );
};
