import clsx from "clsx";
import React, { forwardRef, useEffect, useRef } from "react";
import type { AriaNumberFieldProps } from "react-aria";
import { useLocale, useNumberField } from "react-aria";
import { useNumberFieldState } from "react-stately";

import { Icon } from "./Icon";
import { iconComponents } from "./IconImports";

interface NumberFieldProps extends AriaNumberFieldProps {
  required?: boolean;
  title?: string;
  icon?: keyof typeof iconComponents;
}

/**
 * Combines an external ref and an internal ref to synchronize their values.
 *
 * @template T - The type of the ref value.
 * @param {React.Ref<T>} externalRef - The external ref.
 * @param {React.RefObject<T>} internalRef - The internal ref.
 * @returns {React.RefObject<T>} - The internal ref, after synchronization with the external ref.
 */
function useCombinedRefs<T>(
  externalRef: React.Ref<T>,
  internalRef: React.RefObject<T>,
): React.RefObject<T> {
  useEffect(() => {
    if (!externalRef) return;

    if (typeof externalRef === "function") {
      externalRef(internalRef.current);
    } else if (
      externalRef &&
      "current" in externalRef &&
      internalRef.current !== null
    ) {
      const mutableRef = externalRef as React.MutableRefObject<T>;
      mutableRef.current = internalRef.current;
    }
  }, [externalRef, internalRef]);

  return internalRef;
}

const NumberField = forwardRef<HTMLInputElement, NumberFieldProps>(
  (props, ref) => {
    const { placeholder, icon, title, label, description, errorMessage } =
      props;
    const { locale } = useLocale();
    const state = useNumberFieldState({ ...props, locale });
    const internalRef = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs(ref, internalRef);
    const { inputProps, labelProps, descriptionProps, errorMessageProps } =
      useNumberField(props, state, combinedRef);

    /**
     * Sets focus on the specified HTML label element.
     *
     * @param {React.MouseEvent<HTMLLabelElement>} event - The mouse event triggered by clicking the label.
     */
    const focusInput = (event: React.MouseEvent<HTMLLabelElement>) => {
      event.preventDefault();
      combinedRef.current?.focus();
    };

    return (
      <div
        className={clsx(
          "relative w-full flex-col gap-1",
          props.isDisabled && "pointer-events-none opacity-50",
        )}
      >
        <div
          className={clsx(
            "flex h-[40px] w-full flex-row items-center justify-between gap-2 rounded-[6px] border border-gray",
            props.isReadOnly && "bg-gray",
          )}
        >
          {icon && (
            <Icon
              name={icon}
              className="h-5 w-5 shrink-0 transition-all duration-200 ease-in-out"
            />
          )}
          <input
            title={title}
            className="
          peer h-[40px] w-full rounded-[6px] bg-transparent
        px-3 pt-3 font-sans text-sm font-normal text-extra-dark-gray outline outline-0 transition-all duration-100
        ease-in-out placeholder-shown:border
         focus:outline-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
            required={props.required}
            {...inputProps}
            placeholder={placeholder}
            ref={combinedRef}
          />
          {label && (
            <label
              {...labelProps}
              htmlFor={props.id}
              onClick={focusInput}
              className="peer-focus:text-blue-600 peer-focus:dark:text-blue-500 absolute start-3
          top-3 z-10 origin-[0] -translate-y-2.5 scale-75 transform text-sm text-dark-gray duration-300
          peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-2.5
          peer-focus:scale-75 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4"
            >
              {label}
            </label>
          )}
          <div className="grid h-full shrink-0 grid-cols-1 divide-y divide-gray border-l border-gray">
            <button
              type="button"
              disabled={props.isDisabled || props.isReadOnly}
              className="flex items-center justify-center rounded-tr-md px-1 transition-all duration-200 ease-in-out hover:bg-light-gray active:bg-gray"
              onClick={() => state.increment()}
            >
              <Icon
                name="ArrowDropDownIcon"
                className="m-auto h-3 w-3 rotate-180"
              />
            </button>
            <button
              type="button"
              disabled={props.isDisabled || props.isReadOnly}
              className="flex items-center justify-center rounded-br-md transition-all duration-200 ease-in-out hover:bg-light-gray active:bg-gray"
              onClick={() => state.decrement()}
            >
              <Icon name="ArrowDropDownIcon" className="h-3 w-3" />
            </button>
          </div>
        </div>
        {description && (
          <p
            className="pl-1 text-xs text-medium-gray"
            {...(descriptionProps as Record<string, unknown>)}
          >
            {description}
          </p>
        )}

        {errorMessage && (
          <p
            {...(errorMessageProps as Record<string, unknown>)}
            className="text-bright-red text-xs"
          >
            {JSON.stringify(errorMessage)}
          </p>
        )}
      </div>
    );
  },
);

NumberField.displayName = "NumberField";

export default NumberField;
