import clsx from "clsx";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import Button from "../components/Button";
import { Icon } from "../components/Icon";
import TextField from "../components/TextField";
import { NotificationTypes } from "../contexts/NotificationContext";
import { useNotifications } from "../hooks/useNotifications";
import { AuthService } from "../services/authService";

import { calculatePasswordStrength } from "./ResetPassword";

const authService = new AuthService();

interface FormState {
  password: string;
  passwordConfirmation: string;
}

interface ErrorObject {
  key: string;
  valid: boolean;
  label: string;
}

const token = new URLSearchParams(window.location.search)
  .get("token")
  ?.replace(/ /g, "+");

export const Register: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<FormState>({
    password: "",
    passwordConfirmation: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const { createNotification } = useNotifications();
  const navigate = useNavigate();

  /**
   * Handles form submission.
   *
   * @param {FormEvent} e - The form event object.
   * @returns {void}
   */
  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    authService
      .register({ newPassword: form.password }, token || "")
      .then(() => {
        createNotification(
          t("notifications.registrationSuccess"),
          NotificationTypes.SUCCESS,
        );
        navigate("/login");
      })
      .catch((err) => {
        if (err?.response?.errorCode === 5) {
          setErrorMessage(t("errors.invalidUsername"));
        } else {
          setErrorMessage(t("errors.genericError"));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  /**
   * Checks if a token is valid and handles the corresponding actions.
   *
   * @function checkToken
   * @returns {void}
   */
  const checkToken = useCallback(() => {
    setIsLoading(true);
    authService
      .checkToken(token || "")
      .catch(() => {
        createNotification(t("errors.tokenInvalid"), NotificationTypes.DANGER);
        navigate("/login");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createNotification, navigate, t]);

  /**
   * Stores an array of error objects related to password validation.
   *
   * @type {ErrorObject[]}
   * @returns {ErrorObject[]} - An array of error objects containing information about each password error.
   *
   * @typedef {Object} ErrorObject
   * @property {string} key - The key identifier for the error.
   * @property {string} label - The localized label for the error.
   * @property {boolean} valid - Indicates whether the password validation passed or failed.
   *
   * @typedef {Function} ValidatorFunction
   * @param {string} password - The password to be validated.
   * @param {string} [confirmation] - The password confirmation to be validated.
   * @returns {boolean} - The result of the validation.
   *
   * @function useMemo
   * @param {function} factory - The function that creates the memoized value.
   * @param {Array} deps - The dependencies array.
   * @returns {*} - The memoized value.
   *
   * @summary
   * The `passwordErrors` variable is a memoized array of error objects that contains information about each password validation error. The error objects have properties such as key, label
   *, and valid to identify and label the errors.
   * The array is created using the `useMemo` hook with a factory function.
   * The factory function defines an array of all possible errors and returns a new array of error objects by mapping over the possible errors and performing validation checks using the
   * provided validators.
   * The validators are functions that accept the password and confirmation arguments and return a boolean indicating the result of the validation.
   *
   */
  const passwordErrors: ErrorObject[] = useMemo(() => {
    // Define all possible errors
    const allErrors = [
      {
        key: "passwordLength",
        validator: (password: string) => password.length >= 8,
      },
      {
        key: "passwordLowercase",
        validator: (password: string) => /[a-z]/g.test(password),
      },
      {
        key: "passwordUppercase",
        validator: (password: string) => /[A-Z]/g.test(password),
      },
      {
        key: "passwordNumber",
        validator: (password: string) => /[0-9]/g.test(password),
      },
      {
        key: "passwordSpecialCharacter",
        validator: (password: string) => /[^a-zA-Z\d]/g.test(password),
      },
      {
        key: "passwordDifferent",
        validator: (password: string, confirmation: string): boolean =>
          password.length > 0 &&
          confirmation.length > 0 &&
          password === confirmation,
      },
    ];

    return allErrors.map(
      ({ key, validator }): ErrorObject => ({
        key,
        label: t(`validation.${key}`),
        valid: validator(form.password, form.passwordConfirmation),
      }),
    );
  }, [form, t]);

  /**
   * Indicates whether there are any error in the password validation.
   *
   * @type {boolean}
   */
  const hasErrors: boolean = useMemo(
    () => passwordErrors.some((passwordError) => !passwordError.valid),
    [passwordErrors],
  );

  /**
   * Calculates the strength of a password.
   *
   * @param {string} password - The password to calculate the strength for.
   * @returns {number} - The password strength value.
   */
  const passwordStrength: number = useMemo(
    () => calculatePasswordStrength(form.password),
    [form.password],
  );

  /**
   * Determines the color class to use based on the strength of a password.
   *
   * @function
   * @name strengthColor
   * @param {number} passwordStrength - The strength of the password.
   * @returns {string} The color class to apply.
   */
  const strengthColor = useMemo(() => {
    if (passwordStrength < 40) return "bg-dark-red";
    if (passwordStrength < 60) return "bg-yellow";
    if (passwordStrength < 80) return "bg-medium-green";
    return "bg-green";
  }, [passwordStrength]);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      checkToken();
    }
  }, [checkToken, navigate]);

  return (
    <div className="pt-2">
      <div className="flex flex-col gap-4 pt-2">
        <span>{t("auth.registerSubtitle")}</span>
        <ul className="flex flex-col gap-2 pl-4">
          {passwordErrors.map((passwordError: ErrorObject) => (
            <li
              key={passwordError.key}
              className="flex flex-row items-center gap-2 transition-all duration-300 ease-in-out"
            >
              <div
                className={clsx(
                  "h-2 w-2 rounded-full ",
                  passwordError.valid ? "bg-green" : "bg-dark-red",
                )}
              ></div>
              <span className={clsx(passwordError.valid && "line-through")}>
                {passwordError.label}
              </span>
            </li>
          ))}
        </ul>
        <span>{t("auth.resetPasswordTip")}</span>
      </div>
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        className="mt-20 flex h-full w-full flex-col items-start justify-start"
      >
        <div className="flex w-full flex-col gap-6">
          <TextField
            id="password"
            aria-disabled={isLoading}
            aria-label={t("common.password")}
            isDisabled={isLoading}
            name="password"
            type="password"
            autoComplete="password"
            placeholder={t("common.password")}
            value={form.password}
            isRequired
            variant="secondary"
            onChange={(e) => setForm({ ...form, password: e })}
          />
          <div className="flex w-full flex-col gap-2">
            <span>
              {t("common.passwordStrength")}: {Math.ceil(passwordStrength)}%
            </span>
            <div className="h-2.5 w-full rounded-full bg-gray transition-all duration-200 ease-in-out">
              <div
                className={`h-2.5 rounded-full transition-all duration-200 ease-in-out ${strengthColor}`}
                style={{ width: `${passwordStrength}%` }}
              ></div>
            </div>
          </div>
          <TextField
            id="passwordConfirm"
            aria-disabled={isLoading}
            aria-label={t("common.confirmNewPassword")}
            isDisabled={isLoading}
            name="password"
            type="password"
            autoComplete="password"
            placeholder={t("common.confirmNewPassword")}
            value={form.passwordConfirmation}
            isRequired
            variant="secondary"
            onChange={(e) => setForm({ ...form, passwordConfirmation: e })}
          />
          <div className="mt-20 flex h-[24px] flex-row items-center justify-start text-red">
            <Icon
              name="ErrorIcon"
              className={clsx(
                "h-5 w-5",
                errorMessage ? "visible" : "invisible",
              )}
            />
            <span className="ml-2 text-red">
              {errorMessage ? errorMessage : ""}
            </span>
          </div>
          <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
            <div className="flex items-center justify-center">
              <Link
                aria-disabled={isLoading}
                to="/login"
                className={clsx(
                  "text-center text-lg text-dark-blue underline",
                  isLoading && "pointer-events-none opacity-50",
                )}
              >
                {t("common.back")}
              </Link>
            </div>
            <Button
              size="large"
              type="submit"
              isDisabled={hasErrors}
              isLoading={isLoading}
            >
              {t("common.save")}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
