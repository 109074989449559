import type { GridNode } from "@react-types/grid";
import clsx from "clsx";
import React, { ReactNode, useRef } from "react";
import { useTableHeaderRow } from "react-aria";
import type { TableState } from "react-stately";

interface TableHeaderRowProps<T> {
  item: GridNode<T>;
  children: ReactNode;
  state: TableState<T>;
  borderless?: boolean;
}

export default function TableHeaderRow<T>(props: TableHeaderRowProps<T>) {
  const { item, children, state, borderless } = props;
  const ref = useRef(null);
  const { rowProps } = useTableHeaderRow({ node: item }, state, ref);

  return (
    <tr
      {...rowProps}
      ref={ref}
      className={clsx(
        "flex w-full",
        !borderless && "z-10 border-b border-solid border-medium-gray bg-white",
      )}
    >
      {children}
    </tr>
  );
}
