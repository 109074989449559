import React from "react";

import useUserGroupDetail from "../../hooks/useUserGroupDetail";
import { Accordion, AccordionItem } from "../Accordion";
import Button from "../Button";
import { ComputerMultiSelect } from "../common/ComputerMultiSelect";
import { UserMultiSelect } from "../common/UserMultiSelect";
import { Icon } from "../Icon";
import TextArea from "../TextArea";
import TextField from "../TextField";

interface UserGroupDetailProps {
  id?: number;
  onClose: () => void;
  onSaved: () => void;
}

const UserGroupDetail: React.FC<UserGroupDetailProps> = (
  props: UserGroupDetailProps,
) => {
  const {
    isLoading,
    onSubmit,
    isEditing,
    form,
    handleInputChange,
    handleCancel,
    t,
    isCurrentUserReadOnly,
  } = useUserGroupDetail({
    id: Number(props.id),
    onCancel: props.onClose,
    onSaved: props.onSaved,
  });
  return (
    <form
      onSubmit={onSubmit}
      className="flex h-full  flex-col justify-between space-y-4"
    >
      <div className="flex flex-grow flex-col space-y-4 divide-y divide-medium-gray">
        <div className="flex flex-row items-start justify-start gap-4 px-8 py-4">
          <div className="flex flex-col space-y-4">
            <div className="flex flex-row items-center justify-start gap-4">
              <Icon
                name="GroupOfUsersIcon"
                className="h-8 w-8 text-dark-blue"
              />
              <span className="text-xl font-medium text-dark-blue">
                {isEditing
                  ? t("common.editUserGroup")
                  : t("common.addUserGroup")}
              </span>
            </div>
            <p>
              {isEditing
                ? t("common.editUserGroupDescription")
                : t("common.addUserGroupDescription")}
            </p>
            <div className="grid grid-cols-1 gap-3">
              <TextField
                isReadOnly={isCurrentUserReadOnly}
                name="name"
                type="text"
                label={t("common.name")}
                value={form.name}
                placeholder={t("common.userGroupNamePlaceholder")}
                isRequired
                required
                isDisabled={isLoading}
                onChange={(e) => handleInputChange("name", e)}
              />
              <TextArea
                isReadOnly={isCurrentUserReadOnly}
                name="description"
                label={t("common.description")}
                value={form.description}
                isDisabled={isLoading}
                onChange={(e) => handleInputChange("description", e)}
              />
            </div>
          </div>
        </div>
        <Accordion>
          <AccordionItem title={`${t("common.users")} (${form.users.length})`}>
            <UserMultiSelect
              id="users"
              selectedObjects={form.users}
              disabled={isLoading || isCurrentUserReadOnly}
              onChange={(e) => {
                handleInputChange("users", e);
              }}
              onRemove={(e) => {
                handleInputChange(
                  "users",
                  form.users?.filter((item) => item.id !== e.id),
                );
              }}
            />
          </AccordionItem>
          <AccordionItem
            title={`${t("common.assignedComputers")} (${
              form.computers.length + form.computerGroups.length
            })`}
          >
            <ComputerMultiSelect
              id="computers"
              disabled={isLoading || isCurrentUserReadOnly}
              selectedComputers={form.computers}
              selectedGroups={form.computerGroups}
              onComputerChange={(e) => {
                handleInputChange("computers", e);
              }}
              onGroupChange={(e) => {
                handleInputChange("computerGroups", e);
              }}
              onComputerRemove={(e) => {
                handleInputChange(
                  "computers",
                  form.computers?.filter((item) => item.id !== e.id),
                );
              }}
              onGroupRemove={(e) => {
                handleInputChange(
                  "computerGroups",
                  form.computerGroups?.filter((item) => item.id !== e.id),
                );
              }}
            />
          </AccordionItem>
        </Accordion>
      </div>
      <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2">
        <div>
          <Button
            onPress={handleCancel}
            type="button"
            isDisabled={isLoading}
            variant="text"
          >
            {t("common.cancel")}
          </Button>
        </div>
        <div>
          <Button
            isDisabled={isCurrentUserReadOnly}
            type="submit"
            isLoading={isLoading}
            variant="contained"
          >
            {t("common.save")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UserGroupDetail;
