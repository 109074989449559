import qs from "qs";
import { Key } from "react";

import { APIService, RequestBody } from "../api/apiService";
import { UserStatus } from "../interfaces/User";

export class UserService extends APIService {
  /**
   * @description Fetches all users
   */
  getUser(id: string | number) {
    return this.request(`/Admin/User/${id}`, "GET");
  }

  /**
   * @description Fetches all users
   */
  getUserComputers(id: string | number) {
    return this.request(`/Admin/User/${id}/Computers`, "GET");
  }

  /**
   * @description Fetches all users
   */
  getUserGroups(id: string | number) {
    return this.request(`/Admin/User/${id}/Groups`, "GET");
  }

  /**
   * @description Fetches all users
   */
  getUsers(query?: string | null) {
    return this.request(`/Admin/User?${query}`, "GET");
  }

  /**
   * @description Fetches all users
   */
  updateUser(id: string | number, payload: RequestBody) {
    return this.request(`/Admin/User/${id}`, "PUT", payload);
  }

  /**
   * @description Fetches all users
   */
  sendInvitation(id: string | number) {
    return this.request(`/Admin/User/${id}/SendInvitation`, "PUT");
  }

  /**
   * @description Created a new user
   */
  createUser(payload: RequestBody) {
    return this.request(`/Admin/User`, "POST", payload);
  }

  /**
   * @description Delete users
   */
  deleteUsers(query: string) {
    return this.request(`/Admin/User?${query}`, "DELETE");
  }

  /**
   * @description Change status of users
   */
  changeStatus(status: UserStatus, id: Key[]) {
    const query = qs.stringify({ id, status }, { arrayFormat: "comma" });
    return this.request(`/Admin/User/ChangeStatus?${query}`, "PUT");
  }
}
