import clsx from "clsx";
import React, { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";

import ErrorBoundary from "../ErrorBoundary";

function BaseMainLayout() {
  const location = useLocation();

  /**
   * Check if the current web page has a plain background for the dashboard.
   *
   * @function hasPlainBackground
   * @returns {boolean} True if the current web page has a plain background for the dashboard, otherwise false.
   */
  const hasPlainBackground = useMemo(() => {
    return location.pathname.includes("dashboard");
  }, [location.pathname]);

  return (
    <div
      className={clsx(
        "h-full overflow-x-auto",
        !hasPlainBackground && "rounded-lg border border-medium-gray bg-white",
      )}
    >
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </div>
  );
}

export default BaseMainLayout;
