import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../contexts/AuthContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { AccountService } from "../services/accountService";

import { useNotifications } from "./useNotifications";

interface FormState {
  emails: string[];
  violationActivity: number;
}

const accountService = new AccountService();

function useNotificationSettings() {
  const { t } = useTranslation();
  const { isCurrentUserReadOnly } = useAuth();

  const [form, setForm] = useState<FormState>({
    emails: [],
    violationActivity: 0,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailInputValue, setEmailInputValue] = useState("");
  const newEmailTextFieldRef = useRef<HTMLInputElement>(null);
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isViolationActivityChecked, setIsViolationActivityChecked] =
    useState<boolean>(false);

  const { createNotification } = useNotifications();

  /**
   * Fetches notification settings from the server.
   *
   * @function getNotificationSettings
   * @returns {void}
   */
  const getNotificationSettings = useCallback(() => {
    setIsLoading(true);
    accountService
      .getNotificationSettings()
      .then((res) => {
        setForm(res);
        setIsInitialLoaded(true);
      })
      .catch(() => {
        createNotification(t("errors.genericError"), NotificationTypes.DANGER);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createNotification, t]);

  /**
   * Updates the notification settings.
   *
   * This function updates the notification settings by calling the `updateNotificationSettings` method of the `accountService`.
   * It first sets the `isLoading` state to `true` to indicate that the update is in progress.
   * Then it calls the `updateNotificationSettings` method with the `form` object as its parameter.
   * If the update is successful, it creates a success notification with the message "pages.notificationsUpdated".
   * If the update fails, it creates a danger notification with the message "errors.genericError".
   * Finally, it sets the `isLoading` state back to `false`.
   *
   * @function updateNotificationSettings
   * @param {function} createNotification - The function to create notifications.
   * @param {Object} form - The form object with the updated notification settings.
   * @param {function} t - The translation function from the i18n library.
   * @returns {void}
   */
  const updateNotificationSettings = useCallback(() => {
    setIsLoading(true);
    accountService
      .updateNotificationSettings({ ...form })
      .then(() => {
        createNotification(
          t("notifications.genericUpdateSuccess", {
            resource: t("pages.notifications"),
          }),
          NotificationTypes.SUCCESS,
        );
      })
      .catch(() => {
        createNotification(t("errors.genericError"), NotificationTypes.DANGER);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createNotification, form, t]);

  /**
   * Handles adding the input value to an array of values.
   * If the input value is not empty, it adds the input value to the array of values
   * and triggers the onChange callback with the updated array of values.
   * It also resets the input value to an empty string.
   *
   * @function
   * @name handleAddEmail
   * @returns {void}
   */
  const handleAddEmail = (): void => {
    if (
      emailInputValue.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    ) {
      setForm({ ...form, emails: [...form.emails, emailInputValue] });
      setEmailInputValue("");
      setIsFormChanged(true);
    }
  };

  /**
   * Removes a string from the value array at the specified index.
   *
   * @param {number} index - The index of the string to be removed.
   * @returns {void}
   */
  const handleRemoveEmail = (index: number): void => {
    const updatedValue = form.emails.filter((_, idx) => idx !== index);
    setForm({ ...form, emails: updatedValue });
    newEmailTextFieldRef.current?.focus();
    setIsFormChanged(true);
  };

  /**
   * Handles key press event for input field.
   *
   * @param {React.KeyboardEvent<HTMLInputElement>} event - The key press event object.
   * @returns {void}
   */
  const handleAddEmailKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (emailInputValue.trim() !== "") {
        handleAddEmail();
      }
    }
  };

  /**
   * Checks if the given email input value is a valid email address.
   *
   * @param {string} emailInputValue - The value to be checked.
   * @returns {boolean} - True if the email is valid, false otherwise.
   */
  const isEmailValid = useMemo(() => {
    return emailInputValue.match(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    );
  }, [emailInputValue]);

  useEffect(() => {
    if (!isInitialLoaded) {
      getNotificationSettings();
    }
  }, [getNotificationSettings, isInitialLoaded]);

  useEffect(() => {
    if (isInitialLoaded && isFormChanged) {
      updateNotificationSettings();
      setIsFormChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    setIsViolationActivityChecked(form.violationActivity !== 0);
  }, [form.violationActivity]);

  return {
    emailInputValue,
    form,
    handleAddEmail,
    handleAddEmailKeyPress,
    handleRemoveEmail,
    isCurrentUserReadOnly,
    isEmailValid,
    isLoading,
    isViolationActivityChecked,
    newEmailTextFieldRef,
    setEmailInputValue,
    setForm,
    setIsFormChanged,
    setIsViolationActivityChecked,
    t,
  };
}

export default useNotificationSettings;
