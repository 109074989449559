import { SortDescriptor } from "@react-types/shared";
import qs from "qs";
import React, {
  Key,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDateFormatter } from "react-aria";
import { useTranslation } from "react-i18next";
import type { Selection } from "react-stately";
import { useOverlayTriggerState } from "react-stately";

import ComputerDetail from "../components/computer/ComputerDetail";
import { TableAction } from "../components/TableActionsBar";
import { useAuth } from "../contexts/AuthContext";
import { useDialogContext } from "../contexts/DialogContext";
import { useNavigationDirty } from "../contexts/NavigationDirtyContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { usePendingComputerUpdate } from "../contexts/PendingComputerUpdateContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import { useSignalR } from "../contexts/SignalRContext";
import { SignalREventTypeEnum } from "../enum/SignalREventTypeEnum";
import Computer from "../interfaces/Computer";
import { ComputerService } from "../services/computerService";
import { transformObjectArraysToArrayOfIds } from "../utils/utils";

import { useNotifications } from "./useNotifications";
import useTooltip from "./useTooltip";

const computerService = new ComputerService();

interface FilterState {
  count: number;
  offset: number;
  search?: string;
  sort?: SortDescriptor;
}

function useComputersTab() {
  const {
    openRightPanel,
    isRightPanelOpen,
    setRightPanelContent,
    closeRightPanel,
  } = useContext(RightPanelContext) as RightPanelContextProps;
  const [computers, setComputers] = useState<Computer[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]));
  const [selectedComputer, setSelectedComputer] = useState<
    Computer | undefined
  >(undefined); // Used for display the name of the item in the dialogs ( Only one )
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingTamperedCode, setIsLoadingTamperedCode] =
    useState<boolean>(false);
  const [isLoadingProxyCode, setIsLoadingProxyCode] = useState<boolean>(false);
  const [filters, setFilters] = useState<FilterState>({
    count: 15,
    offset: 0,
    search: undefined,
  });
  const [activeComputer, setActiveComputer] = useState<Key | null>(null);
  const [activeComputersForDeletion, setActiveComputersForDeletion] = useState<
    Key[]
  >([]);
  const [tamperedCode, setTamperedCode] = useState<string>("");
  const [proxyCode, setProxyCode] = useState<string>("");
  const [isTamperedCodeVisible, setIsTamperedCodeVisible] =
    useState<boolean>(false);
  const [isProxyCodeVisible, setIsProxyCodeVisible] = useState<boolean>(false);
  const deleteComputerModalState = useOverlayTriggerState({});
  const computerTamperCodeModalState = useOverlayTriggerState({});
  const computerProxyCodeModalState = useOverlayTriggerState({});

  const dialog = useDialogContext();
  const { t } = useTranslation();
  const { confirmDirtyNavigation } = useNavigationDirty();
  const { showTooltip, hideTooltip } = useTooltip();
  const { createNotification } = useNotifications();
  const { getPendingErrorComputers, setHasPendingUpdates } =
    usePendingComputerUpdate();
  const { signalRConnection } = useSignalR();
  const { isCurrentUserReadOnly } = useAuth();

  const dateFormatter = useDateFormatter({
    dateStyle: "medium",
    timeStyle: "short",
  });

  /**
   * Retrieves a list of computers based on provided filters.
   *
   * @returns {Promise<void>} A promise that resolves when the computer retrieval is complete.
   *
   * @throws {Error} If there is an error while retrieving the computers.
   */
  const getComputers = useCallback(async () => {
    setIsLoading(true);
    const transformedFilters = transformObjectArraysToArrayOfIds(filters);
    if (transformedFilters.sort !== undefined) {
      transformedFilters.sortCol = filters.sort?.column;
      transformedFilters.sortDir = filters.sort?.direction;
      delete transformedFilters.sort;
    } else {
      transformedFilters.sortCol = "createdOn";
      transformedFilters.sortDir = "descending";
    }

    computerService
      .getComputers(qs.stringify(transformedFilters))
      .then((res) => {
        setComputers(res.data);
        setTotal(res.total);
      })
      .catch(() => {
        createNotification(
          t("errors.genericFetchError", {
            resource: t("common.computers"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createNotification, filters, t]);

  /**
   * Handles the opening of the right panel.
   * @async
   * @param {number} [computerId] - The user ID.
   */
  const handleOpenRightPanel = async (computerId?: number) => {
    const confirm = await confirmDirtyNavigation();
    if (!confirm) {
      return;
    }
    openRightPanel();
    setRightPanelContent(
      <ComputerDetail
        key={Math.random()}
        id={computerId}
        onCancel={() => {
          closeRightPanel();
        }}
        onSaved={() => {
          getComputers().then(closeRightPanel);
        }}
      />,
    );
    if (!computerId) {
      setActiveComputer(null);
    }
  };

  /**
   * Handles the deletion of a computer.
   *
   * @returns {void}
   */
  const handleComputerDelete = (): void => {
    setIsLoading(true);
    computerService
      .deleteComputer(
        qs.stringify({
          id: activeComputersForDeletion.includes("all")
            ? [-1000]
            : activeComputersForDeletion,
        }),
      )
      .catch(() => {
        createNotification(
          t("errors.genericDeleteError", {
            resource: t("common.computer"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .then(() => {
        getComputers().then(() => {
          setHasPendingUpdates(true);
          deleteComputerModalState.close();
          closeRightPanel();
          setSelectedKeys(new Set([]));
          setActiveComputersForDeletion([]);
          getPendingErrorComputers().then(() => {});
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Copies tampered code to clipboard and shows a notification based on the success or failure of the operation.
   * Finally, closes the computer tamper code modal.
   *
   * @function handleCopyTamperedCode
   * @returns {void}
   */
  const handleCopyCode = (type: "tampered" | "proxy"): void => {
    navigator.clipboard
      .writeText(type === "tampered" ? tamperedCode : proxyCode)
      .then(() =>
        createNotification(
          t("notifications.tamperedCodeCopied"),
          NotificationTypes.SUCCESS,
        ),
      )
      .catch(() =>
        createNotification(
          t("errors.genericCopyError"),
          NotificationTypes.DANGER,
        ),
      )
      .finally(() => {
        computerTamperCodeModalState.close();
      });
  };

  /**
   * Returns the corresponding icon for the given operating system.
   * @param {string} os - The name of the operating system.
   * @returns {string} - The name of the icon for the operating system.
   */
  const getOperatingSystemIcon = (os: string) => {
    const cleanedOS = os.toLowerCase().trim();
    if (cleanedOS.includes("windows 10")) {
      return "Windows10Icon";
    }
    if (cleanedOS.includes("windows 11")) {
      return "Windows11Icon";
    }
    if (cleanedOS.includes("windows 8")) {
      return "Windows8Icon";
    }
    if (cleanedOS.includes("windows 7")) {
      return "Windows7Icon";
    }
    if (cleanedOS.includes("windows server")) {
      return "WindowsServerIcon";
    }
    return "WindowsIcon";
  };

  /**
   * Sets the selected computer using a key.
   *
   * @param {Key} key - The key to search for the selected computer.
   * @returns {void}
   */
  const findComputerByKey = (key: Key): Computer | undefined => {
    return computers.find((computer) => computer.id === Number(key));
  };

  /**
   * Contains an array of table actions.
   *
   * @type {TableAction[]}
   * @see {@link TableAction}
   *
   * @example
   * // Define additional actions
   * const additionalActions = [
   *   {
   *     icon: "DeactivateIcon",
   *     label: t("common.suspend"),
   *     onClick: () => alert("Suspend user clicked!"),
   *   },
   *   {
   *     icon: "DeleteIcon",
   *     label: t("common.delete"),
   *     onClick: () => alert("Delete user clicked!"),
   *   },
   * ];
   *
   * const isSetAndNotEmpty = selectedKeys instanceof Set && selectedKeys.size > 0;
   *
   * if (isSetAndNotEmpty || selectedKeys === "all") {
   *   return additionalActions;
   * }
   *
   * return [];
   */
  const tableActions: TableAction[] = useMemo(() => {
    const additionalActions: TableAction[] = [
      {
        icon: "SuspendIcon",
        label: t("common.suspendPolicies"),
        onClick: () => {
          dialog
            .open(
              t("dialog.suspendComputerPolicies"),
              t("dialog.suspendComputerPoliciesMessage", {
                count: selectedKeys === "all" ? total : selectedKeys.size,
                resourceName: selectedComputer?.name,
              }),
            )
            .then(() => {
              computerService
                .changeComputerStatus({
                  enable: false,
                  id: selectedKeys === "all" ? [-1000] : [...selectedKeys],
                })
                .catch(() => {
                  createNotification(
                    t("errors.genericUpdateError", {
                      resource: t("common.computer"),
                    }),
                    NotificationTypes.DANGER,
                  );
                })
                .then(() => {
                  getComputers().then(() => {
                    setHasPendingUpdates(true);
                  });
                });
            })
            .catch(() => {});
        },
      },
      {
        icon: "PlayIcon",
        label: t("common.enablePolicies"),
        onClick: () => {
          dialog
            .open(
              t("dialog.enableComputerPolicies"),
              t("dialog.enableComputerPoliciesMessage", {
                count: selectedKeys === "all" ? total : selectedKeys.size,
                resourceName: selectedComputer?.name,
              }),
            )
            .then(() => {
              computerService
                .changeComputerStatus({
                  enable: true,
                  id: selectedKeys === "all" ? [-1000] : [...selectedKeys],
                })
                .catch(() => {
                  createNotification(
                    t("errors.genericUpdateError", {
                      resource: t("common.computer"),
                    }),
                    NotificationTypes.DANGER,
                  );
                })
                .then(() => {
                  getComputers().then(() => {
                    setHasPendingUpdates(true);
                  });
                });
            })
            .catch(() => {});
        },
      },
      {
        icon: "DeactivateIcon",
        label: t("common.blockAccess"),
        onClick: () => {
          dialog
            .open(
              t("dialog.blockComputerAccess"),
              t("dialog.blockComputerAccessMessage", {
                count: selectedKeys === "all" ? total : selectedKeys.size,
                resourceName: selectedComputer?.name,
              }),
            )
            .then(() => {
              computerService
                .changeComputerStatus({
                  blocked: true,
                  id: selectedKeys === "all" ? [-1000] : [...selectedKeys],
                })
                .catch(() => {
                  createNotification(
                    t("errors.genericUpdateError", {
                      resource: t("common.computer"),
                    }),
                    NotificationTypes.DANGER,
                  );
                })
                .then(() => {
                  getComputers().then(() => {
                    setHasPendingUpdates(true);
                  });
                });
            })
            .catch(() => {});
        },
      },
      {
        icon: "CheckmarkRoundedIcon",
        label: t("common.unblockAccess"),
        onClick: () => {
          dialog
            .open(
              t("dialog.unblockComputerAccess"),
              t("dialog.unblockComputerAccessMessage", {
                count: selectedKeys === "all" ? total : selectedKeys.size,
                resourceName: selectedComputer?.name,
              }),
            )
            .then(() => {
              computerService
                .changeComputerStatus({
                  blocked: false,
                  id: selectedKeys === "all" ? [-1000] : [...selectedKeys],
                })
                .catch(() => {
                  createNotification(
                    t("errors.genericUpdateError", {
                      resource: t("common.computer"),
                    }),
                    NotificationTypes.DANGER,
                  );
                })
                .then(() => {
                  getComputers().then(() => {
                    setHasPendingUpdates(true);
                  });
                });
            })
            .catch(() => {});
        },
      },
      {
        icon: "DeleteIcon",
        label: t("common.delete"),
        onClick: () => {
          deleteComputerModalState.open();
          setActiveComputersForDeletion(
            selectedKeys === "all" ? ["all"] : Array.from(selectedKeys),
          );
        },
      },
    ];

    if (selectedKeys === "all" || selectedKeys.size > 0) {
      return additionalActions;
    } else {
      return [];
    }
  }, [
    createNotification,
    deleteComputerModalState,
    dialog,
    getComputers,
    selectedKeys,
    setHasPendingUpdates,
    t,
    total,
    selectedComputer?.name,
  ]);

  /**
   * Performs the specified action based on the given key.
   *
   * @param {number} id - The ID of the computer.
   * @param {string} key - The key representing the action to be performed.
   */
  const onMenuAction = (id: number, key: Key) => {
    const foundComputer = findComputerByKey(id);
    switch (key) {
      case "edit":
        handleOpenRightPanel(id).then(() => {});
        break;
      case "suspendPolicy":
        dialog
          .open(
            t("dialog.suspendComputerPolicies"),
            t("dialog.suspendComputerPoliciesMessage", {
              resourceName: foundComputer?.name,
            }),
          )
          .then(() => {
            computerService
              .changeComputerStatus({
                enable: false,
                id: [id],
              })
              .catch(() => {
                createNotification(
                  t("errors.genericUpdateError", {
                    resource: t("common.computer"),
                  }),
                  NotificationTypes.DANGER,
                );
              })
              .then(() => {
                getComputers().then(() => {
                  setHasPendingUpdates(true);
                });
              });
          })
          .catch(() => {});
        break;

      case "enablePolicy":
        dialog
          .open(
            t("dialog.enableComputerPolicies"),
            t("dialog.enableComputerPoliciesMessage", {
              resourceName: foundComputer?.name,
            }),
          )
          .then(() => {
            computerService
              .changeComputerStatus({
                enable: true,
                id: [id],
              })
              .catch(() => {
                createNotification(
                  t("errors.genericUpdateError", {
                    resource: t("common.computer"),
                  }),
                  NotificationTypes.DANGER,
                );
              })
              .then(() => {
                getComputers().then(() => {
                  setHasPendingUpdates(true);
                });
              });
          })
          .catch(() => {});
        break;
      case "blockAccess":
        dialog
          .open(
            t("dialog.blockComputerAccess"),
            t("dialog.blockComputerAccessMessage", {
              resourceName: foundComputer?.name,
            }),
          )
          .then(() => {
            computerService
              .changeComputerStatus({
                blocked: true,
                id: [id],
              })
              .catch(() => {
                createNotification(
                  t("errors.genericUpdateError", {
                    resource: t("common.computer"),
                  }),
                  NotificationTypes.DANGER,
                );
              })
              .then(() => {
                getComputers().then(() => {
                  setHasPendingUpdates(true);
                });
              });
          })
          .catch(() => {});
        break;
      case "unblockAccess":
        dialog
          .open(
            t("dialog.unblockComputerAccess"),
            t("dialog.unblockComputerAccessMessage", {
              resourceName: foundComputer?.name,
            }),
          )
          .then(() => {
            computerService
              .changeComputerStatus({
                blocked: false,
                id: [id],
              })
              .catch(() => {
                createNotification(
                  t("errors.genericUpdateError", {
                    resource: t("common.computer"),
                  }),
                  NotificationTypes.DANGER,
                );
              })
              .then(() => {
                getComputers().then(() => {
                  setHasPendingUpdates(true);
                });
              });
          })
          .catch(() => {});
        break;
      case "tamperedCode":
        computerTamperCodeModalState.open();
        setIsLoadingTamperedCode(true);
        setIsTamperedCodeVisible(false);
        setTamperedCode("");
        computerService
          .getComputerCode(id)
          .then((res) => {
            setTamperedCode(res?.code);
            setIsTamperedCodeVisible(false);
          })
          .catch(() => {
            createNotification(
              t("errors.genericFetchError", {
                resource: t("common.tamperedCode"),
              }),
              NotificationTypes.DANGER,
            );
          })
          .finally(() => {
            setIsLoadingTamperedCode(false);
          });
        break;
      case "proxyCode":
        computerProxyCodeModalState.open();
        setIsLoadingProxyCode(true);
        setIsProxyCodeVisible(false);
        setProxyCode("");
        computerService
          .getComputerProxyUnlockCode(id)
          .then((res) => {
            setProxyCode(res?.code);
            setIsProxyCodeVisible(false);
          })
          .catch(() => {
            createNotification(
              t("errors.genericFetchError", {
                resource: t("common.proxyCode"),
              }),
              NotificationTypes.DANGER,
            );
          })
          .finally(() => {
            setIsLoadingProxyCode(false);
          });
        break;
      case "delete":
        deleteComputerModalState.open();
        setActiveComputersForDeletion([id]);
        break;
      default:
        break;
    }
  };

  /**
   * Executes an action on a row.
   *
   * @param {string} key - The unique identifier of the row.
   * @returns {void} - This function does not return anything.
   */
  const onRowAction = (key: Key): void => {
    handleOpenRightPanel(Number(key)).then(() => {
      setActiveComputer(key);
    });
  };

  useEffect(() => {
    getComputers().then(() => {});
  }, [getComputers]);

  useEffect(() => {
    if (!isRightPanelOpen) {
      setActiveComputer(null);
    }
  }, [isRightPanelOpen]);

  useEffect(() => {
    if (selectedKeys instanceof Set && selectedKeys.size > 0) {
      closeRightPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys]);

  useEffect(() => {
    if (activeComputersForDeletion.length === 1) {
      const foundComputer = computers.find(
        (computer) => computer.id === Number(activeComputersForDeletion[0]),
      );
      setSelectedComputer(foundComputer);
      return;
    }

    if (selectedKeys !== "all" && selectedKeys.size === 1) {
      const foundComputer = computers.find(
        (computer) =>
          computer.id === Number(selectedKeys.values().next().value),
      );
      setSelectedComputer(foundComputer);
      return;
    }
  }, [computers, activeComputersForDeletion, selectedKeys]);

  useEffect(() => {
    if (signalRConnection) {
      const handleReceiveMessage = () => {
        getComputers().then(() => {});
      };

      signalRConnection.on(
        SignalREventTypeEnum.changedComputerStatus,
        handleReceiveMessage,
      );

      // Remove the event listener when the component is unmounted
      return () => {
        signalRConnection.off(
          SignalREventTypeEnum.changedComputerStatus,
          handleReceiveMessage,
        );
      };
    }
  }, [signalRConnection]);

  return {
    activeComputer,
    activeComputersForDeletion,
    computerProxyCodeModalState,
    computerTamperCodeModalState,
    computers,
    dateFormatter,
    deleteComputerModalState,
    filters,
    getOperatingSystemIcon,
    handleComputerDelete,
    handleCopyCode,
    hideTooltip,
    isCurrentUserReadOnly,
    isLoading,
    isLoadingProxyCode,
    isLoadingTamperedCode,
    isProxyCodeVisible,
    isTamperedCodeVisible,
    onMenuAction,
    onRowAction,
    proxyCode,
    selectedComputer,
    selectedKeys,
    setFilters,
    setIsProxyCodeVisible,
    setIsTamperedCodeVisible,
    setSelectedKeys,
    showTooltip,
    t,
    tableActions,
    tamperedCode,
    total,
  };
}

export default useComputersTab;
