import React, { useCallback, useEffect, useRef, useState } from "react";
import type { AriaSearchFieldProps } from "react-aria";
import { useSearchField } from "react-aria";
import { useSearchFieldState } from "react-stately";

import { Icon } from "./Icon";

interface SearchFieldProps extends AriaSearchFieldProps {
  label?: string;
  onChange?: (value: string) => void;
  onDebounce?: (value: string) => void;
}

function SearchField(props: SearchFieldProps) {
  const { label, onDebounce } = props;
  const state = useSearchFieldState(props);
  const ref = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState("");
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedSearch = useCallback(
    (value: string) => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }

      debounceTimerRef.current = setTimeout(() => {
        if (onDebounce) {
          onDebounce(value);
        }
      }, 300);
    },
    [onDebounce],
  );

  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  const { labelProps, inputProps } = useSearchField(props, state, ref);

  return (
    <div className="relative flex w-full flex-col">
      {label && (
        <label className="pb-2 text-dark-blue" {...labelProps}>
          {label}
        </label>
      )}
      <div className="flex flex-row items-center rounded-md border border-gray bg-white px-2 py-2">
        <Icon name="SearchIcon" className="text-medium-gray" />
        <input
          value={inputValue}
          className="pl-2 text-extra-dark-gray placeholder-medium-gray outline-none focus:border-dark-gray"
          {...inputProps}
          onChange={(e) => {
            if (inputProps.onChange) {
              inputProps.onChange(e);
            }
            setInputValue(e.target.value);
            debouncedSearch(e.target.value);
          }}
          ref={ref}
        />
      </div>
    </div>
  );
}

export default SearchField;
