import { APIService } from "../api/apiService";

export class InfoService extends APIService {
  /**
   * @description Get settings
   */
  getSettings() {
    return this.request(`/Admin/Info/Settings`, "GET");
  }

  /**
   * @description Get wizard
   */
  getWizard(query: string) {
    return this.request(`/Admin/Info/Wizard?${query}`, "GET");
  }
}
