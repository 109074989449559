import { APIService } from "../api/apiService";

export class ActivityService extends APIService {
  /**
   * Retrieves data from the server.
   *
   * @returns {Promise} A Promise that resolves to the data retrieved from the server.
   */
  getUserActivities(query: string): Promise<any> {
    return this.request(`/Admin/Activity/User?${query}`, "GET");
  }

  /**
   * Retrieves data from the server.
   *
   * @returns {Promise} A Promise that resolves to the data retrieved from the server.
   */
  getUserActivitiesGroupedByPolicy(query?: string): Promise<any> {
    return this.request(
      `/Admin/Activity/User/GroupBy/TypePolicy?${query}`,
      "GET",
    );
  }

  /**
   * Retrieves data from the server.
   *
   * @returns {Promise} A Promise that resolves to the data retrieved from the server.
   */
  getUserActivitiesByPolicyType(type: string, query?: string): Promise<any> {
    return this.request(
      `/Admin/Activity/User/TypePolicy/${type}?${query}`,
      "GET",
    );
  }

  /**
   * Retrieves data from the server.
   *
   * @returns {Promise} A Promise that resolves to the data retrieved from the server.
   */
  getUserActivitiesByPolicyTypeAndPolicy(
    id: string,
    type: string,
    query?: string,
  ): Promise<any> {
    return this.request(
      `/Admin/Activity/User/TypePolicy/${type}/${id}?${query}`,
      "GET",
    );
  }

  /**
   * Retrieves data from the server.
   * Where entityObject is:
   *
   * - Application hash for type 1000, 1001, 1008
   * - Base64 encoded URL for type 1002
   * - Computer ID for the rest
   *
   * @returns {Promise} A Promise that resolves to the data retrieved from the server.
   */
  getUserActivitiesByPolicyTypeAndPolicyAndEntity(
    id: string,
    type: string,
    entity: string,
    query?: string,
  ): Promise<any> {
    return this.request(
      `/Admin/Activity/User/TypePolicy/${type}/${id}/${entity}?${query}`,
      "GET",
    );
  }

  /**
   * Retrieves data from the server.
   *
   * @returns {Promise} A Promise that resolves to the data retrieved from the server.
   */
  getAdminActivities(query: string): Promise<any> {
    return this.request(`/Admin/Activity/Admin?${query}`, "GET");
  }

  /**
   * Retrieves data from the server.
   *
   * @returns {Promise} A Promise that resolves to the data retrieved from the server.
   */
  getSoftwareActivity(query: string): Promise<any> {
    return this.request(`/Admin/Activity/Software?${query}`, "GET");
  }

  forceUpdate() {
    return this.request("/Admin/ForceUpdate", "GET");
  }

  testActivity() {
    return this.request("/Admin/Activity/Test", "POST", {});
  }

  /**
   * Hides the activity for a given policy ID using the provided application details.
   *
   * @param {string} policyId - The ID of the policy.
   * @param {object} applicationDetail - The details of the application.
   * @return {Promise<object>} A Promise that resolves to the response from the API request.
   */
  hideActivity(policyId: string, applicationDetail: object): Promise<object> {
    return this.request("/Admin/Activity/User/Hide", "POST", {
      applicationDetail,
      policyId,
    });
  }

  /**
   * Hides the activity for a given policy ID using the provided application details.
   *
   * @param {string} policyId - The ID of the policy.
   * @param {object} applicationDetail - The details of the application.
   * @return {Promise<object>} A Promise that resolves to the response from the API request.
   */
  restoreActivity(
    policyId: string,
    applicationDetail: object,
  ): Promise<object> {
    return this.request("/Admin/Activity/User/Restore", "POST", {
      applicationDetail,
      policyId,
    });
  }

  /**
   * @description Created a new user
   */
  markUserActivitiesAsRead() {
    return this.request(`/Admin/Activity/User/MarkAsRead`, "POST", {});
  }
}
