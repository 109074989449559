import { PolicyTypeEnum } from "../enum/PolicyTypeEnum";
import { UserActivityTypeEnum } from "../enum/UserActivityTypeEnum";
import UserActivityType from "../interfaces/UserActivityType";

import { translate } from "./translation";
import { findPolicyByType } from "./utils";

export const userActivityTypes: UserActivityType[] = [
  {
    description: translate("userActivityTypes.DeleteFile.description"),
    id: UserActivityTypeEnum.DeleteFile,
    key: "deleteFile",
    name: translate("userActivityTypes.DeleteFile.name"),
    policy: findPolicyByType(PolicyTypeEnum.DeleteFile),
    type: UserActivityTypeEnum.DeleteFile,
  },
  {
    description: translate("userActivityTypes.downloadFile.description"),
    id: UserActivityTypeEnum.DownloadFile,
    key: "downloadFile",
    name: translate("userActivityTypes.DownloadFile.name"),
    policy: findPolicyByType(PolicyTypeEnum.DownloadFile),
    type: UserActivityTypeEnum.DownloadFile,
  },
  {
    description: translate("userActivityTypes.Network.description"),
    id: UserActivityTypeEnum.Network,
    key: "network",
    name: translate("userActivityTypes.Network.name"),
    policy: findPolicyByType(PolicyTypeEnum.Network),
    type: UserActivityTypeEnum.Network,
  },
  {
    description: translate("userActivityTypes.UploadNewFileExe.description"),
    id: UserActivityTypeEnum.UploadNewFileExe,
    key: "UploadNewFileExe",
    name: translate("userActivityTypes.UploadNewFileExe.name"),
    policy: findPolicyByType(PolicyTypeEnum.UploadNewFileExe),
    type: UserActivityTypeEnum.UploadNewFileExe,
  },
  {
    description: translate("userActivityTypes.Country.description"),
    id: UserActivityTypeEnum.Country,
    key: "country",
    name: translate("userActivityTypes.Country.name"),
    policy: findPolicyByType(PolicyTypeEnum.Country),
    type: UserActivityTypeEnum.Country,
  },
  {
    description: translate("userActivityTypes.DateTimePlan.description"),
    id: UserActivityTypeEnum.DateTimePlan,
    key: "DateTimePlan",
    name: translate("userActivityTypes.DateTimePlan.name"),
    policy: findPolicyByType(PolicyTypeEnum.DateTimePlan),
    type: UserActivityTypeEnum.DateTimePlan,
  },
  {
    description: translate("userActivityTypes.Expiry.description"),
    id: UserActivityTypeEnum.Expiry,
    key: "expiry",
    name: translate("userActivityTypes.Expiry.name"),
    policy: findPolicyByType(PolicyTypeEnum.Expiry),
    type: UserActivityTypeEnum.Expiry,
  },
  {
    description: translate("userActivityTypes.RangeIPAddress.description"),
    id: UserActivityTypeEnum.RangeIPAddress,
    key: "RangeIPAddress",
    name: translate("userActivityTypes.RangeIPAddress.name"),
    policy: findPolicyByType(PolicyTypeEnum.RangeIPAddress),
    type: UserActivityTypeEnum.RangeIPAddress,
  },
  {
    description: translate(
      "userActivityTypes.FileAccessApplication.description",
    ),
    id: UserActivityTypeEnum.FileAccessApplication,
    key: "FileAccessApplication",
    name: translate("userActivityTypes.FileAccessApplication.name"),
    policy: findPolicyByType(PolicyTypeEnum.FileAccessApplication),
    type: UserActivityTypeEnum.FileAccessApplication,
  },
  {
    description: translate("userActivityTypes.WindowsAccess.description"),
    id: UserActivityTypeEnum.WindowsAccess,
    key: "WindowsAccess",
    name: translate("userActivityTypes.WindowsAccess.name"),
    policy: findPolicyByType(PolicyTypeEnum.WindowsAccess),
    type: UserActivityTypeEnum.WindowsAccess,
  },
  {
    description: translate("userActivityTypes.ExternalDrives.description"),
    id: UserActivityTypeEnum.ExternalDrives,
    key: "ExternalDrives",
    name: translate("userActivityTypes.ExternalDrives.name"),
    policy: findPolicyByType(PolicyTypeEnum.ExternalDrives),
    type: UserActivityTypeEnum.ExternalDrives,
  },
  {
    description: translate("userActivityTypes.AccessFolders.description"),
    id: UserActivityTypeEnum.AccessFolders,
    key: "AccessFolders",
    name: translate("userActivityTypes.AccessFolders.name"),
    policy: findPolicyByType(PolicyTypeEnum.AccessFolders),
    type: UserActivityTypeEnum.AccessFolders,
  },
  {
    description: translate("userActivityTypes.ProxyControl.description"),
    id: UserActivityTypeEnum.ProxyControl,
    key: "ProxyControl",
    name: translate("userActivityTypes.ProxyControl.name"),
    policy: findPolicyByType(PolicyTypeEnum.ProxyControl),
    type: UserActivityTypeEnum.ProxyControl,
  },
  {
    description: translate("userActivityTypes.EncryptionFile.description"),
    id: UserActivityTypeEnum.EncryptionFile,
    key: "EncryptionFile",
    name: translate("userActivityTypes.EncryptionFile.name"),
    policy: findPolicyByType(PolicyTypeEnum.EncryptionFile),
    type: UserActivityTypeEnum.EncryptionFile,
  },
  {
    description: translate("userActivityTypes.AntiPhishing.description"),
    id: UserActivityTypeEnum.AntiPhishing,
    key: "AntiPhishing",
    name: translate("userActivityTypes.AntiPhishing.name"),
    policy: findPolicyByType(PolicyTypeEnum.AntiPhishing),
    type: UserActivityTypeEnum.AntiPhishing,
  },
];
