import TimespanOption, {
  TimespanOptionKey,
} from "../interfaces/TimespanOption";

import { translate } from "./translation";

export const TimespanOptions: Record<TimespanOptionKey, TimespanOption> = {
  [TimespanOptionKey.LastHour]: {
    key: TimespanOptionKey.LastHour,
    label: translate("time.last1Hour"),
  },
  [TimespanOptionKey.LastSixHours]: {
    key: TimespanOptionKey.LastSixHours,
    label: translate("time.last6Hours"),
  },
  [TimespanOptionKey.LastTwentyFourHours]: {
    key: TimespanOptionKey.LastTwentyFourHours,
    label: translate("time.last24Hours"),
  },
  [TimespanOptionKey.LastWeek]: {
    key: TimespanOptionKey.LastWeek,
    label: translate("time.lastWeek"),
  },
  [TimespanOptionKey.LastTwoWeeks]: {
    key: TimespanOptionKey.LastTwoWeeks,
    label: translate("time.last2Weeks"),
  },
  [TimespanOptionKey.LastMonth]: {
    key: TimespanOptionKey.LastMonth,
    label: translate("time.lastMonth"),
  },
  [TimespanOptionKey.Custom]: {
    key: TimespanOptionKey.Custom,
    label: translate("common.custom"),
  },
};
