import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "../locales/en.json";
import it from "../locales/it.json";

i18n.use(initReactI18next).init({
  detection: {
    order: ["cookie", "localStorage", "sessionStorage", "navigator", "htmlTag"],
  },
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
  resources: {
    en: {
      translation: en,
    },
    it: {
      translation: it,
    },
  },
});

export default i18n;
