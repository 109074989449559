import { SortDescriptor } from "@react-types/shared";
import qs from "qs";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDateFormatter } from "react-aria";
import { useTranslation } from "react-i18next";
import type { Selection } from "react-stately";
import { useOverlayTriggerState } from "react-stately";

import { NotificationTypes } from "../contexts/NotificationContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import Computer from "../interfaces/Computer";
import SoftwareActivity from "../interfaces/SoftwareActivity";
import SoftwareActivityType from "../interfaces/SoftwareActivityType";
import { TimespanOptionKey } from "../interfaces/TimespanOption";
import { ActivityService } from "../services/activityService";
import { softwareActivityTypes } from "../utils/softwareActivityTypes";
import { getStorageItem, storeStorageItem } from "../utils/storage";
import {
  convertDateToTicks,
  getTimestampOptionDates,
  transformObjectArraysToArrayOfIds,
} from "../utils/utils";

import { useNotifications } from "./useNotifications";
import useTooltip from "./useTooltip";

const activityService = new ActivityService();

interface FilterState {
  activityType?: SoftwareActivityType[];
  computerID?: Computer[];
  count: number;
  dateEnd: number;
  dateStart: number;
  offset: number;
  search?: string;
  sort?: SortDescriptor;
  timespanOption: TimespanOptionKey;
}

function useSoftwareActivitiesTab() {
  const { closeRightPanel } = useContext(
    RightPanelContext,
  ) as RightPanelContextProps;
  const [activities, setActivities] = useState<SoftwareActivity[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]));
  const [selectedActivity, setSelectedActivity] = useState<SoftwareActivity>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const initialFilters: FilterState = (() => {
    const storedFilters = getStorageItem(
      "sessionStorage",
      "softwareActivitiesFilters",
    ) as FilterState;

    const { from, to } = getTimestampOptionDates(
      storedFilters?.timespanOption || TimespanOptionKey.LastTwentyFourHours,
    );

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const filters: Partial<FilterState> = storedFilters || {
      activityType: [],
      computerID: [],
      count: 15,
      dateEnd: convertDateToTicks(to),
      dateStart: convertDateToTicks(from),
      offset: 0,
      search: undefined,
      severity: [],
      timespanOption: TimespanOptionKey.LastTwentyFourHours,
    };

    if (
      storedFilters?.dateStart &&
      storedFilters?.dateEnd &&
      storedFilters.timespanOption !== "custom"
    ) {
      filters.dateStart = convertDateToTicks(from);
      filters.dateEnd = convertDateToTicks(to);
    }

    if (queryParams.activityType) {
      if (
        Array.isArray(queryParams.activityType) &&
        queryParams.activityType.length > 0
      ) {
        filters.activityType = softwareActivityTypes.filter((activityType) => {
          if (typeof queryParams.activityType === "string") {
            return queryParams.activityType.includes(
              activityType?.id.toString() || "",
            );
          } else if (Array.isArray(queryParams.activityType)) {
            return (queryParams.activityType as string[]).includes(
              activityType?.id.toString() || "",
            );
          }
          return false;
        });
      } else {
        const foundActivityType = softwareActivityTypes.find((activityType) => {
          return activityType?.id.toString() === queryParams.activityType;
        });
        if (foundActivityType) {
          filters.activityType = [foundActivityType];
        }
      }
    }

    return filters as FilterState;
  })();
  const [filters, setFilters] = useState<FilterState>(initialFilters);

  const detailModalState = useOverlayTriggerState({});
  const { t } = useTranslation();
  const { showTooltip, hideTooltip } = useTooltip();
  const { createNotification } = useNotifications();
  const dateFormatter = useDateFormatter({
    dateStyle: "medium",
    timeStyle: "short",
  });

  /**
   * @desc Fetch users
   */
  const getSoftwareActivities = useCallback(async () => {
    setIsLoading(true);
    const updatedFilters = structuredClone(filters);

    // Update the date with the latest moments
    if (filters.timespanOption !== TimespanOptionKey.Custom) {
      const { from, to } = getTimestampOptionDates(filters.timespanOption);
      updatedFilters.dateEnd = convertDateToTicks(to);
      updatedFilters.dateStart = convertDateToTicks(from);
    }

    const transformedFilters =
      transformObjectArraysToArrayOfIds(updatedFilters);
    if (transformedFilters.sort !== undefined) {
      transformedFilters.sortCol = filters.sort?.column;
      transformedFilters.sortDir = filters.sort?.direction;
      delete transformedFilters.sort;
    } else {
      transformedFilters.sortCol = "createdOn";
      transformedFilters.sortDir = "descending";
    }

    activityService
      .getSoftwareActivity(
        qs.stringify(transformedFilters, {
          filter: (prefix, value) => (value === "" ? undefined : value),
          skipNulls: true,
        }),
      )
      .then((res) => {
        setActivities(res.data);
        setTotal(res.total);
      })
      .catch(() => {
        createNotification(
          t("errors.genericFetchError", {
            resource: t("common.activities"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createNotification, filters, t]);

  /**
   * Handles the click event on a detail element.
   *
   * @param {UserActivity} element - The user activity element clicked.
   *
   * @returns {void}
   */
  const handleDetailClick = (element: SoftwareActivity): void => {
    setSelectedActivity(element);
    detailModalState.open();
  };

  /**
   * Resets the advanced filters by clearing the values for the activityType, computerID, groupComputerID, policyID, and userID properties in the filters state.
   * @function
   * @name handleResetAdvancedFilters
   * @return {void}
   */
  const handleResetAdvancedFilters = (): void => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      activityType: [],
      computerID: [],
      offset: 0,
    }));
  };

  useEffect(() => {
    getSoftwareActivities().then(() => {});
  }, [getSoftwareActivities]);

  useEffect((): void => {
    if (selectedKeys instanceof Set && selectedKeys.size > 0) {
      closeRightPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys]);

  /**
   * Calculates the count of applied filters.
   *
   * @function
   * @returns {number} The count of applied filters.
   */
  const appliedFiltersCount = useMemo(() => {
    let count = 0;
    Object.entries(filters).forEach(([, value]) => {
      if (Array.isArray(value)) {
        count += value.length;
      }
    });
    return count;
  }, [filters]);

  useEffect(() => {
    storeStorageItem("sessionStorage", "softwareActivitiesFilters", filters);
  }, [filters]);

  return {
    activities,
    appliedFiltersCount,
    dateFormatter,
    detailModalState,
    filters,
    handleDetailClick,
    handleResetAdvancedFilters,
    hideTooltip,
    isLoading,
    selectedActivity,
    selectedKeys,
    setFilters,
    setSelectedKeys,
    showTooltip,
    t,
    total,
  };
}

export default useSoftwareActivitiesTab;
