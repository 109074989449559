import { APIService, RequestBody } from "../api/apiService";

export class AccountService extends APIService {
  /**
   * Retrieves data from the server.
   *
   * @returns {Promise} A Promise that resolves to the data retrieved from the server.
   */
  getData() {
    return this.request("/Admin/Account/Data", "GET");
  }

  /**
   * Retrieves data from the server.
   *
   * @returns {Promise} A Promise that resolves to the data retrieved from the server.
   */
  getDashboardData(query: string) {
    return this.request(`/Admin/Account/Dashboard?${query}`, "GET");
  }

  /**
   * Retrieves data from the server.
   *
   * @returns {Promise} A Promise that resolves to the data retrieved from the server.
   */
  getComputers(query: string) {
    return this.request(`/Admin/Account/Computer?${query}`, "GET");
  }

  /**
   * Retrieves data from the server.
   *
   * @returns {Promise} A Promise that resolves to the data retrieved from the server.
   */
  getNotificationSettings() {
    return this.request("/Admin/Account/NotifyActivity", "GET");
  }

  /**
   * Retrieves the license information for the user account.
   *
   * @returns {Promise} A promise that resolves with the license information.
   */
  getLicense() {
    return this.request("/Admin/Account/License", "GET");
  }

  /**
   * Retrieves setup agent information from the server.
   *
   * @returns {Promise<Object>} A promise that resolves to an object containing setup information.
   */
  getSetupAgent() {
    return this.request("/Admin/Account/Setup", "GET");
  }

  /**
   * Changes the data of the user account.
   *
   * @param {Object} payload - The payload containing the username and password.
   * @param {string} payload.username - The new username.
   * @param {string} payload.password - The new password.
   * @return {Promise} A promise that resolves with the result of the request.
   */
  changeData(payload: RequestBody) {
    return this.request("/Admin/Account/ChangeData", "PUT", payload);
  }

  /**
   * Sets the avatar image for the user's account.
   *
   * @param {Object} payload - The payload containing the avatar image data.
   * @param {string} payload.base64 - The base64 encoded string representation of the avatar image.
   * @return {Promise} A promise that resolves with the result of the request.
   */
  setAvatar(payload: string) {
    return this.request("/Admin/Account/SetAvatar", "PUT", payload);
  }

  /**
   * Retrieves data from the server.
   *
   * @returns {Promise} A Promise that resolves to the data retrieved from the server.
   */
  updateNotificationSettings(payload: RequestBody) {
    return this.request("/Admin/Account/NotifyActivity", "PUT", payload);
  }

  /**
   * Changes the user's password.
   *
   * @param {Object} payload - The payload object containing the oldPassword and newPassword.
   * @param {string} payload.oldPassword - The current password of the user.
   * @param {string} payload.newPassword - The new password to be set for the user.
   * @returns {Promise} - A promise that resolves when the password change request is successful.
   */
  changePassword(payload: { oldPassword: string; newPassword: string }) {
    return this.request("/Admin/Account/ChangePassword", "POST", payload);
  }

  /**
   * Generates an authenticator code.
   * This method requests a POST operation to the "/Admin/Account/GenAppAuthCode" endpoint and returns the result.
   *
   * @returns {Promise<Response>} A promise that resolves to the response of the operation.
   */
  generateAuthenticatorCode() {
    return this.request("/Admin/Account/GenAppAuthCode", "POST");
  }

  /**
   * Verifies the authenticator code for the admin account.
   * @param {object} payload - The payload containing the OTP (One-Time Password) to verify.
   * @param {string} payload.otp - The OTP to verify.
   * @return {Promise} - A promise that resolves with the verification result.
   */
  verifyAuthenticatorCode(payload: { otp: string }) {
    return this.request("/Admin/Account/VerifyAppAuthCode", "POST", payload);
  }

  /**
   * Changes the status of the authenticator.
   *
   * @param {boolean} enable - Whether to enable or disable the authenticator.
   *
   * @returns {Promise} A promise that resolves when the status of the authenticator is changed successfully.
   */
  changeAuthenticatorStatus(enable: boolean) {
    return this.request(
      `/Admin/Account/StatusAppAuthCode?enable=${enable}`,
      "PUT",
    );
  }

  /**
   * Updates the agent status.
   *
   * @param {boolean} enable - Whether to enable or disable the agent.
   * @returns {Promise} A Promise that resolves when the agent status is updated successfully.
   */
  updateAgent(enable: boolean) {
    return this.request(`/Admin/Account/AgentUpdate?enable=${enable}`, "PUT");
  }

  /**
   * Updates the agent notify status.
   *
   * @param {boolean} enable - Whether to enable or disable the agent.
   * @returns {Promise} A Promise that resolves when the agent status is updated successfully.
   */
  updateAgentNotify(enable: boolean) {
    return this.request(`/Admin/Account/AgentNotify?enable=${enable}`, "PUT");
  }

  /**
   * Removes the authenticator for the account.
   *
   * @returns {Promise} A Promise that resolves when the authenticator is successfully removed, or rejects with an error message if the removal fails.
   */
  removeAuthenticator(): Promise<unknown> {
    return this.request("/Admin/Account/AppAuthCode", "DELETE");
  }
}
