import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useNotifications } from "../hooks/useNotifications";
import { GenericService } from "../services/genericService";
import { getStorageItem, storeStorageItem } from "../utils/storage";

import { NotificationTypes } from "./NotificationContext";

interface PendingComputerUpdateContextProps {
  totalErrorUpdates: number;
  hasPendingUpdates: boolean;
  isAlertVisible: boolean;
  isAlertLoading: boolean;
  getPendingErrorComputers: () => Promise<void>;
  forceUpdatePendingComputers: () => Promise<void>;
  setHasPendingUpdates: (hasPendingUpdates: boolean) => void;
}

const defaultValues: PendingComputerUpdateContextProps = {
  forceUpdatePendingComputers: async () => {},
  getPendingErrorComputers: async () => {},
  hasPendingUpdates: false,
  isAlertLoading: false,
  isAlertVisible: false,
  setHasPendingUpdates: () => {},
  totalErrorUpdates: 0,
};

const PendingComputerUpdateContext =
  createContext<PendingComputerUpdateContextProps>(defaultValues);

export const usePendingComputerUpdate = () =>
  useContext(PendingComputerUpdateContext);
const genericService = new GenericService();
const TIMEOUT_DURATION = 5000; // 5 seconds
export const PendingComputerUpdateProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [isAlertLoading, setIsAlertLoading] = useState<boolean>(false);
  const [totalErrorUpdates, setTotalErrorUpdates] = useState(() => {
    const savedState = getStorageItem(
      "sessionStorage",
      "totalPendingComputerUpdates",
    );
    return savedState !== null ? Number(savedState) : 0;
  });
  const [hasPendingUpdates, setHasPendingUpdates] = useState(() => {
    const savedState = getStorageItem(
      "sessionStorage",
      "hasPendingComputerUpdates",
    );
    return Boolean(savedState);
  });

  const { createNotification } = useNotifications();
  const { t } = useTranslation();

  /**
   * Retrieves the pending update computers from the server and updates the total count of pending updates.
   *
   * @returns {void}
   */
  const getPendingErrorComputers = useCallback(async () => {
    try {
      const res = await genericService.getPendingUpdateComputers();
      setTotalErrorUpdates(res.total);
      setIsAlertVisible(res.total > 0);
    } catch (error) {
      setIsAlertLoading(false);
      createNotification(t("errors.genericError"), NotificationTypes.DANGER);
    }
  }, [createNotification, t]);

  /**
   * Updates the pending computers by forcing an update.
   *
   * @callback forceUpdatePendingComputers
   * @async
   * @returns {Promise<void>} Promise that resolves when the pending computers are updated.
   *
   * @throws {Error} If an error occurs while updating the pending computers.
   */
  const forceUpdatePendingComputers = useCallback(async () => {
    setIsAlertLoading(true);
    try {
      await genericService.forceUpdatePendingComputers();
      const timeoutEnd = Date.now() + TIMEOUT_DURATION;
      storeStorageItem("localStorage", "pendingComputerTimeoutEnd", timeoutEnd);
      const remainingTime = timeoutEnd - Date.now();
      if (remainingTime > 0) {
        setTimeout(() => {
          getPendingErrorComputers().then(() => {
            setIsAlertLoading(false);
            setHasPendingUpdates(false);
            storeStorageItem("localStorage", "timeoutEnd", null);
          });
        }, remainingTime);
      }
    } catch (error) {
      createNotification(t("errors.genericError"), NotificationTypes.DANGER);
      setIsAlertLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNotification, getPendingErrorComputers, t, totalErrorUpdates]);

  useEffect(() => {
    storeStorageItem(
      "sessionStorage",
      "totalPendingComputerUpdates",
      totalErrorUpdates,
    );
    setIsAlertVisible(totalErrorUpdates > 0);
  }, [hasPendingUpdates, totalErrorUpdates]);

  useEffect(() => {
    storeStorageItem(
      "sessionStorage",
      "hasPendingComputerUpdates",
      hasPendingUpdates,
    );

    // Se `hasPendingUpdates` è true, avvia un timeout
    if (hasPendingUpdates) {
      const timeoutId: NodeJS.Timeout = setTimeout(async () => {
        // Al termine del timeout, chiama `getPendingErrorComputers`
        await getPendingErrorComputers();
        setIsAlertLoading(false);
        setHasPendingUpdates(false);
      }, TIMEOUT_DURATION);

      return () => clearTimeout(timeoutId);
    }

    // Specifica `hasPendingUpdates` e tutte le funzioni usate dentro useEffect come dipendenze
  }, [hasPendingUpdates, getPendingErrorComputers, setIsAlertLoading]);

  useEffect(() => {
    const timeoutEndString = getStorageItem(
      "localStorage",
      "pendingComputerTimeoutEnd",
    );
    const timeoutEnd = timeoutEndString ? Number(timeoutEndString) : null;

    if (timeoutEnd) {
      const remainingTime = timeoutEnd - Date.now();
      if (remainingTime > 0) {
        setIsAlertLoading(true);
        setHasPendingUpdates(true);
        setTimeout(() => {
          getPendingErrorComputers().then(() => {
            setIsAlertLoading(false);
            setHasPendingUpdates(false);
            storeStorageItem("localStorage", "pendingComputerTimeoutEnd", null);
          });
        }, remainingTime);
      }
    }
  }, [getPendingErrorComputers]);

  return (
    <PendingComputerUpdateContext.Provider
      value={{
        forceUpdatePendingComputers,
        getPendingErrorComputers,
        hasPendingUpdates,
        isAlertLoading,
        isAlertVisible,
        setHasPendingUpdates,
        totalErrorUpdates,
      }}
    >
      {children}
    </PendingComputerUpdateContext.Provider>
  );
};
