export enum ComputerStatus {
  offline = 0,
  locked = 1,
  processing = 2,
  unlocked = 3,
  connected = 4,
}

export interface ComputerStatusDetail {
  background: string;
  description: string;
}

export default interface Computer {
  id: number;
  description?: string;
  createdOn?: string;
  deviceID?: string;
  fullname?: string;
  ipAddress?: string;
  lastAccess?: string;
  name?: string;
  status?: ComputerStatus;
  enable?: boolean;
  operatingSystem?: string;
  blocked?: boolean;
}
