import React, { useEffect, useRef, useState } from "react";

import { PolicyField } from "../../utils/policyFields";
import Button from "../Button";
import { Icon } from "../Icon";
import TextField from "../TextField";

interface SecurityPolicyInputStringListProps {
  onChange: (newValue: string[]) => void;
  value?: string[];
  field: PolicyField;
  isLoading: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  invalidFields: string[];
}

export const SecurityPolicyInputStringList: React.FC<
  SecurityPolicyInputStringListProps
> = ({
  onChange,
  value = [],
  field,
  isLoading,
  isDisabled,
  invalidFields,
  isReadOnly,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const lastTextFieldRef = useRef<HTMLInputElement>(null);
  const newTextFieldRef = useRef<HTMLInputElement>(null);

  /**
   * Changes the value of the input field based on the new value provided.
   *
   * @param {string} newValue - The new value to set for the input field.
   */
  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      onChange([...value, newValue]);
      setInputValue("");
    }, 300);
    setTimer(newTimer);
  };

  /**
   * Handles adding the input value to an array of values.
   * If the input value is not empty, it adds the input value to the array of values
   * and triggers the onChange callback with the updated array of values.
   * It also resets the input value to an empty string.
   *
   * @function
   * @name handleAddString
   * @returns {void}
   */
  const handleAddString = (): void => {
    onChange([...value, inputValue]);
    setInputValue("");
  };

  /**
   * Removes a string from the value array at the specified index.
   *
   * @param {number} index - The index of the string to be removed.
   * @returns {void}
   */
  const handleRemoveString = (index: number): void => {
    const updatedValue = value.filter((_, idx) => idx !== index);
    onChange(updatedValue);
    newTextFieldRef.current?.focus();
  };

  /**
   * Handles key press event for input field.
   *
   * @param {React.KeyboardEvent<HTMLInputElement>} event - The key press event object.
   * @returns {void}
   */
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (inputValue.trim() !== "") {
        handleAddString();
      }
    }
  };

  /**
   * Updates the value at the given index with the new value.
   *
   * @param {number} index - The index of the value to be updated.
   * @param {string} newValue - The new value to replace the existing value.
   * @returns {void}
   */
  const handleUpdate = (index: number, newValue: string): void => {
    const updatedValue = [...value];
    updatedValue[index] = newValue;
    if (newValue.trim() === "") {
      handleRemoveString(index);
    } else {
      onChange(updatedValue);
    }
  };

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  useEffect(() => {
    if (value.length > 0 && lastTextFieldRef.current) {
      lastTextFieldRef.current.focus();
    }
  }, [value.length]);

  return (
    <div className="flex w-full flex-col gap-2">
      {value.map((str, index) => (
        <div key={index} className="flex w-full flex-row items-center gap-2">
          <TextField
            isReadOnly={isReadOnly}
            id={`${field.id}-${index}`}
            name={field.name}
            type={field.type}
            label={field.label}
            value={str}
            onChange={(e) => handleUpdate(index, e)}
            //pattern={field?.pattern?.source}
            isInvalid={invalidFields?.includes(`${field.id}-${index}`)}
            isDisabled={isLoading}
            validationBehavior="native"
            title={field.title}
            ref={index === value.length - 1 ? lastTextFieldRef : null}
          />
          <div>
            <Button
              noPadding
              type="button"
              variant="text"
              isDisabled={isLoading || field.readOnly || isReadOnly}
              onPress={() => handleRemoveString(index)}
            >
              <Icon name="RemoveIcon" className="h-6 w-6 text-red" />
            </Button>
          </div>
        </div>
      ))}
      <div className="flex flex-row items-center gap-2">
        <TextField
          isReadOnly={isReadOnly}
          id={`${field.id}-new`}
          ref={newTextFieldRef}
          name={field.name}
          type={field.type}
          label={field.label}
          value={inputValue}
          isDisabled={isLoading || isDisabled}
          //pattern={field?.pattern?.source}
          onChange={(e) => handleInputChange(e)}
          onKeyDown={handleKeyPress}
          title={field.title}
          validationBehavior="native"
        />
        <div>
          <Button
            noPadding
            type="button"
            variant="text"
            isDisabled={isLoading || isDisabled || isReadOnly}
            onPress={handleAddString}
          >
            <Icon name="AddIcon" className="h-6 w-6 text-dark-blue" />
          </Button>
        </div>
      </div>
    </div>
  );
};
