import clsx from "clsx";
import React, { useRef } from "react";
import type { AriaCheckboxProps } from "react-aria";
import { useCheckbox } from "react-aria";
import { useToggleState } from "react-stately";

interface CheckboxProps extends AriaCheckboxProps {
  children?: React.ReactNode;
  onChange?: (checked: boolean) => void;
  size?: "normal" | "large";
}

function Checkbox(props: CheckboxProps) {
  const { children, onChange, size = "normal" } = props;
  const state = useToggleState(props);
  const ref = useRef<HTMLInputElement>(null);

  const { inputProps } = useCheckbox(
    {
      ...props,
      onChange: (isChecked: boolean) => {
        if (onChange) {
          onChange(isChecked);
        }
      },
    },
    state,
    ref,
  );

  const sizeStyles = {
    large: "h-[32px] w-[32px] rounded-md",
    normal: "h-[16px] w-[16px] rounded-sm text-xs",
  };

  return (
    <label
      className={clsx(
        "flex cursor-pointer items-center gap-2 ",
        props.isDisabled && "opacity-50",
        props.isReadOnly
          ? "cursor-not-allowed text-dark-gray opacity-50"
          : "cursor-pointer text-dark-blue",
      )}
    >
      <div
        className={clsx(
          "relative flex  cursor-pointer items-center justify-center overflow-hidden border transition-all duration-200 hover:opacity-90",

          state.isSelected
            ? "border-dark-blue bg-dark-blue"
            : "border-gray bg-white",
          sizeStyles[size],
          props.isDisabled && "border-dark-gray bg-dark-gray opacity-50",
        )}
      >
        <input
          {...inputProps}
          ref={ref}
          className="absolute inset-0 z-10 cursor-pointer appearance-none opacity-0"
        />
        {state.isSelected && <span className="z-0 text-white">✓</span>}
      </div>
      {children && <p>{children}</p>}
    </label>
  );
}

export default Checkbox;
