import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../contexts/AuthContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { UserLicense } from "../interfaces/UserLicense";
import { AccountService } from "../services/accountService";
import { AuthService } from "../services/authService";

import { useNotifications } from "./useNotifications";
import useTooltip from "./useTooltip";

const authService = new AuthService();
const accountService = new AccountService();

function useConfigurationSettings() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userLicense, setUserLicense] = useState<UserLicense>({
    agent: {
      notify: false,
      update: false,
    },
    apiKey: "",
    companyName: "",
    createdOn: "",
    expiry: "",
    id: 0,
    licenseKey: "",
    maxComputers: 0,
    maxUsers: 0,
    notify: {
      emails: [],
      violationActivity: 0,
    },
  });
  const { createNotification } = useNotifications();
  const { t } = useTranslation();
  const { isCurrentUserReadOnly } = useAuth();
  const { showTooltip, hideTooltip } = useTooltip();

  /**
   * A custom hook that retrieves the user's license from the account service.
   * It sets the user's license using the setUserLicense function and handles error notification and loading state.
   *
   * @returns {Function} The callback function that can be used to retrieve the user's license.
   */
  const getLicense = useCallback(() => {
    setIsLoading(true);
    accountService
      .getLicense()
      .then((response) => {
        setUserLicense(response);
      })
      .catch(() => {
        createNotification(t("errors.genericError"), NotificationTypes.DANGER);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createNotification, t]);

  /**
   * Updates the agent status.
   *
   * @param {boolean} enable - The new status of the agent.
   * @returns {void} - No return value.
   */
  const updateAgent = (enable: boolean): void => {
    setIsLoading(true);
    accountService
      .updateAgent(enable)
      .then(() => {
        createNotification(
          t("notifications.genericUpdateSuccess", { resource: "Setting" }),
          NotificationTypes.SUCCESS,
        );
      })
      .catch(() => {
        createNotification(t("errors.genericError"), NotificationTypes.DANGER);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateAgentNotify = (enable: boolean): void => {
    setIsLoading(true);
    accountService
      .updateAgentNotify(enable)
      .then(() => {
        createNotification(
          t("notifications.genericUpdateSuccess", { resource: "Setting" }),
          NotificationTypes.SUCCESS,
        );
      })
      .catch(() => {
        createNotification(t("errors.genericError"), NotificationTypes.DANGER);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Updates the user's license form field with the provided value.
   * If the field is not recognized, no action is taken.
   *
   * @param {string} field - The name of the field to update.
   * @param {any} value - The new value for the field.
   * @returns {void}
   */
  const handleInputChange = (field: string, value: any): void => {
    switch (field) {
      case "agent.update":
        updateAgent(value);
        setUserLicense((prevForm) => ({
          ...prevForm,
          agent: { ...prevForm.agent, update: value },
        }));
        break;
      case "agent.notify":
        updateAgentNotify(value);
        setUserLicense((prevForm) => ({
          ...prevForm,
          agent: { ...prevForm.agent, notify: value },
        }));
        break;
      default:
        setUserLicense((prevForm) => ({ ...prevForm, [field]: value }));
        break;
    }
  };

  /**
   * Function to handle the download of a configuration file from the authentication service.
   * It creates a blob from the response data and creates a download link for the user to download the file.
   * If an error occurs during the download, a danger notification is displayed.
   * Finally, it sets the isLoading state to false.
   *
   * @returns {void}
   */
  const handleDownloadConfigurationFile = (): void => {
    setIsLoading(true);
    authService
      .agentConfiguration()
      .then((response) => {
        response.blob().then((blob: Blob | MediaSource) => {
          const filename = "remotegrant.config";
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      })
      .catch(() => {
        createNotification(t("errors.genericError"), NotificationTypes.DANGER);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Handles the download setup agent functionality.
   * @returns {void}
   */
  const handleDownloadSetupAgent = (): void => {
    setIsLoading(true);
    accountService
      .getSetupAgent()
      .then((response) => {
        response.blob().then((blob: Blob | MediaSource) => {
          const filename = "Remotegrant.exe";
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          setIsLoading(false);
        });
      })
      .catch(() => {
        createNotification(t("errors.genericError"), NotificationTypes.DANGER);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getLicense();
  }, [getLicense]);

  return {
    handleDownloadConfigurationFile,
    handleDownloadSetupAgent,
    handleInputChange,
    hideTooltip,
    isCurrentUserReadOnly,
    isLoading,
    showTooltip,
    t,
    userLicense,
  };
}

export default useConfigurationSettings;
