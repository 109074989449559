import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { NotificationTypes } from "../contexts/NotificationContext";
import Language from "../interfaces/Language";
import { AccountService } from "../services/accountService";
import { languages } from "../utils/languages";

import { useNotifications } from "./useNotifications";

interface FormState {
  avatar?: string;
  username: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  language: string;
}

type FormField = {
  id: keyof FormState;
  name: string;
  type?: "text" | "number" | "file" | "email" | "tel";
  required?: boolean;
  label?: string;
  placeholder?: string;
  pattern?: string;
  title?: string;
};

const accountService = new AccountService();

function useProfileSettings() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [form, setForm] = useState<FormState>({
    avatar: "",
    email: "",
    language: "en",
    name: "",
    phone: "",
    surname: "",
    username: "",
  });

  const fields: FormField[] = [
    /*{
      id: "username",
      label: t("common.usernameEmailFormat"),
      name: "username",
      pattern: "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$",
      placeholder: t("common.usernameEmailFormat"),
      required: true,
      title: t("validation.username"),
      type: "email",
    },*/
    {
      id: "name",
      label: t("common.name"),
      name: "name",
      placeholder: "Name",
      required: true,
      type: "text",
    },
    {
      id: "surname",
      label: t("common.surname"),
      name: "surname",
      placeholder: t("common.surname"),
      required: true,
      type: "text",
    },
    /*{
      id: "email",
      label: t("common.email"),
      name: "email",
      placeholder: t("common.email"),
      required: true,
      type: "email",
    },*/
    /*{
      id: "phone",
      label: t("common.phone"),
      name: "phone",
      placeholder: t("common.phone"),
      required: true,
      type: "tel",
    },*/
  ];
  const [fieldEditing, setFieldEditing] = useState<string | null>(null);
  const { createNotification } = useNotifications();

  /**
   * Retrieves a list of computers based on provided filters.
   *
   * @returns {Promise<void>} A promise that resolves when the computer retrieval is complete.
   *
   * @throws {Error} If there is an error while retrieving the computers.
   */
  const getData = useCallback(async () => {
    setIsLoading(true);
    accountService
      .getData()
      .then((res) => {
        setForm(res);
      })
      .catch(() => {
        createNotification(
          t("errors.genericFetchError", {
            resource: t("common.profile"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createNotification, t]);

  /**
   * Handle form submission
   *
   * @param {FormEvent} e - The event object generated by the form submission
   * @returns {Promise<void>} - A promise that resolves once the form submission is complete
   */
  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    setIsLoading(true);
    accountService
      .changeData({ ...form })
      .then(() => {
        createNotification(
          t("notifications.genericUpdateSuccess", {
            resource: t("common.profile"),
          }),
          NotificationTypes.SUCCESS,
        );
        setFieldEditing(null);
      })
      .catch(() => {
        createNotification(
          t("errors.genericUpdateError", {
            resource: t("common.profile"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Updates the avatar for the user.
   *
   * @async
   * @param {string} value - The new avatar value.
   * @returns {Promise<void>} - A Promise that resolves once the avatar is successfully updated.
   */
  const onAvatarChange = async (value: string) => {
    setIsLoading(true);
    setForm({ ...form, avatar: value });
    accountService
      .setAvatar(value)
      .then(() => {
        createNotification(
          t("notifications.genericUpdateSuccess", {
            resource: t("common.photo"),
          }),
          NotificationTypes.SUCCESS,
        );
        setFieldEditing(null);
      })
      .catch(() => {
        createNotification(
          t("errors.genericUpdateError", {
            resource: t("common.photo"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Represents the list of available languages.
   *
   * @type {Array}
   */
  const availableLanguages: Language[] = useMemo(() => {
    return languages.filter((language) => ["en", "it"].includes(language.key));
  }, []);

  useEffect(() => {
    getData().then(() => {});
  }, [getData]);

  return {
    availableLanguages,
    fieldEditing,
    fields,
    form,
    isLoading,
    onAvatarChange,
    onSubmit,
    setFieldEditing,
    setForm,
    t,
  };
}

export default useProfileSettings;
