export enum UserActivityTypeEnum {
  DeleteFile = 1000,
  DownloadFile = 1001,
  Network = 1002,
  UploadNewFileExe = 1003,
  Country = 1004,
  DateTimePlan = 1005,
  Expiry = 1006,
  RangeIPAddress = 1007,
  FileAccessApplication = 1008,
  WindowsAccess = 1009,
  ExternalDrives = 1010,
  AccessFolders = 1011,
  ProxyControl = 1012,
  EncryptionFile = 1013,
  AntiPhishing = 1014,
}
