import { MultiselectComboboxOption } from "../components/MultiSelectComboBox";
import { OptionWizardNetworkEnum } from "../enum/OptionWizardNetworkEnum";
import { PolicyTypeEnum } from "../enum/PolicyTypeEnum";

import { countries } from "./countries";
import { directions } from "./directions";
import { specialFolders } from "./specialFolders";
import { translate } from "./translation";

export type PolicyField = {
  id: string;
  name: string;
  type?:
    | "text"
    | "number"
    | "checkbox"
    | "select"
    | "date"
    | "time"
    | "divider";
  inputType:
    | "textfield"
    | "select"
    | "combobox"
    | "numberfield"
    | "numbertuple"
    | "timespan"
    | "checkbox"
    | "stringlist"
    | "datepicker"
    | "comboboxlist"
    | "checkboxgroup"
    | "timespantuple"
    | "stringtuple"
    | "comboboxstringtuple"
    | "radiogroup"
    | "multiselect"
    | "divider"
    | "textarea"
    | "label"
    | "comboboxbutton";
  description?: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  initialValue?: string | null | object | any[] | number | boolean;
  pattern?: RegExp;
  title?: string;
  readOnly?: boolean;
  options?: MultiselectComboboxOption[];
  hasOptionExcept?: boolean;
  firstFieldPlaceholder?: string;
  secondFieldPlaceholder?: string;
  minValue?: number;
  maxValue?: number;
  error?: string;
  actionLabel?: string;
  className?: string;
  info?: string;
};

export const policyFields: Partial<Record<PolicyTypeEnum, PolicyField[]>> = {
  [PolicyTypeEnum.BlockPort]: [
    {
      firstFieldPlaceholder: translate("common.fromPort"),
      id: "ports",
      initialValue: "",
      inputType: "numbertuple",
      maxValue: 65535,
      minValue: 1,
      name: "ports",
      required: true,
      secondFieldPlaceholder: translate("common.toPort"),
      type: "number",
    },
    {
      id: "direction",
      inputType: "combobox",
      label: "Direction",
      name: "direction",
      options: directions,
      placeholder: "Select direction",
      required: true,
    },
  ],
  [PolicyTypeEnum.PortRDP]: [
    {
      id: "port",
      inputType: "numberfield",
      label: "Port number",
      maxValue: 65535,
      minValue: 1,
      name: "port",
      required: true,
      type: "number",
    },
  ],
  [PolicyTypeEnum.SessionExpiry]: [
    {
      id: "value",
      inputType: "timespan",
      label: "Interval time",
      name: "value",
      required: true,
      type: "number",
    },
  ],
  [PolicyTypeEnum.Activities]: [
    {
      id: "file",
      inputType: "checkbox",
      label: "File activity",
      name: "file",
      type: "checkbox",
    },
    {
      id: "monitor",
      inputType: "checkbox",
      label: "Screen activity",
      name: "monitor",
      type: "checkbox",
    },
  ],
  [PolicyTypeEnum.DeleteFile]: [
    {
      id: "monitorSpecificFolders",
      inputType: "checkbox",
      label: "Monitor specific folders",
      name: "monitorSpecificFolders",
      type: "checkbox",
    },
    {
      firstFieldPlaceholder: "Special folder",
      hasOptionExcept: false,
      id: "folders",
      inputType: "comboboxstringtuple",
      label: "Absolute path",
      name: "folders",
      options: specialFolders,
      required: false,
      secondFieldPlaceholder: "Folder",
      title: "Special folder must be a valid path",
      type: "text",
    },
    {
      hasOptionExcept: false,
      id: "customFolders",
      inputType: "stringlist",
      label: "Custom folder",
      name: "values",
      required: true,
      type: "text",
    },
    {
      description: "Type of file you want to protect",
      hasOptionExcept: false,
      id: "extensions",
      initialValue: "",
      inputType: "multiselect",
      label: "",
      name: "values",
      options: [],
      placeholder: "File Types",
      required: false,
    },
    {
      hasOptionExcept: false,
      id: "customExtensions",
      inputType: "stringlist",
      label: "Custom file extension",
      name: "values",
      required: true,
      type: "text",
    },
    {
      id: "applicationAuthorized",
      inputType: "label",
      label: "Applications Authorized",
      name: "applicationAuthorized",
    },
    {
      hasOptionExcept: false,
      id: "certificates",
      initialValue: "",
      inputType: "multiselect",
      label: "Apps Certified",
      name: "certificates",
      options: [],
      placeholder: "Apps Certified",
      required: false,
    },
    {
      description: "Exceptions ( None except these )",
      firstFieldPlaceholder: "Application",
      hasOptionExcept: false,
      id: "exceptInclude",
      inputType: "comboboxstringtuple",
      label: "Original Filename",
      name: "exceptInclude",
      options: [],
      required: false,
      secondFieldPlaceholder: "Original Filename",
      title: "Special folder must be a valid path",
      type: "text",
    },
    {
      description: "Exceptions ( All except these )",
      firstFieldPlaceholder: "Application",
      hasOptionExcept: false,
      id: "exceptExclude",
      inputType: "comboboxstringtuple",
      label: "Original Filename",
      name: "exceptExclude",
      options: [],
      required: false,
      secondFieldPlaceholder: "Original Filename",
      title: "Special folder must be a valid path",
      type: "text",
    },
    {
      description: "Custom certificates",
      firstFieldPlaceholder: "Name",
      hasOptionExcept: false,
      id: "customCertificates",
      inputType: "stringtuple",
      label: "Custom certificate",
      name: "customCertificates",
      required: true,
      secondFieldPlaceholder: "Thumbprint",
      type: "text",
    },
    {
      description: "Hashed applications authorized",
      firstFieldPlaceholder: "Name",
      hasOptionExcept: false,
      id: "hashes",
      inputType: "stringtuple",
      label: "File Hash",
      name: "hashes",
      required: true,
      secondFieldPlaceholder: "Hash",
      type: "text",
    },
    {
      id: "modeLabel",
      inputType: "label",
      label: "Mode",
      name: "mode",
    },
    {
      id: "mode",
      inputType: "combobox",
      label: "Mode",
      name: "mode",
      options: [
        { id: "0", value: "Block Action" },
        { id: "1", value: "Monitor Only" },
      ],
      placeholder: "Mode",
      required: true,
    },
    {
      id: "confirm",
      inputType: "checkbox",
      label: "Validate actions with user confirmation",
      name: "confirm",
      type: "checkbox",
    },
    {
      id: "confirmCustomMessage",
      inputType: "checkbox",
      label: "Personalized confirmation message",
      name: "confirmCustomMessage",
      type: "checkbox",
    },
    {
      hasOptionExcept: false,
      id: "confirmMessage",
      inputType: "textarea",
      label: "Confirm message",
      name: "confirmMessage",
      type: "text",
    },
  ],
  /*[PolicyTypeEnum.downloadFile]: [
    {
      id: "allExtensions",
      inputType: "checkbox",
      label: "Block all extensions",
      name: "allExtensions",
      type: "checkbox",
    },
    {
      id: "extensions",
      inputType: "stringlist",
      label: "Extension",
      name: "extensions",
      pattern: "^.\\w+$",
      required: true,
      title: "Extension must start with a dot (.)",
      type: "text",
    },
  ],*/
  [PolicyTypeEnum.Network]: [
    {
      actionLabel: "Import",
      id: "wizardType",
      inputType: "comboboxbutton",
      label: "Import predefined rules",
      name: "wizardType",
      options: [{ id: OptionWizardNetworkEnum.Github, value: "Github" }],
      placeholder: "Mode",
      required: true,
    },
    {
      description: " ",
      hasOptionExcept: true,
      id: "domains",
      inputType: "stringlist",
      label: translate("common.urlContent"),
      name: "domains",
      pattern: /^[a-zA-Z0-9.#@=*+%\\/&,:?[\]-]+$/,
      required: true,
      title:
        "URL Path Segment (at least 2 characters, includes typical URL characters)",
      type: "text",
    },
  ],
  [PolicyTypeEnum.Obfuscator]: [
    {
      id: "complete",
      inputType: "checkbox",
      label: "Any data",
      name: "complete",
      type: "checkbox",
    },
    {
      id: "divider",
      inputType: "divider",
      name: "divider",
      type: "divider",
    },
    {
      id: "email",
      inputType: "checkbox",
      label: "E-mail",
      name: "email",
      type: "checkbox",
    },
    {
      id: "phone",
      inputType: "checkbox",
      label: "Phone number",
      name: "phone",
      type: "checkbox",
    },
    {
      id: "vat",
      inputType: "checkbox",
      label: "VAT",
      name: "vat",
      type: "checkbox",
    },
    {
      id: "creditCard",
      inputType: "checkbox",
      label: "Credit card",
      name: "creditCard",
      type: "checkbox",
    },
    {
      id: "taxCode",
      inputType: "checkbox",
      label: "National Insurance Number",
      name: "taxCode",
      type: "checkbox",
    },
    {
      id: "socialSecurityNumber",
      inputType: "checkbox",
      label: "Social Security Number",
      name: "socialSecurityNumber",
      type: "checkbox",
    },
  ],
  [PolicyTypeEnum.SharedFolder]: [],
  [PolicyTypeEnum.UploadNewFileExe]: [
    {
      id: "monitorSpecificFolders",
      inputType: "checkbox",
      label: "Monitor specific folders",
      name: "monitorSpecificFolders",
      type: "checkbox",
    },
    {
      firstFieldPlaceholder: "Special folder",
      hasOptionExcept: false,
      id: "folders",
      inputType: "comboboxstringtuple",
      label: "Absolute path",
      name: "folders",
      options: specialFolders,
      required: false,
      secondFieldPlaceholder: "Folder",
      title: "Special folder must be a valid path",
      type: "text",
    },
    {
      hasOptionExcept: false,
      id: "customFolders",
      inputType: "stringlist",
      label: "Custom folder",
      name: "values",
      required: true,
      type: "text",
    },
    {
      description: "Type of file you want to protect",
      hasOptionExcept: false,
      id: "extensions",
      initialValue: "",
      inputType: "multiselect",
      label: "",
      name: "values",
      options: [],
      placeholder: "File Types",
      required: false,
    },
    {
      hasOptionExcept: false,
      id: "customExtensions",
      inputType: "stringlist",
      label: "Custom file extension",
      name: "values",
      required: true,
      type: "text",
    },
    {
      id: "applicationAuthorized",
      inputType: "label",
      label: "Applications Authorized",
      name: "applicationAuthorized",
    },
    {
      hasOptionExcept: false,
      id: "certificates",
      initialValue: "",
      inputType: "multiselect",
      label: "Apps Certified",
      name: "certificates",
      options: [],
      placeholder: "Apps Certified",
      required: false,
    },
    {
      description: "Exceptions ( None except these )",
      firstFieldPlaceholder: "Application",
      hasOptionExcept: false,
      id: "exceptInclude",
      inputType: "comboboxstringtuple",
      label: "Original Filename",
      name: "exceptInclude",
      options: [],
      required: false,
      secondFieldPlaceholder: "Original Filename",
      title: "Special folder must be a valid path",
      type: "text",
    },
    {
      description: "Exceptions ( All except these )",
      firstFieldPlaceholder: "Application",
      hasOptionExcept: false,
      id: "exceptExclude",
      inputType: "comboboxstringtuple",
      label: "Original Filename",
      name: "exceptExclude",
      options: [],
      required: false,
      secondFieldPlaceholder: "Original Filename",
      title: "Special folder must be a valid path",
      type: "text",
    },
    {
      description: "Custom certificates",
      firstFieldPlaceholder: "Name",
      hasOptionExcept: false,
      id: "customCertificates",
      inputType: "stringtuple",
      label: "Custom certificate",
      name: "customCertificates",
      required: true,
      secondFieldPlaceholder: "Thumbprint",
      type: "text",
    },
    {
      description: "Hashed applications authorized",
      firstFieldPlaceholder: "Name",
      hasOptionExcept: false,
      id: "hashes",
      inputType: "stringtuple",
      label: "File Hash",
      name: "hashes",
      required: true,
      secondFieldPlaceholder: "Hash",
      type: "text",
    },
    {
      id: "modeLabel",
      inputType: "label",
      label: "Mode",
      name: "mode",
    },
    {
      id: "mode",
      inputType: "combobox",
      label: "Mode",
      name: "mode",
      options: [
        { id: "0", value: "Block Action" },
        { id: "1", value: "Monitor Only" },
      ],
      placeholder: "Mode",
      required: true,
    },
    {
      id: "confirm",
      inputType: "checkbox",
      label: "Validate actions with user confirmation",
      name: "confirm",
      type: "checkbox",
    },
    {
      id: "confirmCustomMessage",
      inputType: "checkbox",
      label: "Personalized confirmation message",
      name: "confirmCustomMessage",
      type: "checkbox",
    },
    {
      hasOptionExcept: false,
      id: "confirmMessage",
      inputType: "textarea",
      label: "Confirm message",
      name: "confirmMessage",
      type: "text",
    },
  ],
  [PolicyTypeEnum.Country]: [
    {
      hasOptionExcept: true,
      id: "values",
      inputType: "comboboxlist",
      label: "Country",
      name: "values",
      options: countries,
      placeholder: "Select country",
      required: true,
    },
  ],
  [PolicyTypeEnum.DateTimePlan]: [
    {
      id: "days",
      inputType: "checkboxgroup",
      label: "Days",
      name: "days",
      options: [
        { id: "0", value: "Sunday" },
        { id: "1", value: "Monday" },
        { id: "2", value: "Tuesday" },
        { id: "3", value: "Wednesday" },
        { id: "4", value: "Thursday" },
        { id: "5", value: "Friday" },
        { id: "6", value: "Saturday" },
      ],
      required: true,
    },
    {
      firstFieldPlaceholder: "From",
      id: "times",
      initialValue: "",
      inputType: "timespantuple",
      name: "times",
      required: true,
      secondFieldPlaceholder: "To",
    },
  ],
  [PolicyTypeEnum.Expiry]: [
    {
      id: "value",
      inputType: "datepicker",
      label: "Date",
      name: "value",
      required: true,
      type: "date",
    },
  ],
  [PolicyTypeEnum.RangeIPAddress]: [
    {
      firstFieldPlaceholder: "From",
      id: "values",
      initialValue: "",
      inputType: "stringtuple",
      label: "IP address",
      name: "values",
      placeholder: "Enter IP address",
      required: true,
      secondFieldPlaceholder: "To",
      title: "IP address must be a valid IPv4 or IPv6 address",
      type: "text",
    },
  ],
  [PolicyTypeEnum.WithoutRDP]: [],
  [PolicyTypeEnum.MultiUsersRDP]: [],
  [PolicyTypeEnum.WindowsAccess]: [
    {
      hasOptionExcept: true,
      id: "username",
      inputType: "stringlist",
      label: "Username",
      name: "username",
      required: true,
      type: "text",
    },
  ],

  [PolicyTypeEnum.FileAccessApplication]: [
    {
      id: "monitorSpecificFolders",
      inputType: "checkbox",
      label: "Monitor specific folders",
      name: "monitorSpecificFolders",
      type: "checkbox",
    },
    {
      firstFieldPlaceholder: "Special folder",
      hasOptionExcept: false,
      id: "folders",
      inputType: "comboboxstringtuple",
      label: "Absolute path",
      name: "folders",
      options: specialFolders,
      required: false,
      secondFieldPlaceholder: "Folder",
      title: "Special folder must be a valid path",
      type: "text",
    },
    {
      hasOptionExcept: false,
      id: "customFolders",
      inputType: "stringlist",
      label: "Custom folder",
      name: "values",
      required: true,
      type: "text",
    },
    {
      description: "Type of file you want to protect",
      hasOptionExcept: false,
      id: "extensions",
      initialValue: "",
      inputType: "multiselect",
      label: "",
      name: "values",
      options: [],
      placeholder: "File Types",
      required: false,
    },
    {
      hasOptionExcept: false,
      id: "customExtensions",
      inputType: "stringlist",
      label: "Custom file extension",
      name: "values",
      required: true,
      type: "text",
    },
    {
      id: "checkFileSignature",
      info: "Supported extensions: .mp3, .ogg, .wav, .flac, .aac, .asf, .wma, .mp4, .mov, .mkv, .webm, .wmv, .mpg, .mpeg, .jpg, .jpeg, .png, .gif, .bmp, .tif, .tiff, .ico, .webp, .pdf, .doc, .dot, .xls, .xlt, .xlm, .ppt, .pot, .pps, .docx, .docm, .dotx, .dotm, .xlsx, .xlsm, .xltx, .xltm, .pptx, .pptm, .potx, .potm, .ppsx, .ppsm, .zip, .apk, .epub, .ipa, .jar, .odp, .ods, .odt, .vsdx, .dwg, .psd",
      inputType: "checkbox",
      label: "Enable check file signature",
      name: "checkFileSignature",
      type: "checkbox",
    },
    {
      id: "applicationAuthorized",
      inputType: "label",
      label: "Applications Authorized",
      name: "applicationAuthorized",
    },
    {
      hasOptionExcept: false,
      id: "certificates",
      initialValue: "",
      inputType: "multiselect",
      label: "Apps Certified",
      name: "certificates",
      options: [],
      placeholder: "Apps Certified",
      required: false,
    },
    {
      description: "Exceptions ( None except these )",
      firstFieldPlaceholder: "Application",
      hasOptionExcept: false,
      id: "exceptInclude",
      inputType: "comboboxstringtuple",
      label: "Original Filename",
      name: "exceptInclude",
      options: [],
      required: false,
      secondFieldPlaceholder: "Original Filename",
      title: "Special folder must be a valid path",
      type: "text",
    },
    {
      description: "Exceptions ( All except these )",
      firstFieldPlaceholder: "Application",
      hasOptionExcept: false,
      id: "exceptExclude",
      inputType: "comboboxstringtuple",
      label: "Original Filename",
      name: "exceptExclude",
      options: [],
      required: false,
      secondFieldPlaceholder: "Original Filename",
      title: "Special folder must be a valid path",
      type: "text",
    },
    {
      description: "Custom certificates",
      firstFieldPlaceholder: "Name",
      hasOptionExcept: false,
      id: "customCertificates",
      inputType: "stringtuple",
      label: "Custom certificate",
      name: "customCertificates",
      required: true,
      secondFieldPlaceholder: "Thumbprint",
      type: "text",
    },
    {
      description: "Hashed applications authorized",
      firstFieldPlaceholder: "Name",
      hasOptionExcept: false,
      id: "hashes",
      inputType: "stringtuple",
      label: "File Hash",
      name: "hashes",
      required: true,
      secondFieldPlaceholder: "Hash",
      type: "text",
    },
    {
      id: "modeLabel",
      inputType: "label",
      label: "Mode",
      name: "mode",
    },
    {
      id: "mode",
      inputType: "combobox",
      label: "Mode",
      name: "mode",
      options: [
        { id: "0", value: "Block Action" },
        { id: "1", value: "Monitor Only" },
      ],
      placeholder: "Mode",
      required: true,
    },
    {
      id: "confirm",
      inputType: "checkbox",
      label: "Validate actions with user confirmation",
      name: "confirm",
      type: "checkbox",
    },
    {
      id: "confirmCustomMessage",
      inputType: "checkbox",
      label: "Personalized confirmation message",
      name: "confirmCustomMessage",
      type: "checkbox",
    },
    {
      hasOptionExcept: false,
      id: "confirmMessage",
      inputType: "textarea",
      label: "Confirm message",
      name: "confirmMessage",
      type: "text",
    },
  ],

  [PolicyTypeEnum.ExternalDrives]: [
    {
      id: "mode",
      inputType: "combobox",
      label: "Mode",
      name: "mode",
      options: [
        { id: "0", value: "Block Action" },
        { id: "1", value: "Monitor Only" },
      ],
      placeholder: "Mode",
      required: true,
    },
    {
      id: "confirm",
      inputType: "checkbox",
      label: "Validate actions with user confirmation",
      name: "confirm",
      type: "checkbox",
    },
    {
      id: "confirmCustomMessage",
      inputType: "checkbox",
      label: "Personalized confirmation message",
      name: "confirmCustomMessage",
      type: "checkbox",
    },
    {
      hasOptionExcept: false,
      id: "confirmMessage",
      inputType: "textarea",
      label: "Confirm message",
      name: "confirmMessage",
      type: "text",
    },
  ],
  [PolicyTypeEnum.AccessFolders]: [
    {
      description: "Special folders",
      firstFieldPlaceholder: "Special folder",
      hasOptionExcept: false,
      id: "folders",
      inputType: "comboboxstringtuple",
      label: "Absolute path",
      name: "folders",
      options: specialFolders,
      pattern: /^[^/:*?"<>|]*$/,
      required: true,
      secondFieldPlaceholder: "Folder",
      title: "Special folder must be a valid path",
      type: "text",
    },
    {
      description: "Absolute Paths",
      hasOptionExcept: false,
      id: "absolutePaths",
      inputType: "stringlist",
      label: "Absolute path",
      name: "absolutePaths",
      pattern:
        /^(?:[a-zA-Z]:\\(?:[^\\/:*?"<>|\r\n]+\\)*[^\\/:*?"<>|\r\n]*|\\\\[a-zA-Z0-9._-]+\\[a-zA-Z0-9._-]+(?:\\[a-zA-Z0-9._-]+)*)$/,
      required: true,
      title: "Absolute path must be a valid path",
      type: "text",
    },
    {
      id: "visible",
      inputType: "checkbox",
      label: "Make folders invisible",
      name: "visible",
      type: "checkbox",
    },
    {
      description: "Access Code Type",
      id: "modeAccessCode",
      inputType: "radiogroup",
      name: "modeAccessCode",
      options: [
        { id: "0", value: "Authenticator App" },
        { id: "1", value: "Manual Code Entry" },
      ],
      placeholder: "Mode",
      required: true,
    },
    {
      id: "accessCode",
      inputType: "textfield",
      label: "Access code",
      name: "accessCode",
      type: "text",
    },
    {
      description: "Auto lock option",
      id: "timeout",
      inputType: "combobox",
      label: "Auto lock option",
      name: "timeout",
      options: [
        { id: "00:00:30", value: "30 Seconds" },
        { id: "00:01:00", value: "1 Minute" },
        { id: "00:05:00", value: "5 Minutes" },
        { id: "00:15:00", value: "15 Minutes" },
        { id: "00:30:00", value: "30 Minutes" },
        { id: "01:00:00", value: "1 Hour" },
        { id: "05:00:00", value: "5 Hours" },
        { id: "15:00:00", value: "15 Hours" },
      ],
      placeholder: "Mode",
      required: true,
    },
  ],
  [PolicyTypeEnum.ProxyControl]: [
    {
      id: "setProxy",
      inputType: "checkbox",
      label: "Set default proxy",
      name: "setProxy",
      type: "checkbox",
    },
    {
      id: "address",
      inputType: "textfield",
      label: "Address",
      name: "address",
      type: "text",
    },
    {
      id: "port",
      inputType: "numberfield",
      label: "Port number",
      maxValue: 65535,
      minValue: 1,
      name: "port",
      required: true,
      type: "number",
    },
    {
      description: " ",
      id: "lockSettings",
      inputType: "checkbox",
      label: "Lock proxy settings",
      name: "lockSettings",
      type: "checkbox",
    },
    {
      id: "allowUnlock",
      inputType: "checkbox",
      label: "Allow temporary unlock",
      name: "allowUnlock",
      type: "checkbox",
    },
    {
      id: "unlockTimeout",
      inputType: "combobox",
      label: "Duration",
      name: "unlockTimeout",
      options: [
        { id: "00:01:00", value: "1 Minute" },
        { id: "00:05:00", value: "5 Minutes" },
        { id: "00:15:00", value: "15 Minutes" },
        { id: "00:30:00", value: "30 Minutes" },
        { id: "01:00:00", value: "1 Hour" },
        { id: "05:00:00", value: "5 Hours" },
        { id: "15:00:00", value: "15 Hours" },
      ],
      placeholder: "Duration",
      required: true,
    },
  ],
  [PolicyTypeEnum.EncryptionFile]: [
    {
      id: "monitorSpecificFolders",
      inputType: "checkbox",
      label: "Monitor specific folders",
      name: "monitorSpecificFolders",
      type: "checkbox",
    },
    {
      firstFieldPlaceholder: "Special folder",
      hasOptionExcept: false,
      id: "folders",
      inputType: "comboboxstringtuple",
      label: "Absolute path",
      name: "folders",
      options: specialFolders,
      required: false,
      secondFieldPlaceholder: "Folder",
      title: "Special folder must be a valid path",
      type: "text",
    },
    {
      hasOptionExcept: false,
      id: "customFolders",
      inputType: "stringlist",
      label: "Custom folder",
      name: "values",
      required: true,
      type: "text",
    },
    {
      description: "Type of file you want to protect",
      hasOptionExcept: false,
      id: "extensions",
      initialValue: "",
      inputType: "multiselect",
      label: "",
      name: "values",
      options: [],
      placeholder: "File Types",
      required: false,
    },
    {
      hasOptionExcept: false,
      id: "customExtensions",
      inputType: "stringlist",
      label: "Custom file extension",
      name: "values",
      required: true,
      type: "text",
    },
    {
      description: "Filegrant Settings",
      id: "apiKey",
      inputType: "textfield",
      label: "API Key",
      name: "apiKey",
      required: true,
      type: "text",
    },
    {
      id: "certificate",
      inputType: "combobox",
      label: "Certificate",
      name: "certificate",
      options: [],
      placeholder: "Certificate",
      required: true,
    },
    {
      id: "accessRule",
      inputType: "combobox",
      label: "Access Rule",
      name: "accessRule",
      options: [],
      placeholder: "Access Rule",
      required: true,
    },
  ],
  [PolicyTypeEnum.SecureBrowser]: [
    {
      hasOptionExcept: true,
      id: "urls",
      inputType: "stringlist",
      label: "URL Content",
      name: "urls",
      required: true,
      type: "text",
    },
    {
      description: "Start-up address",
      id: "address",
      inputType: "textfield",
      label: "Start-up address",
      name: "address",
      type: "text",
    },
    {
      id: "navigationBar",
      inputType: "checkbox",
      label: "Show navigation bar",
      name: "navigationBar",
      type: "checkbox",
    },
    {
      id: "securityOptions",
      inputType: "label",
      label: "Security Options",
      name: "securityOptions",
    },
    {
      id: "blockCookies",
      inputType: "checkbox",
      label: "Block cookies",
      name: "blockCookies",
      type: "checkbox",
    },
    {
      id: "blockClipboard",
      inputType: "checkbox",
      label: "Block use of clipboard",
      name: "blockClipboard",
      type: "checkbox",
    },
    {
      id: "clipboardMode",
      inputType: "combobox",
      label: "Clipboard mode",
      name: "clipboardMode",
      options: [
        { id: "0", value: "Both" },
        { id: "1", value: "Both 1" },
      ],
      placeholder: "Clipboard mode",
      required: true,
    },
    {
      id: "screenCapture",
      inputType: "checkbox",
      label: "Deny screen capture",
      name: "screenCapture",
      type: "checkbox",
    },
    {
      id: "downloadFile",
      inputType: "checkbox",
      label: "Deny file download",
      name: "downloadFile",
      type: "checkbox",
    },
    {
      id: "uploadFile",
      inputType: "checkbox",
      label: "Deny file upload",
      name: "uploadFile",
      type: "checkbox",
    },
    {
      description: "Access Code Type",
      id: "modeAccessCode",
      inputType: "radiogroup",
      name: "modeAccessCode",
      options: [
        { id: "0", value: "Without Authentication" },
        { id: "1", value: "Authenticator App" },
        { id: "2", value: "Manual Code Entry" },
      ],
      placeholder: "Mode",
      required: true,
    },
    {
      id: "accessCode",
      inputType: "textfield",
      label: "Access code",
      name: "accessCode",
      type: "text",
    },
    {
      id: "emails",
      inputType: "stringlist",
      label: "Emails",
      name: "emails",
      required: true,
      type: "text",
    },
    {
      description: "Auto lock option",
      id: "timeout",
      inputType: "combobox",
      label: "Auto lock option",
      name: "timeout",
      options: [
        { id: "00:00:30", value: "30 Seconds" },
        { id: "00:01:00", value: "1 Minute" },
        { id: "00:05:00", value: "5 Minutes" },
        { id: "00:15:00", value: "15 Minutes" },
        { id: "00:30:00", value: "30 Minutes" },
        { id: "01:00:00", value: "1 Hour" },
        { id: "05:00:00", value: "5 Hours" },
        { id: "15:00:00", value: "15 Hours" },
      ],
      placeholder: "Mode",
      required: true,
    },
  ],
  [PolicyTypeEnum.AntiPhishing]: [
    {
      className: "text-medium-red text-sm",
      id: "networkPolicyRequired",
      inputType: "label",
      label:
        "To make this policy work, you need to create a Internet Access Control policy. Be sure to have it enabled.",
      name: "networkPolicyRequired",
    },
    {
      description: "Block website that exceeded the set unreliability score",
      id: "block",
      inputType: "combobox",
      label: "Score",
      name: "block",
      options: [
        { id: "10", value: "10" },
        { id: "20", value: "20" },
        { id: "30", value: "30" },
        { id: "40", value: "40" },
        { id: "50", value: "50" },
        { id: "60", value: "60" },
        { id: "70", value: "70" },
        { id: "80", value: "80" },
        { id: "90", value: "90" },
        { id: "100", value: "100" },
      ],
      placeholder: "Score",
      required: true,
    },
  ],
};
