import ActivitiesMenuIcon from "../assets/icons/activities-menu-icon.svg";
import ActivityIcon from "../assets/icons/activity-icon.svg";
import AddIcon from "../assets/icons/add-icon.svg";
import AllComputersIcon from "../assets/icons/all-computers-icon.svg";
import AllowedIpAddressIcon from "../assets/icons/allowed-ip-address-icon.svg";
import ArrowDropDownIcon from "../assets/icons/arrow-drop-down-icon.svg";
import ArrowRightIcon from "../assets/icons/arrow-right-icon.svg";
import BarcodeBarIcon from "../assets/icons/barcode-bar-icon.svg";
import BrowserIcon from "../assets/icons/browser-icon.svg";
import BugIcon from "../assets/icons/bug-icon.svg";
import CalendarIcon from "../assets/icons/calendar-icon.svg";
import CertificateIcon from "../assets/icons/certificate-icon.svg";
import ChangeRdpPortIcon from "../assets/icons/change-rdp-port-icon.svg";
import CheckmarkRoundedIcon from "../assets/icons/checkmark-rounded-icon.svg";
import CloseIcon from "../assets/icons/close-icon.svg";
import ClosePanelIcon from "../assets/icons/close-panel-icon.svg";
import ComputersMenuIcon from "../assets/icons/computers-menu-icon.svg";
import CopyPasteIcon from "../assets/icons/copy-paste-icon.svg";
import DashboardMenuIcon from "../assets/icons/dashboard-menu-icon.svg";
import DeactivateIcon from "../assets/icons/deactivate-icon.svg";
import DeleteIcon from "../assets/icons/delete-icon.svg";
import DeniedIcon from "../assets/icons/denied-icon.svg";
import DoneIcon from "../assets/icons/done-icon.svg";
import DragIcon from "../assets/icons/drag-icon.svg";
import DriveBlockIcon from "../assets/icons/drive-block-icon.svg";
import EditIcon from "../assets/icons/edit-icon.svg";
import ErrorIcon from "../assets/icons/error-icon.svg";
import ExpiredIcon from "../assets/icons/expired-icon.svg";
import ExpiringSessionIcon from "../assets/icons/expiring-session-icon.svg";
import EyeIcon from "../assets/icons/eye-icon.svg";
import FacingChevronsIcon from "../assets/icons/facing-chevrons-icon.svg";
import FileAccessLimitationIcon from "../assets/icons/file-access-limitation-icon.svg";
import FileDownloadIcon from "../assets/icons/file-download-icon.svg";
import FileIcon from "../assets/icons/file-icon.svg";
import FilegrantLogoIcon from "../assets/icons/filegrant-logo-icon.svg";
import FolderLockIcon from "../assets/icons/folder-lock-icon.svg";
import FunnelIcon from "../assets/icons/funnel-icon.svg";
import GeolocationIcon from "../assets/icons/geolocation-icon.svg";
import GroupByIcon from "../assets/icons/group-by-icon.svg";
import GroupOfComputersIcon from "../assets/icons/group-of-computers-icon.svg";
import GroupOfUsersIcon from "../assets/icons/group-of-users-icon.svg";
import HandRaiseIcon from "../assets/icons/hand-raise-icon.svg";
import HidePasswordIcon from "../assets/icons/hide-password-icon.svg";
import InfoIcon from "../assets/icons/info-icon.svg";
import KeyIcon from "../assets/icons/key-icon.svg";
import MenuIcon from "../assets/icons/menu-icon.svg";
import MultiuserRdpIcon from "../assets/icons/multiuser-rdp-icon.svg";
import NetworkIcon from "../assets/icons/network-icon.svg";
import NotificationsIcon from "../assets/icons/notifications-icon.svg";
import OutlineBellIcon from "../assets/icons/outline-bell-icon.svg";
import OutlineSettingsIcon from "../assets/icons/outline-settings-icon.svg";
import OutlineShieldIcon from "../assets/icons/outline-shield-icon.svg";
import PlayFilledIcon from "../assets/icons/play-filled-icon.svg";
import PlayIcon from "../assets/icons/play-icon.svg";
import PoliciesMenuIcon from "../assets/icons/policies-menu-icon.svg";
import ProxyIcon from "../assets/icons/proxy-icon.svg";
import RemoveIcon from "../assets/icons/remove-icon.svg";
import SearchIcon from "../assets/icons/search-icon.svg";
import SettingsIcon from "../assets/icons/settings-icon.svg";
import SharedFoldersIcon from "../assets/icons/shared-folders-icon.svg";
import SuspendIcon from "../assets/icons/suspend-icon.svg";
import TrashIcon from "../assets/icons/trash-icon.svg";
import UploadExecutableFileIcon from "../assets/icons/upload-executable-file-icon.svg";
import UserIcon from "../assets/icons/user-icon.svg";
import UsersMenuIcon from "../assets/icons/users-menu-icon.svg";
import ViewPasswordIcon from "../assets/icons/view-password-icon.svg";
import WindowsIcon from "../assets/icons/windows-icon.svg";
import WindowsServerIcon from "../assets/icons/windows-server-icon.svg";
import Windows10Icon from "../assets/icons/windows10-icon.svg";
import Windows11Icon from "../assets/icons/windows11-icon.svg";
import Windows7Icon from "../assets/icons/windows7-icon.svg";
import Windows8Icon from "../assets/icons/windows8-icon.svg";

export const iconComponents = {
  ActivitiesMenuIcon,
  ActivityIcon,
  AddIcon,
  AllComputersIcon,
  AllowedIpAddressIcon,
  ArrowDropDownIcon,
  ArrowRightIcon,
  BarcodeBarIcon,
  BrowserIcon,
  BugIcon,
  CalendarIcon,
  CertificateIcon,
  ChangeRdpPortIcon,
  CheckmarkRoundedIcon,
  CloseIcon,
  ClosePanelIcon,
  ComputersMenuIcon,
  CopyPasteIcon,
  DashboardMenuIcon,
  DeactivateIcon,
  DeleteIcon,
  DeniedIcon,
  DoneIcon,
  DragIcon,
  DriveBlockIcon,
  EditIcon,
  ErrorIcon,
  ExpiredIcon,
  ExpiringSessionIcon,
  EyeIcon,
  FacingChevronsIcon,
  FileAccessLimitationIcon,
  FileDownloadIcon,
  FileIcon,
  FilegrantLogoIcon,
  FolderLockIcon,
  FunnelIcon,
  GeolocationIcon,
  GroupByIcon,
  GroupOfComputersIcon,
  GroupOfUsersIcon,
  HandRaiseIcon,
  HidePasswordIcon,
  InfoIcon,
  KeyIcon,
  MenuIcon,
  MultiuserRdpIcon,
  NetworkIcon,
  NotificationsIcon,
  OutlineBellIcon,
  OutlineSettingsIcon,
  OutlineShieldIcon,
  PlayFilledIcon,
  PlayIcon,
  PoliciesMenuIcon,
  ProxyIcon,
  RemoveIcon,
  SearchIcon,
  SettingsIcon,
  SharedFoldersIcon,
  SuspendIcon,
  TrashIcon,
  UploadExecutableFileIcon,
  UserIcon,
  UsersMenuIcon,
  ViewPasswordIcon,
  Windows10Icon,
  Windows11Icon,
  Windows7Icon,
  Windows8Icon,
  WindowsIcon,
  WindowsServerIcon,
};
