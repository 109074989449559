import { SortDescriptor } from "@react-types/shared";
import qs from "qs";
import React, {
  Key,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import GuestComputerDetail from "../components/computer/GuestComputerDetail";
import { useNavigationDirty } from "../contexts/NavigationDirtyContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import Computer from "../interfaces/Computer";
import { AccountService } from "../services/accountService";

import { useNotifications } from "./useNotifications";
import useTooltip from "./useTooltip";

const accountService = new AccountService();

interface FilterState {
  search?: string | null;
  sort?: SortDescriptor;
}

function useGuestComputersTab() {
  const {
    openRightPanel,
    isRightPanelOpen,
    setRightPanelContent,
    setHasRightPanelCloseButton,
  } = useContext(RightPanelContext) as RightPanelContextProps;
  const [computers, setComputers] = useState<Computer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<FilterState>({
    search: null,
  });
  const [activeComputer, setActiveComputer] = useState<Key | null>(null);

  const { t } = useTranslation();
  const { confirmDirtyNavigation } = useNavigationDirty();
  const { showTooltip, hideTooltip } = useTooltip();
  const { createNotification } = useNotifications();

  /**
   * Retrieves a list of computers based on provided filters.
   *
   * @returns {Promise<void>} A promise that resolves when the computer retrieval is complete.
   *
   * @throws {Error} If there is an error while retrieving the computers.
   */
  const getComputers = useCallback(async () => {
    setIsLoading(true);
    accountService
      .getComputers(qs.stringify(filters))
      .then((res) => {
        setComputers(res);
        /*setComputers([
          {
            blocked: false,
            createdOn: "2022-12-28T08:32:38",
            description: "Lorem ipsum dolor sit amet lorem",
            deviceID:
              "7170dcc802cb9b2a97de13444531b81d2867706a36121719e8d793867f5b03d1",
            enable: true,
            fullname: "DESKTOP-LQKJ84U (Lorem ipsum dolor sit amet lorem)",
            id: 7,
            ipAddress: "62.23.132.70",
            lastAccess: "2022-12-28T13:32:15",
            name: "DESKTOP-LQKJ84U",
            operatingSystem: "Microsoft Windows 10 Home",
            status: 0,
          },
          {
            blocked: false,
            createdOn: "2022-12-28T08:32:38",
            description: "Lorem ipsum dolor sit amet lorem",
            deviceID:
              "7170dcc802cb9b2a97de13444531b81d2867706a36121719e8d793867f5b03d1",
            enable: true,
            fullname: "DESKTOP-LQKJ84U (Lorem ipsum dolor sit amet lorem)",
            id: 6,
            ipAddress: "62.23.132.70",
            lastAccess: "2022-12-28T13:32:15",
            name: "DESKTOP-LQKJ84U",
            operatingSystem: "Microsoft Windows 10 Home",
            status: 1,
          },
          {
            blocked: false,
            createdOn: "2022-12-28T08:32:38",
            description: "Lorem ipsum dolor sit amet lorem",
            deviceID:
              "7170dcc802cb9b2a97de13444531b81d2867706a36121719e8d793867f5b03d1",
            enable: true,
            fullname: "DESKTOP-LQKJ84U (Lorem ipsum dolor sit amet lorem)",
            id: 8,
            ipAddress: "62.23.132.70",
            lastAccess: "2022-12-28T13:32:15",
            name: "DESKTOP-LQKJ84U",
            operatingSystem: "Microsoft Windows 10 Home",
            status: 2,
          },
          {
            blocked: false,
            createdOn: "2022-12-28T08:32:38",
            description: "Lorem ipsum dolor sit amet lorem",
            deviceID:
              "7170dcc802cb9b2a97de13444531b81d2867706a36121719e8d793867f5b03d1",
            enable: true,
            fullname: "DESKTOP-LQKJ84U (Lorem ipsum dolor sit amet lorem)",
            id: 9,
            ipAddress: "62.23.132.70",
            lastAccess: "2022-12-28T13:32:15",
            name: "DESKTOP-LQKJ84U",
            operatingSystem: "Microsoft Windows 10 Home",
            status: 3,
          },
          {
            blocked: false,
            createdOn: "2022-12-28T08:32:38",
            description: "Lorem ipsum dolor sit amet lorem",
            deviceID:
              "7170dcc802cb9b2a97de13444531b81d2867706a36121719e8d793867f5b03d1",
            enable: true,
            fullname: "DESKTOP-LQKJ84U (Lorem ipsum dolor sit amet lorem)",
            id: 10,
            ipAddress: "62.23.132.70",
            lastAccess: "2022-12-28T13:32:15",
            name: "DESKTOP-LQKJ84U",
            operatingSystem: "Microsoft Windows 10 Home",
            status: 4,
          },
        ]);*/
      })
      .catch(() => {
        createNotification(
          t("errors.genericFetchError", {
            resource: t("common.computers"),
          }),
          NotificationTypes.DANGER,
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createNotification, filters, t]);

  /**
   * Handles the opening of the right panel.
   * @async
   * @param computer
   */
  const handleOpenRightPanel = async (computer?: Computer) => {
    const confirm = await confirmDirtyNavigation();
    if (!confirm) {
      return;
    }
    openRightPanel();
    setHasRightPanelCloseButton(false);
    setRightPanelContent(
      <GuestComputerDetail key={Math.random()} computer={computer} />,
    );
    if (!computer) {
      setActiveComputer(null);
    }
  };

  /**
   * Returns the corresponding icon for the given operating system.
   * @param {string} os - The name of the operating system.
   * @returns {string} - The name of the icon for the operating system.
   */
  const getOperatingSystemIcon = (os: string) => {
    const cleanedOS = os.toLowerCase().trim();
    if (cleanedOS.includes("windows 10")) {
      return "Windows10Icon";
    }
    if (cleanedOS.includes("windows 11")) {
      return "Windows11Icon";
    }
    if (cleanedOS.includes("windows 8")) {
      return "Windows8Icon";
    }
    if (cleanedOS.includes("windows 7")) {
      return "Windows7Icon";
    }
    if (cleanedOS.includes("windows server")) {
      return "WindowsServerIcon";
    }
    return "WindowsIcon";
  };

  /**
   * Executes an action on a row.
   *
   * @param {string} key - The unique identifier of the row.
   * @returns {void} - This function does not return anything.
   */
  const onRowAction = (key: Key): void => {
    const foundComputer = computers.find((c) => c.id === key);
    handleOpenRightPanel(foundComputer).then(() => {
      setActiveComputer(key);
    });
  };

  useEffect(() => {
    getComputers().then(() => {});
  }, [getComputers]);

  useEffect(() => {
    if (!isRightPanelOpen) {
      setActiveComputer(null);
      setHasRightPanelCloseButton(false);
      setRightPanelContent(<GuestComputerDetail key={Math.random()} />);
      openRightPanel();
    }
  }, [
    isRightPanelOpen,
    openRightPanel,
    setHasRightPanelCloseButton,
    setRightPanelContent,
  ]);

  useEffect(() => {
    setHasRightPanelCloseButton(false);
  }, [setHasRightPanelCloseButton]);

  return {
    activeComputer,
    computers,
    filters,
    getOperatingSystemIcon,
    hideTooltip,
    isLoading,
    onRowAction,
    setFilters,
    showTooltip,
    t,
  };
}

export default useGuestComputersTab;
