import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next"; // Aggiusta il percorso secondo la tua struttura
import { useNavigate } from "react-router-dom";

import { useDialogContext } from "./DialogContext";

interface NavigationDirtyContextProps {
  setDirty: (isDirty: boolean) => void;
  isDirty: boolean;
  requestDirtyNavigation: (path: string) => void;
  confirmDirtyNavigation: () => Promise<boolean>;
}

interface NavigationDirtyProviderProps {
  children: React.ReactNode;
}

const NavigationDirtyContext = createContext<NavigationDirtyContextProps>(
  {} as NavigationDirtyContextProps,
);

export const NavigationDirtyProvider: React.FC<
  NavigationDirtyProviderProps
> = ({ children }) => {
  const [isDirty, setIsDirty] = useState(false);
  const isDirtyRef = useRef(isDirty);
  const navigate = useNavigate();
  const dialog = useDialogContext();
  const { t } = useTranslation();
  const [title] = useState<string>(t("errors.unsavedChangesTitle"));
  const [message] = useState<string>(t("errors.unsavedChangesMessage"));

  useEffect(() => {
    isDirtyRef.current = isDirty;
  }, [isDirty]);

  /**
   * Sets the dirty state.
   *
   * @param {boolean} dirty - The new dirty state.
   * @returns {void}
   */
  const setDirty = useCallback((dirty: boolean) => {
    setIsDirty(dirty);
  }, []);

  /**
   * A custom callback function that performs navigation to a new path
   * and checks if there are any unsaved changes before navigating.
   *
   * @function requestDirtyNavigation
   *
   * @param {string} path - The path to navigate to.
   *
   * @returns {Promise<boolean> | void} - A promise that resolves to a boolean or undefined.
   * If there are unsaved changes, the promise resolves to false. Otherwise, it resolves to undefined.
   */
  const requestDirtyNavigation = useCallback(
    async (path: string) => {
      if (isDirtyRef.current) {
        dialog
          .open(
            "Wait!",
            "Are you sure you want to leave? Any unsaved changes will be lost.",
          )
          .then(() => {
            setIsDirty(false);
            navigate(path);
          })
          .catch(() => false);
      } else {
        navigate(path);
      }
    },
    [dialog, navigate],
  );

  /**
   * Checks if the current page contains unsaved changes and prompts the user for confirmation before leaving the page.
   *
   * @async
   * @function confirmDirtyNavigation
   * @returns {Promise<boolean>} A Promise that resolves to `true` if the user confirms navigation, or `false` if the user cancels navigation.
   *
   * @throws {Error} If an error occurs while opening the dialog.
   *
   */
  const confirmDirtyNavigation = async (): Promise<boolean> => {
    if (isDirtyRef.current) {
      try {
        await dialog.open(title, message);
        setIsDirty(false);
        return true;
      } catch {
        return false;
      }
    } else {
      return true;
    }
  };

  /**
   * Adds a listener for the `beforeunload` event.
   */
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirtyRef.current) {
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [message]);

  return (
    <NavigationDirtyContext.Provider
      value={{
        confirmDirtyNavigation,
        isDirty,
        requestDirtyNavigation,
        setDirty,
      }}
    >
      {children}
    </NavigationDirtyContext.Provider>
  );
};

export const useNavigationDirty = () => useContext(NavigationDirtyContext);
