import clsx from "clsx";
import React from "react";
import { Cell, Column, Row, TableBody, TableHeader } from "react-stately";

import useAdminActivitiesTab from "../../hooks/useAdminActivitiesTab";
import AdminActivity from "../../interfaces/AdminActivity";
import AdminActivityType from "../../interfaces/AdminActivityType";
import User from "../../interfaces/User";
import {
  convertDateToTicks,
  convertTicksToCalendarDateTime,
  findAdminActivityByType,
} from "../../utils/utils";
import Button from "../Button";
import { AdminActivityTypeMultiselect } from "../common/AdminActivityTypeMultiSelect";
import { UserMultiSelect } from "../common/UserMultiSelect";
import { Modal } from "../Modal";
import Pagination from "../Pagination";
import { Table } from "../table/Table";
import { TableActionsBar } from "../TableActionsBar";
import { TimespanDropdown } from "../TimespanDropdown";

function UsersTab() {
  const {
    activities,
    isLoading,
    selectedKeys,
    setSelectedKeys,
    setFilters,
    filters,
    t,
    dateFormatter,
    total,
    handleDetailClick,
    selectedActivity,
    showTooltip,
    hideTooltip,
    detailModalState,
    appliedFiltersCount,
    handleResetAdvancedFilters,
  } = useAdminActivitiesTab();

  return (
    <div className="flex h-full w-full flex-col overflow-x-hidden transition-all duration-200 ease-in-out">
      <TableActionsBar
        filtersCount={appliedFiltersCount}
        hasMoreFilters
        onSearchChange={(value) => {
          setFilters({ ...filters, offset: 0, search: value });
        }}
        searchValue={filters.search}
        isAdvancedSearchInitialOpen={appliedFiltersCount > 0}
        onAdvancedFilterOpenChange={(isOpen) => {
          if (!isOpen) {
            handleResetAdvancedFilters();
          }
        }}
        additionalFilters={
          <div className="grid w-full grid-cols-1 gap-4 px-2 py-4 md:grid-cols-2 lg:grid-cols-4">
            <UserMultiSelect
              maxSelectableItems={5}
              id="users"
              label={t("common.users")}
              placeholder={t("common.users")}
              hasList={false}
              selectedObjects={filters.userID as User[]}
              onChange={(e) => {
                setFilters({ ...filters, offset: 0, userID: e });
              }}
            />
            <AdminActivityTypeMultiselect
              maxSelectableItems={5}
              id="policies"
              selectedObjects={filters.activityType as AdminActivityType[]}
              onChange={(e) => {
                setFilters({ ...filters, activityType: e, offset: 0 });
              }}
            />
          </div>
        }
        customFilters={[
          <TimespanDropdown
            key="timespan"
            initialOption={filters.timespanOption}
            initialCustomFrom={convertTicksToCalendarDateTime(
              filters.dateStart,
            )}
            initialCustomTo={convertTicksToCalendarDateTime(filters.dateEnd)}
            onChange={(from, to, option) => {
              setFilters({
                ...filters,
                dateEnd: convertDateToTicks(to),
                dateStart: convertDateToTicks(from),
                offset: 0,
                timespanOption: option,
              });
            }}
          />,
        ]}
      />
      <div className="flex flex-1 flex-col gap-4">
        <div className="flex-1">
          <Table
            aria-labelledby={t("common.adminActivities")}
            isLoading={isLoading}
            aria-label={t("common.adminActivities")}
            selectedKeys={selectedKeys}
            onSelectionChange={setSelectedKeys}
            sortDescriptor={filters.sort}
            onSortChange={(e) => setFilters({ ...filters, offset: 0, sort: e })}
          >
            <TableHeader>
              <Column allowsResizing allowsSorting key="user">
                {t("common.user")}
              </Column>
              <Column allowsResizing allowsSorting key="type">
                {t("common.type")}
              </Column>
              <Column allowsResizing key="description">
                {t("common.activityDescription")}
              </Column>
              <Column allowsResizing allowsSorting key="createdOn">
                {t("common.time")}
              </Column>
            </TableHeader>
            <TableBody items={activities}>
              {(item: AdminActivity) => (
                <Row>
                  {(columnKey) => {
                    switch (columnKey) {
                      case "user":
                        return <Cell>{item?.user?.fullname || "N/D"}</Cell>;
                      case "createdOn":
                        return (
                          <Cell>
                            {item[columnKey]
                              ? dateFormatter.format(new Date(item[columnKey]))
                              : "N/D"}
                          </Cell>
                        );
                      case "description":
                        return (
                          <Cell>
                            <div
                              onMouseEnter={(e) =>
                                showTooltip(
                                  <pre className="max-w-[200px] grow-0 cursor-pointer overflow-scroll whitespace-pre-wrap rounded-md bg-extra-dark-gray p-2 text-white duration-200 ease-in-out hover:bg-dark-blue">
                                    {JSON.stringify(item.entity, null, 2)}
                                  </pre>,
                                  e,
                                  true,
                                )
                              }
                              onMouseLeave={hideTooltip}
                              className={clsx(
                                "flex w-fit flex-row items-start justify-start",
                                !item.entity &&
                                  "pointer-events-none opacity-50",
                              )}
                            >
                              <Button
                                onPress={() => {
                                  handleDetailClick(item);
                                }}
                                type="button"
                                variant="text"
                                noPadding
                              >
                                <span className="underline">Detail</span>
                              </Button>
                            </div>
                          </Cell>
                        );
                      case "type":
                        return (
                          <Cell>
                            {findAdminActivityByType(item?.type)?.name || "N/D"}
                          </Cell>
                        );
                      default:
                        return (
                          <Cell>
                            {(item as any)[String(columnKey)] || "N/D"}
                          </Cell>
                        );
                    }
                  }}
                </Row>
              )}
            </TableBody>
          </Table>
        </div>
        <Pagination
          offset={Number(filters.offset)}
          total={total}
          count={Number(filters.count)}
          onCountChange={(e) =>
            setFilters((prevState) => ({ ...prevState, count: e, offset: 0 }))
          }
          onOffsetChange={(e) =>
            setFilters((prevState) => ({ ...prevState, offset: e }))
          }
        />
      </div>
      <Modal
        state={detailModalState}
        className="min-w-[50vh] max-w-[80vh] transition-all duration-200 ease-in-out"
      >
        <div className="flex w-full flex-1 grow flex-col gap-2">
          <h3 className="pb-2 text-xl text-dark-blue">
            {t("common.activityDescription")}
          </h3>
          <pre className="max-w-[80vh] grow-0 cursor-pointer overflow-scroll whitespace-pre-wrap rounded-md bg-extra-dark-gray p-2 text-white duration-200 ease-in-out">
            {JSON.stringify(selectedActivity?.entity, null, 2)}
          </pre>
        </div>
      </Modal>
    </div>
  );
}

export default UsersTab;
