import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { Icon } from "./Icon";

type AvatarFieldProps = {
  value: string | undefined; // Base64 image string without MIME type prefix
  onChange: (base64: string, base64WithoutPrefix: string) => void;
  isLoading?: boolean;
};

const AvatarField: React.FC<AvatarFieldProps> = ({
  value,
  onChange,
  isLoading,
}) => {
  const [avatar, setAvatar] = useState<string | undefined>(undefined);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        const base64 = loadEvent.target?.result as string;
        setAvatar(base64);
        const base64WithoutPrefix = base64.split(",")[1];
        onChange(base64, base64WithoutPrefix);
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (value) {
      const base64WithPrefix = `data:image/png;base64,${value}`;
      setAvatar(base64WithPrefix);
    } else {
      setAvatar(undefined);
    }
  }, [value]);

  return (
    <div
      className="relative h-16 w-16"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <input
        aria-label="Upload image"
        aria-disabled={isLoading}
        disabled={isLoading}
        type="file"
        className="absolute inset-0 z-10 h-full w-full cursor-pointer opacity-0"
        onChange={handleImageChange}
        accept="image/png, image/jpeg, image/jpg"
      />
      <div
        className={clsx(
          "flex h-full w-full flex-shrink-0 cursor-pointer items-center justify-center rounded-full transition-opacity duration-300",
          isHovering ? "bg-dark-blue bg-opacity-60" : "bg-extra-light-blue",
          isLoading && "blur-sm",
        )}
      >
        {avatar ? (
          <img
            src={avatar}
            className="h-16 w-16 rounded-full object-cover"
            alt="Avatar"
          />
        ) : (
          <Icon name="UserIcon" className="h-8 w-8 text-dark-blue" />
        )}
        <div
          className={`absolute inset-0 flex h-full w-full cursor-pointer items-center justify-center text-white transition-opacity duration-300 ${
            isHovering ? "opacity-100" : "opacity-0"
          }`}
        >
          <Icon name="EditIcon" />
        </div>
      </div>
    </div>
  );
};

export default AvatarField;
