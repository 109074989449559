import React, { Key, useState } from "react";
import { Item } from "react-stately";

import { PolicyField } from "../../utils/policyFields";
import Button from "../Button";
import { ComboBox } from "../ComboBox";
import { Icon } from "../Icon";

interface SecurityPolicyInputComboBoxListProps {
  onChange: (newValue: string[]) => void;
  value?: string[];
  field: PolicyField;
  isLoading: boolean;
  isReadOnly?: boolean;
}

export const SecurityPolicyInputComboBoxList: React.FC<
  SecurityPolicyInputComboBoxListProps
> = ({ onChange, value = [], field, isLoading, isReadOnly }) => {
  const [inputValue, setInputValue] = useState<Key>("");

  const handleAddString = () => {
    if (inputValue) {
      onChange([...value, String(inputValue)]);
      setInputValue("");
    }
  };

  const handleRemoveString = (index: number) => {
    const updatedValue = value.filter((_, idx) => idx !== index);
    onChange(updatedValue);
  };

  /**
   * Handles key press event for input field.
   *
   * @param {React.KeyboardEvent<HTMLInputElement>} event - The key press event object.
   * @returns {void}
   */
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (inputValue) {
        handleAddString();
      }
    }
  };

  return (
    <div className="flex w-full flex-col gap-2">
      {value.map((str, index) => (
        <div key={index} className="flex w-full flex-row items-center gap-2">
          {field.options && field.options.length > 0 && (
            <ComboBox
              isReadOnly={isReadOnly}
              id={field.id}
              aria-label={field.label}
              label={field.label}
              placeholder={field.placeholder}
              isDisabled={isLoading}
              defaultSelectedKey={str}
              onSelectionChange={(e) => {
                const updatedValue = [...value];
                updatedValue[index] = String(e);
                onChange(updatedValue);
              }}
            >
              {field.options
                ?.filter(
                  (option) =>
                    value.includes(str) ||
                    !value.includes(option.id.toString()),
                )
                .map((option) => (
                  <Item key={option.id} aria-label={option.value}>
                    {option.value}
                  </Item>
                ))}
            </ComboBox>
          )}
          <div>
            <Button
              noPadding
              type="button"
              variant="text"
              isDisabled={isLoading || isReadOnly}
              onPress={() => handleRemoveString(index)}
            >
              <Icon name="RemoveIcon" className="h-6 w-6 text-red" />
            </Button>
          </div>
        </div>
      ))}
      <div className="flex flex-row items-center gap-2">
        {field.options && field.options.length > 0 && (
          <ComboBox
            id={field.id}
            aria-label={field.label}
            label={field.label}
            placeholder={field.placeholder}
            isDisabled={isLoading}
            selectedKey={inputValue as string}
            isReadOnly={isReadOnly}
            onKeyDown={handleKeyPress}
            onSelectionChange={(e) => {
              onChange([...value, String(e)]);
              setInputValue("");
            }}
          >
            {field.options
              ?.filter((option) => !value.includes(option.id.toString()))
              .map((option) => (
                <Item key={option.id} aria-label={option.value}>
                  {option.value}
                </Item>
              ))}
          </ComboBox>
        )}
        <div>
          <Button
            noPadding
            type="button"
            variant="text"
            isDisabled={!inputValue || isLoading || isReadOnly}
            onPress={handleAddString}
          >
            <Icon name="AddIcon" className="h-6 w-6 text-dark-blue" />
          </Button>
        </div>
      </div>
    </div>
  );
};
