import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import type { OverlayTriggerState } from "react-stately";

import Button from "../Button";
import { Icon } from "../Icon";
import { Modal } from "../Modal";
import TextField from "../TextField";

interface Props {
  state: OverlayTriggerState;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
  deleteKeyword?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
}

export const ConfirmDeletionModal: React.FC<Props> = ({
  state,
  title,
  message,
  onConfirm,
  onCancel,
  children,
  confirmLabel,
  cancelLabel,
  isLoading,
  deleteKeyword = "delete",
}) => {
  const { t } = useTranslation();
  const [text, setText] = React.useState("");
  const [isConfirmDisabled, setIsConfirmDisabled] = React.useState(true);

  /**
   * Executes the handleConfirm function.
   * This function calls the onConfirm() function and resets the text to an empty string.
   */
  const handleConfirm = () => {
    onConfirm();
    setText("");
  };

  useEffect(() => {
    setIsConfirmDisabled(text !== deleteKeyword);
  }, [text, deleteKeyword]);

  // Reset text every time the modal is closed
  useEffect(() => {
    if (!state.isOpen || state.isOpen) {
      setText("");
    }
  }, [state.isOpen]);

  return (
    <Modal state={state}>
      <div className="flex max-w-lg flex-col gap-4 outline-none">
        <div className="flex flex-row items-center gap-2 pb-2 text-dark-blue">
          <Icon name="ErrorIcon" className="h-6 w-6 text-red" />
          <h3 className="text-lg text-dark-blue">{title}</h3>
        </div>
        <p className="text-lg text-extra-dark-gray">
          <Trans>{message}</Trans>
        </p>
        <TextField
          label={t("common.confirmationText")}
          placeholder={t("common.confirmationText")}
          isDisabled={isLoading}
          onChange={(e) => {
            setText(e);
          }}
          value={text}
          name="confirmationText"
          type="text"
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === "Enter" && !isConfirmDisabled) {
              handleConfirm();
            }
          }}
        />
        {children}
        <div className="flex justify-end space-x-3 pt-16">
          <Button variant="text" isDisabled={isLoading} onPress={onCancel}>
            {cancelLabel || t("common.cancel")}
          </Button>
          <Button
            className="bg-dark-red"
            isLoading={isLoading}
            isDisabled={isConfirmDisabled}
            autoFocus
            variant="contained"
            onPress={handleConfirm}
          >
            {confirmLabel || t("common.confirm")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
