import { SoftwareActivityTypeEnum } from "../interfaces/SoftwareActivity";
import SoftwareActivityType from "../interfaces/SoftwareActivityType";

import { translate } from "./translation";

export const softwareActivityTypes: SoftwareActivityType[] = [
  {
    background: "bg-green",
    description: translate("softwareActivityTypes.newRegisteredPC.description"),
    id: SoftwareActivityTypeEnum.newRegisteredPC,
    key: "newRegisteredPC",
    name: translate("softwareActivityTypes.newRegisteredPC.name"),
    type: SoftwareActivityTypeEnum.newRegisteredPC,
  },
  {
    background: "bg-dark-red",
    description: translate(
      "softwareActivityTypes.lostConnectionPC.description",
    ),
    id: SoftwareActivityTypeEnum.lostConnectionPC,
    key: "lostConnectionPC",
    name: translate("softwareActivityTypes.lostConnectionPC.name"),
    type: SoftwareActivityTypeEnum.lostConnectionPC,
  },

  {
    background: "bg-green",
    description: translate("softwareActivityTypes.reconnectedPC.description"),
    id: SoftwareActivityTypeEnum.reconnectedPC,
    key: "reconnectedPC",
    name: translate("softwareActivityTypes.reconnectedPC.name"),
    type: SoftwareActivityTypeEnum.reconnectedPC,
  },
  {
    background: "bg-green",
    description: translate("softwareActivityTypes.loginPC.description"),
    id: SoftwareActivityTypeEnum.loginPC,
    key: "loginPC",
    name: translate("softwareActivityTypes.loginPC.name"),
    type: SoftwareActivityTypeEnum.loginPC,
  },
  {
    background: "bg-yellow",
    description: translate("softwareActivityTypes.logoffPC.description"),
    id: SoftwareActivityTypeEnum.logoffPC,
    key: "logoffPC",
    name: translate("softwareActivityTypes.logoffPC.name"),
    type: SoftwareActivityTypeEnum.logoffPC,
  },
  {
    background: "bg-green",
    description: translate("softwareActivityTypes.policyApplied.description"),
    id: SoftwareActivityTypeEnum.policyApplied,
    key: "policyApplied",
    name: translate("softwareActivityTypes.policyApplied.name"),
    type: SoftwareActivityTypeEnum.policyApplied,
  },
  {
    background: "bg-dark-red",
    description: translate(
      "softwareActivityTypes.policyNotApplied.description",
    ),
    id: SoftwareActivityTypeEnum.policyNotApplied,
    key: "policyNotApplied",
    name: translate("softwareActivityTypes.policyNotApplied.name"),
    type: SoftwareActivityTypeEnum.policyNotApplied,
  },
];
