import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps {
  data: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
    }[];
  };
}

const options = {
  aspectRatio: 1,
  cutout: 60,
  plugins: {
    legend: {
      align: "start" as const,
      display: false,
      labels: {
        font: {
          family: "Inter, sans-serif",
          size: 12,
          weight: "bold" as const,
        },
        padding: 30,
      },
      position: "right" as const,
      responsive: true,
      textAlign: "left",
    },

    tooltip: {
      bodyFont: {
        size: 16,
      },
      titleFont: {
        size: 16,
      },
    },
  },
};

const DoughnutChart: React.FC<DoughnutChartProps> = ({ data }) => {
  return <Doughnut data={data} options={options} />;
};

export default DoughnutChart;
