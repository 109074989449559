import clsx from "clsx";
import React from "react";

import useGuestComputerDetail from "../../hooks/useGuestComputerDetail";
import Computer, { ComputerStatus } from "../../interfaces/Computer";
import { getComputerStatusDetail } from "../../utils/utils";
import Button from "../Button";
import { Icon } from "../Icon";

interface Props {
  computer?: Computer;
}

const GuestComputerDetail: React.FC<Props> = (props: Props) => {
  const {
    isLoading,
    computer,
    t,
    getAgentOTP,
    agentOTP,
    showTooltip,
    hideTooltip,
    dateFormatter,
    isAgentOTPVisible,
    setIsAgentOTPVisible,
    handleCopyAgentOTP,
    formattedAgentOTPCountdown,
    handleDownloadRDPConfigurationFile,
  } = useGuestComputerDetail({
    computer: props.computer,
  });
  return (
    <div className="flex h-full flex-col items-center">
      {computer?.id ? (
        <div className="mt-20 flex w-full flex-col items-center gap-4 px-6">
          <Icon name="ComputersMenuIcon" className="h-32 w-32 text-pale-blue" />
          <div className="flex flex-row items-center gap-4">
            <div
              onMouseEnter={(e) =>
                showTooltip(
                  getComputerStatusDetail(computer.status || 0).description,
                  e,
                  true,
                )
              }
              onMouseLeave={hideTooltip}
              className={clsx(
                "h-4 w-4 rounded-full",
                getComputerStatusDetail(computer.status).background,
              )}
            />
            <span className="text-lg text-dark-blue">
              {computer?.name || "N/D"}
            </span>
          </div>
          <span className="text-dark-gray">
            {t("common.ipAddress")}: {computer?.ipAddress || "N/D"}
          </span>
          <span className="text-dark-gray">
            {t("common.invitedOn")}:{" "}
            {computer?.createdOn
              ? dateFormatter.format(new Date(computer?.createdOn))
              : "N/D"}
          </span>

          {(() => {
            switch (computer.status) {
              case ComputerStatus.offline:
                return (
                  <span className="mt-[50%] text-dark-gray">
                    {t("common.computerOffline")}
                  </span>
                );
              default:
                return (
                  <>
                    {agentOTP ? (
                      <div className="mt-20 flex w-full flex-col gap-4">
                        <span>
                          {t("common.toConnectToComputerInsertCode")}:
                        </span>
                        <div className="flex w-full items-center justify-between rounded-md border border-light-gray p-4 font-bold text-dark-blue shadow-lg">
                          <span>
                            {isAgentOTPVisible
                              ? agentOTP || "N/D"
                              : "*********"}
                          </span>
                          <div className="flex flex-row items-center justify-center gap-4">
                            <div>
                              <Button
                                variant="text"
                                noPadding
                                onPress={() => {
                                  setIsAgentOTPVisible(!isAgentOTPVisible);
                                }}
                              >
                                <Icon
                                  name={
                                    isAgentOTPVisible
                                      ? "EyeIcon"
                                      : "HidePasswordIcon"
                                  }
                                  className="h-6 w-6"
                                />
                              </Button>
                            </div>
                            <div>
                              <Button
                                variant="text"
                                noPadding
                                isDisabled={!agentOTP}
                                onPress={handleCopyAgentOTP}
                              >
                                <Icon
                                  name="CopyPasteIcon"
                                  className="h-6 w-6"
                                />
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full flex-row items-center justify-start gap-2 text-yellow">
                          <Icon name="ErrorIcon" className="h-6 w-6" />
                          <span>
                            {t("common.agentOTPValidFor", {
                              time: formattedAgentOTPCountdown,
                            })}{" "}
                          </span>
                        </div>
                        <div className="mt-12">
                          <Button
                            onPress={handleDownloadRDPConfigurationFile}
                            variant="outlined"
                          >
                            <div className="flex w-full flex-row items-center justify-center gap-2">
                              <Icon name="FileIcon" className="h-6 w-6" />
                              <span>{t("common.downloadRDPFile")}</span>
                            </div>
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <Button
                        className="mt-[50%]"
                        isLoading={isLoading}
                        onPress={getAgentOTP}
                      >
                        {t("common.enableComputer")}
                      </Button>
                    )}
                  </>
                );
            }
          })()}
        </div>
      ) : (
        <div className="m-auto flex flex-col items-center justify-center gap-4">
          <Icon name="ComputersMenuIcon" className="h-32 w-32 text-pale-blue" />
          <span>{t("common.selectComputerToAccess")}</span>
        </div>
      )}
    </div>
  );
};

export default GuestComputerDetail;
