import React, { createContext, ReactElement, ReactNode, useState } from "react";

import { EventBus, EventBusEvents } from "../utils/EventBus";

export interface RightPanelContextProps {
  rightPanelContent: ReactNode;
  setRightPanelContent: React.Dispatch<React.SetStateAction<ReactNode>>;
  isRightPanelOpen: boolean;
  toggleRightPanel: () => void;
  openRightPanel: (closeButtonVisible?: boolean) => void;
  closeRightPanel: () => void;
  setHasRightPanelCloseButton: React.Dispatch<React.SetStateAction<boolean>>;
  hasRightPanelCloseButton: boolean;
  setIsRightPanelCheckDirtyOnClose: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isRightPanelCheckDirtyOnClose: boolean;
}

interface RightPanelProviderProps {
  children: ReactNode;
}

export const RightPanelContext = createContext<
  RightPanelContextProps | undefined
>(undefined);

export const RightPanelProvider = ({
  children,
}: RightPanelProviderProps): ReactElement => {
  const [rightPanelContent, setRightPanelContent] = useState<ReactNode>(null);
  const [isRightPanelOpen, setIsRightPanelOpen] = useState<boolean>(false);
  const [isRightPanelCheckDirtyOnClose, setIsRightPanelCheckDirtyOnClose] =
    useState<boolean>(true);
  const [hasRightPanelCloseButton, setHasRightPanelCloseButton] =
    useState<boolean>(true);

  /**
   * Toggles the right panel by updating the state and emitting the corresponding event.
   *
   * @function toggleRightPanel
   */
  const toggleRightPanel = () => {
    setIsRightPanelOpen((prevState) => !prevState);
    // Send correct event
    if (isRightPanelOpen) {
      EventBus.emit(EventBusEvents.RIGHT_PANEL_CLOSE, null);
    } else {
      EventBus.emit(EventBusEvents.RIGHT_PANEL_OPEN, null);
    }
  };

  /**
   * @desc Open the right panel
   */
  const openRightPanel = (closeButtonVisible = true) => {
    setIsRightPanelOpen(true);
    setHasRightPanelCloseButton(closeButtonVisible);
    EventBus.emit(EventBusEvents.RIGHT_PANEL_OPEN, null);
  };

  /**
   * @desc Open the right panel
   */
  const closeRightPanel = () => {
    setIsRightPanelOpen(false);
    EventBus.emit(EventBusEvents.RIGHT_PANEL_CLOSE, null);
  };

  return (
    <RightPanelContext.Provider
      value={{
        closeRightPanel,
        hasRightPanelCloseButton,
        isRightPanelCheckDirtyOnClose,
        isRightPanelOpen,
        openRightPanel,
        rightPanelContent,
        setHasRightPanelCloseButton,
        setIsRightPanelCheckDirtyOnClose,
        setRightPanelContent,
        toggleRightPanel,
      }}
    >
      {children}
    </RightPanelContext.Provider>
  );
};
