import type { GridNode } from "@react-types/grid";
import clsx from "clsx";
import React, { CSSProperties, useRef } from "react";
import { mergeProps, useFocusRing, useTableCell } from "react-aria";
import type { TableColumnResizeState, TableState } from "react-stately";

import useTooltip from "../../hooks/useTooltip";

interface TableCellProps<T> {
  cell: GridNode<T>;
  state: TableState<T>;
  layoutState: TableColumnResizeState<T>;
  style?: CSSProperties;
  isCompact?: boolean;
}

function TableCell<T>(props: TableCellProps<T>) {
  const { cell, state, style, layoutState, isCompact } = props;
  const ref = useRef(null);
  const { gridCellProps } = useTableCell({ node: cell }, state, ref);
  const { focusProps } = useFocusRing();
  const column = cell.column;
  const { showTooltip, hideTooltip } = useTooltip();

  if (!column) {
    return null;
  }

  return (
    <td
      {...mergeProps(gridCellProps, focusProps)}
      style={{
        ...style,
        width: `${
          column.props.width
            ? column.props.width
            : layoutState.getColumnWidth(column.key)
        }px`,
      }}
      className={clsx(
        "box-border  truncate px-4 outline-none",
        isCompact ? "py-2.5" : "py-3",
        column.props.width === undefined ? "flex-[1_1_auto]" : null,
      )}
      ref={ref}
    >
      <span
        onMouseEnter={(e) =>
          cell.textValue ? showTooltip(cell.textValue, e) : null
        }
        onMouseLeave={hideTooltip}
        className="flex h-full items-center justify-start truncate"
      >
        {cell.rendered}
      </span>
    </td>
  );
}

export default TableCell;
