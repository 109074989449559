import React from "react";
import { useTranslation } from "react-i18next";

import NoDataIllustration from "../../assets/images/no-data-illustration.svg";

interface Props {
  title?: string;
  message?: string;
}

export const NoResultState: React.FC<Props> = ({ title, message }) => {
  const { t } = useTranslation();
  return (
    <div className="flex h-full flex-col items-center justify-center gap-4">
      <NoDataIllustration className="h-auto max-h-[250px]" />
      <h2 className="text-center text-base font-bold text-dark-blue lg:pt-8 lg:text-xl">
        {title || t("common.noResultTitle")}
      </h2>
      <p className="w-full max-w-md text-center text-base lg:text-lg">
        {message || t("common.noResultMessage")}
      </p>
    </div>
  );
};
