import React, { ChangeEvent, useEffect, useRef, useState } from "react";

interface Props {
  segments?: number;
  onCompleted?: (value: string) => void;
}

const SegmentedInput: React.FC<Props> = ({ onCompleted, segments = 6 }) => {
  const [inputs, setInputs] = useState<string[]>(new Array(segments).fill(""));
  const inputRefs = useRef<HTMLInputElement[]>([]);

  /**
   * Handles the change event for an input element.
   *
   * @param {number} index - The index of the input element being changed.
   * @param {ChangeEvent<HTMLInputElement>} event - The change event object.
   * @returns {void}
   */
  const handleChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = event.target.value;
    const newInputs = [...inputs];
    newInputs[index] = value.substring(value.length - 1, value.length);

    if (/^[0-9]$/.test(value) && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    setInputs(newInputs);
  };

  /**
   * Handles the paste event, extracts text from the clipboard,
   * formats it, and updates the inputs accordingly.
   *
   * @param {React.ClipboardEvent} event - The paste event.
   */
  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text").slice(0, 6).split("");
    const newInputs = pasteData.map((char, index) => {
      if (/^[0-9]$/.test(char)) {
        if (index < 5) {
          setTimeout(() => inputRefs.current[index + 1].focus(), 10);
        }
        return char;
      }
      return "";
    });
    setInputs(newInputs);
  };

  /**
   * Handles key down events for a specific input element.
   *
   * @param {number} index - The index of the input element.
   * @param {React.KeyboardEvent<HTMLInputElement>} event - The keyboard event object.
   */
  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      inputs[index] === ""
    ) {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  useEffect(() => {
    if (inputs.join("").length === segments) {
      onCompleted?.(inputs.join(""));
    }
  }, [inputs, onCompleted, segments]);

  return (
    <div className="flex w-full justify-around">
      {inputs.map((value, index) => (
        <input
          key={index}
          type="text"
          inputMode="numeric"
          maxLength={1}
          value={value}
          onKeyDown={(event) => handleKeyDown(index, event)}
          onChange={(event) => handleChange(index, event)}
          onPaste={handlePaste}
          ref={(el) => (inputRefs.current[index] = el!)}
          className="h-14 w-14 rounded-md border border-light-gray bg-white text-center"
        />
      ))}
    </div>
  );
};

export default SegmentedInput;
