import clsx from "clsx";
import React, { ReactNode } from "react";

import { useNavigationDirty } from "../contexts/NavigationDirtyContext";

import { Icon } from "./Icon";

interface RightPanelProps {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  hasCloseButton?: boolean;
  checkDirtyOnClose?: boolean;
}

const RightPanel: React.FC<RightPanelProps> = ({
  isOpen,
  onClose,
  children,
  checkDirtyOnClose = true,
  hasCloseButton = true,
}) => {
  const { setDirty, confirmDirtyNavigation } = useNavigationDirty();

  /**
   * Closes the handle and performs necessary actions based on the dirty state.
   */
  const handleClose = async () => {
    if (checkDirtyOnClose) {
      const confirm = await confirmDirtyNavigation();
      if (confirm) {
        onClose();
        setDirty(false);
      }
    } else {
      onClose();
      setDirty(false);
    }
  };

  return (
    <aside
      className={clsx(
        " fixed bottom-0 right-0 top-16 z-30 flex flex-shrink-0 flex-col overflow-y-auto border border-medium-gray bg-white transition-all duration-300",
        isOpen ? "w-[425px] opacity-100" : "w-0 opacity-0",
      )}
    >
      {hasCloseButton && (
        <button
          aria-label="Toggle panel"
          onClick={handleClose}
          className="absolute right-4 top-4 z-40 flex shrink-0 cursor-pointer items-center rounded-full bg-extra-light-blue text-dark-blue"
        >
          <Icon
            name="ClosePanelIcon"
            className={clsx(
              "m-auto w-full transition-all duration-200 ease-in-out",
              isOpen ? "rotate-180" : "rotate-0",
            )}
          />
        </button>
      )}
      <div className={clsx("relative h-full", hasCloseButton && "pt-8")}>
        {children}
      </div>
    </aside>
  );
};

export default RightPanel;
