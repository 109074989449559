import type { AriaSelectProps } from "@react-types/select";
import clsx from "clsx";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { mergeProps, useButton, useFocusRing, useSelect } from "react-aria";
import { createPortal } from "react-dom";
import { useSelectState } from "react-stately";

import { Icon } from "./Icon";
import ListBox from "./ListBox";

export { Item } from "react-stately";

interface SelectProps extends AriaSelectProps<object> {
  variant?: "contained" | "text";
  labelClassName?: string;
}

export function Select(props: SelectProps) {
  const state = useSelectState(props);
  const ref = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const listBoxRef = useRef<HTMLDivElement>(null);
  const [isListOpenAbove, setIsListOpenAbove] = useState(false);
  const { variant = "contained", labelClassName } = props;

  // Destructuring functions and props from useSelect
  const { labelProps, triggerProps, valueProps, menuProps } = useSelect(
    props,
    state,
    triggerRef,
  );
  const { buttonProps } = useButton(triggerProps, triggerRef);
  const { focusProps, isFocusVisible } = useFocusRing();

  // Determine if the ListBox should open above or below the trigger
  useEffect(() => {
    if (state.isOpen && ref.current) {
      const rect = ref.current?.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect) {
        const isInputLowerThanHalfScreen = rect.top > windowHeight / 2;
        setIsListOpenAbove(isInputLowerThanHalfScreen);
      }
    }
  }, [state.isOpen]);

  useEffect(() => {
    if (state.isOpen && ref.current && listBoxRef.current) {
      const triggerRect = ref.current.getBoundingClientRect();
      const listBoxHeight = listBoxRef.current.offsetHeight;
      const spaceAbove = triggerRect.top;
      const spaceBelow = window.innerHeight - triggerRect.bottom;
      const hasEnoughSpaceBelow = spaceBelow >= listBoxHeight;

      setIsListOpenAbove(!hasEnoughSpaceBelow);

      const listBoxStyle = listBoxRef.current.style;
      listBoxStyle.width = `${triggerRect.width}px`;
      listBoxStyle.left = `${triggerRect.left}px`;

      if (!hasEnoughSpaceBelow && spaceAbove >= listBoxHeight) {
        listBoxStyle.top = "";
        listBoxStyle.bottom = `${window.innerHeight - triggerRect.top + 10}px`;
      } else {
        listBoxStyle.bottom = "";
        listBoxStyle.top = `${triggerRect.bottom + 10}px`;
      }
    }
  }, [state.isOpen, ref, listBoxRef]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        listBoxRef.current &&
        !listBoxRef.current.contains(event.target as Node)
      ) {
        state.close();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, listBoxRef, state]);

  /**
   * @description Handles the scroll event on the window to keep the dropdown in the right position.
   */
  useEffect(() => {
    const handleElementScroll = () => {
      if (!state.isOpen || !ref.current || !listBoxRef.current) {
        return;
      }

      const rect = ref.current.getBoundingClientRect();
      const ulHeight = listBoxRef.current.offsetHeight;
      const shouldOpenAbove =
        window.innerHeight - rect.bottom < ulHeight && rect.top > ulHeight;

      setIsListOpenAbove(shouldOpenAbove);

      listBoxRef.current.style.width = `${rect.width}px`;
      listBoxRef.current.style.left = `${window.pageXOffset + rect.left}px`;

      if (shouldOpenAbove) {
        listBoxRef.current.style.top = "auto";
        listBoxRef.current.style.bottom = `${
          window.innerHeight - rect.top + 10
        }px`;
      } else {
        listBoxRef.current.style.bottom = "auto";
        listBoxRef.current.style.top = `${
          window.pageYOffset + rect.bottom + 10
        }px`;
      }
    };

    document.addEventListener("scroll", handleElementScroll, true);
    document.addEventListener("resize", handleElementScroll, true);

    return () => {
      document.removeEventListener("scroll", handleElementScroll, true);
      document.removeEventListener("resize", handleElementScroll, true);
    };
  }, [state.isOpen, ref, listBoxRef, isListOpenAbove]);

  // CSS classes for styling based on variant
  const variantStyles = {
    contained: "rounded-[6px] border border-gray px-3 h-10",
    text: "rounded-none border-none h-6",
  };

  const selectVariantStyle = variantStyles[variant];

  return (
    <div ref={ref} className="relative w-full rounded-md bg-white">
      <div
        className={`flex w-full flex-row items-center gap-2 ${selectVariantStyle}`}
      >
        {props.label && (
          <label
            {...labelProps}
            className="block cursor-default truncate text-left text-sm font-medium"
          >
            {props.label}
          </label>
        )}
        <button
          {...mergeProps(buttonProps, focusProps)}
          ref={triggerRef}
          className={`relative  flex h-full flex-1 cursor-default flex-row items-center justify-between overflow-hidden outline-none ${
            isFocusVisible ? "bg-light-gray" : "bg-white"
          }`}
        >
          <span {...valueProps} className={labelClassName}>
            {state.selectedItem
              ? state.selectedItem.rendered
              : "Select an option"}
          </span>
          <Icon
            name="ArrowDropDownIcon"
            className={`m-auto w-full transition-all duration-200 ease-in-out ${
              state.isOpen ? "rotate-180" : "rotate-0"
            }`}
          />
        </button>
      </div>
      {state.isOpen &&
        createPortal(
          <div
            ref={listBoxRef}
            className={clsx(
              "fixed z-50 max-h-80 overflow-y-auto rounded-[6px] border border-gray bg-white p-3 shadow-md",
              isListOpenAbove && "bottom-16 top-auto",
              state.isOpen ? "visible" : "hidden",
            )}
          >
            <ListBox {...menuProps} state={state} />
          </div>,
          document.getElementById("select-root")!,
        )}
    </div>
  );
}
