import React from "react";
import {
  Cell,
  Column,
  Item,
  Row,
  Section,
  TableBody,
  TableHeader,
} from "react-stately";

import PolicyIllustration from "../../assets/images/policy-illustration.svg";
import usePoliciesTab from "../../hooks/usePoliciesTab";
import Computer from "../../interfaces/Computer";
import ComputerGroup from "../../interfaces/ComputerGroup";
import Policy from "../../interfaces/Policy";
import PolicySeverity from "../../interfaces/PolicySeverity";
import Button from "../Button";
import { ComputerMultiSelect } from "../common/ComputerMultiSelect";
import { ConfirmDeletionModal } from "../common/ConfirmDeletionModal";
import { PolicySeverityMultiselect } from "../common/PolicySeverityMultiSelect";
import { Icon } from "../Icon";
import { MenuButton } from "../Menu";
import { Table } from "../table/Table";
import { TableActionsBar } from "../TableActionsBar";

function PoliciesTab() {
  const {
    policies,
    isLoading,
    selectedKeys,
    setSelectedKeys,
    tableActions,
    onMenuAction,
    setFilters,
    filters,
    handleAddPolicy,
    onRowAction,
    t,
    getPolicyActions,
    appliedFiltersCount,
    handleResetAdvancedFilters,
    deleteModalState,
    handlePolicyDelete,
    activePoliciesForDeletion,
    showTooltip,
    hideTooltip,
    isCurrentUserReadOnly,
    selectedPolicy,
  } = usePoliciesTab();

  return (
    <div className="flex h-full w-full flex-col overflow-x-hidden transition-all duration-200 ease-in-out">
      <TableActionsBar
        hasMoreFilters
        filtersCount={appliedFiltersCount}
        actions={tableActions}
        onSearchChange={(value) => {
          setFilters({ ...filters, search: value });
        }}
        isAdvancedSearchInitialOpen={appliedFiltersCount > 0}
        onAdvancedFilterOpenChange={(isOpen) => {
          if (!isOpen) {
            handleResetAdvancedFilters();
          }
        }}
        additionalFilters={
          <div className="grid w-full grid-cols-1 gap-4 px-2 py-4 md:grid-cols-2 lg:grid-cols-4">
            <ComputerMultiSelect
              maxSelectableItems={5}
              id="computers"
              hasList={false}
              label={t("common.computers")}
              placeholder={t("common.computers")}
              selectedComputers={filters.computerID as Computer[]}
              selectedGroups={filters.groupComputerID as ComputerGroup[]}
              hasNegativeIds={false}
              onComputerChange={(e) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  computerID: e,
                }));
              }}
              onGroupChange={(e) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  groupComputerID: e,
                }));
              }}
            />
            <PolicySeverityMultiselect
              id="severities"
              label={t("common.severity")}
              placeholder={t("common.severity")}
              selectedObjects={filters.severity as PolicySeverity[]}
              onChange={(e) => {
                setFilters({ ...filters, severity: e });
              }}
            />
          </div>
        }
      />
      {policies.length === 0 &&
      !isLoading &&
      (!filters.search ||
        !filters.groupComputerID?.length ||
        !filters.computerID?.length) ? (
        <div className="flex flex-col gap-24 p-8">
          <span className="text-medium-gray">{t("common.noPolicies")}</span>
          <div className="mx-auto flex h-full w-full max-w-xl flex-col items-center justify-center gap-8 text-center">
            <PolicyIllustration className="h-auto w-full" />
            <span className="leading-8 text-extra-dark-gray">
              {t("common.noPoliciesMessage")}
            </span>
            <Button onPress={handleAddPolicy}>{t("common.addPolicy")}</Button>
          </div>
        </div>
      ) : (
        <Table
          aria-labelledby="Policies"
          isLoading={isLoading}
          aria-label="Policies"
          selectionMode={isCurrentUserReadOnly ? "none" : "multiple"}
          selectedKeys={selectedKeys}
          onSelectionChange={setSelectedKeys}
          sortDescriptor={filters.sort}
          onSortChange={(e) => setFilters({ ...filters, sort: e })}
          onRowAction={(key) => {
            onRowAction(key).then(() => {});
          }}
        >
          <TableHeader>
            <Column
              minWidth={20}
              maxWidth={100}
              allowsResizing
              allowsSorting
              key="name"
            >
              {t("common.typeOfPolicy")}
            </Column>
            <Column allowsResizing allowsSorting key="description">
              {t("common.description")}
            </Column>
            <Column width={100} allowsSorting key="securityPolicies">
              {t("common.numberOfRules")}
            </Column>
            <Column width={50} key="status">
              {""}
            </Column>
            <Column width={50} key="_actions">
              {""}
            </Column>
          </TableHeader>
          <TableBody items={policies}>
            {(item: Policy) => (
              <Row key={item.type}>
                {(columnKey) => {
                  if (columnKey === "_actions") {
                    if (isCurrentUserReadOnly) {
                      return <Cell>{""}</Cell>;
                    }

                    return (
                      <Cell>
                        <MenuButton
                          label={
                            <Icon
                              name="MenuIcon"
                              className="h-full text-extra-dark-gray"
                            />
                          }
                          onAction={(key) => onMenuAction(item.type, key)}
                        >
                          {getPolicyActions(item.id).map((action) =>
                            (() => {
                              switch (action.key) {
                                case "edit":
                                  return (
                                    <Section>
                                      <Item key={action.key}>
                                        {t("common.edit")}
                                      </Item>
                                    </Section>
                                  );
                                case "delete":
                                  return (
                                    <Section>
                                      <Item
                                        key={action.key}
                                        textValue={t("common.delete")}
                                      >
                                        <span className="text-red">
                                          {t("common.delete")}
                                        </span>
                                      </Item>
                                    </Section>
                                  );
                                default:
                                  return (
                                    <Section>
                                      <Item key={action.key}>
                                        {action.label}
                                      </Item>
                                    </Section>
                                  );
                              }
                            })(),
                          )}
                        </MenuButton>
                      </Cell>
                    );
                  } else if (columnKey === "name") {
                    return (
                      <Cell>
                        <p className="flex flex-row items-center justify-start gap-4">
                          <Icon
                            name={item.icon}
                            className="h-6 w-6 text-medium-pale-blue"
                          />
                          {(item as any)[columnKey] || "N/D"}
                        </p>
                      </Cell>
                    );
                  } else if (columnKey === "securityPolicies") {
                    return (
                      <Cell>{(item as any)[columnKey].length || "0"}</Cell>
                    );
                  } else if (columnKey === "status") {
                    return (
                      <Cell>
                        <div className="flex h-full w-full items-center justify-center">
                          {(() => {
                            if (
                              item.securityPolicies.every(
                                (policy) => !policy.enable,
                              )
                            ) {
                              return (
                                <div
                                  onMouseEnter={(e) =>
                                    showTooltip(
                                      t("common.policyWithAllRulesDisabled"),
                                      e,
                                      true,
                                      180,
                                    )
                                  }
                                  onMouseLeave={hideTooltip}
                                  className="my-auto h-4 w-4 rounded-full bg-dark-gray"
                                ></div>
                              );
                            }
                            if (
                              item.securityPolicies.some(
                                (policy) => !policy.enable,
                              )
                            ) {
                              return (
                                <div
                                  onMouseEnter={(e) =>
                                    showTooltip(
                                      t("common.policyWithSomeRulesDisabled"),
                                      e,
                                      true,
                                      180,
                                    )
                                  }
                                  onMouseLeave={hideTooltip}
                                  className="my-auto h-4 w-4 rounded-full bg-yellow"
                                ></div>
                              );
                            }
                            return (
                              <div className="my-auto h-4 w-4 rounded-full bg-green"></div>
                            );
                          })()}
                        </div>
                      </Cell>
                    );
                  } else {
                    return (
                      <Cell>{(item as any)[String(columnKey)] || "N/D"}</Cell>
                    );
                  }
                }}
              </Row>
            )}
          </TableBody>
        </Table>
      )}
      <ConfirmDeletionModal
        state={deleteModalState}
        title={t("dialog.deletePolicy")}
        message={t("dialog.deletePolicyMessage", {
          count: activePoliciesForDeletion.includes("all")
            ? policies.length
            : activePoliciesForDeletion.length,
          deleteKeyword: "delete",
          resourceName: selectedPolicy?.name,
        })}
        onConfirm={handlePolicyDelete}
        onCancel={deleteModalState.close}
        isLoading={isLoading}
      />
    </div>
  );
}

export default PoliciesTab;
