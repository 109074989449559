import clsx from "clsx";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Item } from "react-stately";

import { Icon } from "./Icon";
import { Select } from "./Select";

interface PaginationProps {
  offset: number;
  total: number;
  count: number;
  onOffsetChange: (value: number) => void;
  onCountChange: (value: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  offset,
  total,
  count,
  onOffsetChange,
  onCountChange,
}) => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(count);
  const totalPages = Math.ceil(total / itemsPerPage);
  const currentPage = Math.floor(offset / itemsPerPage) + 1;

  /**
   * Callback function for handling previous page.
   * It updates the offset by subtracting itemsPerPage from the current offset value,
   * ensuring that the new offset is not less than zero.
   *
   * @callback handlePrevious
   * @returns {void}
   */
  const handlePrevious = useCallback((): void => {
    onOffsetChange(Math.max(0, offset - itemsPerPage));
  }, [offset, itemsPerPage, onOffsetChange]);

  /**
   * Callback function that handles next button click event.
   * It calculates the new offset value based on the current offset, items per page, and total count.
   * Then it invokes the provided onOffsetChange function with the new offset value.
   *
   * @function
   * @name handleNext
   * @memberof module:myComponent
   * @param {Function} onOffsetChange - The function to be invoked with the new offset value
   * @returns {void}
   */
  const handleNext = useCallback((): void => {
    const newOffset = offset + itemsPerPage;
    // Assicurati che l'offset non superi il numero totale di elementi
    // tenendo conto del caso in cui non siamo su un multiplo esatto per l'ultima pagina
    const adjustedOffset = Math.min(
      newOffset,
      total - (total % itemsPerPage || itemsPerPage),
    );
    onOffsetChange(adjustedOffset);
  }, [offset, itemsPerPage, total, onOffsetChange]);

  /**
   * Handles the click event of a page number.
   *
   * @param {number} pageNumber - The selected page number.
   * @returns {void}
   */
  const handlePageNumberClick = (pageNumber: number): void => {
    onOffsetChange((pageNumber - 1) * itemsPerPage);
  };

  /**
   * Handles the change of items per page.
   *
   * @param {number} e - The number of items per page.
   */
  const handleItemsPerPageChange = (e: number): void => {
    setItemsPerPage(e);
    onCountChange(e);
    onOffsetChange(0);
  };

  /**
   * Generates a pagination array based on the current page and total number of pages.
   * @returns {Array} An array of numbers and strings representing the pagination items.
   */
  const getPaginationItems = () => {
    const pages: (number | string)[] = [];

    // Se c'è solo una pagina, non aggiungere la pagina 1 due volte
    if (totalPages === 1) {
      pages.push(1);
      return pages;
    }

    // Aggiungi sempre la prima pagina
    pages.push(1);

    let startPage: number, endPage: number;

    if (totalPages <= 5) {
      // Mostra tutte le pagine se ce ne sono al massimo 5
      startPage = 2;
      endPage = totalPages - 1;
    } else {
      if (currentPage <= 3) {
        startPage = 2;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages - 1;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    // Aggiungi i puntini all'inizio se necessario
    if (startPage > 2) {
      pages.push("start-dots");
    }

    // Aggiungi le pagine numerate visibili
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Aggiungi i puntini alla fine se necessario
    if (endPage < totalPages - 1) {
      pages.push("end-dots");
    }

    // Aggiungi sempre l'ultima pagina se ci sono più di una pagina
    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  const handleDotsClick = (dotsPosition: "start-dots" | "end-dots") => {
    if (dotsPosition === "start-dots" && currentPage > 1) {
      // Vai alla pagina prima del primo gruppo visibile di pagine
      const targetPage = currentPage - 3 < 1 ? 2 : currentPage - 3;
      handlePageNumberClick(targetPage);
    } else if (dotsPosition === "end-dots" && currentPage < totalPages) {
      // Vai alla pagina dopo l'ultimo gruppo visibile di pagine
      const targetPage =
        currentPage + 3 > totalPages ? totalPages - 1 : currentPage + 3;
      handlePageNumberClick(targetPage);
    }
  };

  return (
    <div className="flex items-center justify-between p-4">
      <div className="flex items-center">
        <span className="text-sm">
          {t("pagination.showingItems", {
            from: offset + 1,
            to: Math.min(offset + itemsPerPage, total),
            total: total,
          })}
        </span>
      </div>
      <div className="flex h-10 flex-row items-center">
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className="inline-flex h-full items-center rounded-l-md border-y border-l border-gray px-3  text-dark-blue hover:bg-light-blue focus:outline-none active:bg-light-blue disabled:pointer-events-none disabled:opacity-50"
        >
          <Icon name="ArrowRightIcon" className="h-4 w-4 rotate-180" />
        </button>
        {getPaginationItems().map((item, idx) => {
          if (item === "start-dots" || item === "end-dots") {
            return (
              <button
                key={idx}
                disabled
                onClick={() => handleDotsClick(item)}
                className="flex h-full items-end border-l border-gray px-3"
              >
                <span>...</span>
              </button>
            );
          } else {
            return (
              <button
                key={idx}
                className={clsx(
                  "h-full border-y border-l border-gray px-3 transition-all duration-200 ease-in-out hover:bg-light-blue",
                  currentPage === item
                    ? "bg-light-blue text-dark-blue"
                    : "bh-white text-extra-dark-gray",
                )}
                onClick={() => handlePageNumberClick(Number(item))}
              >
                {item}
              </button>
            );
          }
        })}
        <button
          className="inline-flex h-full items-center rounded-r-md border-x border-y border-gray px-3 text-dark-blue hover:bg-light-blue focus:outline-none active:bg-light-blue disabled:pointer-events-none disabled:opacity-50"
          onClick={handleNext}
          disabled={currentPage === totalPages}
        >
          <Icon name="ArrowRightIcon" className="h-4 w-4" />
        </button>
      </div>
      <div className="flex flex-row items-center gap-2">
        <span className="block flex-shrink-0 text-sm">
          {t("pagination.itemsPerPage")}:
        </span>
        <Select
          selectedKey={String(itemsPerPage)}
          aria-label={t("pagination.selectItemsPerPage")}
          placeholder={t("pagination.selectItemsPerPage")}
          onSelectionChange={(value) => {
            handleItemsPerPageChange(Number(value));
          }}
        >
          {[15, 30, 50, 100].map((item) => (
            <Item key={item} aria-label={String(item)}>
              {item}
            </Item>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default Pagination;
