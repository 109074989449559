import { RefObject, useRef } from "react";
import type { AriaTimeFieldProps } from "react-aria";
import { useLocale, useTimeField } from "react-aria";
import { useTimeFieldState } from "react-stately";

import { DateSegment } from "./DateSegment";

export function TimeField(props: AriaTimeFieldProps<any>) {
  const { locale } = useLocale();
  const state = useTimeFieldState({ ...props, locale: locale });

  const ref: RefObject<HTMLDivElement> = useRef(null);
  const { labelProps, fieldProps } = useTimeField(props, state, ref);

  return (
    <div className="relative inline-flex w-full rounded-md border border-gray bg-white px-2 text-left">
      <span
        {...labelProps}
        className="absolute left-3 top-0 z-10 text-[10px] text-dark-gray"
      >
        {props.label}
      </span>
      <div
        {...fieldProps}
        ref={ref}
        className="flex rounded-md bg-white px-2 pb-1 pt-3 transition-colors focus:outline-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
      >
        {state.segments.map((segment, i: number) => (
          <DateSegment key={i} segment={segment} state={state} />
        ))}
      </div>
    </div>
  );
}
