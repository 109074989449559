import React from "react";
import type { AriaDialogProps } from "react-aria";
import { useDialog } from "react-aria";
import { Trans } from "react-i18next";
import type { OverlayTriggerState } from "react-stately";

import Button from "./Button";
import { Icon } from "./Icon";
import { iconComponents } from "./IconImports";
import { Modal } from "./Modal";

interface DialogProps extends AriaDialogProps {
  children: React.ReactNode;
  title: string;
  variant?: "default" | "destructive";
  confirmLabel?: string;
  cancelLabel?: string;
  onClose: () => void;
  onConfirm: () => void;
  state: OverlayTriggerState;
  icon?: keyof typeof iconComponents;
  confirmButtonClassName?: string;
  iconClassName?: string;
}

export function Dialog(props: DialogProps) {
  const {
    children,
    onClose,
    confirmLabel,
    cancelLabel,
    onConfirm,
    icon,
    confirmButtonClassName,
    iconClassName = "w-6 h-6 text-red",
  } = props;

  const ref = React.useRef(null);
  const { dialogProps, titleProps } = useDialog(
    {
      ...props,
      role: "alertdialog",
    },
    ref,
  );

  return (
    <Modal state={props.state}>
      <div {...dialogProps} ref={ref} className="max-w-lg outline-none">
        <div className="flex flex-row items-center gap-2 pb-2 text-dark-blue">
          {icon && <Icon name={icon} className={iconClassName} />}
          <h3 {...titleProps} className="text-lg">
            {props.title}
          </h3>
        </div>
        <p className="text-lg text-extra-dark-gray">
          <Trans>{children}</Trans>
        </p>
        <div className="flex justify-end space-x-3 pt-16">
          <Button variant="text" onPress={onClose}>
            {cancelLabel || "Cancel"}
          </Button>
          <Button
            autoFocus
            variant="contained"
            onPress={onConfirm}
            className={confirmButtonClassName}
          >
            {confirmLabel || "Confirm"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
