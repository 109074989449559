import clsx from "clsx";
import React from "react";
import { Item } from "react-stately";

import Button from "../components/Button";
import { ComboBox } from "../components/ComboBox";
import { Icon } from "../components/Icon";
import SettingSection from "../components/settings/SettingSection";
import Switch from "../components/Switch";
import TextField from "../components/TextField";
import useNotificationSettings from "../hooks/useNotificationSettings";
import { policySeverities } from "../utils/policySeverities";

export const NotificationSettings: React.FC = () => {
  const {
    t,
    handleAddEmail,
    handleAddEmailKeyPress,
    emailInputValue,
    newEmailTextFieldRef,
    handleRemoveEmail,
    isLoading,
    setEmailInputValue,
    form,
    isEmailValid,
    isCurrentUserReadOnly,
    isViolationActivityChecked,
    setForm,
    setIsFormChanged,
  } = useNotificationSettings();
  return (
    <SettingSection withoutPadding title={t("pages.notifications")}>
      <div className="flex flex-col gap-8">
        <div className="flex w-full flex-col divide-y divide-gray">
          <div className="grid h-[100px] w-full grid-cols-5 items-center px-6">
            <p className="col-span-2 text-lg text-dark-blue">
              {t("common.violationActivity")}
            </p>
            <div className="col-span-1">
              <Switch
                isDisabled={isLoading || isCurrentUserReadOnly}
                aria-label={t("common.violationActivity")}
                id="violationActivity"
                name={t("common.violationActivity")}
                isSelected={isViolationActivityChecked}
                onChange={(e) => {
                  setForm({ ...form, violationActivity: e ? 1 : 0 });
                  setIsFormChanged(true);
                }}
              />
            </div>
            <div />
            <div className="col-span-2">
              <ComboBox
                isReadOnly={isCurrentUserReadOnly}
                id="severity"
                selectedKey={String(form.violationActivity)}
                isDisabled={isLoading}
                aria-label={t("common.severity")}
                label={t("common.severity")}
                onSelectionChange={(value) => {
                  setForm({ ...form, violationActivity: Number(value) });
                  setIsFormChanged(true);
                }}
              >
                {policySeverities.map((item) => (
                  <Item key={item.id} aria-label={String(item.name)}>
                    <span className={clsx(item.color)}>{item.name}</span>
                  </Item>
                ))}
              </ComboBox>
            </div>
          </div>
        </div>
        <div className="flex max-w-[450px] flex-col gap-4 px-6">
          <span className="text-lg text-dark-blue">
            {t("common.sendNotificationsTo")}:
          </span>
          <div className="flex flex-row items-center gap-2">
            <div className="w-full max-w-[80%]">
              <TextField
                isReadOnly={isCurrentUserReadOnly}
                id={`email-new`}
                ref={newEmailTextFieldRef}
                name="email"
                type="email"
                label={t("common.email")}
                value={emailInputValue}
                isDisabled={isLoading}
                onChange={(e) => setEmailInputValue(e)}
                onKeyDown={handleAddEmailKeyPress}
              />
            </div>
            <div className="h-full">
              <Button
                noPadding
                type="button"
                variant="text"
                isDisabled={
                  !emailInputValue.trim() ||
                  isLoading ||
                  !isEmailValid ||
                  isCurrentUserReadOnly
                }
                onPress={handleAddEmail}
              >
                <Icon name="AddIcon" className="h-8 w-8 text-dark-blue" />
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            {form.emails.map((email, index) => (
              <div
                className="flex w-full flex-row items-center gap-2"
                key={index}
              >
                <div className="flex w-full max-w-[80%] flex-row items-center justify-between gap-2 rounded-full border border-gray bg-[#F2FCFF] px-4 py-2 text-dark-blue">
                  <span>{email}</span>
                  {form.emails.length > 1 && (
                    <div className="flex items-center justify-center">
                      <Button
                        noPadding
                        type="button"
                        variant="text"
                        isDisabled={isLoading || isCurrentUserReadOnly}
                        onPress={() => handleRemoveEmail(index)}
                      >
                        <Icon name="CloseIcon" className="h-5 w-5" />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </SettingSection>
  );
};
