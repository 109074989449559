import { createCalendar } from "@internationalized/date";
import clsx from "clsx";
import React, { RefObject, useRef } from "react";
import type { AriaButtonProps, AriaCalendarProps } from "react-aria";
import {
  mergeProps,
  useButton,
  useCalendar,
  useFocusRing,
  useLocale,
} from "react-aria";
import { useCalendarState } from "react-stately";

import { Icon } from "../Icon";

import { CalendarGrid } from "./CalendarGrid";

const Button = React.forwardRef(
  (props: AriaButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { buttonProps, isPressed } = useButton(
      props,
      ref as RefObject<HTMLButtonElement>,
    );
    const { focusProps } = useFocusRing();

    return (
      <button
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        className={clsx(
          " outline-none transition-all duration-100 ease-in-out",
          isPressed && "opacity-50",
        )}
      >
        {props.children}
      </button>
    );
  },
);

Button.displayName = "MenuButton";

export function Calendar(props: AriaCalendarProps<any>) {
  const { locale } = useLocale();
  const state = useCalendarState({
    ...props,
    createCalendar,
    locale,
  });

  const ref: RefObject<HTMLDivElement> = useRef(null);
  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useCalendar(props, state);

  return (
    <div
      {...calendarProps}
      ref={ref}
      className="flex w-full flex-col rounded-md border border-gray p-2"
    >
      <div className="flex w-full flex-row items-center justify-between pb-4">
        <Button {...prevButtonProps}>
          <Icon
            name="ArrowRightIcon"
            className="h-6 w-6 rotate-180 text-extra-dark-gray"
          />
        </Button>
        <p className="flex-shrink-0 text-base text-extra-dark-gray">{title}</p>
        <Button {...nextButtonProps}>
          <Icon
            name="ArrowRightIcon"
            className="h-6 w-6 text-extra-dark-gray"
          />
        </Button>
      </div>
      <CalendarGrid state={state} />
    </div>
  );
}
