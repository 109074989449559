import React from "react";
import { useDateSegment } from "react-aria";

interface DateSegmentProps {
  segment: any;
  state: any;
}

export function DateSegment({ segment, state }: DateSegmentProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      className={`text-extra-dark-gray outline-1 outline-gray/50 ${
        segment.isPlaceholder ? "text-dark-gray" : ""
      }`}
    >
      {segment.text}
    </div>
  );
}
