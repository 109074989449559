import { APIService, RequestBody } from "../api/apiService";

export class ComputerGroupService extends APIService {
  /**
   * @description Fetch Computer group by id
   */
  getComputerGroup(id: string | number) {
    return this.request(`/Admin/ComputerGroup/${id}`, "GET");
  }

  /**
   * @description Fetches all Computers groups
   * @param query
   */
  getComputerGroups(query?: string | null) {
    return this.request(`/Admin/ComputerGroup?${query}`, "GET");
  }

  /**
   * @description Updates a Computer group
   */
  updateComputerGroup(id: string | number, payload: RequestBody) {
    return this.request(`/Admin/ComputerGroup/${id}`, "PUT", payload);
  }

  /**
   * @description Create a Computer group
   */
  createComputerGroup(payload: RequestBody) {
    return this.request(`/Admin/ComputerGroup`, "POST", payload);
  }

  /**
   * @description Delete a Computer group
   */
  deleteComputerGroup(query: string) {
    return this.request(`/Admin/ComputerGroup?${query}`, "DELETE");
  }
}
