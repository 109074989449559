import { HubConnectionState } from "@microsoft/signalr";
import clsx from "clsx";
import React, { Key, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Item, Section } from "react-stately";

import LogoHorizontal from "../assets/logo-horizontal.svg";
import { useAuth } from "../contexts/AuthContext";
import { useNavigationDirty } from "../contexts/NavigationDirtyContext";
import {
  RightPanelContext,
  RightPanelContextProps,
} from "../contexts/RightPanelContext";
import { useSignalR } from "../contexts/SignalRContext";

import DirtyLink from "./DirtyLink";
import { Icon } from "./Icon";
import { MenuButton } from "./Menu";
import NotificationList from "./notifications/NotificationList";

interface HeaderProps {
  isSidebarOpen: boolean;
}

const Header: React.FC<HeaderProps> = ({ isSidebarOpen }) => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [isNotificationsSidebarOpen, setIsNotificationSidebarOpen] =
    React.useState(false);
  const [avatar, setAvatar] = useState<string | undefined>(undefined);

  const { openRightPanel, setRightPanelContent, closeRightPanel } = useContext(
    RightPanelContext,
  ) as RightPanelContextProps;
  const { confirmDirtyNavigation } = useNavigationDirty();
  const { t } = useTranslation();
  const { isCurrentUserReadOnly, isCurrentUserGuest } = useAuth();
  const { signalRConnectionState } = useSignalR();

  /**
   * Performs an action based on the given key.
   *
   * @param {string} key - The key to determine the action.
   * @returns {void}
   */
  const onAction = async (key: Key): Promise<void> => {
    const confirm = await confirmDirtyNavigation();
    if (!confirm) {
      return;
    }

    switch (key) {
      case "profile":
        navigate("/settings", { replace: true });
        break;
      case "help":
        window.open(
          "https://chatgpt.com/g/g-JrXsYulKP-remotegrant-guide",
          "_blank",
        );
        break;
      case "logout":
        logout();
        navigate("/login", { replace: true });
        break;
    }
  };

  /**
   * Opens the right panel and sets its content to display a greeting message.
   *
   * @function handleViewNotifications
   * @memberof global
   * @returns {void}
   */
  const handleViewNotifications = (): void => {
    if (isNotificationsSidebarOpen) {
      setIsNotificationSidebarOpen(false);
      closeRightPanel();
      return;
    }
    setIsNotificationSidebarOpen(true);
    openRightPanel(false);
    setRightPanelContent(<NotificationList />);
  };

  /**
   * Sets the avatar to the user's avatar if it exists.
   */
  useEffect(() => {
    if (currentUser) {
      const userAvatar = currentUser.avatar;
      if (userAvatar) {
        const base64WithPrefix = `data:image/png;base64,${userAvatar}`;
        setAvatar(base64WithPrefix);
      } else {
        setAvatar(undefined);
      }
    }
  }, [currentUser]);

  const menuItems = useMemo(() => {
    const items = [];

    if (!isCurrentUserGuest) {
      items.push({
        key: "profile",
        label: t("common.profile"),
      });
    }

    items.push({
      key: "help",
      label: t("common.help"),
    });

    items.push({
      key: "logout",
      label: t("common.logout"),
    });

    return items;
  }, [t, isCurrentUserGuest]);

  return (
    <div className={clsx("fixed left-0 right-0 top-0 z-40 w-[100vw] bg-white")}>
      <div
        className={clsx(
          "top-0 z-40 border-b border-medium-gray pr-4 transition-all duration-200 lg:mx-auto lg:pr-8",
          isSidebarOpen ? "pl-80" : "pl-28",
        )}
      >
        <div className="flex h-16 flex-row items-center justify-between bg-white">
          {isCurrentUserGuest ? (
            <LogoHorizontal className="block w-auto py-1" />
          ) : (
            <DirtyLink to="/dashboard" className="flex items-center gap-2">
              <LogoHorizontal className="block w-auto py-1" />
            </DirtyLink>
          )}

          {isCurrentUserReadOnly && (
            <span className="text-sm font-medium uppercase text-extra-dark-gray">
              {t("common.readOnly")}
            </span>
          )}

          <div className="flex h-[40px] flex-row items-center justify-center gap-3">
            {isCurrentUserGuest ? (
              <span className="font-medium text-extra-dark-gray">
                {t("common.welcome")} {currentUser?.name}
              </span>
            ) : (
              <button
                onClick={handleViewNotifications}
                type="button"
                aria-label="View notifications"
                className={clsx(
                  "pointer-events-none hidden rounded-full p-2 transition-all duration-200 ease-in-out disabled:pointer-events-none disabled:opacity-50",
                  isNotificationsSidebarOpen
                    ? "bg-steel-blue text-white"
                    : "bg-extra-light-blue text-dark-blue",
                )}
              >
                <Icon name="NotificationsIcon" className="h-6 w-6" />
                <span className="sr-only">View notifications</span>
              </button>
            )}
            <div className="relative">
              <div
                title={
                  t("common.connectionStatus") + `: ${signalRConnectionState}`
                }
                className={clsx(
                  "absolute -right-1 -top-1 z-10 h-2 w-2 rounded-full transition-all duration-200 ease-in-out",
                  signalRConnectionState === HubConnectionState.Connected &&
                    "bg-green",
                  signalRConnectionState === HubConnectionState.Disconnected &&
                    "bg-red",
                  signalRConnectionState === HubConnectionState.Reconnecting &&
                    "animate-ping bg-yellow",
                  signalRConnectionState === HubConnectionState.Connecting &&
                    "bg-green opacity-50",
                  signalRConnectionState === HubConnectionState.Disconnecting &&
                    "animate-ping bg-red",
                  !signalRConnectionState && "opacity-0",
                )}
              />

              <MenuButton
                label={
                  <div className="flex flex-row items-center justify-center gap-2 rounded-md border border-gray px-2">
                    <div className="flex flex-col items-start py-1">
                      <span>
                        {currentUser?.name} {currentUser?.surname}
                      </span>
                      <span className="text-xs text-dark-gray">
                        {currentUser?.email}
                      </span>
                    </div>
                    <div
                      aria-label="View notifications"
                      className={clsx(
                        "rounded-full bg-extra-light-blue text-dark-blue",
                        !avatar && "p-2",
                      )}
                    >
                      {avatar ? (
                        <img
                          src={avatar}
                          className="h-10 w-10 rounded-full border border-extra-light-blue object-cover"
                          alt="Avatar"
                        />
                      ) : (
                        <Icon name="UserIcon" className="h-6 w-6" />
                      )}
                    </div>
                  </div>
                }
                onAction={onAction}
              >
                <Section>
                  {menuItems.map((item) => (
                    <Item key={item.key}>{item.label}</Item>
                  ))}
                </Section>
              </MenuButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
