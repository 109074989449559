export enum TimespanOptionKey {
  LastHour = "lastHour",
  LastSixHours = "lastSixHours",
  LastTwentyFourHours = "lastTwentyFourHours",
  LastWeek = "lastWeek",
  LastTwoWeeks = "lastTwoWeeks",
  LastMonth = "lastMonth",
  Custom = "custom",
}

export default interface TimespanOption {
  label: string;
  key: TimespanOptionKey;
}
