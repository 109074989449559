import type { TableColumnResizeState } from "@react-stately/table";
import type { GridNode } from "@react-types/grid";
import React, { Key, ReactElement, RefObject } from "react";
import { useTableColumnResize, VisuallyHidden } from "react-aria";

interface ResizerProps<T> {
  column: GridNode<T>;
  layoutState: TableColumnResizeState<T>;
  onResizeStart?: (widths: Map<Key, number | string>) => void;
  onResize?: (widths: Map<Key, number | string>) => void;
  onResizeEnd?: (widths: Map<Key, number | string>) => void;
  triggerRef: RefObject<HTMLButtonElement>;
  showResizer: boolean;
}

function Resizer<T>(
  props: ResizerProps<T>,
  ref:
    | ((instance: HTMLInputElement | null) => void)
    | RefObject<HTMLInputElement>
    | null,
) {
  const {
    column,
    layoutState,
    onResizeStart,
    onResize,
    onResizeEnd,
    triggerRef,
    showResizer,
  } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { resizerProps, inputProps } = useTableColumnResize(
    {
      "aria-label": "Resizer",
      column,
      onResize,
      onResizeEnd,
      onResizeStart,
      triggerRef,
    },
    layoutState,
    ref && "current" in ref ? ref : inputRef,
  );
  const borderColor =
    layoutState.resizingColumn === column.key
      ? "border-[orange]"
      : "border-slate-800";
  const visiblity = showResizer ? "visible" : "invisible";

  return (
    <div
      role="presentation"
      className={`h-auto w-[6px] cursor-col-resize border-2 text-dark-blue transition-all duration-100 ease-in-out ${borderColor} box-border flex-[0_0_auto] touch-none ${visiblity}`}
      style={{
        borderStyle: "none solid",
        marginInlineStart: "4px",
      }}
      {...resizerProps}
    >
      <VisuallyHidden>
        <input
          ref={ref && "current" in ref ? ref : inputRef}
          type="range"
          {...inputProps}
        />
      </VisuallyHidden>
    </div>
  );
}

const ForwardRefResizer = React.forwardRef(Resizer) as <T>(
  props: ResizerProps<T> & {
    ref?:
      | ((instance: HTMLInputElement | null) => void)
      | RefObject<HTMLInputElement>;
  },
) => ReactElement;
export { ForwardRefResizer as Resizer };
