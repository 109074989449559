import React from "react";

import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import { Icon } from "../components/Icon";
import SettingSection from "../components/settings/SettingSection";
import useConfigurationSettings from "../hooks/useConfigurationSettings";

export const ConfigurationSettings: React.FC = () => {
  const {
    t,
    handleDownloadConfigurationFile,
    isLoading,
    userLicense,
    handleInputChange,
    isCurrentUserReadOnly,
    handleDownloadSetupAgent,
    showTooltip,
    hideTooltip,
  } = useConfigurationSettings();
  return (
    <SettingSection
      withoutPadding
      title={t("common.configurationSettings")}
      subtitle={t("common.configurationSettingsDescription")}
    >
      <div className="grid w-fit grid-cols-1 gap-8 px-8 pt-8">
        <div className="flex flex-col gap-4">
          <span className="text-dark-blue">
            {t("common.downloadAndInstall")} <strong>Remotegrant.exe</strong>
          </span>
          <div>
            <Button onPress={handleDownloadSetupAgent} isLoading={isLoading}>
              {t("common.downloadRemotegrant")}
            </Button>
          </div>
          <Checkbox
            isDisabled={isLoading}
            isReadOnly={isCurrentUserReadOnly}
            isSelected={userLicense.agent.update}
            onChange={(e) => handleInputChange("agent.update", e)}
          >
            <div className="flex items-center gap-2">
              <span className="text-extra-dark-gray">
                {t("common.keepAgentUpdated")}
              </span>
              <div
                onMouseEnter={(e) =>
                  showTooltip(t("common.keepAgentUpdatedDescription"), e, true)
                }
                onMouseLeave={hideTooltip}
              >
                <Icon name="InfoIcon" className="h-4 w-4" />
              </div>
            </div>
          </Checkbox>
          <Checkbox
            isDisabled={isLoading}
            isReadOnly={isCurrentUserReadOnly}
            isSelected={userLicense.agent.notify}
            onChange={(e) => handleInputChange("agent.notify", e)}
          >
            <div className="flex items-center gap-2">
              <span className="text-extra-dark-gray">
                {t("common.enableAgentNotification")}
              </span>
              <div
                onMouseEnter={(e) =>
                  showTooltip(
                    t("common.enableAgentNotificationDescription"),
                    e,
                    true,
                  )
                }
                onMouseLeave={hideTooltip}
              >
                <Icon name="InfoIcon" className="h-4 w-4" />
              </div>
            </div>
          </Checkbox>
        </div>
        <div className="flex flex-col gap-4">
          <span className="text-dark-blue">
            {t("common.useConfigurationFile")}
          </span>
          <div>
            <Button
              onPress={handleDownloadConfigurationFile}
              isLoading={isLoading}
            >
              {t("common.downloadConfigurationFile")}
            </Button>
          </div>
        </div>
      </div>
    </SettingSection>
  );
};
