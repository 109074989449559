import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOverlayTriggerState } from "react-stately";

import { useAuth } from "../contexts/AuthContext";
import { useDialogContext } from "../contexts/DialogContext";
import { NotificationTypes } from "../contexts/NotificationContext";
import { AccountService } from "../services/accountService";

import { useNotifications } from "./useNotifications";

interface FormState {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const accountService = new AccountService();

function useSecuritySettings() {
  const [passwordForm, setPasswordForm] = useState<FormState>({
    confirmNewPassword: "",
    newPassword: "",
    oldPassword: "",
  });
  const [activities] = useState<any[]>([
    {
      _action: true,
      browser: "User 1",
      id: 1,
      lastActivity: "2021-08-10 10:00:00",
    },
    {
      _action: true,
      browser: "User 2",
      id: 2,
      lastActivity: "2021-08-10 10:00:00",
    },
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditingPassword, setIsEditingPassword] = useState<boolean>(false);
  const { createNotification } = useNotifications();
  const { t } = useTranslation();
  const dialog = useDialogContext();
  const { currentUser, fetchCurrentUser } = useAuth();
  const authenticatorModalState = useOverlayTriggerState({});

  /**
   * Updates the password form state with the input value of a password input field.
   *
   * @returns {void}
   * @param field
   * @param value
   */
  const handlePasswordInputChange = (field: string, value: any): void => {
    setPasswordForm((prevForm) => ({ ...prevForm, [field]: value }));
  };

  /**
   * Handle the submission of password form.
   *
   * @param {React.FormEvent<HTMLFormElement>} e - The form event object.
   *
   * @returns {void}
   */
  const handlePasswordFormSubmit = (
    e: React.FormEvent<HTMLFormElement>,
  ): void => {
    e.preventDefault();
    setIsLoading(true);
    accountService
      .changePassword({ ...passwordForm })
      .then(() => {
        createNotification(
          t("notifications.genericUpdateSuccess", {
            resource: t("common.password"),
          }),
          NotificationTypes.SUCCESS,
        );
        setPasswordForm({
          confirmNewPassword: "",
          newPassword: "",
          oldPassword: "",
        });
        setIsEditingPassword(false);
      })
      .catch((error) => {
        if (error?.response?.errorCode === 7) {
          createNotification(
            t("validation.oldPasswordInvalid"),
            NotificationTypes.DANGER,
          );
        } else {
          createNotification(
            t("errors.genericUpdateError", {
              resource: t("common.password"),
            }),
            NotificationTypes.DANGER,
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Handles the removal of an authenticator.
   * Sets isLoading to true, then calls the removeAuthenticator method from the accountService.
   * If the removal succeeds, it creates a success notification.
   * If the removal fails, it creates a danger notification.
   * Finally, it sets isLoading to false.
   */
  const handleRemoveAuthenticator = () => {
    dialog
      .open(
        t("dialog.removeAuthenticator"),
        t("dialog.removeAuthenticatorMessage"),
      )
      .then(() => {
        setIsLoading(true);
        accountService
          .removeAuthenticator()
          .then(() => {
            createNotification(
              t("notifications.authenticatorRemoved"),
              NotificationTypes.SUCCESS,
            );
            fetchCurrentUser();
          })
          .catch(() => {
            createNotification(
              t("errors.authenticatorNotRemoved"),
              NotificationTypes.DANGER,
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch(() => {});
  };

  /**
   * Handles the removal of an authenticator.
   * Sets isLoading to true, then calls the removeAuthenticator method from the accountService.
   * If the removal succeeds, it creates a success notification.
   * If the removal fails, it creates a danger notification.
   * Finally, it sets isLoading to false.
   */
  const handleChangeStatusAuthenticator = () => {
    let needToEnable = true;

    if (currentUser?.twoStepAuth === 3) {
      needToEnable = false;
    }

    if (currentUser?.twoStepAuth === 0) {
      needToEnable = true;
    }

    dialog
      .open(
        needToEnable
          ? t("dialog.enableAuthenticator")
          : t("dialog.disableAuthenticator"),
        needToEnable
          ? t("dialog.enableAuthenticatorMessage")
          : t("dialog.disableAuthenticatorMessage"),
      )
      .then(() => {
        setIsLoading(true);
        accountService
          .changeAuthenticatorStatus(needToEnable)
          .then(() => {
            createNotification(
              needToEnable
                ? t("notifications.authenticatorEnabled")
                : t("notifications.authenticatorDisabled"),
              NotificationTypes.SUCCESS,
            );
            fetchCurrentUser();
          })
          .catch(() => {
            createNotification(
              t("errors.genericError"),
              NotificationTypes.DANGER,
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch(() => {});
  };
  /**
   * Opens the authenticator modal and generates an authenticator code.
   * Displays a success notification if the code is generated successfully,
   * or a danger notification if there is an error.
   * Finally, sets the isLoading state to false.
   */
  const handleOpenAuthenticatorModal = () => {
    authenticatorModalState.open();
  };

  /**
   * @description Calculates and returns an array of errors based on the values of `passwordForm.newPassword` and `passwordForm.oldPassword`.
   * @param {string} passwordForm.newPassword - The new password entered by the user.
   * @param {string} passwordForm.oldPassword - The old password entered by the user.
   * @returns {Array} An array of error messages. If there are no errors, an empty array is returned.
   */
  const passwordErrors = useMemo(() => {
    const errors: string[] = [];

    if (!passwordForm.newPassword && !passwordForm.oldPassword) {
      return errors;
    }

    if (!passwordForm.newPassword) {
      return errors;
    }

    if (passwordForm.newPassword.length < 8) {
      errors.push(t("validation.passwordLength"));
    }
    if (!passwordForm.newPassword.match(/[a-z]/g)) {
      errors.push(t("validation.passwordLowercase"));
    }
    if (!passwordForm.newPassword.match(/[A-Z]/g)) {
      errors.push(t("validation.passwordUppercase"));
    }
    if (!passwordForm.newPassword.match(/[0-9]/g)) {
      errors.push(t("validation.passwordNumber"));
    }
    if (!passwordForm.newPassword.match(/[^a-zA-Z\d]/g)) {
      errors.push(t("validation.passwordSpecialCharacter"));
    }
    if (
      passwordForm.newPassword &&
      passwordForm.newPassword === passwordForm.oldPassword
    ) {
      errors.push(t("validation.passwordDifferentOld"));
    }

    if (passwordForm.newPassword !== passwordForm.confirmNewPassword) {
      errors.push(t("validation.passwordDifferent"));
    }
    return errors;
  }, [
    t,
    passwordForm.confirmNewPassword,
    passwordForm.newPassword,
    passwordForm.oldPassword,
  ]);

  useEffect(() => {
    fetchCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    activities,
    authenticatorModalState,
    currentUser,
    fetchCurrentUser,
    handleChangeStatusAuthenticator,
    handleOpenAuthenticatorModal,
    handlePasswordFormSubmit,
    handlePasswordInputChange,
    handleRemoveAuthenticator,
    isEditingPassword,
    isLoading,
    passwordErrors,
    passwordForm,
    setIsEditingPassword,
    t,
  };
}

export default useSecuritySettings;
