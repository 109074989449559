import React, { useMemo, useState } from "react";

import { PolicyField } from "../../utils/policyFields";
import MultiSelectComboBox, {
  MultiselectComboboxOption,
} from "../MultiSelectComboBox";

interface SecurityPolicyInputMultiSelectProps {
  onChange: (newValue: any[]) => void;
  value?: MultiselectComboboxOption[];
  field: PolicyField;
  isLoading: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

export const SecurityPolicyInputMultiSelect: React.FC<
  SecurityPolicyInputMultiSelectProps
> = ({ onChange, value = [], field, isLoading, isDisabled, isReadOnly }) => {
  const [query, setQuery] = useState("");

  /**
   * @description Represents the filtered options based on the provided query.
   * @typedef {Array<Object>} filteredOptions
   * @property {string} value - The value of the option.
   * @property {string} label - The label of the option.
   *
   * @param {Object} field - The field object containing options.
   * @param {string} query - The query string to filter options.
   * @returns {filteredOptions} - The filtered options based on the query.
   */
  const filteredOptions = useMemo(() => {
    const regex = new RegExp(query.toLowerCase());
    return (
      field?.options?.filter((option) =>
        regex.test(option?.value?.toLowerCase()),
      ) || []
    );
  }, [field, query]);

  return (
    <MultiSelectComboBox
      isReadOnly={isReadOnly}
      id={field.id}
      label={field.label}
      useDebounceForSearch={false}
      isAllSelectable
      disabled={isLoading || isDisabled}
      isLoading={isLoading}
      aria-label={field.label}
      placeholder={field.placeholder}
      selectedObjects={value || []}
      onSelectionObjectChange={(selectedObjects) => {
        onChange(selectedObjects);
      }}
      items={filteredOptions}
      onSearch={setQuery}
    />
  );
};
