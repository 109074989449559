import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import packageJson from "../../../package.json";
import { useSignalR } from "../../contexts/SignalRContext";
import { SignalREventTypeEnum } from "../../enum/SignalREventTypeEnum";
import { StorageItemKeyEnum } from "../../enum/StorageItemKeyEnum";
import RouteNotification from "../../interfaces/RouteNotification";
import RouterPage from "../../interfaces/RouterPage";
import { routerPages } from "../../utils/routerPages";
import {
  getStorageItem,
  onStorageChange,
  storeStorageItem,
} from "../../utils/storage";
import DirtyLink from "../DirtyLink";
import { Icon } from "../Icon";
import Sidebar from "../Sidebar";

interface SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
}

const RoutesSidebar: React.FC<SidebarProps> = ({ isOpen, onToggle }) => {
  const location = useLocation();
  const [routesWithNotifications, setRoutesWithNotifications] = useState<
    RouteNotification[]
  >(() => {
    const storedData = getStorageItem(
      "sessionStorage",
      StorageItemKeyEnum.routesWithNotifications,
    );
    if (Array.isArray(storedData)) {
      return storedData;
    } else {
      return [];
    }
  });

  const { signalRConnection } = useSignalR();

  /**
   * Handles the click event for a link.
   *
   * @param {RouterPage} link - The link that was clicked.
   * @returns {void}
   */
  const handleLinkClick = (link: RouterPage): void => {
    if (
      link.key === "dashboard" &&
      routesWithNotifications?.some((route) => route.key === link.key)
    ) {
      setRoutesWithNotifications(
        routesWithNotifications?.filter((route) => route.key !== link.key) ||
          [],
      );
    }
  };

  onStorageChange((key, newValue) => {
    if (key === StorageItemKeyEnum.routesWithNotifications) {
      setRoutesWithNotifications(newValue || []);
      return;
    }
  });

  useEffect(() => {
    if (signalRConnection) {
      const handleReceiveMessage = (count: number) => {
        const updatedRoutes: RouteNotification[] = [...routesWithNotifications];

        if (
          location.pathname !== "/dashboard" &&
          !updatedRoutes.some((route) => route.key === "dashboard")
        ) {
          updatedRoutes.push({
            icon: "ErrorIcon",
            key: "dashboard",
          });
        }

        const activitiesIndex = updatedRoutes.findIndex(
          (route) => route.key === "activities",
        );
        if (count > 0) {
          if (activitiesIndex === -1) {
            /*updatedRoutes.push({
              count: count,
              key: "activities",
            });*/
          } else {
            updatedRoutes[activitiesIndex].count = count;
          }
        } else {
          if (activitiesIndex !== -1) {
            updatedRoutes.splice(activitiesIndex, 1);
          }
        }

        setRoutesWithNotifications(updatedRoutes);
      };

      signalRConnection.on(
        SignalREventTypeEnum.notifiedNewActivity,
        handleReceiveMessage,
      );

      return () => {
        signalRConnection.off(
          SignalREventTypeEnum.notifiedNewActivity,
          handleReceiveMessage,
        );
      };
    }
  }, [signalRConnection, routesWithNotifications, location.pathname]);

  useEffect(() => {
    storeStorageItem(
      "sessionStorage",
      StorageItemKeyEnum.routesWithNotifications,
      routesWithNotifications,
    );
  }, [routesWithNotifications]);

  return (
    <Sidebar isOpen={isOpen} onToggle={onToggle}>
      <ul role="list" className="flex flex-col space-y-8">
        {routerPages.map((link) => (
          <li
            key={link.key}
            aria-disabled={link.isDisabled}
            className={clsx(
              link.isDisabled && "pointer-events-none opacity-50",
            )}
          >
            <DirtyLink to={link.href} onClick={() => handleLinkClick(link)}>
              <div
                className={clsx(
                  "duration-400 relative flex flex-row items-center px-[1.35rem] py-4 text-pale-blue transition-all hover:bg-steel-blue hover:text-white",
                  location.pathname.startsWith(link.href) &&
                    "bg-steel-blue text-white",
                  isOpen ? "space-x-2" : "space-x-0",
                )}
              >
                <div
                  className={clsx(
                    "absolute right-2 top-2 z-10 flex h-4 w-4 rounded-full transition-all duration-200 ease-in-out",
                  )}
                >
                  {routesWithNotifications?.find((r) => r.key === link.key)
                    ?.icon && (
                    <Icon
                      name={
                        routesWithNotifications?.find((r) => r.key === link.key)
                          ?.icon || "ErrorIcon"
                      }
                      className="m-auto h-4 w-4 animate-pulse text-white"
                    />
                  )}

                  {routesWithNotifications?.find((r) => r.key === link.key)
                    ?.count && (
                    <span className="m-auto flex h-full w-full items-center justify-center rounded-full bg-white text-[0.5rem] text-dark-blue">
                      <span className="m-auto flex h-full w-full items-center justify-center rounded-full bg-white text-[0.5rem] text-dark-blue">
                        {(routesWithNotifications?.find(
                          (r) => r.key === link.key,
                        )?.count || 0) > 99
                          ? "99+"
                          : routesWithNotifications?.find(
                              (r) => r.key === link.key,
                            )?.count || 0}
                      </span>
                    </span>
                  )}
                </div>
                <Icon name={link.icon} className="m-auto" />
                <CSSTransition
                  in={isOpen}
                  unmountOnExit
                  timeout={{ enter: 0, exit: 250 }}
                  classNames={{
                    enter: "opacity-0 translate-x-0",
                    enterDone: "opacity-1 translate-x-2 transition ease-in",
                    exit: "opacity-0 translate-x-0 transition ease-out",
                  }}
                >
                  <span className="w-full truncate text-lg">{link.label}</span>
                </CSSTransition>
              </div>
            </DirtyLink>
          </li>
        ))}
      </ul>
      <div className="flex flex-1 items-end justify-center">
        <span className="text-center text-sm">v{packageJson.version}</span>
      </div>
    </Sidebar>
  );
};

export default RoutesSidebar;
